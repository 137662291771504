import { Box } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { policyDetailInfoTabs } from 'common/constants';
import BusinessInfoSection from 'components/QuotePolicyDetailEndorsement/BusinessInfoSection';
import Eligibility from 'components/QuotePolicyDetailEndorsement/Eligibility';
import ScrollToTop from 'components/ScrollToTop';
import { useFormik } from 'formik';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { createRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const BusinessInfo = forwardRef<any, any>((_props, _ref) => {
  const { t } = useTranslation();
  const { underwritingQuestions, underwritingQuestionsState, loading } = usePolicyDetail();
  const [state, setState] = useState<any>({ ...underwritingQuestionsState });

  const eligibilityRef = createRef<{ validateFormik: () => Promise<{}> }>();

  const businessInfoFields = useMemo(
    () =>
      (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find(
        (f) => f.code === policyDetailInfoTabs.BUSINESS_INFO.code,
      )?.fields ?? [],
    [underwritingQuestions],
  );

  const commonValidations = {
    field: yup.string().required(t('This field cannot be left blank.')),
  };

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema: yup.object({ ...commonValidations }),
    onSubmit: async (_values) => {},
  });

  const isFormsLoading = (underwritingQuestions.policy?.loading ?? true) || loading;

  const isReadOnly = true;

  useEffect(() => {
    setState(underwritingQuestionsState);
  }, [underwritingQuestionsState]);

  return isFormsLoading ? (
    <>{PreQualQuestionsLoading()}</>
  ) : (
    <>
      <Eligibility
        ref={eligibilityRef}
        parentFormik={formik}
        state={state}
        setState={setState}
        isReadOnly={isReadOnly}
        mustNotDisableSelectedDate
      />

      {businessInfoFields.map((f, i) => (
        <BusinessInfoSection
          key={f?.code}
          field={f}
          formik={formik}
          isFormsLoading={isFormsLoading}
          index={i}
          state={state}
          setState={setState}
          isReadOnly={isReadOnly}
        />
      ))}

      <Box sx={{ my: 2 }} />

      <ScrollToTop />
    </>
  );
});

export default BusinessInfo;
