/* eslint-disable no-restricted-syntax */
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import Head from 'components/Head';
import SettingsHeader from 'components/Settings/Header';
import SettingsTopActionBar from 'components/Settings/TopActionBar/SettingsTopActionBar';
import {
  detailPageGridContainerStyles,
  detailPageGridItemStyles,
  detailPageRowKeyStyles,
  detailPageRowValueStyles,
  detailPageStackStyles,
} from 'helpers/MuiSharedStyles';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Settings: FC = () => {
  const { t } = useTranslation();
  const tableRows = [
    { id: 1, key: 'first_name', displayName: t('First Name'), value: '' },
    { id: 2, key: 'last_name', displayName: t('Last Name'), value: '' },
    { id: 3, key: 'role', displayName: t('System Role'), value: '' },
    { id: 4, key: 'organization', displayName: t('Organization'), value: '' },
  ];

  const [rows, setRows] = useState(tableRows);

  const { data: user, loading } = useUser();

  useEffect(() => {
    if (!isEmpty(user)) {
      for (const [key, value] of Object.entries(user!)) {
        const findInRows = tableRows.find(({ key: rowKey }) => rowKey === key);

        if (findInRows) {
          if (key === 'role' || key === 'organization') {
            findInRows.value = value?.name;
          } else {
            findInRows.value = value;
          }
        }
      }
      setRows([...tableRows]);
    }
  }, [user]);

  return (
    <>
      <Head title={t('Settings')} />
      <SettingsTopActionBar />
      <SettingsHeader currentTab={0} />
      <Box
        sx={{
          ml: 3,
          mt: 3,
        }}
      >
        <Grid
          sx={[detailPageGridContainerStyles, { maxWidth: 480, marginTop: '0px !important' }]}
          container
          columns={{ xs: 1 }}
          rowSpacing={1}
        >
          {rows.map((row) => (
            <Grid key={row.id} item xs={1} md={1} sx={detailPageGridItemStyles}>
              <Stack sx={detailPageStackStyles} flexDirection="row">
                <Typography
                  sx={[
                    detailPageRowKeyStyles,
                    {
                      color: (theme) => theme.customColors.grey800,
                      marginRight: '6px',
                      minWidth: '142px',
                      maxWidth: '142px',
                    },
                  ]}
                >
                  {row.displayName}
                </Typography>
                <Typography sx={[detailPageRowValueStyles, { paddingLeft: '8px' }]}>
                  {loading ? (
                    <Skeleton animation="wave" height="20px" width={row.displayName.length * 15} />
                  ) : (
                    row.value ?? '-'
                  )}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Settings;
