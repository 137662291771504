import React, { useEffect, useState } from 'react';

// services
import { fetchAdvancedSearchConfig, fetchTimezoneConfig } from 'api/services/ConfigService';

/// types
import { IContextProps } from 'providers/types';

import useAuth from 'hooks/useAuth';

/// data
import { Industry } from 'api/models/Industries/industry.model';
import { EndorsementType } from 'api/models/Policy/Endorsements/endorsementType.model';
import { getKeyValuesService } from 'api/services/KeyValues';
import { getProductEndorsementTypes } from 'api/services/PolicyEndorsement';
import { getProducts } from 'api/services/ProductList';
import { defaultDateFormat, defaultTimezone, keyValueTables } from 'common/constants';
import { addMinutes, addYears } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { isEmpty, range } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { ConfigContext, initialConfigData } from './ConfigProviderContext';

export const ConfigProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialConfigData);
  const { auth, verifyLoading } = useAuth();
  const { t } = useTranslation();

  const resetConfigState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialConfigData,
    }));
  };

  const getTimezoneConfig = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        timezoneConfig: { loading: true },
      }));

      const res = await fetchTimezoneConfig();

      setState((prevState) => ({
        ...prevState,
        timezoneConfig: {
          loading: false,
          loaded: true,
          data: res ?? { ...prevState.timezoneConfig.data },
        },
      }));
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const getEndorsementTypes = async (product: string, policyLocator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        endorsementTypes: {
          [`${policyLocator}`]: {
            loading: true,
          },
        },
      }));

      let res: EndorsementType[];

      if (isEmpty(state.endorsementTypes?.[`${policyLocator}`]?.data)) {
        const query = { policy_locator: policyLocator };
        res = await getProductEndorsementTypes(product, query);
      } else {
        res = state.endorsementTypes?.[`${policyLocator}`]?.data ?? [];
      }

      setState((prevState) => ({
        ...prevState,
        endorsementTypes: {
          [`${policyLocator}`]: {
            loading: false,
            loaded: true,
            data: res,
          },
        },
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        endorsementTypes: {
          [`${policyLocator}`]: {
            loading: false,
            loaded: true,
          },
        },
      }));
      throw error;
    }
  };

  const getAdvancedSearchConfig = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        advancedSearchConfig: {
          ...prevState.advancedSearchConfig,
          loading: true,
        },
      }));

      const res = await fetchAdvancedSearchConfig();

      setState((prevState) => ({
        ...prevState,
        advancedSearchConfig: {
          loading: false,
          loaded: true,
          data: res,
        },
      }));
    } catch (error) {
      setState({
        ...state,
        advancedSearchConfig: {
          ...state.advancedSearchConfig,
          loading: false,
          loaded: true,
        },
      });
      throw error;
    }
  };

  const fetchIndustries = async () => {
    try {
      if (isEmpty(state.industries.data)) {
        setState((prevState) => ({
          ...prevState,
          industries: {
            ...prevState.industries,
            loading: true,
          },
        }));

        const industryData = (await getKeyValuesService('primarily-industry', {
          cache: true,
        })) as any;

        if (!industryData?.value || industryData?.value?.length === 0) {
          throw new Error();
        }

        setState((prevState) => ({
          ...prevState,
          industries: {
            ...prevState.industries,
            loading: false,
            loaded: true,
            data: (industryData?.value as Industry[]) ?? [],
          },
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        industries: {
          ...prevState.industries,
          loading: false,
          loaded: true,
        },
      }));
      displayBackendErrorMessage(
        error,
        t(
          'An error occurred while fetching the industries. If the problem persists, contact your support team.',
        ),
      );
    }
  };

  const fetchVintelligenceYears = () => {
    setState((prevState) => ({
      ...prevState,
      vintelligenceYears: {
        ...prevState.vintelligenceYears,
        loading: false,
        loaded: true,
        data: range(1961, new Date().getFullYear() + 1).reverse(),
      },
    }));
  };

  const fetchLocFilingIdTable = async () => {
    try {
      if (isEmpty(state.locFilingIdTable.data)) {
        setState((prevState) => ({
          ...prevState,
          locFilingIdTable: {
            ...prevState.locFilingIdTable,
            loading: true,
          },
        }));

        const table = (await getKeyValuesService(keyValueTables.LOC_FILINGS_TABLE, {
          cache: true,
        })) as any;

        setState((prevState) => ({
          ...prevState,
          locFilingIdTable: {
            ...prevState.locFilingIdTable,
            loading: false,
            loaded: true,
            data: table?.value ?? {},
          },
        }));
      }
    } catch (_error) {
      setState((prevState) => ({
        ...prevState,
        locFilingIdTable: {
          ...prevState.locFilingIdTable,
          loading: false,
          loaded: true,
        },
      }));
    }
  };

  const getProductsConfig = async () => {
    try {
      if (isEmpty(state.products.data)) {
        setState((prevState) => ({
          ...prevState,
          products: {
            ...prevState.products,
            loading: true,
          },
        }));

        const products = await getProducts();

        setState((prevState) => ({
          ...prevState,
          products: {
            ...prevState.products,
            loading: false,
            loaded: true,
            data: products,
          },
        }));
      }
    } catch (_error) {
      setState((prevState) => ({
        ...prevState,
        products: {
          ...prevState.products,
          loading: false,
          loaded: true,
        },
      }));
    }
  };

  const convertZonedTimeToUtc = (date: Date | string) =>
    zonedTimeToUtc(new Date(date), state.timezoneConfig?.data?.code ?? defaultTimezone);

  const formatDateInTimeZone = (
    dateString: string | Date | undefined,
    dateFormat = defaultDateFormat,
  ) => {
    if (dateString) {
      try {
        return formatInTimeZone(
          new Date(dateString),
          state.timezoneConfig?.data?.code ?? defaultTimezone,
          dateFormat,
        );
      } catch (_error) {
        return '-';
      }
    } else {
      return '-';
    }
  };

  const addYearsDstSafe = (date: Date, amount: number) => {
    const endDate = addYears(date, amount);
    return addMinutes(endDate, date.getTimezoneOffset() - endDate.getTimezoneOffset());
  };

  useEffect(() => {
    if (auth && !verifyLoading) {
      getTimezoneConfig();
      getProductsConfig();
      getAdvancedSearchConfig();
      fetchLocFilingIdTable();
    }
  }, [auth, verifyLoading]);

  return (
    <ConfigContext.Provider
      value={{
        ...state,
        getProductsConfig,
        getTimezoneConfig,
        getEndorsementTypes,
        resetConfigState,
        getAdvancedSearchConfig,
        fetchIndustries,
        fetchVintelligenceYears,
        fetchLocFilingIdTable,
        convertZonedTimeToUtc,
        formatDateInTimeZone,
        addYearsDstSafe,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
