import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threePolicyGroupNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { DrivingIncidentsDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/DrivingIncidentsDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

export interface DrivingIncidentDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isAdd?: boolean;
}

const DrivingIncidentsEditDrawer: FC<DrivingIncidentDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isAdd = false,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const INCIDENT = url.drivingIncident;
  const {
    fields: fieldConfig,
    fields: {
      policy: { loaded: fieldConfigLoaded },
    },
    data: quoteDetail,
    loaded: quoteDetailLoaded,
    groups,
    updateQuoteDetail,
  } = useQuoteDetail();
  const [state, setState] = useState<any>({});

  const drivers = useMemo(() => {
    const r =
      groups?.pol_drivers?.data?.map((driver) => ({
        ...driver,
        name: `${driver.pol_drivers_first || ''} ${driver.pol_drivers_last || ''}`,
        id: driver.pol_drivers_id,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const incidents: any[] = groups?.pol_incidents?.data ?? [];

  const incident = useMemo(
    () => incidents.find((inc) => inc.locator === INCIDENT) ?? {},
    [incidents, INCIDENT],
  );

  const { loading, setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['drivingIncident', 'addDrivingIncident'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (!loading && quoteDetailLoaded && isEmpty(incident) && !isAdd) {
      displayToastMessage('ERROR', t('Incident not found.'));
      handleQuery();
    }
  }, [incident, quoteDetailLoaded]);

  const fields = useMemo(() => {
    let tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])
        ?.find((con) => con.code === 'policy-details')
        ?.fields?.find((x) => x.code === threePolicyGroupNames.INCIDENTS)?.nested_fields ?? [];
    const choices = drivers.map((d) => ({ name: d.name, id: d.id }));

    tmpFields = tmpFields.map((f) => {
      if (f.code?.includes('driver')) {
        return { ...f, choices };
      }
      return f;
    });

    return tmpFields as DynamicField[];
  }, [fieldConfig, drivers]);

  const initialValues = useMemo(() => {
    const modifiedIncident = {
      ...incident,
      pol_incidents_driver: incident.pol_incidents_driver?.split(';')?.[0] ?? '',
    };
    return isAdd
      ? fields.reduce((res, f) => ({ ...res, [`${f.code}`]: '' }), {})
      : modifiedIncident;
  }, [fields, incident, isAdd]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!isAdd) {
      const newState = {
        ...incident,
        pol_incidents_type: incident.pol_incidents_type?.split(';'),
        pol_incidents_driver: incident.pol_incidents_driver?.split(';')?.[0] ?? '',
      };
      setState((prevState) => ({ ...prevState, ...newState }));
      formik.setValues({ ...newState, pol_incidents_type: incident.pol_incidents_type });
    }
  }, [incident, drivers]);

  const handleAdd = async () => {
    try {
      setLoading(true);

      const tmpIncidents = [...incidents];
      const newIncident = {
        ...formik.values,
        pol_incidents_driver: `${formik.values.pol_incidents_driver};${tmpIncidents.length}`,
      };
      tmpIncidents.push(newIncident);

      await updateQuoteDetail(
        quoteDetail?.policy_locator as string,
        quoteDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.INCIDENTS}`]: tmpIncidents,
          },
        },
        { validate: threePolicyGroupNames.INCIDENTS, strategy: 'create' },
      );

      displayToastMessage('SUCCESS', t('The incident has been added.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the incident.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      const tmpIncidents = [...incidents];
      const rowIndex = tmpIncidents.findIndex((row) => row.locator === incident.locator);
      const deletedIncidents = tmpIncidents.splice(rowIndex, 1);

      await updateQuoteDetail(
        quoteDetail?.policy_locator as string,
        quoteDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.INCIDENTS}`]: tmpIncidents,
            // Temporary data sending for backend development purposes #13059
            // remove or refactor when backend is ready
            [`deleted_${threePolicyGroupNames.INCIDENTS}`]: deletedIncidents,
          },
        },
        {
          validate: threePolicyGroupNames.INCIDENTS,
          strategy: 'delete',
          locator: incident.locator,
        },
      );

      displayToastMessage('SUCCESS', t('The incident has been deleted.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the incident.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const tmpIncidents = [...incidents];
      const rowIndex = tmpIncidents.findIndex((row) => row.locator === incident.locator);
      tmpIncidents[rowIndex] = {
        ...formik.values,
        pol_incidents_driver: `${formik.values.pol_incidents_driver};${rowIndex}`,
      };

      await updateQuoteDetail(
        quoteDetail?.policy_locator as string,
        quoteDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.INCIDENTS}`]: tmpIncidents,
          },
        },
        {
          validate: threePolicyGroupNames.INCIDENTS,
          strategy: 'update',
          locator: incident.locator,
        },
      );

      displayToastMessage('SUCCESS', t('The incident has been updated.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the incident.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();
    if (!formik.dirty && !isAdd) {
      return handleQuery();
    }

    if (isEmpty(errors)) {
      if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    }
    return {};
  };

  const showLoader = isAdd
    ? !quoteDetailLoaded || !fieldConfigLoaded
    : !quoteDetailLoaded || !fieldConfigLoaded || isEmpty(state);

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isContentScrollable
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        width="600px"
        onClose={handleQuery}
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Driving Incident Details')}
          </Typography>
        }
        content={
          <Stack sx={{ mb: 6, pt: 1 }}>
            <DrivingIncidentsDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              showLoader={showLoader}
              setState={setState}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
            />
          </Stack>
        }
        footer={
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {!isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_INCIDENT',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}
            <Stack direction="row" gap={1.5}>
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default DrivingIncidentsEditDrawer;
