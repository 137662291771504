/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PolicyDetailHeader from 'components/Policies/PolicyDetail/Header';
import PolicyDetailInfoTab from 'components/Policies/PolicyDetail/Info/Info';
import PolicyDetailTopActionBar from 'components/Policies/PolicyDetail/TopActionBar';
import PolicyEndorsementDetailHeader from 'components/Policies/PolicyEndorsementDetail/Header';
import PolicyEndorsementDetailTab from 'components/Policies/PolicyEndorsementDetail/Info/Info';
import PolicyEndorsementDetailTopActionBar from 'components/Policies/PolicyEndorsementDetail/TopActionBar';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

const PolicyDetail: FC = () => {
  const { id, endorsementId } = useParams<{ id: string; endorsementId: string }>();

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {endorsementId ? (
        <PolicyEndorsementDetailTopActionBar policyId={id} />
      ) : (
        <PolicyDetailTopActionBar policyId={id} />
      )}
      {endorsementId ? (
        <PolicyEndorsementDetailHeader currentTab={1} policyId={id} />
      ) : (
        <PolicyDetailHeader currentTab={1} policyId={id} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: endorsementId
            ? (theme) => `calc(100vh - ${theme.policyEndorsementDetailFixedItemsHeight})`
            : (theme) => `calc(100vh - ${theme.policyDetailFixedItemsHeight})`,
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {endorsementId ? <PolicyEndorsementDetailTab /> : <PolicyDetailInfoTab />}
        </Box>
      </Box>
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              background: (theme) => theme.customColors.activeField,
              color: (theme) => theme.customColors.white50,
              '&:hover': {
                background: (theme) => theme.customColors.activeField,
              },
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
    </>
  );
};

export default PolicyDetail;
