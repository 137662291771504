import { userRoles } from 'common/constants';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WCExposureScheduleModDetailDrawer from './Detail/WCExposureScheduleModDetailDrawer';

export interface IWCExposureScheduleModDrawer {
  isEdit: boolean;
}

const WCExposureScheduleModDrawer: FC<IWCExposureScheduleModDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isWCExposureScheduleModDrawerOpen, setIsWCExposureScheduleModDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    if (url?.tab === 'wc_exposures' && url?.schedule_mod) {
      setIsWCExposureScheduleModDrawerOpen(true);
    } else if (url?.tab === 'wc_exposures') {
      setIsWCExposureScheduleModDrawerOpen(false);
    }

    if (!isUnderwriter) {
      setIsWCExposureScheduleModDrawerOpen(false);
    }
  }, [LOCATION, isUnderwriter]);

  return isWCExposureScheduleModDrawerOpen ? (
    <WCExposureScheduleModDetailDrawer
      isDrawerOpen={isWCExposureScheduleModDrawerOpen}
      setIsDrawerOpen={setIsWCExposureScheduleModDrawerOpen}
      isEdit={isEdit}
    />
  ) : (
    <></>
  );
};
export default WCExposureScheduleModDrawer;
