import { Box } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { policyDetailInfoTabs } from 'common/constants';
import ScrollToTop from 'components/ScrollToTop';
import { useFormik } from 'formik';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import DetailAccordion from './Accordions/DetailAccordion';

const Operations = forwardRef<any, any>((_props, _ref) => {
  const { underwritingQuestionsState, underwritingQuestions, loading } = usePolicyDetail();

  const [state, setState] = useState<any>({
    ...underwritingQuestionsState,
  });

  const operationsFields = useMemo(
    () =>
      (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find(
        (f) => f.code === policyDetailInfoTabs.OPERATIONS.code,
      )?.fields ?? [],
    [underwritingQuestions],
  );

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: (_values) => {},
  });

  const isFormsLoading = (underwritingQuestions.policy?.loading ?? true) || loading;

  useEffect(() => {
    setState(underwritingQuestionsState);
  }, [underwritingQuestionsState]);

  return isFormsLoading ? (
    <>{PreQualQuestionsLoading()}</>
  ) : (
    <Box sx={{ m: -0.5 }}>
      {operationsFields.map((section) => (
        <DetailAccordion
          key={section.code}
          title={section.name ?? ''}
          formik={formik}
          state={state}
          setState={setState}
          fields={section.nested_fields ?? []}
          relationalFields={section?.relatedFields}
          isReadOnly
        />
      ))}

      <Box sx={{ my: 2 }} />

      <ScrollToTop />
    </Box>
  );
});

export default Operations;
