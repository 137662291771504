import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { GridAlignment, GridColDef, GridSortModel } from '@mui/x-data-grid';
import DataTable from 'components/DataTable';
import PageHeader from 'components/PageHeader';
import useDialog from 'hooks/useDialog';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import QuickActionMenu from 'components/Tasks/QuickActionMenu';
import TaskDetail from 'components/Tasks/TaskDetail';
import displayToastMessage from 'helpers/DisplayToastMessage';
import useTasks from 'hooks/useTasks';
import { debounce } from 'lodash-es';
import { Task } from 'api/models/Tasks/task.model';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultDebounceDelay,
  taskStatuses,
} from 'common/constants';
import Head from 'components/Head';
import ViewSearch from 'components/ViewSearch';
import useConfig from 'hooks/useConfig';
import { initialTasksData } from 'providers/TasksProvider/TasksProviderContext';
import qs from 'query-string';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const ClosedTasks: FC = () => {
  const { setDialogOpen } = useDialog();
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const themeHook = useTheme();
  const url = qs.parse(LOCATION.search);

  const {
    data: tasks,
    deleteTask,
    reFetchData,
    closeTask,
    addToQueue,
    fetch,
    loading,
    pagination,
    resetTasksState,
  } = useTasks();
  const { formatDateInTimeZone } = useConfig();
  const [selectedRowId, setSelectedRowId] = useState<number | string>(-1);

  const isDetailTaskDrawerOpen = url?.message !== undefined;
  const [pageNumber, setPage] = useState(0);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  useEffect(() => {
    fetch({ ...initialTasksData.pagination, status: 'closed' });

    return () => {
      resetTasksState();
    };
  }, []);

  const handleCloseTask = async (task: Task) => {
    const updatedTask = { status: taskStatuses.CLOSED.key };
    await closeTask(task.display_id!, updatedTask);
    displayToastMessage('SUCCESS', t('The message has been marked as closed.'));
  };

  const handleAssignToQueue = async (task: Task, id: number) => {
    const updatedTask = { task_queue: id };
    await addToQueue(task.display_id!, updatedTask);
    await reFetchData();
    displayToastMessage('SUCCESS', t('The message has been assigned to queue.'));
  };

  const handleDeleteTask = async (taskId: number | string) => {
    setDialogOpen({
      dialog: 'DELETE_TASK',
      isOpen: false,
    });
    await deleteTask(taskId);
    await reFetchData();

    displayToastMessage('SUCCESS', t('The message has been deleted.'));
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fetch({
      ...pagination,
      status: 'closed',
      page: 1,
      search: event.target.value,
    });
    setPage(0);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);

    if (newModel.length) {
      const [nm] = newModel;
      setPage(0);
      fetch({
        ...pagination,
        status: 'closed',
        page: 1,
        ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
      });
    } else {
      fetch({
        ...pagination,
        status: 'closed',
        ordering: '',
      });
    }
  };

  const columns: IColumns[] = [
    {
      name: 'title',
      display_name: t('Title'),
      minWidth: 120,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'button',
      display_name: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 40,
    },
    {
      name: 'reference__reference_type',
      display_name: t('Related to'),
      type: 'string',
      minWidth: 180,
      flex: 2,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
    },
    {
      name: 'assigned_to__full_name',
      display_name: t('Assigned to'),
      type: 'string',
      minWidth: 130,
      flex: 1,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'created_by__full_name',
      display_name: t('Created By'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 135,
      flex: 1,
    },
    {
      name: 'created_at',
      display_name: t('Opened at'),
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
    },
    {
      name: 'priority__value',
      display_name: t('Priority'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 110,
      flex: 1,
    },
  ];

  const columnsAllTasks: GridColDef[] = columns.map((field, i) => ({
    field: field.name,
    headerName: field.display_name,
    flex: field.flex ?? 1,
    minWidth: field.minWidth,
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (fieldValue === '-' && i !== 0 && field.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (i === 0) {
        const r = params.row as Task;

        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { message: r?.display_id! },
            })}`}
            underline="hover"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
              color: (theme) => theme.customColors.table.link,
            }}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.type === dataFieldTypes.ACTION) {
        const r = params.row as Task;

        return (
          <QuickActionMenu
            key={r.id}
            rowData={r}
            selectedRowId={selectedRowId}
            setSelectedRowId={setSelectedRowId}
            handleCloseTask={handleCloseTask}
            handleDeleteTask={handleDeleteTask}
            handleAssignToQueue={handleAssignToQueue}
          />
        );
      } else if (field.name === 'reference__reference_type') {
        const r = params.row as Task;
        let mainPath = '';

        switch (r.reference?.reference_link_type) {
          case 'policy':
            mainPath = `/policies/${r.reference.locator}/overview`;

            break;
          case 'policyholder':
            mainPath = `/policyholders/${r.reference.locator}/details`;

            break;
          case 'submission':
            mainPath = `/applications/submissions/${r.reference.locator}`;

            break;
          case 'quote':
            mainPath = `/quotes/${r.reference.locator}`;

            break;

          default:
            break;
        }

        return (
          <Link
            component={RouterLink}
            to={mainPath}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
            }}
          >
            <Stack
              sx={{
                flexDirection: {
                  sm: 'column',
                },
              }}
            >
              <Typography>{r.three_number || '-'}</Typography>
            </Stack>
          </Link>
        );
      } else if (field?.name === 'created_at') {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');
        return (
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                mr: 1,
              }}
            >
              {splitDate[0]}
            </Box>
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Box>
          </Box>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        return fieldValue && fieldValue !== '-' ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return fieldValue;
      }
    },
  }));

  return (
    <>
      <Head title={t('Closed Messages')} />
      {isDetailTaskDrawerOpen && <TaskDetail isDrawerOpen={isDetailTaskDrawerOpen} />}
      <PageHeader
        sx={{
          px: 3,
        }}
        left={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              sx={{
                userSelect: 'none',
                color: (theme) => theme.customColors.pageHeader.title,
                fontWeight: '500',
              }}
            >
              {t('Closed Messages')}
            </Typography>
          </Box>
        }
        right={<ViewSearch onChange={debounce(handleSearchInputChange, defaultDebounceDelay)} />}
      />

      <DataTable
        onRowClick={(r) => setSelectedRowId(r.id === selectedRowId ? '' : (r.id as string))}
        autoRowCellHeight
        rowHeight={75}
        loading={loading}
        rows={tasks! ?? []}
        columns={columnsAllTasks}
        paginationMode="server"
        focusedRowId={selectedRowId}
        columnVisibilityModel={createColumnVisibilityModel(columns)}
        paginationModel={{ page: pageNumber, pageSize: pagination.page_size! }}
        pageSizeOptions={[pagination.page_size!]}
        rowCount={pagination.total_count}
        onPageChange={(page) => {
          setPage(page);
          fetch({ ...pagination, status: 'closed', page: page + 1 });
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        hideFooterPagination={pagination.total_count! < pagination.page_size!}
        wrapperSx={{ mx: 3 }}
      />
    </>
  );
};

export default ClosedTasks;
