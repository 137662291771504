import { t } from 'i18next';

export const DIALOGS = {
  DELETE_AGENCY: {
    title: (): string => t('Delete Agency'),
    description: (): string => t('Are you sure you want to delete agency?'),
    cancel: { color: 'info', text: (): string => t('Cancel') },
    accept: { color: 'error', text: (): string => t('Delete') },
    icon: '',
  },
  DELETE_NOTE: {
    title: (): string => t('Delete Note'),
    description: (): string => t('Are you sure you want to delete this note?'),
    cancel: { color: 'info', text: (): string => t('Cancel') },
    accept: { color: 'error', text: (): string => t('Delete') },
    icon: '',
  },
  DELETE_DOCUMENT: {
    title: (): string => t('Delete Document'),
    description: (): string => t('Are you sure you want to delete this document?'),
    cancel: { color: 'info', text: (): string => t('Cancel') },
    accept: { color: 'error', text: (): string => t('Delete') },
    icon: '',
  },
  DELETE_TASK: {
    title: (): string => t('Delete Message'),
    description: (): string => t('Are you sure you want to delete this message?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_LOCATION_EXPOSURE: {
    title: (): string => t('Delete Location'),
    description: (): string => t('Are you sure you want to delete this location?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_LIABILITY_PERIL: {
    title: (): string => t('Delete Liability Peril'),
    description: (): string => t('Are you sure you want to delete this peril?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_CLASS_CODE: {
    title: (): string => t('Delete Class Code'),
    description: (): string => t('Are you sure you want to delete this class code?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_VEHICLE_EXPOSURE: {
    title: (): string => t('Delete Vehicle'),
    description: (): string => t('Are you sure you want to delete this vehicle?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_PROPERTY_EXPOSURE: {
    title: (): string => t('Delete Property'),
    description: (): string => t('Are you sure you want to delete this property?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_OWNER: {
    title: (): string => t('Delete Owner'),
    description: (): string => t('Are you sure you want to delete this owner?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_ADDITIONAL_INTEREST: {
    title: (): string => t('Delete Additional Interest'),
    description: (): string => t('Are you sure you want to delete this additional interest?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_OFFICER: {
    title: (): string => t('Delete Officer'),
    description: (): string => t('Are you sure you want to delete this officer?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_PARTNER: {
    title: (): string => t('Delete Partner'),
    description: (): string => t('Are you sure you want to delete this partner?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_LLC_MEMBER: {
    title: (): string => t('Delete LLC Member'),
    description: (): string => t('Are you sure you want to delete this LLC member?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  DELETE_CLAIM: {
    title: (): string => t('Delete Claim'),
    description: (): string => t('Are you sure you want to delete this claim?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  INTEGRATION_RUNNING: {
    title: (): string => t('Integration is running'),
    description: (): string => t('Please wait for the integration results before saving.'),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },

  PRIMARY_LOCATION_DELETE: {
    title: (): string => t('Location is Primary'),
    description: (): string =>
      t(
        'You cannot delete primary location. To delete this location, you need to assign primary status to another location first.',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },

  PRIMARY_OWNER_DELETE: {
    title: (): string => t('Owner is Primary'),
    description: (): string =>
      t(
        'You cannot delete primary owner. To delete this owner, you need to assign primary status to another owner first.',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },
  PRIMARY_OWNER_DELETE_ENDORSEMENT: {
    title: (): string => t('Owner is Primary'),
    description: (): string => t('You cannot delete primary owner.'),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },
  MAILING_LOCATION_DELETE: {
    title: (): string => t('Location is Mailing'),
    description: (): string =>
      t(
        'You cannot delete mailing location. To delete this location, you need to assign mailing status to another location first.',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },

  CLONE_QUOTE: {
    title: (): string => t('Clone Submission'),
    description: (): string => t('Are you sure you want to Clone this Quote?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Clone') },
    icon: 'info',
  },
  DISCARD_QUOTE: {
    title: (): string => t('Discard Quote'),
    description: (): string => t('Are you sure you want to discard this quote?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Discard') },
    icon: 'alert',
  },
  SEND_BACK_QUOTE: {
    title: (): string => t('Send Back Submission'),
    description: (): string => t('Are you sure you want to send this submission back to Producer?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Send Back') },
    icon: 'sendBack',
    maxWidth: 500,
  },
  DECLINE_QUOTE: {
    title: (): string => t('Decline Quote'),
    description: (): string => t('Are sure you want to decline this quote?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Decline') },
    icon: 'decline',
  },
  DISCARD_ENDORSEMENT: {
    title: (): string => t('Discard Endorsement'),
    description: (): string => t('Are you sure you want to discard this endorsement?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Discard') },
    icon: 'alert',
  },
  SEND_BACK_ENDORSEMENT: {
    title: (): string => t('Send Back Endorsement'),
    description: (): string =>
      t('Are you sure you want to send this endorsement back to Producer?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Send Back') },
    icon: 'sendBack',
    maxWidth: 520,
  },
  LOCK_QUOTE: {
    title: (): string => t('Confirm Quote Generation'),
    description: (): string =>
      t(
        "You are about to generate a Quote Letter. Please note:\n\n1. You may access the Quote Letter from the Documents section in order that you can share with your customer.\n2. Generating the Quote Letter will {{variable}} this quote, preventing any further changes.\n3. You may 'Clone' the locked quote into a new submission to make edits and/or present additional options.",
        { variable: '*lock*' },
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Lock Quote') },
    icon: 'info',
  },
  REFER_QUOTE: {
    title: (): string => t('Refer'),
    description: (): string =>
      t(
        'Are sure you want to refer this submission? You will be locked from making further changes',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Refer') },
    icon: 'info',
  },
  REFER_ENDORSEMENT: {
    title: (): string => t('Refer'),
    description: (): string =>
      t(
        'Are sure you want to refer this policy changes? You will be locked from making further changes',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Refer') },
    icon: 'info',
  },
  REFER_QUOTE_UW: {
    title: (): string => t('Refer'),
    description: (): string => t('Are sure you want to refer this submission?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Refer') },
    icon: 'info',
  },
  REFER_ENDORSEMENT_UW: {
    title: (): string => t('Refer'),
    description: (): string => t('Are sure you want to refer this policy changes?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Refer') },
    icon: 'info',
  },
  SUBMIT_PREQUAL_QUOTE: {
    title: (): string => t('Submit the Pre-Qual'),
    description: (): string => t('Are you sure you want to submit this Pre-Qual?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Submit') },
    icon: 'info',
  },
  QUOTE_ENDORSEMENT: {
    title: (): string => t('Quote Policy Change'),
    description: (): string =>
      t(
        'Are you sure you want to quote this policy change? You will be locked from making further changes.',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Quote') },
  },
  ISSUE_ENDORSEMENT: {
    title: (): string => t('Issue Policy Change'),
    description: (): string => t('Are sure you want to issue this policy change?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Issue') },
  },
  DECLINE_ENDORSEMENT: {
    title: (): string => t('Decline Policy Change'),
    description: (): string => t('Are sure you want to decline this policy change referral?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Decline') },
    icon: 'decline',
  },
  ISSUE_REINSTATEMENT: {
    title: (): string => t('Confirm Force Reinstate'),
    description: (): string => t('Are you sure you want to force reinstate this Cancellation?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Issue Reinstate') },
  },
  RESCIND_CANCELLATION: {
    title: (): string => t('Confirm Rescind Cancellation'),
    description: (): string => t('Are you sure you want to rescind this Cancellation?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Rescind') },
  },
  ISSUE_CANCELLATION: {
    title: (): string => t('Confirm Issue Cancellation'),
    description: (): string => t('Are you sure you want to issue this Cancellation?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Issue') },
  },
  ISSUE_QUOTE: {
    title: (): string => t('Issue Quote'),
    description: (): string =>
      t('Are you sure you want to issue this quote and convert it to a policy?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogInfo', text: (): string => t('Issue') },
    icon: 'info',
  },
  INVALIDATE_QUOTE: {
    title: (): string => t('Invalidate Quote'),
    description: (): string => t('Are you sure you want to invalidate this quote?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Invalidate') },
    icon: 'alert',
  },
  DELETE_QUEUE: {
    title: (): string => t('Delete Message'),
    description: (): string => t('Are you sure you want to delete this message?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  CHANGE_UNSAVEDPAGE: {
    title: (): string => t('Unsaved Changes'),
    description: (): string =>
      t('You have unsaved changes. Are you sure you want to leave this page?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Leave') },
    icon: 'alert',
  },

  DELETE_QUEUE_CONFIG: {
    title: (): string => t('Delete Queue'),
    description: (): string => t('Are you sure you want to delete this queue?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  DELETE_VEHICLE: {
    title: (): string => t('Delete Vehicle'),
    description: (): string => t('Are you sure you want to delete this vehicle?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  DELETE_DRIVER: {
    title: (): string => t('Delete Driver'),
    description: (): string =>
      t('Are you sure you want to delete this driver and related driving incidents?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },
  DELETE_INCIDENT: {
    title: (): string => t('Delete Incident'),
    description: (): string => t('Are you sure you want to delete this incident?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  EDIT_MODE_ON: {
    title: (): string => t('Unsaved Changes'),
    description: (): string =>
      t('You are editing a record already, you cannot edit or add a record without saving.'),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    // accept: { color: 'dialogAlert', text: (): string => t('Delete') },
    icon: 'alert',
  },

  TABLE_ON_LOADING: {
    title: (): string => t('Table is Loading'),
    description: (): string =>
      t('The table is loading you cannot add a record while the table is loading.'),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    // accept: { color: 'dialogAlert', text: (): string => t('DELETE') },
    icon: 'alert',
  },

  // #upload document

  DISCARD_UPLOAD_DOCUMENT: {
    title: (): string => t('Upload in Progress'),
    description: (): string => t('Are you sure you want to leave this page?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Leave') },
    icon: 'alert',
  },

  // #upload document

  DISCARD_UPLOAD_DOCUMENT_FORM: {
    title: (): string => t('Unsaved Changes'),
    description: (): string =>
      t('You have not uploaded the document. Do you still wish to continue to the next step?'),
    cancel: { color: 'dialogCancel', text: (): string => t('CANCEL') },
    accept: { color: 'dialogAlert', text: (): string => t('CONTINUE') },
    icon: 'alert',
  },

  REVERSE_PAYMENT: {
    title: (): string => t('Reverse Payment'),
    description: (): string => t('Are you sure you want to reverse this payment?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Reverse') },
    icon: 'alert',
  },

  LOADING_SUBMISSIONS: {
    title: (): string => t('Loading submissions'),
    description: (): string =>
      t('Please wait for the current filter results before changing the filter type.'),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },

  LOADING_POLICIES: {
    title: (): string => t('Loading policies'),
    description: (): string =>
      t('Please wait for the current filter results before changing the filter type.'),
    cancel: { color: 'dialogCancel', text: (): string => t('OK') },
    icon: 'alert',
  },

  REFRESH_ERC_BUTTON: {
    title: (): string => t('Refresh Replacement Cost Estimate'),
    description: (): string =>
      t(
        'Click the refresh icon to update the Estimated Replacement Cost based on recent changes to building characteristics.',
      ),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('OK') },
    icon: 'alert',
  },

  DEACTIVATE_USER: {
    title: (): string => t('Deactivate User'),
    description: (): string => t('Are you sure you want to deactivate this user?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('OK') },
    icon: 'info',
  },

  REACTIVATE_USER: {
    title: (): string => t('Reactivate User'),
    description: (): string => t('Are you sure you want to reactivate this user?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('OK') },
    icon: 'info',
  },

  OVERRIDE_DECLINE_RULE: {
    title: (): string => t('Override Decline Rule'),
    description: (): string => t('Are you sure you want to override this declination?'),
    cancel: { color: 'dialogCancel', text: (): string => t('Cancel') },
    accept: { color: 'dialogAlert', text: (): string => t('Override') },
    icon: 'sendBack',
  },

};

// #endregion
