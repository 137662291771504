import React, { useState } from 'react';
/// types
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { IContextProps } from 'providers/types';

/// data
import { Task } from 'api/models/Tasks/task.model';
import { TaskCreateUpdate } from 'api/models/Tasks/taskCreateUpdate.model';
import {
  deleteTaskService,
  getAllTasks,
  getCurrentUserTasks,
  getTask,
  updateTaskService,
} from 'api/services/Tasks';
import { AxiosError } from 'axios';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { IAxiosError } from 'types/ErrorResponseTypes';
import { initialTasksData, TasksContext } from './TasksProviderContext';
import { ITaskPagination } from './types';

export const TasksProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialTasksData);

  const resetTasksState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialTasksData,
    }));
  };

  const fetch = async (query: ITaskPagination): Promise<Task[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      let res;

      // eslint-disable-next-line no-useless-catch
      try {
        res =
          query.source && query.source === 'me'
            ? await getCurrentUserTasks({
                ...query,
                page_size: initialTasksData.pagination.page_size,
              })
            : await getAllTasks({ ...query, page_size: initialTasksData.pagination.page_size });
      } catch (error) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          if (e.response?.status === 404) {
            res =
              query.source && query.source === 'me'
                ? await getCurrentUserTasks({
                    ...query,
                    page_size: initialTasksData.pagination.page_size,
                    page: 1,
                  })
                : await getAllTasks({
                    ...query,
                    page_size: initialTasksData.pagination.page_size,
                    page: 1,
                  });
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res?.results,
        pagination: {
          ...query,
          total_count: res?.count,
          page_size: prevState.pagination.page_size,
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      throw error;
    }
  };

  const fetchTask = async (id: string): Promise<Task | undefined> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeTask: { ...prevState.activeTask, loading: true },
      }));

      const res = await getTask(id);

      setState((prevState) => ({
        ...prevState,
        activeTask: {
          ...prevState.activeTask,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        activeTask: { ...prevState.activeTask, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const updateSelectedTaskRow = (ids: GridRowSelectionModel): void => {
    const selectedRowData = state.data!.find((row) => row.id === ids[0]);
    setState((prevState) => ({
      ...prevState,
      selectedTaskRow: selectedRowData,
    }));
  };

  const updateTask = async (id: number | string, data: TaskCreateUpdate) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const res = await updateTaskService(id, data);

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      const e = error as IAxiosError;
      if (e.isAxiosError) {
        if (e.response?.data?.messages && e.response?.data?.messages[0]) {
          displayToastMessage('ERROR', e.response?.data?.messages[0]);
        }
      }
      throw error;
    }
  };

  const addToQueue = async (id: number | string, data: TaskCreateUpdate) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const res = await updateTaskService(id, data);

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      const e = error as IAxiosError;
      if (e.isAxiosError) {
        if (e.response?.data?.messages && e.response?.data?.messages[0]) {
          displayToastMessage('ERROR', e.response?.data?.messages[0]);
        }
      }
      throw error;
    }
  };

  const closeTask = async (id: number | string, data: TaskCreateUpdate) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await updateTaskService(id, data);

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      throw error;
    }
  };

  const reFetchData = async () => {
    await fetch(state.pagination);
  };

  const deleteTask = async (id: number | string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      await deleteTaskService(id as number);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      throw error;
    }
  };


  return (
    <TasksContext.Provider
      value={{
        ...state,
        fetch,
        updateSelectedTaskRow,
        fetchTask,
        updateTask,
        deleteTask,
        reFetchData,
        resetTasksState,
        closeTask,
        addToQueue,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
