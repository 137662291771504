import { Box, BoxProps, Skeleton, Stack, useTheme } from '@mui/material';
import {
  policyDetailEndorsementStatuses,
  policyDetailEndorsementTabs,
  productCodes,
  threeExposureNames,
  THREE_EndorsementTypes,
  userRoles,
} from 'common/constants';
import Head from 'components/Head';
import SkeletonList from 'components/SkeletonList';
import StatusBox from 'components/StatusBox';
import TextBadge from 'components/TextBadge/TextBadge';
import VerticalTabs from 'components/VerticalTabs';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import { useBlockRoute } from 'helpers/RouteBlocker';
import useConfig from 'hooks/useConfig';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useRouterPrompt from 'hooks/useRouterPrompt/useRouterPrompt';
import useUser from 'hooks/useUser';
import { cloneDeep, isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// #region Tabs
import AdditionalInterests from './Tabs/AdditionalInterests';
import AutoExposures from './Tabs/AutoExposures';
import BusinessInfo from './Tabs/BusinessInfo';
import ClaimsHistory from './Tabs/ClaimsHistory';
import Drivers from './Tabs/Drivers';
import LiabilityExposures from './Tabs/LiabilityExposures';
import Locations from './Tabs/Locations';
import Operations from './Tabs/Operations';
import OwnersMembers from './Tabs/OwnersMembers';
import PricingBreakdown from './Tabs/PricingBreakdown';
import PropertyExposures from './Tabs/PropertyExposures';
import SummaryPricing from './Tabs/SummaryPricing';
import UWResults from './Tabs/UWResults';
import WCExposures from './Tabs/WCExposures';

// #endregion

const tabs = [...Object.values(policyDetailEndorsementTabs).map((v) => v)];

const PolicyEndorsementDetailTab: FC = () => {
  const { formatDateInTimeZone } = useConfig();
  const importedTheme = useTheme();
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id, endorsementId } = useParams<{ id: string; endorsementId: string }>();
  const { tab } = qs.parse(LOCATION.search) as { tab: string };
  const {
    loaded,
    getExposures,
    getFieldConfig,
    data: endorsementDetail,
    getUnderwritingQuestions,
    exposureList,
    canEdit,
  } = useEndorsementDetail();
  const { data: user } = useUser();
  const { setWhen } = useRouterPrompt();

  const businessInfoRef = useRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();
  const operationsRef = useRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();
  const propertyExposuresRef = useRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();
  const wcExposuresRef = useRef<{ validateWCExposures: (bool: boolean) => Promise<{}> }>();
  const summaryPricingRef = useRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();

  const detailLoadingSkeleton = (
    <Box>
      <Box
        sx={{
          ml: 3,
          pr: 5,
          mt: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Stack gap={1} direction="column">
            <Skeleton animation="wave" width="30%" height={28} sx={{ maxWidth: '200px' }} />
            <Skeleton animation="wave" width="10%" height={28} sx={{ maxWidth: '80px' }} />
          </Stack>
        </Box>
      </Box>

      <Stack gap={1} direction="row" sx={{ width: '100%', mx: 2.3, mt: 1 }}>
        <Box sx={{ width: '190px' }}>
          <SkeletonList
            count={15}
            width="100%"
            height={36}
            sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
          />
        </Box>

        <Box sx={{ width: '100%', ml: 3, mt: 1 }}>{PreQualQuestionsLoading()}</Box>
      </Stack>
    </Box>
  );

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`];
  const isDriverTabHidden = (exposureList?.[`${threeExposureNames.VEHICLE}`].data?.length || 0) > 5;

  const fetchExposures = () => {
    setTimeout(() => {
      getExposures(id, endorsementId);
    }, 100);
  };

  const endorsementTabs = useMemo(
    () =>
      tabs
        .map((currTab) => {
          const tmpTab = cloneDeep(currTab);

          if (
            currTab.code === policyDetailEndorsementTabs.PRICING_BREAKDOWN.code &&
            user?.role?.code !== userRoles.UNDERWRITER.code
          ) {
            tmpTab.disabled = true;
          }

          // unhide liablity tab for underwriters
          if (
            policyDetailEndorsementTabs.LIABILITY_EXPOSURES.code === currTab.code &&
            user?.role?.code === userRoles.UNDERWRITER.code
          ) {
            tmpTab.hidden = false;
          }

          // hide Pricing Breakdown tab for producers
          if (
            policyDetailEndorsementTabs.PRICING_BREAKDOWN.code === currTab.code &&
            user?.role?.code === userRoles.AGENT.code
          ) {
            tmpTab.hidden = true;
          }

          // Display Workers’ Comp tab for three_with_workers_compensation product
          if (
            policyDetailEndorsementTabs.WC_EXPOSURES.code === currTab.code &&
            endorsementDetail?.policy?.product?.code ===
              productCodes.THREE_WITH_WORKERS_COMPENSATION
          ) {
            tmpTab.hidden = false;
          }

          // Hide Drivers Tab when more than 5 vehicles added
          if (currTab.code === policyDetailEndorsementTabs.DRIVERS.code && isDriverTabHidden) {
            tmpTab.hidden = true;
          }

          if (
            endorsementDetail?.endorsement_type?.code ===
              THREE_EndorsementTypes.ADDITIONAL_INTEREST &&
            currTab.code !== policyDetailEndorsementTabs.ADDITIONAL_INTEREST.code &&
            currTab.code !== policyDetailEndorsementTabs.UW_RESULTS.code
          ) {
            tmpTab.disabled = true;
          }

          if (
            endorsementDetail?.endorsement_type?.code === THREE_EndorsementTypes.WC_AUDIT &&
            ![
              policyDetailEndorsementTabs.WC_EXPOSURES.code,
              policyDetailEndorsementTabs.OWNERS_MEMBERS.code,
              policyDetailEndorsementTabs.UW_RESULTS.code,
              policyDetailEndorsementTabs.SUMMARY_PRICING.code,
              policyDetailEndorsementTabs.PRICING_BREAKDOWN.code,
            ].includes(currTab.code)
          ) {
            tmpTab.disabled = true;
          }

          return tmpTab;
        })
        .filter((ta) => !ta.hidden),
    [tabs, user, endorsementDetail, isDriverTabHidden],
  );

  const findTabWithIndex = (index: number) => endorsementTabs[index];

  const findIndexInTabs = (step: string) => {
    const found = endorsementTabs.findIndex((s) => s.code === step && !s.disabled);

    return found === -1 ? 0 : found;
  };

  const [activeTab, setActiveTab] = useState(findIndexInTabs(tab));

  const defaultTab = useMemo(() => {
    let tb = { code: policyDetailEndorsementTabs.BUSINESS_INFO.code, index: 0 };

    switch (endorsementDetail?.endorsement_type?.code) {
      case THREE_EndorsementTypes.WC_AUDIT:
        tb = {
          code: policyDetailEndorsementTabs.WC_EXPOSURES.code,
          index: findIndexInTabs(policyDetailEndorsementTabs.WC_EXPOSURES.code),
        };
        break;

      case THREE_EndorsementTypes.ADDITIONAL_INTEREST:
        tb = {
          code: policyDetailEndorsementTabs.ADDITIONAL_INTEREST.code,
          index: findIndexInTabs(policyDetailEndorsementTabs.ADDITIONAL_INTEREST.code),
        };
        break;

      default:
        break;
    }

    return tb;
  }, [endorsementDetail, endorsementTabs]);

  useEffect(() => {
    setWhen(true);
  }, []);

  // Set active tab when change endorsement tabs
  useEffect(() => {
    setActiveTab(findIndexInTabs(tab) ?? defaultTab?.index);
  }, [endorsementTabs]);

  // Change query to the default tab. if the tab is not in the tabs list.
  useEffect(() => {
    const activeEndorsementTab = defaultTab?.code;

    if (findIndexInTabs(tab) === 0) {
      HISTORY.replace({
        search: `tab=${activeEndorsementTab}`,
      });
    }
  }, [defaultTab, isDriverTabHidden]);

  useEffect(() => {
    fetchExposures();
  }, []);

  // to scroll on tab change
  useEffect(() => {
    const el = document?.getElementById('scroll-anchor');
    // Scroll to top on load
    setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  }, [activeTab]);

  // Get exposure field configs
  useEffect(() => {
    if (!isEmpty(endorsementDetail?.policy?.product?.code)) {
      const productCode = endorsementDetail?.policy?.product?.code!;
      const policyLocator = endorsementDetail?.policy?.locator;
      const query = { policy_locator: policyLocator };

      getUnderwritingQuestions(productCode, 'policy', undefined, query);
      getFieldConfig(productCode, 'exposure', undefined, query);
      getFieldConfig(productCode, 'policy', undefined, query);
      getFieldConfig(productCode, 'peril', threeExposureNames.EMPLOYER, query);
      getFieldConfig(productCode, 'peril', threeExposureNames.LOCATION, query);
    }
  }, [endorsementDetail?.policy?.product?.code]);

  const handleTabChangeSave = async (currentTab: number) => {
    if (canEdit) {
      // when chaning tab from business info or operations, save the page informations
      switch (currentTab) {
        case findIndexInTabs(policyDetailEndorsementTabs.BUSINESS_INFO.code): {
          await businessInfoRef?.current?.savePageInfo(false);
          break;
        }

        case findIndexInTabs(policyDetailEndorsementTabs.OPERATIONS.code): {
          await operationsRef?.current?.savePageInfo(false);
          break;
        }

        case findIndexInTabs(policyDetailEndorsementTabs.PROPERTY_EXPOSURES.code): {
          await propertyExposuresRef?.current?.savePageInfo(false);
          break;
        }

        case findIndexInTabs(policyDetailEndorsementTabs.SUMMARY_PRICING.code): {
          await summaryPricingRef?.current?.savePageInfo(false);
          break;
        }

        case findIndexInTabs(policyDetailEndorsementTabs.WC_EXPOSURES.code): {
          await wcExposuresRef?.current?.validateWCExposures(false);
          break;
        }

        default:
          break;
      }
    }
  };

  const updateTab = async (currentTab: number, nextTab: string) => {
    try {
      if (
        nextTab &&
        findTabWithIndex(currentTab)?.code !== nextTab &&
        loaded &&
        endorsementDetail?.endorsement_type?.code === THREE_EndorsementTypes.GENERIC
      ) {
        await handleTabChangeSave(currentTab);
      }

      setActiveTab(findIndexInTabs(nextTab) ?? defaultTab?.index);
      return null;
    } catch (_error) {
      return null;
    }
  };

  useEffect(() => {
    updateTab(activeTab, tab);
  }, [tab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    HISTORY.push({ search: `?tab=${findTabWithIndex(newValue)?.code ?? ''}` });
  };

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case findIndexInTabs(policyDetailEndorsementTabs.BUSINESS_INFO.code):
        return <BusinessInfo ref={businessInfoRef} />;

      case findIndexInTabs(policyDetailEndorsementTabs.OPERATIONS.code):
        return <Operations ref={operationsRef} />;

      case findIndexInTabs(policyDetailEndorsementTabs.LIABILITY_EXPOSURES.code):
        return user?.role?.code === userRoles.UNDERWRITER.code ? <LiabilityExposures /> : <></>;

      case findIndexInTabs(policyDetailEndorsementTabs.PROPERTY_EXPOSURES.code):
        return <PropertyExposures ref={propertyExposuresRef} />;

      case findIndexInTabs(policyDetailEndorsementTabs.LOCATIONS.code):
        return <Locations />;

      case findIndexInTabs(policyDetailEndorsementTabs.DRIVERS.code):
        return <Drivers />;

      case findIndexInTabs(policyDetailEndorsementTabs.WC_EXPOSURES.code):
        return <WCExposures ref={wcExposuresRef} />;

      case findIndexInTabs(policyDetailEndorsementTabs.AUTO_EXPOSURES.code):
        return <AutoExposures />;

      case findIndexInTabs(policyDetailEndorsementTabs.OWNERS_MEMBERS.code):
        return <OwnersMembers />;

      case findIndexInTabs(policyDetailEndorsementTabs.CLAIMS_HISTORY.code):
        return <ClaimsHistory />;

      case findIndexInTabs(policyDetailEndorsementTabs.ADDITIONAL_INTEREST.code):
        return <AdditionalInterests />;

      case findIndexInTabs(policyDetailEndorsementTabs.UW_RESULTS.code):
        return <UWResults />;

      case findIndexInTabs(policyDetailEndorsementTabs.SUMMARY_PRICING.code):
        return <SummaryPricing ref={summaryPricingRef} />;

      case findIndexInTabs(policyDetailEndorsementTabs.PRICING_BREAKDOWN.code):
        return <PricingBreakdown />;

      default:
        return <></>;
    }
  }, [activeTab, user]);

  const renderBadge = (status) => {
    const statusBackgroundColor = importedTheme.customColors.endorsementDetailStatusColor[
      status
    ] as BoxProps['color'];

    switch (status) {
      case policyDetailEndorsementStatuses.APPLICATION:
        return <StatusBox label={t('Draft')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.QUOTED:
        return <StatusBox label={t('Quoted')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.ACCEPTED:
        return <StatusBox label={t('Approved')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.ISSUED:
        return <StatusBox label={t('Issued')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.DECLINED:
        return <StatusBox label={t('Declined')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.DISCARDED:
        return <StatusBox label={t('Discarded')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.REFERRED:
        return <StatusBox label={t('Referred')} color={statusBackgroundColor} />;

      case policyDetailEndorsementStatuses.DECLINED_BY_UW:
        return <StatusBox label={t('Declined by UW')} color={statusBackgroundColor} />;

      default:
        return <TextBadge label={status} color="darkCopper" />;
    }
  };

  const renderHead = useMemo(
    () =>
      !loaded ? (
        <Head title={t('Policy Endorsement')} />
      ) : (
        <Head
          title={`${t('Policy Endorsement')} - ${
            endorsementDetail?.policy?.policyholder?.name
          } - ${findTabWithIndex(activeTab)?.name?.()}`}
        />
      ),
    [activeTab, loaded],
  );

  // block route change until save is done
  useBlockRoute(async (location) => {
    if (!location.pathname.includes(`/policies/${id}/endorsement/${endorsementId}/overview`)) {
      try {
        if (
          businessInfoRef?.current?.isDirty() ||
          operationsRef?.current?.isDirty() ||
          propertyExposuresRef?.current?.isDirty() ||
          summaryPricingRef?.current?.isDirty()
        ) {
          await handleTabChangeSave(activeTab);
        }
        setWhen(false);
        return false;
      } catch (_error) {
        return true;
      }
    }

    return false;
  });

  return !loaded || !vehicleExposures?.loaded ? (
    detailLoadingSkeleton
  ) : (
    <Stack direction="row" sx={{ height: '100%' }}>
      {renderHead}
      <Box
        sx={{
          height: '100%',
          flexShrink: 0,
          overflowY: 'auto',
          p: 3,
          pr: 2,
        }}
      >
        <Box
          gap={0.5}
          sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Box>
            {renderBadge(
              `Endorsement ${formatDateInTimeZone(endorsementDetail?.endorsement_start_at)}`,
            )}
          </Box>
          <Box>{renderBadge(endorsementDetail?.state?.key)}</Box>
        </Box>

        <VerticalTabs
          tabs={endorsementTabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          progressLoading={vehicleExposures?.loading}
        />
      </Box>
      <Box />
      <Box
        sx={{ width: '100%', overflow: 'auto', padding: '4px', pt: 3.5, pl: 1.5 }}
        id="tabContentContanier"
      >
        <Box id="scroll-anchor" sx={{ width: '1px' }} />
        {renderActiveTab}
      </Box>
    </Stack>
  );
};

export default PolicyEndorsementDetailTab;
