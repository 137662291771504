import { Box, Skeleton } from '@mui/material';
import { GridAlignment } from '@mui/x-data-grid';
import { waiveFee } from 'api/services/Policies';
import { billingChipCategories } from 'common/constants';
import BillingConfirmFeeWaiverDialog from 'components/Policies/PolicyDetail/Billing/BillingConfirmFeeWaiverDialog';
import EnrollInAutopayDialog from 'components/Policies/PolicyDetail/Billing/EnrollInAutopayDialog';
import InvoicesTable from 'components/Policies/PolicyDetail/Billing/InvoicesTable';
import PayInvoiceDialog from 'components/Policies/PolicyDetail/Billing/PayInvoiceDialog';
import PaymentInfo from 'components/Policies/PolicyDetail/Billing/PaymentInfo';
import PaymentLinkDialog from 'components/Policies/PolicyDetail/Billing/PaymentLinkDialog';
import StopAutopayDialog from 'components/Policies/PolicyDetail/Billing/StopAutopayDialog';
import TableFilters from 'components/Policies/PolicyDetail/Billing/TableFilters';
import PolicyDetailHeader from 'components/Policies/PolicyDetail/Header';
import PolicyDetailTopActionBar from 'components/Policies/PolicyDetail/TopActionBar';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export interface IColumns {
  name: string;
  headerName: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const PolicyDetailBillings: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: policyDetail,
    getBillingData,
    loading: policyDetailLoading,
    resetPolicyInvoices,
    invoices,
    groupedInvoices,
    billingPaymentAmounts,
    billingPaymentInfo,
  } = usePolicyDetail();
  const { setLoading } = useLoader();
  const [chips, setChips] = React.useState([{ code: 'all invoices', label: 'All Invoices' }]);
  const [activeChip, setActiveChip] = React.useState<string>(chips[0].code);
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const url = qs.parse(LOCATION.search);

  const handleChipClick = (chip: string) => {
    setActiveChip(chip);
  };

  const isPayInvoiceDialogOpen = url?.payInvoice === 'true' && url?.step === '1';
  const isPaymentLinkDialogOpen = url?.payInvoice === 'true' && url?.step === '2';
  const isStopAutopayDialogOpen = url?.stopAutopay === 'true';
  const isEnrollInAutopayDialogOpen = url?.enrollInAutopay === 'true';
  const isWaiveFeeDialogOpen = url?.waive !== undefined;

  const handleFeeWaive = async (invoiceId, policyId) => {
    try {
      setLoading(true);
      await waiveFee(invoiceId, policyId);
      getBillingData(id);
    } catch (error) {
      displayBackendErrorMessage(error);
    } finally {
      setLoading(false);
      HISTORY.push({
        search: deleteFromQueryStrings({
          locationSearch: LOCATION.search,
          omitKeys: ['waive'],
        }),
      });
    }
  };

  const fillChipsDynamically = () => {
    if (groupedInvoices && !isEmpty(groupedInvoices)) {
      Object.entries(groupedInvoices).map((key) => {
        if (!isEmpty(key[1])) {
          if (billingChipCategories.some((e) => e.code === key[0])) {
            const chip = billingChipCategories.filter((e) => e.code === key[0]);
            setChips((val) => [...val, { code: chip[0].code, label: chip[0].label }]);
          }
        }
        return null;
      });
    }
  };

  useEffect(() => {
    getBillingData(id);

    return () => {
      resetPolicyInvoices();
    };
  }, []);

  useEffect(() => {
    if (invoices.loading === false) fillChipsDynamically();
  }, [invoices.loading]);

  return (
    <>
      <PolicyDetailTopActionBar policyId={id} />

      <PolicyDetailHeader currentTab={5} policyId={id} />

      {isWaiveFeeDialogOpen && (
        <BillingConfirmFeeWaiverDialog
          isOpen={isWaiveFeeDialogOpen}
          onAccept={handleFeeWaive}
          onCancel={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['waive'],
              }),
            });
          }}
        />
      )}

      {isPayInvoiceDialogOpen && <PayInvoiceDialog isOpen={isPayInvoiceDialogOpen} />}

      {isPaymentLinkDialogOpen && (
        <PaymentLinkDialog
          isOpen={isPaymentLinkDialogOpen}
          customerEmail={policyDetail?.characteristics?.data?.pol_business_email as string}
          policyId={id}
        />
      )}

      {isEnrollInAutopayDialogOpen && (
        <EnrollInAutopayDialog
          isOpen={isEnrollInAutopayDialogOpen}
          policyDetail={policyDetail}
          onClose={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['enrollInAutopay'],
              }),
            });
          }}
        />
      )}

      {isStopAutopayDialogOpen && (
        <StopAutopayDialog
          isOpen={isStopAutopayDialogOpen}
          onClose={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['stopAutopay'],
              }),
            });
          }}
        />
      )}

      {policyDetailLoading ? (
        <Skeleton sx={{ ml: 3, mt: 5 }} animation="wave" width={200} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: (theme) => `calc(100vh - ${theme.policyDetailFixedItemsHeight})`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              p: 2,
              pt: 5,
            }}
          >
            <Box>
              {' '}
              <PaymentInfo
                billingPaymentAmounts={billingPaymentAmounts}
                billingPaymentInfo={billingPaymentInfo}
                loading={invoices.loading}
                paymentPlanValue={policyDetail?.payment_schedule?.value}
              />
              {invoices.loading ? (
                <Box sx={{ display: 'flex' }}>
                  {[...Array(3).keys()].map((_, i) => (
                    <Skeleton
                      sx={{ ml: 2, py: 1, mb: 3 }}
                      animation="wave"
                      width={100}
                      key={i as number}
                    />
                  ))}
                </Box>
              ) : (
                <TableFilters
                  activeChip={activeChip}
                  handleChipClick={handleChipClick}
                  chips={chips}
                />
              )}
              <InvoicesTable activeChip={activeChip} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PolicyDetailBillings;
