import { ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, Link, Stack, Typography, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  cancellationReasons,
  defaultRowTransactionList,
  THREE_EndorsementTypes,
  userRoles,
  wcAuditSubTypes,
} from 'common/constants';
import DataTable from 'components/DataTable';
import DataTablePro from 'components/DataTablePro';
import PolicyDetailHeader from 'components/Policies/PolicyDetail/Header';
import PolicyDetailTopActionBar from 'components/Policies/PolicyDetail/TopActionBar';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import {
  createColumnVisibilityModel,
  currencyFormat,
  deleteFromQueryStrings,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';

import {
  DataGridProProps,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import {
  issueCancellationPolicy,
  issueReinstatePolicy,
  rescindCancellationPolicy,
} from 'api/services/Policies';
import EditDateIcon from 'assets/images/EditDateIcon.svg';
import ReviseCancellationDialog from 'components/Policies/Cancellation/ReviseCancellationDialog';
import ReinstateDialog from 'components/Policies/Reinstatement';
import { tabTitleStyles } from 'helpers/MuiSharedStyles';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import { IPoliciesTransactionHistoryProps } from 'providers/PolicyDetailProvider/types';
import { ReactSVG } from 'react-svg';

interface IcolumnsDrivers {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const determineEndorsementLinkText = (code: string, auditType?: string) => {
  let text = 'Endorsement';

  if (code === THREE_EndorsementTypes.WC_AUDIT) {
    text = `WC Audit (${
      wcAuditSubTypes[`${auditType?.toUpperCase()}`]?.name ?? wcAuditSubTypes.ESTIMATED.name
    })`;
  }

  return text;
};

const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ArrowForwardIos
        sx={{
          transform: `rotateZ(${isExpanded ? 90 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

const PolicyDetailTransactionHistory: FC = () => {
  const { t } = useTranslation();
  const themeHook = useTheme();
  const { formatDateInTimeZone } = useConfig();
  const { id: policyId } = useParams<{ id: string }>();
  const {
    fetch: getPolicyDetail,
    getTransactionHistory,
    getGracePeriods,
    transactionHistory,
    gracePeriods,
    resetTransactionHistory,
  } = usePolicyDetail();
  const { data: user } = useUser();
  const LOCATION = useLocation();
  const { setDialogOpen } = useDialog();
  const { setLoading } = useLoader();
  const HISTORY = useHistory();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const isReinstateModalOpen = useMemo(
    () => LOCATION.search.includes('requestReinstatement'),
    [LOCATION.search],
  );

  const isReviseCancellationModalOpen = useMemo(
    () => LOCATION.search.includes('reviseCancellation'),
    [LOCATION.search],
  );

  interface IModifiedTransactions {
    last_premium?: string;
    list: IPoliciesTransactionHistoryProps[] | [];
  }

  const transactions = useMemo(() => {
    if (transactionHistory?.loaded) {
      // Iterate on transactions.data and add premium field to each transaction by the sum of previous transaction premium and premium_change
      const modifiedTransactions = transactionHistory.data?.reduce(
        (acc: IModifiedTransactions, transaction, index) => {
          // If transaction is not issued, don't display premium or premium_change
          if (transaction.date_of_issue === null) {
            return {
              ...acc,
              list: [...(acc.list ?? []), { ...transaction, premium: '', premium_change: '' }],
            };
          }
          // If transaction is reinstated, display premium as the last premium and hide premium_change
          if (transaction.status?.key === 'reinstated') {
            return {
              ...acc,
              list: [
                ...(acc.list ?? []),
                { ...transaction, premium: acc.last_premium, premium_change: '' },
              ],
            };
          }
          // If transaction is the first one, display premium as the premium_change and hide premium_change
          if (index === 0) {
            return {
              last_premium: transaction.premium_change,
              list: [{ ...transaction, premium: transaction.premium_change, premium_change: '' }],
            };
          }

          const premium = String(
            parseFloat(acc.last_premium ?? '0') + parseFloat(transaction.premium_change ?? '0'),
          );

          return {
            last_premium: premium,
            list: [...(acc.list ?? []), { ...transaction, premium }],
          };
        },
        { list: [] },
      );

      return modifiedTransactions ?? { list: [] };
    }
    return { list: [] };
  }, [transactionHistory?.loaded, transactionHistory?.loading]);

  const cancellations = useMemo(() => {
    if (transactionHistory?.loaded) {
      return transactions.list.filter((tr) => tr.transaction_type === 'Cancellation');
    }
    return [];
  }, [transactions.list]);

  useEffect(() => {
    getTransactionHistory(policyId);
    getGracePeriods(policyId);

    // reset transaction history when unmounting
    return () => {
      resetTransactionHistory();
    };
  }, []);

  const handleIssueReinstatement = async (cancellationId, reinstatementId) => {
    try {
      setLoading(true);
      await issueReinstatePolicy(policyId, cancellationId!, reinstatementId);
      getTransactionHistory(policyId);
      getPolicyDetail(policyId, true);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while issuing reinstatement.'));
    } finally {
      setLoading(false);
      HISTORY.push({
        search: deleteFromQueryStrings({
          locationSearch: LOCATION.search,
          omitKeys: ['forceReinstatement'],
        }),
      });
    }
  };

  const handleRescindCancellation = async (cancellationId) => {
    try {
      setLoading(true);
      await rescindCancellationPolicy(policyId, cancellationId!);
      getTransactionHistory(policyId);
      getPolicyDetail(policyId, true);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while rescinding cancellation.'));
    } finally {
      setLoading(false);
    }
  };

  const handleIssueCancellation = async (cancellationId) => {
    try {
      setLoading(true);
      await issueCancellationPolicy(policyId, cancellationId!);
      getTransactionHistory(policyId);
      getPolicyDetail(policyId, true);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while issuing cancellation.'));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
    },
    {
      field: 'transaction_type',
      headerName: t('Type'),
      type: 'string',
      hideable: false,
      sortable: false,
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        const {
          transaction_type: fieldValue,
          locator,
          code,
          pol_wc_audit_type: polWcAuditType,
        } = params.row;

        const statusKey = getNestedValueFromObject(params.row, 'status__key');

        if (fieldValue === 'Endorsement') {
          const linkText = determineEndorsementLinkText(code, polWcAuditType);
          return (
            <Link
              component={RouterLink}
              to={`/policies/${policyId}/endorsement/${locator}/`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
              title={`${linkText} ${locator}`}
            >
              {linkText || '-'}
            </Link>
          );
        } else if (fieldValue === 'New Business') {
          return (
            <Link
              component={RouterLink}
              to={`/policies/${policyId}/new-business/`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
              title={`${fieldValue} ${policyId}`}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else if (fieldValue === 'Cancellation') {
          const reinstatement = getNestedValueFromObject(params.row, 'reinstatement');
          const isReinstatementDraft = reinstatement.state === 'draft';
          const isReinstatementExpired = reinstatement.state === 'expired';
          const isReinstatementAccepted = reinstatement.state === 'accepted';
          const isCancellationDraft = statusKey === 'draft';
          const isUnderwritingCancel = params.row.code === cancellationReasons.NON_COMPLIANCE;
          const isCancellationIssued = statusKey === 'issued';
          const isCancellationPendingReinstate = statusKey === 'pending_reinstate';
          const canRequestReinstate =
            (isCancellationIssued || isCancellationPendingReinstate) &&
            (isEmpty(reinstatement) || isReinstatementDraft || isReinstatementExpired) &&
            isUnderwriter;
          const canForceReinstate =
            (isCancellationIssued || isCancellationPendingReinstate) &&
            isReinstatementAccepted &&
            isUnderwriter;
          const canRescindCancellation =
            isCancellationDraft && isUnderwritingCancel && isUnderwriter;
          const canIssueCancellation = isCancellationDraft && isUnderwritingCancel && isUnderwriter;

          return (
            <>
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                }}
              >
                {fieldValue || '-'}
              </Typography>
              {canRequestReinstate && (
                <Link
                  component={RouterLink}
                  to={`?${updateQueryStrings({
                    locationSearch: LOCATION.search,
                    newQueries: { requestReinstatement: locator },
                  })}`}
                  underline="hover"
                  sx={{
                    color: (theme) => theme.customColors.table.link,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    ml: 1,
                    fontSize: 'smaller',
                  }}
                  title={`Reinstate ${locator}`}
                >
                  {t('Request Reinstate')}
                </Link>
              )}
              {canForceReinstate && (
                <Link
                  component={RouterLink}
                  to={`?${updateQueryStrings({
                    locationSearch: LOCATION.search,
                    newQueries: {},
                  })}`}
                  underline="hover"
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'ISSUE_REINSTATEMENT',
                      isOpen: true,
                      onAccept: () => handleIssueReinstatement(locator, reinstatement.locator),
                    })
                  }
                  sx={{
                    color: (theme) => theme.customColors.table.link,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    ml: 1,
                    fontSize: 'smaller',
                  }}
                  title={`Reinstate ${locator}`}
                >
                  {t('Force Reinstate')}
                </Link>
              )}
              {canIssueCancellation && (
                <Link
                  component={RouterLink}
                  to={`?${updateQueryStrings({
                    locationSearch: LOCATION.search,
                    newQueries: {},
                  })}`}
                  underline="hover"
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'ISSUE_CANCELLATION',
                      isOpen: true,
                      onAccept: () => handleIssueCancellation(locator),
                    })
                  }
                  sx={{
                    color: (theme) => theme.customColors.table.link,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    ml: 1,
                    fontSize: 'smaller',
                  }}
                  title={`Issue ${locator}`}
                >
                  {t('Issue')}
                </Link>
              )}
              {canRescindCancellation && (
                <Link
                  component={RouterLink}
                  to={`?${updateQueryStrings({
                    locationSearch: LOCATION.search,
                    newQueries: {},
                  })}`}
                  underline="hover"
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'RESCIND_CANCELLATION',
                      isOpen: true,
                      onAccept: () => handleRescindCancellation(locator),
                    })
                  }
                  sx={{
                    color: (theme) => theme.customColors.table.link,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    ml: 1,
                    fontSize: 'smaller',
                  }}
                  title={`Rescind ${locator}`}
                >
                  {t('Rescind')}
                </Link>
              )}
            </>
          );
        } else {
          return (
            <Typography
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
            >
              {fieldValue || '-'}
            </Typography>
          );
        }
      },
    },
    {
      field: 'status',
      type: 'string',
      headerName: t('Status'),
      hideable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      minWidth: themeHook.statusColumnWidth,
      flex: 0.1,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'status__value');
        const statusKey = getNestedValueFromObject(params.row, 'status__key');
        if (!fieldValue) return '-';
        return (
          <Box
            sx={{
              px: (theme) => theme.spacing(1.5),
              borderRadius: (theme) => theme.spacing(0.5),
              color: (theme) => theme.customColors.black,
              backgroundColor: (theme) =>
                theme.customColors.policiesTransactionStatusColor[statusKey],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.subtitle2.fontSize,
                color: (theme) => theme.customColors.white50,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              }}
              variant="body1"
            >
              {fieldValue || '-'}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'date_of_issue',
      headerName: t('Date of Issue'),
      type: 'string',
      hideable: false,
      sortable: false,
      align: 'left',
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'date_of_issue');
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      },
    },
    {
      field: 'date_of_decline',
      headerName: t('Date of Decline'),
      type: 'string',
      hideable: false,
      sortable: false,
      align: 'left',
      minWidth: 130,
      flex: 0.1,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'declined_by_uw_at');
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      },
    },
    {
      field: 'effective_date',
      headerName: t('Effective Date'),
      type: 'string',
      hideable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'effective_date');
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      },
    },
    {
      field: 'premium',
      type: 'currency',
      headerName: t('Written Premium'),
      hideable: false,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'premium');
        return fieldValue ? `${currencyFormat(undefined, fieldValue).merged}` : '-';
      },
    },
    {
      field: 'premium_change',
      type: 'currency',
      headerName: t('Written Premium Change'),
      hideable: false,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const fieldValue = params.row.date_of_issue
          ? getNestedValueFromObject(params.row, 'premium_change')
          : null;

        return (
          <Typography
            sx={{
              color: (theme) =>
                Number(params.row.premium_change) < 0 ? theme.customColors.errorRed : '',
            }}
            variant="body1"
          >
            {fieldValue
              ? Number(params.row.premium_change) < 0
                ? `(${currencyFormat(undefined, Math.abs(fieldValue)).merged})`
                : `${currencyFormat(undefined, fieldValue).merged}`
              : '-'}
          </Typography>
        );
      },
    },
  ];

  const DetailPanelContent = ({ row: rowProp }) => (
    <Stack
      sx={{
        height: {
          xs: 'auto',
          sm: 'auto',
          md: 1,
          lg: 1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: (theme) => theme.spacing(2, 2, 4, 3),
          margin: (theme) => theme.spacing(1, 3, 2, 3),
          borderRadius: 1,
          boxShadow: (theme) => theme.customShadows.formBackground,
        }}
      >
        <Box
          sx={{
            minWidth: '200px',
            mr: 8,
            display: 'flex',
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>{t('Created Date:')}</Typography>
          <Typography sx={{ fontWeight: 400, ml: 1 }}>
            {rowProp.created_at ? formatDateInTimeZone(rowProp.created_at) : '-'}
          </Typography>
        </Box>
        {rowProp.transaction_type === 'Cancellation' && (
          <>
            <Box
              sx={{
                minWidth: '200px',
                mr: 8,
                display: 'flex',
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>{t('Cancellation Type:')}</Typography>
              <Typography sx={{ fontWeight: 400, ml: 1 }}>{rowProp.name ?? '-'}</Typography>
            </Box>
          </>
        )}
        {rowProp.transaction_type !== 'Cancellation' && (
          <>
            <Box
              sx={{
                minWidth: '200px',
                mr: 8,
                display: 'flex',
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>{t('Referred Date:')}</Typography>
              <Typography sx={{ fontWeight: 400, ml: 1 }}>
                {rowProp.referred_at ? formatDateInTimeZone(rowProp.referred_at) : '-'}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Stack>
  );

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );

  const getDetailPanelHeight = React.useCallback(() => 100, []);

  const columnsCancellationNotices: IcolumnsDrivers[] = [
    {
      name: 'name',
      display_name: t('Type'),
      flex: 2,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'status',
      display_name: t('Status'),
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
      minWidth: 80,
      flex: 1,
    },
    {
      name: 'date_of_issue',
      display_name: t('Date Issued'),
      flex: 0.1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: true,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'effective_date',
      display_name: t('Cancellation Effective Date'),
      flex: 0.1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: true,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const cancellationNoticesColumns: GridColDef[] = columnsCancellationNotices.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    // eslint-disable-next-line consistent-return
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (
        field.name === 'effective_date' &&
        params.row.code === 'non_payment' &&
        params.row.status.key === 'draft' &&
        isUnderwriter
      ) {
        const cancellationGracePeriod = gracePeriods?.data?.find(
          (gracePeriod) => params.row.locator === gracePeriod.locator,
        );
        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { reviseCancellation: params.row.locator },
            })}`}
            underline="always"
            sx={{
              color: (theme) => theme.customColors.primary.buttonBg,
              textDecorationColor: (theme) => theme.customColors.primary.buttonBg,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              textOverflow: 'ellipsis',
              marginLeft: '24px',
              '& svg': {
                marginTop: '4px',
              },
            }}
          >
            {cancellationGracePeriod
              ? formatDateInTimeZone(cancellationGracePeriod.cancel_effective_at)
              : fieldValue
              ? formatDateInTimeZone(fieldValue)
              : '-'}
            <ReactSVG className="icon" src={EditDateIcon} />
          </Link>
        );
      } else if (field.name.includes('date')) {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else if (field.name === 'status') {
        if (!fieldValue) return '-';
        const r = params.row;
        const statusColor = r?.status?.key!;
        const statusText = r?.status?.value!;
        return (
          <Box
            sx={{
              px: (theme) => theme.spacing(1.5),
              borderRadius: (theme) => theme.spacing(0.5),
              color: (theme) => theme.customColors.black,
              backgroundColor: (theme) =>
                theme.customColors.policiesTransactionStatusColor[statusColor],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.subtitle2.fontSize,
                color: (theme) => theme.customColors.white50,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              }}
              variant="body1"
            >
              {statusText}
            </Typography>
          </Box>
        );
      } else {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  return (
    <>
      {isReviseCancellationModalOpen && <ReviseCancellationDialog />}
      <PolicyDetailTopActionBar policyId={policyId} />
      <PolicyDetailHeader currentTab={0} policyId={policyId} />
      {isReinstateModalOpen && isUnderwriter && <ReinstateDialog />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: (theme) => `calc(100vh - ${theme.policyDetailFixedItemsHeight})`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            p: 3,
          }}
        >
          <Box>
            <Typography sx={[tabTitleStyles]}>{t('Policy Transactions')}</Typography>
            <DataTablePro
              getRowId={(row) => row.locator}
              columns={columns as GridColDef[]}
              rows={transactions.list}
              loading={transactionHistory?.loading}
              pageSize={defaultRowTransactionList}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              hideFooterPagination={transactions.list?.length! < defaultRowTransactionList}
            />
          </Box>
          <Box sx={{ maxWidth: 1184, mt: 1 }}>
            <Typography sx={[tabTitleStyles]}>{t('Cancellations Notices')}</Typography>

            <Box sx={{ maxWidth: 1184 }}>
              <DataTable
                columns={cancellationNoticesColumns}
                rows={cancellations}
                columnVisibilityModel={createColumnVisibilityModel(columnsCancellationNotices)}
                loading={!transactionHistory?.loaded}
                hideFooterPagination={cancellations?.length! <= defaultRowTransactionList}
                pageSize={defaultRowTransactionList}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PolicyDetailTransactionHistory;
