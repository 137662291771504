import { Box, Skeleton } from '@mui/material';
import { GridAlignment } from '@mui/x-data-grid';
import { billingChipCategories, quoteStatuses } from 'common/constants';
import InvoicesTable from 'components/Policies/PolicyDetail/Billing/InvoicesTable';
import PayInvoiceDialog from 'components/Policies/PolicyDetail/Billing/PayInvoiceDialog';
import PaymentInfo from 'components/Policies/PolicyDetail/Billing/PaymentInfo';
import PaymentLinkDialog from 'components/Policies/PolicyDetail/Billing/PaymentLinkDialog';
import TableFilters from 'components/Policies/PolicyDetail/Billing/TableFilters';
import QuoteDetailHeader from 'components/Quotes/QuoteDetail/QuoteDetailHeader';
import QuoteDetailTopActionBar from 'components/Quotes/QuoteDetail/QuoteDetailTopActionBar';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export interface IColumns {
  name: string;
  headerName: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const QuoteDetailBillings: FC = () => {
  const { id: quoteId } = useParams<{ id: string }>();
  const {
    getBillingData,
    resetPolicyInvoices,
    invoices,
    billingPaymentInfo,
    billingPaymentAmounts,
    groupedInvoices,
  } = usePolicyDetail();

  const { data: quoteDetail, loading: quoteDetailLoading } = useQuoteDetail();
  const { policy_locator: policyId } = quoteDetail || {};

  const [chips, setChips] = React.useState([{ code: 'all invoices', label: 'All Invoices' }]);
  const [activeChip, setActiveChip] = React.useState<string>(chips[0].code);
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);

  const handleChipClick = (chip: string) => {
    setActiveChip(chip);
  };

  const isPayInvoiceDialogOpen = url?.payInvoice === 'true' && url?.step === '1';
  const isPaymentLinkDialogOpen = url?.payInvoice === 'true' && url?.step === '2';
  const isQuoteAccepted =
    quoteDetail?.state?.code && quoteDetail?.state?.code === quoteStatuses.ACCEPTED;

  const fillChipsDynamically = () => {
    if (groupedInvoices && !isEmpty(groupedInvoices)) {
      Object.entries(groupedInvoices).map((key) => {
        if (!isEmpty(key[1])) {
          if (billingChipCategories.some((e) => e.code === key[0])) {
            const chip = billingChipCategories.filter((e) => e.code === key[0]);
            setChips((val) => [...val, { code: chip[0].code, label: chip[0].label }]);
          }
        }
        return null;
      });
    }
  };

  useEffect(() => {
    // Second parameter assumes that the customer is not enrolled in autopay
    // https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/13065
    // Request billing data only for quotes with accepted status
    if (policyId && isQuoteAccepted) getBillingData(policyId, true);

    return () => {
      resetPolicyInvoices();
    };
  }, [policyId, isQuoteAccepted]);

  useEffect(() => {
    if (invoices.loading === false) fillChipsDynamically();
  }, [invoices.loading]);

  return (
    <>
      <QuoteDetailTopActionBar />

      <QuoteDetailHeader currentTab={isQuoteAccepted ? 4 : 0} quoteId={quoteId} />

      {isPayInvoiceDialogOpen && <PayInvoiceDialog isOpen={isPayInvoiceDialogOpen} />}

      {isPaymentLinkDialogOpen && (
        <PaymentLinkDialog
          isOpen={isPaymentLinkDialogOpen}
          customerEmail={quoteDetail?.characteristics?.[0].data?.pol_business_email as string}
          policyId={quoteDetail?.policy_locator!}
        />
      )}

      {quoteDetailLoading ? (
        <Skeleton sx={{ ml: 3, mt: 5 }} animation="wave" width={200} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: (theme) => `calc(100vh - ${theme.quoteDetailFixedItemsHeight})`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              p: 2,
              pt: 5,
            }}
          >
            <Box>
              <PaymentInfo
                billingPaymentAmounts={billingPaymentAmounts}
                billingPaymentInfo={billingPaymentInfo}
                loading={invoices.loading}
                paymentPlanValue={quoteDetail?.payment_schedule}
                isEnrollInAutopayButtonVisible={false}
              />

              {invoices.loading ? (
                <Box sx={{ display: 'flex' }}>
                  {[...Array(3).keys()].map((_, i) => (
                    <Skeleton
                      sx={{ ml: 2, py: 1, mb: 3 }}
                      animation="wave"
                      width={100}
                      key={i as number}
                    />
                  ))}
                </Box>
              ) : (
                <TableFilters
                  activeChip={activeChip}
                  handleChipClick={handleChipClick}
                  chips={chips}
                />
              )}

              <InvoicesTable activeChip={activeChip} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default QuoteDetailBillings;
