import ApiService from 'api/helpers/Sender';
import { AdvancedSearchConfig } from 'api/models/AdvancedSearch/advancedSearchConfig.model';
import { prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';

const API = new ApiService();

export const fetchTimezoneConfig = async (): Promise<{ code: string; name: string }> => {
  const { data } = await API.get('meta', {}, { ...prepareCacheOptions() });

  return { code: data?.timezone, name: data?.timezone };
};

export const fetchAdvancedSearchConfig = async (): Promise<AdvancedSearchConfig[]> => {
  const { data } = await API.get('configs/search', {}, { ...prepareCacheOptions() });
  return data;
};
