import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { Queues } from 'api/models/Queues/queues.model';
import { Task } from 'api/models/Tasks/task.model';
import { getQueueTasks, getTaskQueueDetail, getTaskQueues } from 'api/services/Queues';
import { getTask } from 'api/services/Tasks';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { initialQueuesData, QueuesContext } from './QueuesProviderContext';
import { IQueuesPagination } from './types';

export const QueuesProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialQueuesData);
  const HISTORY = useHistory();

  const fetch = async (): Promise<Queues[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const res = await getTaskQueues();

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res?.results,
      }));

      return res?.results ?? [];
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const fetchQueueTasks = async (queueId: string, query: IQueuesPagination): Promise<Task[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        queueTasks: {
          ...prevState.queueTasks,
          [`${queueId}`]: {
            ...(prevState.queueTasks![`${queueId}`] ?? {}),
            loading: true,
          },
        },
      }));

      let res;

      try {
        res = await getQueueTasks(queueId, {
          ...query,
          page_size: state.pagination.page_size,
        });
      } catch (error) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          if (e.response?.status === 404) {
            res = await getQueueTasks(queueId, {
              ...query,
              page_size: state.pagination.page_size,
              page: 1,
            });
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        queueTasks: {
          ...prevState.queueTasks,
          [`${queueId}`]: {
            ...(prevState.queueTasks![`${queueId}`] ?? {}),
            loading: false,
            loaded: true,
            data: res?.results,
          },
        },
        pagination: {
          ...query,
          total_count: res?.count,
          page_size: prevState.pagination.page_size,
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        queueTasks: {
          ...prevState.queueTasks,
          [`${queueId}`]: {
            ...(prevState.queueTasks![`${queueId}`] ?? {}),
            loading: false,
            loaded: true,
          },
        },
      }));
      throw error;
    }
  };

  const getQueue = async (id: string): Promise<Queues> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeQueue: { ...prevState.activeQueue, loading: true },
      }));

      const res = await getTaskQueueDetail(id);

      setState((prevState) => ({
        ...prevState,
        activeQueue: {
          ...prevState.activeQueue,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res;
    } catch (error) {
      const e = error as AxiosError;
      if (e.isAxiosError) {
        if (e.response?.status === 404) {
          HISTORY.replace('/404');
        }
      }
      setState((prevState) => ({
        ...prevState,
        activeQueue: { ...prevState.activeQueue, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const getQueueTask = async (id: string): Promise<Queues | undefined> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeQueueTask: { ...prevState.activeQueueTask, loading: true },
      }));

      const res = await getTask(id);

      setState((prevState) => ({
        ...prevState,
        activeQueueTask: {
          ...prevState.activeQueueTask,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        activeQueueTask: { ...prevState.activeQueueTask, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const reFetchData = async (queueId: string) => {
    await fetchQueueTasks(queueId, state.pagination);
  };

  return (
    <QueuesContext.Provider
      value={{
        ...state,
        fetch,
        fetchQueueTasks,
        getQueue,
        getQueueTask,
        reFetchData,
      }}
    >
      {children}
    </QueuesContext.Provider>
  );
};
