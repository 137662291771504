import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { initialRouterPromptData, RouterPromptContext } from './RouterPromptProviderContext';

export const RouterPromptProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialRouterPromptData);

  const setWhen = (isDataEqual: boolean): boolean => {
    setState((prevState) => ({
      ...prevState,
      when: isDataEqual,
    }));

    return isDataEqual;
  };

  const resetRouterState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialRouterPromptData,
    }));
  };

  const setBypassRouteBlock = (bypassRouteBlock: boolean): void => {
    setState((prevState) => ({
      ...prevState,
      bypassRouteBlock,
    }));
  };

  return (
    <RouterPromptContext.Provider
      value={{ ...state, setWhen, resetRouterState, setBypassRouteBlock }}
    >
      {children}
    </RouterPromptContext.Provider>
  );
};
