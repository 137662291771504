import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AdditionalInterestsDetailDrawer from './Detail';
import AdditionalInterestsEditDrawer from './Edit';

export interface IAdditionalInterestsDrawer {
  isEdit: boolean;
}
const AdditionalInterestsDrawer: FC<IAdditionalInterestsDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isAdditionalInterestsDrawerOpen =
    url?.tab === 'additional_interests' &&
    (!isEmpty(url?.additional_interest) || !isEmpty(url?.addAdditionalInterest));

  return isAdditionalInterestsDrawerOpen ? (
    isEdit ? (
      <AdditionalInterestsEditDrawer
        isDrawerOpen={isAdditionalInterestsDrawerOpen ?? false}
        isAdd={!isEmpty(url?.addAdditionalInterest)}
      />
    ) : (
      <AdditionalInterestsDetailDrawer isDrawerOpen={isAdditionalInterestsDrawerOpen ?? false} />
    )
  ) : (
    <></>
  );
};
export default AdditionalInterestsDrawer;
