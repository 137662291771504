import { Box } from '@mui/material';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import React from 'react';

interface DefaultLayoutProps {
  children?: React.ReactNode | React.ReactNode[];
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
      }}
    >
      <Header />
      <Box
        sx={{
          flexDirection: 'row',
          background: (theme) => theme.palette.common.white,
          overflow: 'hidden',
          width: 1,
          display: 'flex',
          marginTop: (theme) => ({
            xs: `${theme.appBarHeight}px`,
            sm: `${theme.appBarHeight}px`,
            lg: 0,
          }),
        }}
      >
        <Sidebar />
        <Box
          id="mainContanier"
          sx={{
            width: () => ({
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '100%',
            }),
            overflow: 'auto',
            marginTop: (theme) => ({
              xs: 0,
              sm: 0,
              lg: `${theme.appBarHeight}px`,
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
