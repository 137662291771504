import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import LocationDetailDrawer from './Detail';
import LocationEditDrawer from './Edit';

export interface ILocationDrawer {
  isEdit: boolean;
}

const LocationDrawer: FC<ILocationDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isLocationDrawerOpen =
    url?.tab === 'locations' && (!isEmpty(url?.location) || !isEmpty(url?.addLocation));

  return isLocationDrawerOpen ? (
    isEdit ? (
      <LocationEditDrawer
        isDrawerOpen={isLocationDrawerOpen ?? false}
        isAdd={!isEmpty(url?.addLocation)}
      />
    ) : (
      <LocationDetailDrawer isDrawerOpen={isLocationDrawerOpen ?? false} />
    )
  ) : (
    <></>
  );
};

export default LocationDrawer;
