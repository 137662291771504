import { Box, Button, CircularProgress, Typography } from '@mui/material';
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { PaymentIntent, SetupIntent } from 'api/models/Payments/payments.model';
import { setPaymentIntent } from 'api/services/Payments';
import { AxiosError } from 'axios';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle } from 'helpers/MuiSharedStyles';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface ICheckoutForm {
  paymentIntent?: PaymentIntent;
  setupIntent?: SetupIntent;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  isRecurring: boolean;
}

const CheckoutFormV2 = ({
  paymentIntent,
  setupIntent,
  setIsLoading,
  isLoading,
  isRecurring,
}: ICheckoutForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const { paymentIntentId, policyLocator } = useParams<{
    paymentIntentId: string;
    policyLocator: string;
  }>();

  const [email, setEmail] = useState(paymentIntent?.customer_email ?? '');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let enrollmentRequest: { isFailed: boolean; status: number } = {
      isFailed: false,
      status: 200,
    };
    const envAppUrl = window.location.origin;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (isRecurring) {
      try {
        await setPaymentIntent({ paymentIntentId, policyLocator });
      } catch (error) {
        const err = error as AxiosError;
        if (err.response && err.isAxiosError) {
          enrollmentRequest = { isFailed: true, status: err.response.status };
        } else {
          enrollmentRequest = { isFailed: true, status: 500 };
        }
      }
    }

    if (paymentIntent) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // The URL to redirect the user to after the payment is confirmed
          return_url: isRecurring
            ? `${envAppUrl}/payments/success?enrollment_failed=${enrollmentRequest.isFailed}&status=${enrollmentRequest.status}`
            : `${envAppUrl}/payments/success`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        displayToastMessage('ERROR', error?.message);
      } else {
        displayToastMessage('ERROR', error?.message);
      }
    } else if (setupIntent) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // The URL to redirect the user to after the setup is confirmed
          return_url: `${envAppUrl}/payments/setup-success`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        displayToastMessage('ERROR', error?.message);
      } else {
        displayToastMessage('ERROR', error?.message);
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: { type: 'tabs', defaultCollapsed: false },
    fields: { billingDetails: { email: 'never' } },
    defaultValues: {
      billingDetails: {
        address: { country: 'US' },
        email: paymentIntent?.customer_email ?? undefined,
      },
    },
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => setEmail(e.value.email)}
          options={{
            defaultValues: {
              email,
            },
          }}
        />

        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {!paymentIntent && (
          <Typography sx={{ fontSize: 12, mt: 2 }}>
            By clicking Enroll in Autopay, I authorize THREE to automatically debit this payment
            method for future insurance payments when they become due.
          </Typography>
        )}
        <Button
          sx={{ ...primaryButtonStyle, width: '100%', mt: 2 }}
          disabled={isLoading || !stripe || !elements}
          id="submit"
          type="submit"
          data-test="pay-button"
        >
          <Box component="span" id="button-text">
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : paymentIntent ? (
              'Pay now'
            ) : (
              'Enroll in Autopay'
            )}
          </Box>
        </Button>
      </form>
    </>
  );
};

export default CheckoutFormV2;
