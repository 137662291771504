import ApiService, { Params } from 'api/helpers/Sender';
import { Note } from 'api/models/Notes/note.model';
import { NoteCreate } from 'api/models/Notes/noteCreate.model';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getNotesService = async (
  pagination: Pagination,
  reference_type: string,
  reference_locator: string,
): Promise<PaginationResponse<Note[]>> => {
  const { data } = await API.get(
    `${reference_type}/${reference_locator}/notes`,
    pagination as Params,
  );
  return data;
};

export const addNoteService = async (body: NoteCreate) => {
  const { data } = await API.post(`notes`, body);
  return data;
};

export const deleteNoteService = async (id: number) => {
  const { data } = await API.delete(`notes/${id}`);
  return data;
};

export const getNoteWithLocator = async (noteLocator: string): Promise<Note> => {
  const { data } = await API.get(`notes/${noteLocator}`);
  return data;
};

export const updateNoteWithLocator = async (
  noteLocator: string,
  body: NoteCreate,
): Promise<NoteCreate> => {
  const { data } = await API.patch(`notes/${noteLocator}`, body);
  return data;
};
