import { AgenciesContext } from 'providers/AgenciesProvider/AgenciesProviderContext';
import { IAgenciesContextType } from 'providers/AgenciesProvider/types';
import { useContext } from 'react';

export default function useAgencies(): IAgenciesContextType {
  const context = useContext(AgenciesContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useAgencies must be used within a AgenciesProvider');
  }

  return context;
}
