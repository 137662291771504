import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import AddScheduleIcon from 'assets/images/icon_schedule_mod.svg';
import {
  acceptedBdgOccupancy,
  defaultRowVirtualization,
  propertyExposureRelatedKeyValues,
  propertyExposureRelatedKeyValuesMap,
  quotePolicyEndorsementInfoTitlesDescriptions,
  smodLines,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import { useFormik } from 'formik';
import {
  columnHeaderTitleStyle,
  tabTitleStyles,
  truncatedTextStyle,
} from 'helpers/MuiSharedStyles';
import { calculateSchedMod } from 'helpers/ScheduleModificationHelpers';
import {
  createColumnVisibilityModel,
  currencyFormat,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  updateQueryStrings,
} from 'helpers/Utils';
import useKeyValues from 'hooks/useKeyValues';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import DetailAccordion from './Accordions/DetailAccordion';
import PropertyDrawer from './Drawers/PropertyDrawer/PropertyDrawer';
import PropertyExposureScheduleModDrawer from './Drawers/PropertyExposureScheduleModDrawer/PropertyExposureScheduleModDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const PropertyExposures: FC = () => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const [locationFilter, setLocationFilter] = useState('Location');
  const themeHook = useTheme();
  const { data: userData } = useUser();
  const [selectedPropertyRowId, setSelectedPropertyRowId] = useState('');
  const { exposureList, groups, underwritingQuestionsState, underwritingQuestions, loading } =
    usePolicyDetail();
  const { fetch: fetchKeyValues, data: keyValueStore } = useKeyValues();

  const SCHED_MOD_TABLES =
    keyValueStore?.[`${propertyExposureRelatedKeyValues.SCHED_MOD_TABLES}`]?.data?.value ?? [];

  const currentExposures = exposureList?.[`${threeExposureNames.BUILDING}`];

  const currentLiabilityExposures = exposureList?.[`${threeExposureNames.LOCATION}`];

  const schedModGroupFields: any[] =
    groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data ?? [];

  // get property exposures from provider
  const propertyExposures = exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [];

  // Find the first element in occupancyList that is also present in acceptedBdgOccupancy.
  // If no such element is found, default to an empty string.
  const occupancyList = propertyExposures.filter((exposure) =>
    acceptedBdgOccupancy.includes(exposure?.data?.bdg_occupancy),
  );

  const bdg_occupancy = !isEmpty(occupancyList) ? occupancyList?.[0].data?.bdg_occupancy : '';

  const [state, setState] = useState<any>({
    ...underwritingQuestionsState,
  });

  const isUnderwritingQuestionsLoading = (underwritingQuestions.policy?.loading ?? true) || loading;

  useEffect(() => {
    setState({
      ...underwritingQuestionsState,
    });
  }, [isUnderwritingQuestionsLoading]);

  // From the underwritingQuestions, find the item with the code 'premises'.
  const propertyExposuresFields = useMemo(
    () =>
      (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find((f) => f.code === 'premises')
        ?.fields ?? [],
    [underwritingQuestions],
  );

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: (_values) => {},
  });

  useEffect(() => {
    formik.setValues({ ...state });
  }, [JSON.stringify(underwritingQuestions)]);

  const setScheduleModeColor = (value: number) => {
    if (value > 1) {
      return themeHook.customColors.errorRed;
    } else if (value < 1) {
      return themeHook.customColors.successGreen;
    }

    return themeHook.customColors.gunMetal;
  };

  const columns: IColumns[] = [
    {
      name: 'bdg_location',
      display_name: t('Location'),
      flex: 1.5,
      type: 'string',
      minWidth: 190,
      is_sortable: true,
    },
    {
      name: 'bdg_name',
      display_name: t('Building Name'),
      flex: 1,
      type: 'string',
      minWidth: 140,
      is_link: true,
      is_sortable: true,
    },

    ...(userData?.role?.code === userRoles.UNDERWRITER.code
      ? ([
          {
            name: 'bdg_class_code',
            display_name: t('Class'),
            flex: 1,
            type: 'string',
            minWidth: 130,
          },
        ] as IColumns[])
      : []),

    {
      name: 'bdg_tiv_building',
      display_name: t('Insured Value Building'),
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 130,
      is_sortable: true,
    },
    {
      name: 'bdg_tiv_contents',
      display_name: t('Insured Value Other Property'),
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 140,
      is_sortable: true,
    },

    ...(userData?.role?.code === userRoles.UNDERWRITER.code
      ? ([
          {
            name: 'bdg_tiv_income',
            display_name: t('Insured Value Business Interruption'),
            type: 'currency',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.5,
            minWidth: 190,
            align: 'right',
            headerAlign: 'right',
          },
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod.'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 1,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const propertyExposureColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    sortComparator: (a, b, param1, param2) => {
      if (param1.id === 'total' || param2.id === 'total') {
        return 0;
      }

      // Set n/a value as -1, to compare n/a with number
      const _a = a ?? -1;
      const _b = b ?? -1;

      if (!Number.isNaN(Number(_a)) && !Number.isNaN(Number(_b))) return Number(_a) - Number(_b);

      return a.localeCompare(b);
    },
    // eslint-disable-next-line consistent-return
    renderHeader: () => {
      if (field.name === 'bdg_tiv_income') {
        return (
          <Typography sx={[columnHeaderTitleStyle, { width: 190 }]} component="span">
            {t('Insured Value')}
            <br />
            {t('Business Interruption')}
          </Typography>
        );
      }
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      const isTotalRow = params.row.bdg_location === 'Total';

      if (field.is_link) {
        if (isTotalRow) return null;

        if (fieldValue.length > 10) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { property: params.row.locator },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { property: params.row.locator },
              })}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        }
      } else if (field.name === 'bdg_location') {
        if (isTotalRow) {
          return <Typography fontWeight={600}>{fieldValue}</Typography>;
        }

        const location = parseLocation(fieldValue);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      } else if (field.name === 'bdg_class_code') {
        if (isTotalRow) return null;

        const val = `${fieldValue ?? ''}${
          params.row?.bdg_class_descr ? ` - ${params.row?.bdg_class_descr}` : ''
        }`;

        return (
          <Typography sx={truncatedTextStyle} title={val}>
            {val}
          </Typography>
        );
      } else if (field.name === 'scheduleMod') {
        if (isTotalRow) return null;

        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    property: params.row.locator,
                    schedule_mod: true,
                  },
                })}`}
                underline="hover"
                sx={[
                  truncatedTextStyle,
                  {
                    color: setScheduleModeColor(+fieldValue),

                    display: 'flex',
                    alignItems: 'center',
                    gap: '14px',
                  },
                ]}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.type === 'currency') {
        const insuredValue = isTotalRow && fieldValue !== null ? String(fieldValue) : fieldValue;

        return insuredValue && insuredValue !== '-'
          ? currencyFormat('USD', String(Math.trunc(Number(insuredValue))), true).merged
          : 'n/a';
      } else {
        return fieldValue ?? '-';
      }
    },
  }));

  const handleChangeSelection = (event: SelectChangeEvent, filterName) => {
    switch (filterName) {
      case 'location':
        setLocationFilter(event.target.value);
        break;

      default:
        break;
    }
  };

  const rows = useMemo(() => {
    const expArr =
      currentExposures?.data?.map((ex) => {
        const relatedLocationExposure = currentLiabilityExposures?.data?.find(
          (exposure) => exposure?.data?.loc_seq_no === ex?.data?.bdg_loc_seq_no,
        )?.data;

        const filteredTableRows = SCHED_MOD_TABLES?.[`${smodLines.PROPERTY}`]
          ?.filter(
            (item) => String(item.FilingSetID) === relatedLocationExposure?.loc_filing_set_id,
          )
          ?.map((row) => ({
            ...row,
            MaxCredit: -row?.MaxCredit,
          }));
        const relatedFieldGroups = schedModGroupFields.filter(
          (schedMod) =>
            schedMod.pol_sched_mod_line === smodLines.PROPERTY &&
            schedMod.pol_sched_mod_state === relatedLocationExposure?.loc_address_state,
        );

        return {
          ...(ex.data ?? {}),
          locator: ex.locator,
          scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
        };
      }) ?? [];

    return expArr as any[];
  }, [currentExposures, keyValueStore, schedModGroupFields, currentLiabilityExposures]);

  const locationFilterOptions = useMemo(
    () =>
      uniqBy(
        rows?.map((r) => {
          const location = parseLocation(r.bdg_location);

          const formatted = formatLocation({
            ...location,
          });

          return {
            code: formatted.storing,
            head: formatted.showing.head,
            tail: formatted.showing.tail,
          };
        }) ?? [],
        (r) => r.code,
      ),
    [rows],
  );

  const computedRows = useMemo(() => {
    if (locationFilter === 'Location') {
      return rows;
    } else {
      return rows.filter((r) =>
        isEmpty(r.bdg_location)
          ? locationFilter === formatLocation({ addressLine1: '' }).storing
            ? true
            : r.bdg_location === locationFilter
          : r.bdg_location === locationFilter,
      );
    }
  }, [rows, locationFilter]);

  const tableRows = useMemo(() => {
    if (computedRows.length) {
      const totalComputedRow = computedRows.reduce(
        (acc, premise) => ({
          ...acc,
          bdg_tiv_building: !Number.isNaN(Number(premise.bdg_tiv_building))
            ? acc.bdg_tiv_building + Number(premise.bdg_tiv_building)
            : acc.bdg_tiv_building,
          bdg_tiv_contents: !Number.isNaN(Number(premise.bdg_tiv_contents))
            ? acc.bdg_tiv_contents + Number(premise.bdg_tiv_contents)
            : acc.bdg_tiv_contents,
          bdg_tiv_income: !Number.isNaN(Number(premise.bdg_tiv_income))
            ? acc.bdg_tiv_income + Number(premise.bdg_tiv_income)
            : acc.bdg_tiv_income,
        }),
        {
          locator: 'total',
          bdg_location: 'Total',
          bdg_tiv_building: null,
          bdg_tiv_contents: null,
          bdg_tiv_income: null,
        },
      );

      return [...computedRows, totalComputedRow];
    }

    return computedRows;
  }, [computedRows]);

  useEffect(() => {
    try {
      Promise.allSettled(propertyExposureRelatedKeyValuesMap.map((key) => fetchKeyValues(key)));
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  const showLoader = useMemo(
    () =>
      currentLiabilityExposures?.loading ||
      currentExposures?.loading ||
      groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.loading ||
      propertyExposureRelatedKeyValuesMap.some(
        (map) => keyValueStore?.[`${map}`]?.loading ?? false,
      ),
    [currentLiabilityExposures, currentExposures, keyValueStore, groups],
  );

  return (
    <>
      <PropertyDrawer />
      <PropertyExposureScheduleModDrawer />
      <Box
        sx={{
          height: 68,
          boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
          px: 3,
          py: 2,
        }}
      >
        <Stack>
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
                width: 100,
              }}
            >
              {t('Filter by')}
            </Typography>

            <FormControl sx={{ ml: 2, width: 220, height: 37 }} size="small">
              {showLoader ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <Select
                  IconComponent={KeyboardArrowDownRounded}
                  value={locationFilter}
                  onChange={(e) => handleChangeSelection(e, 'location')}
                  MenuProps={{
                    sx: {
                      width: 100,
                    },
                  }}
                >
                  <MenuItem value="Location">{t('Location (All)')}</MenuItem>
                  {locationFilterOptions.map(({ code, head, tail }) => (
                    <MenuItem key={code} value={code} sx={{ w: 100 }}>
                      <LocationShow location={{ head, tail }} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ my: 1.5 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.PROPERTY_EXPOSURES.title()}
        </Typography>

        <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
          {quotePolicyEndorsementInfoTitlesDescriptions.PROPERTY_EXPOSURES.description()}
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <DataTable
          onRowClick={(r) =>
            setSelectedPropertyRowId(r.id === selectedPropertyRowId ? '' : (r.id as string))
          }
          getRowId={(row) => row.locator}
          isHeaderWidthFit
          autoRowCellHeight
          isTotalRowVisible
          loading={showLoader}
          rows={tableRows}
          columns={propertyExposureColumns}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={computedRows?.length! < defaultRowVirtualization}
          focusedRowId={selectedPropertyRowId}
        />
      </Box>
      {showLoader ? (
        <Skeleton animation="wave" width="100%" height={60} />
      ) : (
        propertyExposuresFields.map((section) => (
          <DetailAccordion
            key={section.code}
            title={section.name ?? ''}
            formik={formik}
            state={{ ...state, bdg_occupancy }}
            setState={setState}
            fields={section.nested_fields ?? []}
            relationalFields={section?.relatedFields}
            isReadOnly
          />
        ))
      )}
    </>
  );
};

export default PropertyExposures;
