/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Price } from 'api/models/Policy/Endorsements/endorsementPriceChange.model';
import { ExposurePricing } from 'api/models/Policy/policiesPrice.model';
import ArrowDown from 'assets/images/ArrowDown.svg';
import ArrowGrey from 'assets/images/ArrowGrey.svg';
import Car from 'assets/images/car.svg';
import DocumentFileText from 'assets/images/documents-file-text.svg';
import HighRiseBuilding from 'assets/images/high-rise-building.svg';
import PinLocation from 'assets/images/pin-location.svg';
import ServerDatabasesDown from 'assets/images/server-databases-down.svg';
import ServerDatabasesUp from 'assets/images/server-databases-up.svg';
import UserProfile from 'assets/images/user-profile.svg';
import {
  LOCATION_DEFAULT_PERIL,
  quotePolicyEndorsementInfoTitlesDescriptions,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import LocationShow from 'components/LocationShow';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { tabTitleStyles } from 'helpers/MuiSharedStyles';
import {
  colorOfAmount,
  displayCorrectAmount,
  renderAddress,
} from 'helpers/PricingBreakdownHelpers';
import { formatLocation, parseLocation } from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useUser from 'hooks/useUser';
import { isEmpty, omit, uniqBy } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

interface IPerilPriceRowProps {
  peril: Price;
  idx: string;
  onClick?: any;
  perilCollapse: any;
  exposure: ExposurePricing;
  perilsRatingFactors: any;
}

export interface IRow {
  id: number;
  key: string;
  value: string;
}

const PerilPriceRow: FC<IPerilPriceRowProps> = ({
  peril,
  idx,
  onClick,
  perilCollapse,
  exposure,
  perilsRatingFactors,
}) => {
  const { title, name, new_gross_premium, locator } = peril;
  const perilRatingFactor = perilsRatingFactors.find(
    (eachPeril) => eachPeril.perilLocator === locator,
  ) as object[];

  const perilRatingFactorObject = omit(perilRatingFactor, [
    'exposureLocator',
    'perilName',
    'perilLocator',
  ]);

  return (
    <>
      <TableRow
        key={idx}
        sx={{
          borderColor: (theme) => theme.customColors.grey50,
          borderLeft: (theme) =>
            `4px solid ${theme.customColors.exposureIconColor[exposure.name!]}!important`,
          '& > .MuiTableCell-root': {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 400,
          },
        }}
      >
        <TableCell sx={{ width: '64px' }} />
        <TableCell sx={{ p: 0, width: '36px' }}>
          {!isEmpty(perilRatingFactorObject) ? (
            <IconButton
              aria-label="expand row"
              size="small"
              disableFocusRipple
              disableTouchRipple
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: '18px',
                p: 1,
                backgroundColor: 'transparent !important',
                minWidth: '24px',
              }}
            >
              {perilCollapse.includes(idx) ? (
                <Typography
                  sx={{
                    '&>.plus-icon>div': {
                      display: 'flex',
                      'svg>path': {
                        stroke: (theme) => theme.customColors.exposureIconColor[exposure.name!],
                      },
                    },
                  }}
                  onClick={onClick}
                >
                  <ReactSVG src={ServerDatabasesUp} className="plus-icon" />
                </Typography>
              ) : (
                <Typography
                  sx={{
                    '&>.plus-icon>div': {
                      display: 'flex',
                      'svg>path': {
                        stroke: (theme) => theme.customColors.exposureIconColor[exposure.name!],
                      },
                    },
                  }}
                  onClick={onClick}
                >
                  <ReactSVG src={ServerDatabasesDown} className="plus-icon" />
                </Typography>
              )}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell align="left" sx={{ width: 1 }}>
          {isEmpty(title) ? name ?? '' : title}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            padding: (theme) => theme.spacing(0.5, 1, 0.5, 1),
            minWidth: '140px',
            ...colorOfAmount(new_gross_premium),
          }}
        >
          {displayCorrectAmount(new_gross_premium)}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            minWidth: '140px',
            textAlign: 'right',
          }}
        />
      </TableRow>

      <TableRow
        sx={{
          backgroundColor: '#FBF5F1',
        }}
      >
        <TableCell sx={{ p: 0 }} colSpan={8}>
          <Collapse in={perilCollapse.includes(idx)} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {Object.entries(perilRatingFactorObject).map(([key, value]) => (
                  <>
                    <TableRow
                      key={idx}
                      sx={{
                        '& > .MuiTableCell-root': {
                          fontSize: '12px',
                          lineHeight: '18px',
                          fontWeight: 400,
                          border: 'none !important',
                        },
                      }}
                    >
                      <TableCell sx={{ minWidth: '410px' }} />
                      <TableCell
                        align="left"
                        sx={{
                          width: '100%',
                          paddingLeft: 1,
                        }}
                      >
                        {key}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          padding: (theme) => theme.spacing(0.5, 1, 0.5, 1),
                          minWidth: '140px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {value}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          minWidth: '140px',
                          textAlign: 'right',
                        }}
                      />
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const PricingBreakdown = () => {
  const HISTORY = useHistory();
  const [open, setOpen] = useState<number[]>([]);
  const [openPeril, setOpenPeril] = useState<string[]>([]);
  const [filter, setFilter] = useState('all');
  const { t } = useTranslation();
  const { id: policyId, endorsementId } = useParams<{ id: string; endorsementId: string }>();
  const { data: userData } = useUser();
  const { getEndorsementPrice, price } = useEndorsementDetail();

  const allPerilsRatingFactors = price?.data?.all_perils_rating_factors;

  // Function to extract UUIDs from the given string
  const extractUUIDFromString = (key: string) => {
    // Use regular expression to extract UUID
    const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
    const matches = key.match(regex);

    // If matches are found, return all of them
    const uuids = matches || [];

    const exposureLocator = uuids ? uuids[0] : null;
    const perilLocator = uuids ? uuids[1] : null;

    return { exposureLocator, perilLocator };
  };

  const perilsRatingFactors = useMemo(
    () =>
      allPerilsRatingFactors
        ? Object.entries(allPerilsRatingFactors).map(([key, value]) => {
            const { exposureLocator, perilLocator } = extractUUIDFromString(key);
            return { exposureLocator, perilLocator, ...value };
          })
        : [],
    [allPerilsRatingFactors],
  );

  const fetchEndorsementPrice = async () => {
    try {
      await getEndorsementPrice(policyId, endorsementId);
    } catch (e) {
      displayBackendErrorMessage(e, t('An error occured while fetching the price detail.'));
      HISTORY.replace({
        search: '',
      });
    }
  };

  useEffect(() => {
    fetchEndorsementPrice();
  }, []);

  const computedPrices = useMemo(() => {
    if (filter === 'all') {
      return price;
    } else {
      const filteredExposurePrices = price?.data?.exposure_prices?.filter(
        (item) => item.name === filter,
      );
      return { ...price, data: { ...price?.data, exposure_prices: filteredExposurePrices } };
    }
  }, [price, filter]);

  const filterOptions = useMemo(() => {
    const exposures = price?.data?.exposure_prices!.map((item) => ({
      code: item.name,
      name: item.title,
    }));

    const options = !isEmpty(exposures)
      ? [{ code: 'all', name: 'Type(All)' }, ...exposures!]
      : [{ code: 'all', name: 'Type(All)' }];

    return price?.loaded && uniqBy(options, (r) => r.code);
  }, [price]);

  const getExposureAddress = (exposure: Price) => {
    if (exposure.name === 'employer') {
      return exposure.data?.emp_state;
    }
    if (exposure.name !== 'location') {
      const fieldKey =
        exposure.data && Object.keys(exposure.data).find((i) => i.includes('location'));

      if (!fieldKey) {
        return '-';
      }

      const fieldValue = fieldKey && exposure.data?.[fieldKey];
      const location = parseLocation(fieldValue);

      const formatted = formatLocation({
        ...location,
      });

      return <LocationShow location={formatted.showing} />;
    }

    const formatted = renderAddress(exposure.data);

    return <LocationShow location={formatted} />;
  };

  const handleChangeSelection = (event: SelectChangeEvent<unknown>) => {
    setFilter(event.target.value as string);
  };

  const getExposureInstance = (exposure: Price) => {
    switch (exposure.name) {
      case 'location':
      case 'policyInformation': {
        return exposure.title;
      }
      case 'vehicle': {
        const { veh_vin = '', veh_year = '', veh_make = '', veh_model = '' } = exposure.data ?? {};

        return `${veh_year}, ${veh_make}, ${veh_model}, ${veh_vin}`;
      }
      case 'building': {
        const { bdg_name } = exposure.data ?? {};

        return bdg_name ?? '';
      }

      case 'employer': {
        return `${exposure.title} ${exposure.data?.emp_state}`;
      }

      default:
        return '';
    }
  };

  const groupKeys = {
    location: t('Liability'),
    building: t('Property'),
    vehicle: t('Auto'),
    employer: t('Workers Compensation'),
    policyInformation: t('Fixed Premium Charge'),
  };

  const handleCollapseClick = (id: number) => {
    const isExist = open.includes(id!);
    if (isExist) {
      const newOpenRows = open.filter((item) => item !== id);
      setOpen(newOpenRows);
      closeCollapseForPerilValues(id);
    } else {
      setOpen([...open, id]);
    }
  };

  const handleCollapseClickForPeril = (id: string) => {
    const isExist = openPeril.includes(id!);
    if (isExist) {
      const newOpenRows = openPeril.filter((item) => item !== id);
      setOpenPeril(newOpenRows);
    } else {
      setOpenPeril([...openPeril, id]);
    }
  };

  const closeCollapseForPerilValues = (id: number) => {
    const getExposureLocator = (item: string) => item.split(',')[0];
    const newOpenRows = openPeril.filter((item) => getExposureLocator(item) !== id.toString());
    setOpenPeril(newOpenRows);
  };

  const getExposureIcon = (exposureName: string) => {
    let iconComponent;

    switch (exposureName) {
      case threeExposureNames.BUILDING:
        iconComponent = HighRiseBuilding;
        break;

      case threeExposureNames.EMPLOYER:
        iconComponent = UserProfile;
        break;

      case threeExposureNames.LOCATION:
        iconComponent = PinLocation;
        break;

      case threeExposureNames.POLICY_INFORMATION:
        iconComponent = DocumentFileText;
        break;

      case threeExposureNames.VEHICLE:
        iconComponent = Car;
        break;

      default:
        break;
    }

    return iconComponent;
  };

  return (
    <>
      {userData?.role?.code === userRoles.UNDERWRITER.code && (
        <Stack gap={3} sx={{ maxWidth: 1052, mb: '90px' }}>
          <Box>
            <Typography sx={[tabTitleStyles]}>
              {quotePolicyEndorsementInfoTitlesDescriptions.PRICING_BREAKDOWN.title()}
            </Typography>

            <Table aria-label="Summary Table">
              <TableHead>
                <TableRow
                  sx={{
                    '& > .MuiTableCell-root': {
                      fontWeight: 700,
                      padding: (theme) => theme.spacing(0.5, 1, 0.5, 1),
                      lineHeight: '20px',
                      verticalAlign: 'bottom',
                      borderColor: (theme) => theme.customColors.gunMetal,
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      minWidth: 772,
                    }}
                  >
                    {t('Line of Business')}
                  </TableCell>

                  <TableCell align="right" size="small">
                    {t('Full Term Premium ')}
                  </TableCell>
                  <TableCell align="right">{t('Change')}</TableCell>
                </TableRow>
              </TableHead>
              {computedPrices?.loading &&
                [...Array(5).keys()].map((i) => (
                  <TableHead key={i}>
                    <TableRow
                      sx={{
                        '& > .MuiTableCell-root': {
                          px: 1,
                          py: 0.75,
                          lineHeight: '20px',
                        },
                      }}
                    >
                      <TableCell sx={{ width: 1 }}>
                        <Skeleton animation="wave" width="30%" />
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'right',
                          minWidth: '140px',
                        }}
                      >
                        <Skeleton animation="wave" width="30%" sx={{ marginLeft: 'auto' }} />
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'right',
                          minWidth: '140px',
                        }}
                      >
                        <Skeleton animation="wave" width="30%" sx={{ marginLeft: 'auto' }} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ))}
              {!computedPrices?.loading && (
                <TableBody>
                  {computedPrices?.data?.groups &&
                    Object.keys(price?.data?.groups).map((group, index) => {
                      const groupData = computedPrices?.data?.groups[group];
                      const indexOfLastElement =
                        Object.keys(computedPrices?.data?.groups).length - 1;

                      return (
                        <TableRow
                          key={group}
                          sx={{
                            '& > .MuiTableCell-root': {
                              px: 1,
                              py: 0.75,
                              lineHeight: '20px',
                              borderColor:
                                index === indexOfLastElement
                                  ? (theme) => theme.customColors.gunMetal
                                  : (theme) => theme.customColors.grey50,
                            },
                          }}
                        >
                          <TableCell sx={{ minWidth: 772 }}>{groupKeys[group]}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'right',
                              minWidth: '140px',
                              ...colorOfAmount(groupData.new_gross_premium),
                            }}
                          >
                            {displayCorrectAmount(groupData.new_gross_premium)}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'right',
                              minWidth: '140px',
                              ...colorOfAmount(groupData.gross_premium_change),
                            }}
                          >
                            {displayCorrectAmount(groupData.gross_premium_change)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow
                    key={'total'}
                    sx={{
                      '& > .MuiTableCell-root': {
                        px: 1,
                        py: 0.75,
                        lineHeight: '20px',
                        fontWeight: 700,
                        borderColor: (theme) => theme.customColors.gunMetal,
                      },
                    }}
                  >
                    <TableCell sx={{ width: 1 }}>Total</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                        minWidth: '140px',
                        ...colorOfAmount(computedPrices?.data?.new_gross_premium),
                      }}
                    >
                      {displayCorrectAmount(computedPrices?.data?.new_gross_premium)}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                        minWidth: '140px',
                        ...colorOfAmount(computedPrices?.data?.total_change),
                      }}
                    >
                      {displayCorrectAmount(computedPrices?.data?.total_change)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Box>

          <>
            <Box
              sx={{
                boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
                px: 3,
                py: 2,
                mb: -1.5,
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    fontWeight: 600,
                    width: 100,
                    fontSize: 16,
                    lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
                  }}
                >
                  {t('Filter by')}
                </Typography>

                <FormControl sx={{ ml: 2, width: 220, height: 36 }} size="small">
                  {computedPrices?.loading ? (
                    <Skeleton animation="wave" width="100%" height={37} />
                  ) : (
                    <Select
                      MenuProps={{
                        sx: {
                          width: 100,
                        },
                      }}
                      IconComponent={KeyboardArrowDownRounded}
                      value={filter}
                      onChange={handleChangeSelection}
                    >
                      {filterOptions &&
                        filterOptions!.map((option) => (
                          <MenuItem key={option.code} value={option.code} sx={{ w: 100 }}>
                            {t(`${option.name}`)}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </FormControl>
              </Box>
            </Box>
            {/* exposures */}
            {computedPrices?.loading && (
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    ml: 1,
                  }}
                >
                  <Skeleton animation="wave" width="25%" />
                </Typography>
                <Divider
                  sx={{
                    borderColor: (theme) => theme.customColors.gunMetal,
                  }}
                />
                <Table aria-label="Exposure Table skeleton">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          minWidth: {
                            xs: 'unset',
                          },
                          py: 1,
                        }}
                      />
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: 700,
                          padding: (theme) => theme.spacing(1, 1, 1, 1),
                        }}
                      >
                        <Skeleton animation="wave" width="30%" sx={{ marginLeft: 'auto' }} />
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: 700,
                          padding: (theme) => theme.spacing(1, 1, 1, 1),
                          py: 1,
                        }}
                      >
                        <Skeleton animation="wave" width="30%" sx={{ marginLeft: 'auto' }} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(3).keys()].map((pi) => (
                      <TableRow
                        key={pi}
                        sx={{
                          '& > .MuiTableCell-root': {
                            px: 1,
                            py: 0.75,
                            lineHeight: '20px',
                          },
                        }}
                      >
                        <TableCell sx={{ width: 1 }}>
                          <Skeleton animation="wave" width="30%" />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'right',
                            minWidth: '140px',
                          }}
                        >
                          <Skeleton animation="wave" width="30%" sx={{ marginLeft: 'auto' }} />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'right',
                            minWidth: '140px',
                          }}
                        >
                          <Skeleton animation="wave" width="30%" sx={{ marginLeft: 'auto' }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}

            {!computedPrices?.loading && (
              <Box>
                <Table aria-label={`Exposure Table`}>
                  <TableHead>
                    <TableRow
                      sx={{
                        '& > .MuiTableCell-root': {
                          padding: (theme) => theme.spacing(0.5, 1, 0.5, 1),
                          fontWeight: 700,
                          fontSize: '14px',
                          verticalAlign: 'bottom',
                          borderColor: (theme) => theme.customColors.gunMetal,
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          minWidth: '42px',
                        }}
                      />
                      <TableCell
                        align="left"
                        sx={{
                          minWidth: '368px',
                        }}
                      >
                        {t('Exposure Instance')}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          width: '100%',
                        }}
                      />
                      <TableCell align="right" size="small">
                        {t('Full Term Premium ')}
                      </TableCell>
                      <TableCell align="right">{t('Change')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...(computedPrices?.data?.exposure_prices ?? [])].map(
                      (exposure, pi: number) => (
                        <React.Fragment key={pi}>
                          <TableRow
                            sx={{
                              borderColor: (theme) => theme.customColors.grey50,
                              borderBottom: (theme) => `1.5px solid ${theme.customColors.grey50}`,
                              borderLeft: (theme) =>
                                `4px solid ${theme.customColors.exposureIconColor[exposure.name!]}`,
                              '& > .MuiTableCell-root': {
                                px: 1,
                                py: '5px',
                                fontWeight: 400,
                                textAlign: 'right',
                                fontSize: '12px',
                                lineHeight: '18px',
                              },
                            }}
                          >
                            <TableCell>
                              {exposure?.peril_prices!.length >= 1 && (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  disableFocusRipple
                                  disableTouchRipple
                                  onClick={() => handleCollapseClick(pi)}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    maxHeight: '18px',
                                    p: 0,
                                    backgroundColor: 'transparent !important',
                                    minWidth: '24px',
                                  }}
                                >
                                  {open.includes(pi) ? (
                                    <ReactSVG src={ArrowDown} />
                                  ) : (
                                    <ReactSVG src={ArrowGrey} />
                                  )}
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'left !important',
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ReactSVG src={getExposureIcon(exposure.name!)} />
                                <Box component="span" sx={{ whiteSpace: 'nowrap', ml: 0.5 }}>
                                  {getExposureInstance(exposure) ?? ''}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'left !important',
                                paddingRight: '2rem !important',
                                paddingLeft: '0',
                              }}
                            >
                              {getExposureAddress(exposure) ?? ''}
                            </TableCell>
                            <TableCell
                              sx={{
                                minWidth: 140,
                                ...colorOfAmount(exposure.new_gross_premium),
                              }}
                            >
                              {displayCorrectAmount(exposure.new_gross_premium)}
                            </TableCell>
                            <TableCell
                              sx={{
                                minWidth: 140,
                                ...colorOfAmount(exposure.gross_premium_change),
                              }}
                            >
                              {displayCorrectAmount(exposure.gross_premium_change)}
                            </TableCell>
                          </TableRow>
                          {exposure?.peril_prices!.length > 0 && (
                            <TableRow
                              sx={{
                                '& > .MuiTableCell-root': {
                                  borderBottom: !open.includes(pi)
                                    ? 'none'
                                    : '1px solid rgba(224, 224, 224, 1)',
                                },
                              }}
                            >
                              <TableCell sx={{ p: 0 }} colSpan={8}>
                                <Collapse in={open.includes(pi)} timeout="auto" unmountOnExit>
                                  <Table size="small" aria-label="purchases">
                                    <TableBody>
                                      {exposure
                                        ?.peril_prices!.filter(
                                          (item) => item.title !== LOCATION_DEFAULT_PERIL,
                                        )
                                        .map((peril, idx) => {
                                          const key = `${pi},${idx}`;
                                          return (
                                            <PerilPriceRow
                                              peril={peril}
                                              idx={key}
                                              key={key}
                                              onClick={() => handleCollapseClickForPeril(key)}
                                              perilCollapse={openPeril}
                                              exposure={exposure}
                                              perilsRatingFactors={perilsRatingFactors}
                                            />
                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ),
                    )}
                  </TableBody>
                </Table>
              </Box>
            )}
          </>

          {/* exposures */}
        </Stack>
      )}
    </>
  );
};

export default PricingBreakdown;
