import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { DialogContext, initialDialogData } from './DialogProviderContext';
import { TDialogs } from './types';

export const DialogProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialDialogData);

  const resetDialogState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialDialogData,
    }));
  };

  const setDialogOpen = ({
    isOpen,
    dialog,
    onAccept,
    context = null,
  }: {
    isOpen: boolean;
    dialog: TDialogs;
    onAccept?: () => Promise<void>;
    context?: Record<string, any> | null;
  }): void => {
    setState((prevState) => ({
      ...prevState,
      dialog,
      context,
      dialogs: { ...prevState.dialogs, [dialog]: { open: isOpen, onAccept } },
    }));
  };

  return (
    <DialogContext.Provider value={{ ...state, resetDialogState, setDialogOpen }}>
      {children}
    </DialogContext.Provider>
  );
};
