import { Box, Stack, Typography } from '@mui/material';
import Head from 'components/Head';
import SettingsHeader from 'components/Settings/Header';
import SettingsTopActionBar from 'components/Settings/TopActionBar/SettingsTopActionBar';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const SettingsAbout: FC = () => {
  const { t } = useTranslation();
  const releaseVersion = import.meta.env.REACT_APP_VERSION; // Frontend version
  const tapReleaseVersion = import.meta.env.REACT_APP_TAP_VERSION; // THREE Agent Portal (TAP) version

  const settingsAboutData = [
    {
      id: 1,
      label: t('THREE Agent Portal (TAP) Version'),
      version: `v${tapReleaseVersion}`,
    },
    {
      id: 2,
      label: t('Frontend Version'),
      version: `v${releaseVersion}`,
    },
  ];

  return (
    <>
      <Head title={t('Settings About')} />
      <SettingsTopActionBar />
      <SettingsHeader currentTab={2} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {settingsAboutData.map((item) => (
          <Stack
            key={item.id}
            spacing={{ xs: 1 }}
            sx={{
              ml: 3,
              mt: 3,
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '24px',
              }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{
                mt: 1,
                color: (theme) => theme.customColors.black,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '14px',
              }}
            >
              {item.version}
            </Typography>
          </Stack>
        ))}
      </Box>
    </>
  );
};

export default SettingsAbout;
