import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LiabilityExposurePerilDetailDrawer from './Detail';
import LiabilityExposurePerilEditDrawer from './Edit';

export interface ILiabilityExposurePerilDrawer {
  isEdit: boolean;
}
const LiabilityExposurePerilDrawer: FC<ILiabilityExposurePerilDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isLiabilityExposurePerilDrawerOpen, setIsLiabilityExposurePerilDrawerOpen] =
    useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (
      url?.tab === 'liability_exposures' &&
      ((url?.perilId && url?.exposureId) || !isEmpty(url?.addPeril)) &&
      !url?.schedule_mod
    ) {
      setIsLiabilityExposurePerilDrawerOpen(true);
    } else if (url?.tab === 'liability_exposures') {
      setIsLiabilityExposurePerilDrawerOpen(false);
    }
  }, [LOCATION]);

  return isLiabilityExposurePerilDrawerOpen ? (
    isEdit ? (
      <LiabilityExposurePerilEditDrawer
        isDrawerOpen={isLiabilityExposurePerilDrawerOpen}
        setIsDrawerOpen={setIsLiabilityExposurePerilDrawerOpen}
        isAdd={!isEmpty(url?.addPeril)}
      />
    ) : (
      <LiabilityExposurePerilDetailDrawer
        isDrawerOpen={isLiabilityExposurePerilDrawerOpen}
        setIsDrawerOpen={setIsLiabilityExposurePerilDrawerOpen}
      />
    )
  ) : (
    <></>
  );
};
export default LiabilityExposurePerilDrawer;
