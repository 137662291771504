import StaticErrorPage from 'components/StaticErrorPage';
import qs from 'query-string';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

const SSOLoginErrorPage: FC = () => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const ERROR_DESCRIPTION = url.error_description;
  const ERROR = url.error;
  const TRACKING = url.tracking;

  return (
    <StaticErrorPage
      title="Auth0 Login Error"
      error={ERROR}
      error_description={ERROR_DESCRIPTION}
      tracking={TRACKING}
    />
  );
};

export default SSOLoginErrorPage;
