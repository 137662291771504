import * as Sentry from '@sentry/react';
import { requiredEnvVars } from 'common/constants';

export const checkEnvVariables = () => {
  const missingVars = requiredEnvVars.filter((varName) => !import.meta.env[varName]);

  if (missingVars.length > 0) {
    const message = `These mandatory environment variables are not found:
    ${missingVars.join(', ')}`;
    Sentry.captureException(new Error(message));

    return false;
  }

  return true;
};
