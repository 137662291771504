import { Box, Link, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { GridAlignment, GridColDef, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { Task } from 'api/models/Tasks/task.model';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultDebounceDelay,
  taskStatuses
} from 'common/constants';
import DataTable from 'components/DataTable';
import Head from 'components/Head';
import MenuComponent from 'components/MenuComponent';
import PageHeader from 'components/PageHeader';
import QuickActionMenu from 'components/Tasks/QuickActionMenu';
import TaskDetail from 'components/Tasks/TaskDetail';
import ViewSearch from 'components/ViewSearch';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { usePreviousValue } from 'helpers/PreviousValue';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  hasDiffKeyValues,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useTasks from 'hooks/useTasks';
import useUser from 'hooks/useUser';
import { debounce } from 'lodash-es';
import { ITasksPagination } from 'providers/PoliciesProvider/types';
import qs from 'query-string';
import React, { ChangeEvent, createRef, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

interface IHeaderMenuOptions {
  id: number;
  value: string;
  status?: string;
}

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const DEFAULT_ORDER = { field: 'created_at', sort: 'desc' } as GridSortItem;

const AllTasks: FC = () => {
  const { setDialogOpen } = useDialog();
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { formatDateInTimeZone } = useConfig();
  const queryParams = qs.parse(LOCATION.search) as unknown as ITasksPagination;
  const previousLocationSearch = usePreviousValue(queryParams);
  const { data: user } = useUser();
  const url = qs.parse(LOCATION.search);

  const {
    data: tasks,
    deleteTask,
    reFetchData,
    closeTask,
    addToQueue,
    fetch,
    updateTask,
    loading,
    pagination,
  } = useTasks();
  const themeHook = useTheme();

  const [selectedRowId, setSelectedRowId] = useState<string | number>(-1);

  const isDetailTaskDrawerOpen = url?.message !== undefined;
  const [pageNumber, setPage] = useState(
    queryParams?.page ? parseInt(queryParams?.page as unknown as string, 10) - 1 : 0,
  );

  const headerMenuOptions: IHeaderMenuOptions[] = [
    { id: 1, value: t('Open Messages'), status: 'open' },
    { id: 2, value: t('Closed Messages'), status: 'closed' },
  ];

  const columns: IColumns[] = [
    {
      name: 'title',
      display_name: t('Title'),
      minWidth: 130,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'button',
      display_name: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 40,
    },
    {
      name: 'reference__reference_type',
      display_name: t('Related to'),
      type: 'string',
      flex: 0.5,
      minWidth: 120,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
    },
    {
      name: 'named_insured',
      display_name: t('Named Insured'),
      type: 'string',
      minWidth: 140,
      flex: 1,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'assigned_to__full_name',
      display_name: t('Assigned to'),
      type: 'string',
      minWidth: 140,
      flex: 1,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'created_by__full_name',
      display_name: t('Created By'),
      type: 'string',
      minWidth: 140,
      flex: 1,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'created_at',
      display_name: t('Opened at'),
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.1,
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'priority__value',
      display_name: t('Priority'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 120,
      flex: 0.1,
    },
  ];

  const dueDatePriorityOrderingOption: string = 'due_date__priority';

  const parseStatus = () => {
    if (queryParams?.status) {
      switch (queryParams.status) {
        case taskStatuses.OPEN.key:
          return headerMenuOptions[0];
        case taskStatuses.CLOSED.key:
          return headerMenuOptions[1];

        default:
          return headerMenuOptions[0];
      }
    } else {
      return headerMenuOptions[0];
    }
  };

  const [headerValue, setHeaderValue] = useState(parseStatus());
  const searchInputRef = createRef<HTMLInputElement>();


  const handleCloseTask = async (task: Task) => {
    const updatedTask = { status: taskStatuses.CLOSED.key };
    await closeTask(task.display_id!, updatedTask);
    await reFetchData();
    displayToastMessage('SUCCESS', t('The message has been marked as closed.'));
  };

  const handleDeleteTask = async (taskId: number | string) => {
    setDialogOpen({
      dialog: 'DELETE_TASK',
      isOpen: false,
    });
    await deleteTask(taskId);
    await reFetchData();

    displayToastMessage('SUCCESS', t('The message has been deleted.'));
  };

  const handleAssignToQueue = async (task: Task, id: number) => {
    const updatedTask = { task_queue: id };
    await addToQueue(task.display_id!, updatedTask);
    await reFetchData();
    displayToastMessage('SUCCESS', t('The message has been assigned to queue.'));
  };

  const handleAssignToMe = async (task: Task) => {
    const updatedTask = { assignee: user?.id };
    await updateTask(task.display_id!, updatedTask);
    await reFetchData();
    displayToastMessage('SUCCESS', t('The message has been assigned to me.'));
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    HISTORY.push({ search: qs.stringify({ ...queryParams, search: event.target.value, page: 1 }) });
  };

  const checkIsOrderFieldInColumns = (field: string) =>
    columns.some((col) => col.name === field?.replaceAll('-', ''));

  const checkIsOrderDefaultField = () => {
    if (DEFAULT_ORDER?.sort === 'desc') {
      return `-${DEFAULT_ORDER.field}`;
    } else {
      return DEFAULT_ORDER.field;
    }
  };

  const parseOrdering = () => {
    if (queryParams?.ordering) {
      const splitOrdering = queryParams.ordering?.split(',')?.[0];
      if (splitOrdering?.includes('-')) {
        const field = splitOrdering?.replaceAll('-', '');
        return checkIsOrderFieldInColumns(field)
          ? ({ field, sort: 'desc' } as GridSortItem)
          : DEFAULT_ORDER;
      } else {
        return checkIsOrderFieldInColumns(splitOrdering)
          ? ({ field: splitOrdering, sort: 'asc' } as GridSortItem)
          : DEFAULT_ORDER;
      }
    } else {
      return DEFAULT_ORDER;
    }
  };

  const [sortModel, setSortModel] = useState<GridSortModel>(
    queryParams?.ordering ? [parseOrdering()] : [DEFAULT_ORDER],
  );

  const columnsAllTasks: GridColDef[] = columns.map((field, i) => ({
    field: field.name,
    headerName: field.display_name,
    width: field.width,
    minWidth: field.minWidth,
    align: field.align,
    headerAlign: field.headerAlign,
    flex: field.flex ?? 1,
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (fieldValue === '-' && i !== 0 && field.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (i === 0) {
        const r = params.row as Task;

        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { message: r?.display_id! },
            })}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.type === dataFieldTypes.ACTION) {
        const r = params.row as Task;

        return (
          <QuickActionMenu
            key={r.id}
            rowData={r}
            selectedRowId={selectedRowId}
            setSelectedRowId={setSelectedRowId}
            handleCloseTask={handleCloseTask}
            handleDeleteTask={handleDeleteTask}
            handleAssignToQueue={handleAssignToQueue}
            handleAssignToMe={handleAssignToMe}
            taskType="allTasks"
          />
        );
      } else if (field.name === 'reference__reference_type') {
        const r = params.row as Task;
        let mainPath = '';

        switch (r.reference?.reference_link_type) {
          case 'policy':
            mainPath = `/policies/${r.reference.locator}/overview`;

            break;
          case 'policyholder':
            mainPath = `/policyholders/${r.reference.locator}/details`;

            break;
          case 'submission':
            mainPath = `/applications/quotes/${r.reference.locator}`;

            break;
          case 'quote':
            mainPath = `/quotes/${r.reference.locator}`;

            break;

          default:
            break;
        }
        return (
          <Link
            component={RouterLink}
            to={mainPath}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
            }}
          >
            <Stack
              sx={{
                flexDirection: {
                  sm: 'column',
                },
              }}
            >
              <Typography>{r.three_number || '-'}</Typography>
            </Stack>
          </Link>
        );
      } else if (field?.name === 'created_at') {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');
        return (
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                mr: 1,
              }}
            >
              {splitDate[0]}
            </Box>
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Box>
          </Box>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        return fieldValue && fieldValue !== '-' ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const onFilterChange = (option: IHeaderMenuOptions) => {
    HISTORY.push({
      search: qs.stringify({
        ...queryParams,
        search: '',
        page: 1,
        status: option.id === headerMenuOptions[0].id ? 'open' : 'closed',
      }),
    });
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    const [nm] = newModel;
    if (nm) {
      HISTORY.push({
        search: qs.stringify({
          ...queryParams,
          page: 1,
          ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
        }),
      });
    } else {
      HISTORY.push({
        search: qs.stringify({
          ...queryParams,
          ordering: '',
        }),
      });
    }
  };

  const setCurrentSortModel = (splitedOrdering: string | undefined) => {
    if (!splitedOrdering) {
      setSortModel([DEFAULT_ORDER]);
      return;
    }

    if (splitedOrdering?.includes('-')) {
      const field = splitedOrdering?.replaceAll('-', '');
      setSortModel([{ field, sort: 'desc' }]);
    } else {
      setSortModel([{ field: splitedOrdering, sort: 'asc' }]);
    }
  };

  useEffect(() => {
    if (hasDiffKeyValues(queryParams, previousLocationSearch, 'status', 'source')) {
      fetch({
        ...queryParams,
        // Returns the encoded form of the string. test& -> test%26.
        search: queryParams.search ? encodeURIComponent(queryParams.search) : '',
        source: 'all',
        status: queryParams?.status ?? parseStatus().status,
        ordering:
          queryParams?.ordering === dueDatePriorityOrderingOption // this is for dashboard filter
            ? dueDatePriorityOrderingOption
            : checkIsOrderFieldInColumns(queryParams?.ordering ?? '')
            ? queryParams?.ordering
            : checkIsOrderDefaultField(),
      });

      if (searchInputRef.current) {
        searchInputRef.current!.value = queryParams.search ?? '';
      }

      setPage(queryParams?.page ? parseInt(queryParams?.page as unknown as string, 10) - 1 : 0);
      setCurrentSortModel(queryParams?.ordering);
      setHeaderValue(parseStatus());
    }
  }, [LOCATION.search]);

  return (
    <>
      <Head title={t('All Messages')} />
      {isDetailTaskDrawerOpen && <TaskDetail isDrawerOpen={isDetailTaskDrawerOpen} />}
      <PageHeader
        sx={{
          px: 3,
        }}
        left={
          <MenuComponent
            titleMenu
            anchor={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  sx={{
                    userSelect: 'none',
                    color: (theme) => theme.customColors.pageHeader.title,
                    fontWeight: '500',
                  }}
                >
                  {headerValue.value}
                </Typography>
              </Box>
            }
          >
            <Box sx={{ padding: (theme) => theme.spacing(0.5, 0) }}>
              {headerMenuOptions.map((option) => (
                <MenuItem
                  onClick={() => onFilterChange(option)}
                  key={option.value}
                  value={option.id}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Box>
          </MenuComponent>
        }
        right={
          <ViewSearch
            onChange={debounce(handleSearchInputChange, defaultDebounceDelay)}
            inputRef={searchInputRef}
            defaultValue=""
          />
        }
      />

      <DataTable
        autoRowCellHeight
        rowHeight={75}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        focusedRowId={selectedRowId}
        rows={tasks! ?? []}
        columns={columnsAllTasks}
        paginationMode="server"
        columnVisibilityModel={createColumnVisibilityModel(columns)}
        paginationModel={{ page: pageNumber, pageSize: pagination.page_size! }}
        pageSizeOptions={[pagination.page_size!]}
        rowCount={pagination.total_count}
        onPageChange={(page) => {
          HISTORY.push({
            search: qs.stringify({ ...queryParams, page: page + 1 }),
          });
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        hideFooterPagination={pagination.total_count! < pagination.page_size!}
        wrapperSx={{ mx: 3 }}
      />
    </>
  );
};

export default AllTasks;
