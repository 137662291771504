/* eslint-disable consistent-return */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { QuoteDocumentsRetrieve } from 'api/models/Quote/QuoteDocumentsRetrieve.model';
import { QuoteEditDocuments } from 'api/models/Quote/QuoteEditDocuments.model';
import { quotesDocumentsWithLocator, updateDocumentWithLocator } from 'api/services/Quote';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DrawerComponent from 'components/DrawerComponent';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { changedKeys, deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail/useQuoteDetail';
import { pick } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

interface EditDocumentProps {
  isDrawerOpen: boolean;
  onUpdated?: () => void;
  quoteId: string;
}

const EditDocumentDrawer: FC<EditDocumentProps> = ({ isDrawerOpen, onUpdated, quoteId }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { edit_document_id } = qs.parse(LOCATION.search);
  const { setLoading } = useLoader();
  const activeDocument = {
    data: {} as QuoteDocumentsRetrieve,
    loading: true,
    loaded: false,
  };
  const [state, setState] = useState(activeDocument);
  const { getQuoteDocumentTypes, documentTypes } = useQuoteDetail();

  const validationSchema = yup.object({
    title: yup
      .string()
      .max(512, t('Subject must be at most {{charLimit}} characters.', { charLimit: 512 }))
      .required(t('This field may not be blank.')),
    doc_type: yup.string().required(t('Document Type is required')),
  });

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['edit_document_id'],
      }),
    });
  };

  const formik = useFormik<QuoteEditDocuments>({
    initialValues: {},
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const documentVals = activeDocument.data;
        try {
          const changes = changedKeys(
            {
              title: documentVals?.title,
              doc_type: documentVals?.doc_type?.key,
            },
            values,
          );
          setLoading(true);
          await updateDocumentWithLocator(
            quoteId as string,
            edit_document_id as string,
            pick(values, changes),
          );
          handleQuery();
          onUpdated?.();
          displayToastMessage('SUCCESS', t('Changes have been saved.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const fetchDocumentAndUpdateFormik = async () => {
    try {
      await formik.setValues({
        title: state?.data?.title,
        doc_type: state?.data?.doc_type?.key!,
      });
    } catch (error) {
      displayBackendErrorMessage(error);
    }
  };

  useEffect(() => {
    if (state.data) {
      fetchDocumentAndUpdateFormik();
    }
  }, [state]);

  const quoteDetailsShow = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
      }));
      const res: QuoteDocumentsRetrieve = await quotesDocumentsWithLocator(
        quoteId,
        edit_document_id as string,
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      displayBackendErrorMessage(error);
    }
  };

  useEffect(() => {
    quoteDetailsShow();
    getQuoteDocumentTypes();
  }, []);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      onClose={() => handleQuery()}
      header={
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Edit Document Details')}
        </Typography>
      }
      content={
        <>
          <ScrollToFormikError formik={formik} />
          <form onSubmit={formik.handleSubmit} id="editDocumentForm" noValidate>
            <Stack gap={2}>
              <FormControl size="small" sx={{ flex: 1 }}>
                {state.loading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <TextField
                    required
                    size="small"
                    name="title"
                    label={t('Document Title')}
                    value={formik.values?.title ?? ''}
                    onChange={formik.handleChange}
                    helperText={formik.touched.title && formik.errors.title}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                  />
                )}
              </FormControl>
              <FormControl size="small" sx={{ flex: 1 }}>
                {state.loading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <>
                    <InputLabel required id="typeLabel">
                      {t('Document Type')}
                    </InputLabel>
                    <CustomNativeSelect
                      name="doc_type"
                      value={formik.values?.doc_type! ?? ''}
                      id="doc_type"
                      sx={{
                        '& > .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                      }}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        formik.setFieldValue('doc_type', e.target.value);
                      }}
                      // error={formik.touched.doc_type && Boolean(formik.errors.doc_type)}
                      label={t('Document Type')}
                      IconComponent={KeyboardArrowDownRounded}
                    >
                      {documentTypes?.data?.map((item) => (
                        <option value={`${item.code}`} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomNativeSelect>
                  </>
                )}
              </FormControl>
            </Stack>
          </form>
        </>
      }
      footer={
        <Box>
          <Button
            onClick={() => {
              handleQuery();
            }}
            sx={[drawerFooterSecondaryButtonStyle]}
          >
            {t('Cancel')}
          </Button>
          <Button type="submit" form="editDocumentForm" sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Save')}
          </Button>
        </Box>
      }
    />
  );
};

export default EditDocumentDrawer;
