import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DrivingIncidentsDetailDrawer from './Detail';
import DrivingIncidentsEditDrawer from './Edit';

export interface IDrivingIncidentsDrawer {
  isEdit: boolean;
}
const DrivingIncidentsDrawer: FC<IDrivingIncidentsDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isDrivingIncidentsDrawerOpen, setIsDrivingIncidentsDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'drivers' && (url?.drivingIncident || !isEmpty(url?.addDrivingIncident))) {
      setIsDrivingIncidentsDrawerOpen(true);
    } else if (url?.tab === 'drivers') {
      setIsDrivingIncidentsDrawerOpen(false);
    }
  }, [LOCATION]);

  return isDrivingIncidentsDrawerOpen ? (
    isEdit ? (
      <DrivingIncidentsEditDrawer
        isDrawerOpen={isDrivingIncidentsDrawerOpen}
        setIsDrawerOpen={setIsDrivingIncidentsDrawerOpen}
        isAdd={!isEmpty(url?.addDrivingIncident)}
      />
    ) : (
      <DrivingIncidentsDetailDrawer
        isDrawerOpen={isDrivingIncidentsDrawerOpen}
        setIsDrawerOpen={setIsDrivingIncidentsDrawerOpen}
      />
    )
  ) : (
    <></>
  );
};
export default DrivingIncidentsDrawer;
