import Dialogs from 'components/Dialogs';
import FrontChat from 'components/FrontChat';
import FullscreenLoader from 'components/FullscreenLoader';
import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';

const App: FC = () => {
  return (
    <>
      <FrontChat />
      <Dialogs />
      <FullscreenLoader />
      <ToastContainer position="top-center" newestOnTop hideProgressBar theme="colored" />
      <Routes />
    </>
  );
};

export default App;
