import { userRoles } from 'common/constants';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AutoExposureScheduleModDetailDrawer from './Detail/AutoExposureScheduleModDetailDrawer';

export interface IAutoExposureScheduleModDrawer {
  isEdit: boolean;
}

const AutoExposureScheduleModDrawer: FC<IAutoExposureScheduleModDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isAutoExposureScheduleModDrawerOpen, setIsAutoExposureScheduleModDrawerOpen] =
    useState(false);
  const url = qs.parse(LOCATION.search);
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    if (url?.tab === 'auto_exposures' && url?.vehicle && url?.schedule_mod) {
      setIsAutoExposureScheduleModDrawerOpen(true);
    } else if (url?.tab === 'auto_exposures') {
      setIsAutoExposureScheduleModDrawerOpen(false);
    }

    if (!isUnderwriter) {
      setIsAutoExposureScheduleModDrawerOpen(false);
    }
  }, [LOCATION, isUnderwriter]);

  return isAutoExposureScheduleModDrawerOpen ? (
    <AutoExposureScheduleModDetailDrawer
      isDrawerOpen={isAutoExposureScheduleModDrawerOpen}
      setIsDrawerOpen={setIsAutoExposureScheduleModDrawerOpen}
      isEdit={isEdit}
    />
  ) : (
    <></>
  );
};
export default AutoExposureScheduleModDrawer;
