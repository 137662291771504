import { Box, BoxProps, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { quoteStatuses } from 'common/constants';
import LinkTab from 'components/LinkTab';
import PageHeader from 'components/PageHeader';
import StatusBox from 'components/StatusBox';
import useConfig from 'hooks/useConfig';
import useQuoteDetail from 'hooks/useQuoteDetail';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface LinkTabProps {
  quoteId: string;
  currentTab: number;
}

const PreQualHeader: FC<LinkTabProps> = ({ quoteId, currentTab }) => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const { data, loading, loaded } = useQuoteDetail();
  const importedTheme = useTheme();
  const tabsData = [
    {
      id: 1,
      label: t('Pre-Qual Info'),
      route: `/quotes/${quoteId}/details`,
    },
  ];

  const quotedTabsData = [
    ...tabsData,
    // { id: 6, label: t('Forms/Attachments'), route: `/quotes/${quoteId}/documents` },
    // { id: 7, label: t('Future Invoices'), route: `/quotes/${quoteId}/invoices` },
  ];

  const acceptedTabsData = [
    ...tabsData,
    // { id: 6, label: t('Forms/Attachments'), route: `/quotes/${quoteId}/documents` },
    // { id: 7, label: t('Invoices'), route: `/quotes/${quoteId}/invoices` },
  ];

  const handleTabChange = (status) => {
    switch (status) {
      case quoteStatuses.QUOTED:
        return quotedTabsData;

      case quoteStatuses.ACCEPTED:
        return acceptedTabsData;

      default:
        return tabsData;
    }
  };

  const quoteInformations = [
    {
      id: 'start_date',
      key: t('Effective Date'),
      value:
        loaded && data?.characteristics?.[0].started_at
          ? formatDateInTimeZone(data?.characteristics?.[0].started_at)
          : '',
    },
    {
      id: 'end_date',
      key: t('Expiration Date'),
      value:
        loaded && data?.characteristics?.[0].ended_at
          ? formatDateInTimeZone(data?.characteristics?.[0].ended_at)
          : '',
    },
    // {
    //   id: 'date_created',
    //   key: t('Date Created'),
    //   value:
    //     loaded && data?.created_at
    //       ? formatDateInTimeZone(data?.created_at, `${defaultDateFormat} kk:mm:ss`)
    //       : '',
    // },
    // {
    //   id: 'quote_id',
    //   key: t('Quote No.'),
    //   value: loaded && data?.locator ? data?.locator : '',
    // },
  ];

  const renderBadge = (status) => {
    const statusBackgroundColor = importedTheme.customColors.submissionStatusColor[
      status
    ] as BoxProps['color'];

    switch (status) {
      case quoteStatuses.ACCEPTED:
        return <StatusBox label={t('Accepted')} color={statusBackgroundColor} />;

      case quoteStatuses.QUOTED:
        return <StatusBox label={t('Quoted')} color={statusBackgroundColor} />;

      case quoteStatuses.DISCARDED:
        return <StatusBox label={t('Discarded')} color={statusBackgroundColor} />;

      case quoteStatuses.DECLINED:
        return <StatusBox label={t('Declined')} color={statusBackgroundColor} />;

      default:
        return <StatusBox label={t('Draft')} color={statusBackgroundColor} />;
    }
  };

  return (
    <>
      <PageHeader
        sx={{ boxShadow: 'none', py: 1.25 }}
        left={
          <>
            <Typography
              variant="h6"
              sx={{ fontWeight: '500', color: (theme) => theme.customColors.pageHeader.title }}
            >
              {loading ? <Skeleton animation="wave" width={200} /> : data?.policyholder?.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                mt: 0.75,
                alignItems: 'center',
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: 'nowrap',
                  fontWeight: 400,
                  mr: 1.5,
                  fontSize: 14,
                  color: (theme) => theme.customColors.pageHeader.subTitle,
                }}
              >
                {loading ? <Skeleton animation="wave" width={100} /> : data?.product?.name}
              </Typography>
              {loading ? (
                <Skeleton animation="wave" width={100} />
              ) : (
                renderBadge(data?.state?.code!)
              )}
            </Box>
          </>
        }
        right={
          <Stack gap={2} direction="row" justifyContent="flex-end" sx={{ alignItems: 'top' }}>
            {quoteInformations.map((item) => (
              <Stack
                key={item.id}
                gap={1 / 4}
                sx={{
                  maxWidth: item.key === 'date_created' ? '103px' : 'unset',
                  '&:not(&:last-child)': {
                    borderRight: 1,
                    borderColor: (theme) => theme.customColors.grey400,
                    pr: 2,
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: (theme) => theme.customColors.grey800,
                    fontWeight: 500,
                  }}
                >
                  {loading ? <Skeleton animation="wave" width={100} /> : item.key}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.customColors.actionBar.text }}
                >
                  {loading ? <Skeleton animation="wave" width={100} /> : item.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        }
      />
      {loaded && (
        <Box>
          <LinkTab
            maxWidth={false}
            tabs={handleTabChange(data?.state?.code)}
            currentTab={currentTab}
            loading={loading}
            sx={{ mb: 0 }}
          />
        </Box>
      )}
    </>
  );
};

export default PreQualHeader;
