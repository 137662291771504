import React, { FC } from 'react';

// Styles
import { Box, Divider, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getAgencyDetail } from '../../api/services/Agencies';
import Head from '../../components/Head';
import AgentTable from '../Agents/components/agent-table';
import AgencyDetails from './components/agency-details';
import AgentPolicies from './components/policies';
import AgencyDetailTeams from './components/teams';

const AgencyDetail: FC = () => {
  const { id: agencyId } = useParams<{ id: string }>();
  const [tabValue, setTabValue] = React.useState('details');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const { data: agency, isLoading } = useQuery({
    queryKey: ['agency', agencyId],
    queryFn: () => getAgencyDetail(agencyId),
  });

  const tabs = [
    {
      label: 'Details',
      key: 'details',
    },
    {
      label: agency?.sub_agencies?.length ? `Teams (${agency?.sub_agencies.length})` : 'Teams',
      key: 'teams',
    },
    {
      label: agency?.num_agents ? `Agents (${agency?.num_agents})` : 'Agents',
      key: 'agents',
    },
    {
      label: agency?.policies?.length ? `Policies (${agency?.policies.length})` : 'Policies',
      key: 'policies',
    },
  ];

  return (
    <>
      <Head title={`Agency - ${agencyId}`} />
      <TabContext value={tabValue}>
        <Box sx={{ px: 3, py: 3, boxShadow: 'none' }}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.customColors.pageHeader.title,
            }}
          >
            {isLoading ? <Skeleton animation="wave" width={200} /> : agency?.name}
          </Typography>
        </Box>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          sx={{
            boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.06)',
            '& .MuiTabs-indicator': {
              background: isLoading ? 'transparent' : (theme) => theme.customColors.tabIndicator,
              height: 2,
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              label={tab.label}
              value={tab.key}
              key={tab.key}
              sx={{
                textTransform: 'capitalize',
                px: 3,
              }}
            />
          ))}
        </Tabs>
        <Divider />
        <TabPanel value="details">
          <AgencyDetails agency={agency} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value="teams">
          <AgencyDetailTeams teams={agency?.sub_agencies} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value="agents">
          <AgentTable agencyId={agencyId} />
        </TabPanel>
        <TabPanel value="policies">
          <AgentPolicies policies={agency?.policies} isLoading={isLoading} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default AgencyDetail;
