import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import VehicleDetailDrawer from './Detail';

const VehicleDrawer: FC = () => {
  const LOCATION = useLocation();
  const [isVehicleDrawerOpen, setIsVehicleDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'auto_exposures' && url?.vehicle && !url?.schedule_mod) {
      setIsVehicleDrawerOpen(true);
    } else if (url?.tab === 'auto_exposures') {
      setIsVehicleDrawerOpen(false);
    }
  }, [LOCATION]);

  return isVehicleDrawerOpen ? (
    <VehicleDetailDrawer
      isDrawerOpen={isVehicleDrawerOpen}
      setIsDrawerOpen={setIsVehicleDrawerOpen}
    />
  ) : (
    <></>
  );
};

export default VehicleDrawer;
