import React, { FC, useEffect, useState } from 'react';

import DataTable from 'components/DataTable';
import MenuComponent from 'components/MenuComponent';

import { getNestedValueFromObject, updateQueryStrings } from 'helpers/Utils';

import { Edit, MoreVert } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef, GridSortModel } from '@mui/x-data-grid';
import Delete from 'assets/images/icon_dropdowndelete.svg';
import Download from 'assets/images/icon_dropdowndownload.svg';
import SendViaMail from 'assets/images/icon_dropdownsendviamail.svg';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  globalSearchTypes,
} from 'common/constants';
import DocumentDetailDrawer from 'components/Quotes/QuoteDetail/Documents/Detail';
import EditDocumentDrawer from 'components/Quotes/QuoteDetail/Documents/EditDocument';
import QuoteDetailHeader from 'components/Quotes/QuoteDetail/QuoteDetailHeader';
import QuoteDetailTopActionBar from 'components/Quotes/QuoteDetail/QuoteDetailTopActionBar';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { dropdownItemsStyle } from 'helpers/MuiSharedStyles';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { initialQuoteDetailData } from 'providers/QuoteDetailProvider/QuoteDetailProviderContext';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { IAxiosError } from 'types/ErrorResponseTypes';
import qs from 'query-string';

interface IColumns {
  name: string;
  headerName: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const QuoteDetailDocuments: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const themeHook = useTheme();
  const url = qs.parse(LOCATION.search);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'created_at', sort: 'desc' },
  ]);
  const [pageNumber, setPage] = useState(0);
  const [isClickedMoreButton, setIsClickedMoreButton] = useState<boolean>(false);
  const isEditDocumentDrawerOpen = url?.edit_document_id !== undefined;
  const isDocumentDetailDrawerOpen = url?.document !== undefined;
  const [selectedRowId, setSelectedRowId] = useState<number>(-1);
  const { formatDateInTimeZone } = useConfig();

  const { getQuoteDocuments, documents, reFetchData, resetDocumentsState, deleteDocument } =
    useQuoteDetail();

  const getAssociatedWithType = (type: string) => {
    switch (type) {
      case globalSearchTypes.POLICY:
        return 'policies';

      case globalSearchTypes.QUOTE:
        return 'quotes';

      default:
        return '';
    }
  };

  useEffect(() => {
    getQuoteDocuments(id, {
      ...initialQuoteDetailData.documents!.pagination,
      ordering: '-created_at',
    });
    return () => {
      resetDocumentsState();
    };
  }, []);

  const columns: IColumns[] = [
    {
      name: 'title',
      type: 'string',
      headerName: t('Document Title'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      minWidth: 150,
      flex: 1,
    },
    {
      name: 'button',
      headerName: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 50,
    },
    {
      name: 'doc_type__value',
      type: 'string',
      headerName: t('Document Type'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 150,
      flex: 1,
    },
    {
      name: 'created_at',
      type: 'date',
      headerName: t('Uploaded at'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
    },
    {
      name: 'uploaded_by',
      type: 'string',
      headerName: t('Uploaded by'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 140,
      flex: 1,
    },
  ];

  const handleNewDocument = () => {
    reFetchData(getAssociatedWithType(globalSearchTypes.QUOTE), id);
  };

  const handleClickAway = () => {
    setIsClickedMoreButton(false);
    setSelectedRowId(-1);
  };

  const handleEditDocument = (documentId: number) => {
    setSelectedRowId(documentId);
    HISTORY.push({
      pathname: LOCATION.pathname,
      search: `?edit_document_id=${documentId}`,
    });
  };

  const deleteDocumentsFunc = async (quoteId, documentId: string) => {
    // setLoading(true);
    try {
      setDialogOpen({
        dialog: 'DELETE_DOCUMENT',
        isOpen: false,
      });
      await deleteDocument(quoteId, documentId);
      handleNewDocument();
      // setLoading(false);
      displayToastMessage('SUCCESS', t('The document has been deleted.'));
    } catch (error) {
      const e = error as IAxiosError;
      if (e.isAxiosError) {
        if (e.response?.data.messages && e.response?.data.messages[0]) {
          displayToastMessage('ERROR', e.response?.data.messages[0]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const documentsColumns: GridColDef[] = columns.map((column, index) => ({
    field: column.name,
    headerName: column.headerName,
    flex: column.flex,
    minWidth: column.minWidth,
    is_hidden: column.is_hidden,
    is_sortable: column.is_sortable,
    is_link: column.is_link,
    link_type: column.link_type,
    align: column.align,
    headerAlign: column.headerAlign,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, column.name);
      const activeEditButton: boolean = params?.row.doc_type?.key !== 'socotra';
      if (fieldValue === '-' && index !== 0 && column.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (index === 0) {
        const r = params.row as any;
        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { document: r.id },
            })}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
        // eslint-disable-next-line no-undef
      } else if (column.name === 'created_at') {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');

        return (
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                mr: 1,
              }}
            >
              {splitDate[0]}
            </Box>
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Box>
          </Box>
        );
      } else if (column.type === dataFieldTypes.ACTION) {
        const row = params.row as {
          id: number;
          signed: string;
          license: string;
          verifiedBy: string;
          uploadedAt: string;
          uploadedBy: string;
          button: string;
        };

        return (
          <Box sx={{}}>
            <MenuComponent
              renderDefaultIcon={false}
              sx={{
                height: '100%',
              }}
              anchor={
                <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
                  <IconButton
                    disableRipple
                    size="small"
                    sx={{
                      bgcolor:
                        selectedRowId === row.id && isClickedMoreButton
                          ? (theme) => theme.palette.background.default
                          : 'none',
                      color:
                        selectedRowId === row.id && isClickedMoreButton
                          ? (theme) => theme.customColors.black
                          : 'none',
                      '&:hover': {
                        color: (theme) => theme.customColors.black,
                        bgcolor: (theme) => theme.palette.background.default,
                      },
                    }}
                    onClick={() => {
                      setIsClickedMoreButton(true);
                      setSelectedRowId(row.id!);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </ClickAwayListener>
              }
            >
              <>
                <MenuItem sx={dropdownItemsStyle} value="sentViaEmail" disabled>
                  <ReactSVG src={SendViaMail} />
                  {t('Send via Mail')}
                </MenuItem>

                <Link href={`${params.row.url}`} target="_blank" sx={{ textDecoration: 'none' }}>
                  <MenuItem value="download" sx={dropdownItemsStyle}>
                    <ReactSVG src={Download} />
                    {t('Download')}
                  </MenuItem>
                </Link>

                {params.row.doc_type.key !== 'socotra' && (
                  <>
                    <MenuItem
                      value="edit"
                      onClick={() => handleEditDocument(params.row.id)}
                      sx={dropdownItemsStyle}
                      disabled={!activeEditButton}
                    >
                      <Edit
                        sx={{
                          color: (theme) => theme.customColors.black900,
                          fontSize: (theme) => theme.globalSearch.searchIconSize,
                          mr: 1,
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.customColors.black900, fontSize: 14 }}
                      >
                        {t('Edit')}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      value="delete"
                      sx={dropdownItemsStyle}
                      disabled={!activeEditButton}
                      onClick={() => {
                        setDialogOpen({
                          dialog: 'DELETE_DOCUMENT',
                          isOpen: true,
                          onAccept: () => deleteDocumentsFunc(id, params.row.id),
                        });
                      }}
                    >
                      <ReactSVG src={Delete} />
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.customColors.black900, fontSize: 14 }}
                      >
                        {t('Delete')}
                      </Typography>
                    </MenuItem>
                  </>
                )}
              </>
            </MenuComponent>
          </Box>
        );
      } else {
        return (
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);

    if (newModel.length) {
      const [nm] = newModel;
      setPage(0);
      getQuoteDocuments(id, {
        ...documents?.pagination!,
        page: 1,
        ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
      });
    } else {
      getQuoteDocuments(id, {
        ...documents?.pagination!,
        ordering: '',
      });
    }
  };

  const handleEditedDocument = () => {
    reFetchData(getAssociatedWithType(globalSearchTypes.QUOTE), id);
  };

  return (
    <>
      {isDocumentDetailDrawerOpen && (
        <DocumentDetailDrawer isDrawerOpen={isDocumentDetailDrawerOpen} quoteId={id} />
      )}
      {isEditDocumentDrawerOpen && (
        <EditDocumentDrawer
          isDrawerOpen={isEditDocumentDrawerOpen}
          quoteId={id}
          onUpdated={handleEditedDocument}
        />
      )}
      <QuoteDetailTopActionBar onDocumentCreated={handleNewDocument} />
      <QuoteDetailHeader currentTab={1} quoteId={id} />
      <DataTable
        autoRowCellHeight
        getRowId={(row) => row.id}
        rows={documents?.data ?? []}
        columns={documentsColumns}
        loading={documents?.loading!}
        paginationModel={{ page: pageNumber, pageSize: documents?.pagination?.page_size! }}
        rowCount={documents?.pagination?.total_count}
        pageSizeOptions={[documents?.pagination?.page_size!]}
        hideFooterPagination={
          documents?.pagination?.total_count! < documents?.pagination?.page_size!
        }
        onPageChange={(page) => {
          setPage(page);
          getQuoteDocuments(id, { ...documents?.pagination!, page: page + 1 });
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        wrapperSx={{
          px: 3,
          pt: 3,
          overflow: 'auto',
          height: (theme) => `calc(100vh - ${theme.quoteDetailFixedItemsHeight})`,
        }}
      />
    </>
  );
};

export default QuoteDetailDocuments;
