import React, { useState } from 'react';

/// types
import { Auth } from 'api/models/Auth/auth.model';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
/// data
import { useAuth0 } from '@auth0/auth0-react';
import TokenStorage from 'helpers/TokenStorage';
import { logOut } from '../../api/services/AuthService';
import { AuthContext, authContextDefault } from './AuthContext';
import { IAuthProps, IAuthStore } from './types';

export const AuthProvider = ({ children }: IAuthProps): React.ReactElement<IAuthProps> => {
  const [state, setState] = useState<IAuthStore>(authContextDefault);
  const HISTORY = useHistory();
  const { getAccessTokenSilently, loginWithRedirect, logout: logoutFromAuth0 } = useAuth0();

  const signInWithSSO = async ({ email }: { email: string }): Promise<Auth | null | void> => {
    setState({ ...state, ssoLoading: true });
    try {
      await loginWithRedirect({
        authorizationParams: {
          login_hint: email,
          screen_hint: 'login',
        },
        appState: {
          returnTo: '/redirect',
        },
      });
    } catch (error) {
      setState({ ...state, error: null, ssoLoading: false });
      displayToastMessage('ERROR', t('An error occurred while logging in.'));
      throw error;
    }
  };
  const getAccessTokenFromSSO = async () => {
    setState({ ...state, ssoLoading: true });
    try {
      const accessToken = await getAccessTokenSilently();
      const auth: Auth = {
        access: accessToken,
        isSSOSession: true,
      };
      TokenStorage.set(auth);
      setState({ ...state, auth, ssoLoading: false });
    } catch (error) {
      setState({ ...state, error: null, ssoLoading: false });
      displayToastMessage('ERROR', t('An error occurred while redirecting. Please login again.'));
      TokenStorage.clear();
      await logoutFromAuth0({
        logoutParams: {
          returnTo: `${window.location.origin}/login`,
        },
      });
      HISTORY.push('/login');
      throw error;
    }
  };

  const signOutSSO = async (): Promise<Auth | null> => {
    setState({ ...state, ssoLoading: true, loading: true });
    try {
      await logOut();
    } finally {
      TokenStorage.destroy();

      await logoutFromAuth0({
        logoutParams: {
          returnTo: `${window.location.origin}/login`,
        },
      });
    }
    return null;
  };

  const signOut = async (): Promise<Auth | null> => {
    try {
      await logOut();
    } finally {
      TokenStorage.destroy();
      setState({ ...state, auth: null, loading: false });
      HISTORY.push('/login');

      // for clearing all provider states
      window.location.reload();
    }
    return null;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signOut,
        signOutSSO,
        signInWithSSO,
        getAccessTokenFromSSO,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
