import { AppBar, Box, Toolbar } from '@mui/material';
import LogoSecondary from 'assets/images/logo-secondary.svg';
import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';

const PaymentsAppBar: FC = () => (
  <>
    <AppBar
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        boxShadow: 'none',
        '& > .MuiToolbar-root': {
          minHeight: (theme) => `${theme.appBarHeight}px`,
          padding: 0,
        },
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: {
            xs: 0,
            md: 2,
          },
          py: 1,
          alignItems: 'center',
          maxHeight: (theme) => `${theme.appBarHeight}px`,
          backgroundColor: (theme) => theme.customColors.topNavBar.bg,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            ml: 2,
            py: 1.25,
          }}
        >
          <ReactSVG src={LogoSecondary} width={69} height={24} />
        </Box>
      </Toolbar>
    </AppBar>
  </>
);

export default PaymentsAppBar;
