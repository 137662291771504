import React, { useState } from 'react';

/// types
import {
  addNoteService,
  deleteNoteService,
  getNotesService,
  getNoteWithLocator,
  updateNoteWithLocator,
} from 'api/services/Notes';
import { IContextProps } from 'providers/types';

/// data
import { Note } from 'api/models/Notes/note.model';
import { NoteCreate } from 'api/models/Notes/noteCreate.model';
import { AxiosError } from 'axios';
import { Pagination } from 'types/Pagination';
import { initialNotesData, NotesContext } from './NotesProviderContext';

export const NotesProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialNotesData);

  const resetNotesState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialNotesData,
    }));
  };

  const fetch = async (
    query: Pagination,
    reference_type: string,
    reference_locator: string,
  ): Promise<Note[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      let res;

      try {
        res = await getNotesService(
          {
            ...query,
            page_size: initialNotesData.pagination.page_size,
          },
          reference_type,
          reference_locator,
        );
      } catch (error) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          if (e.response?.status === 404) {
            res = await getNotesService(
              {
                ...query,
                page_size: initialNotesData.pagination.page_size,
                page: 1,
              },
              reference_type,
              reference_locator,
            );
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res?.results ?? [],
        pagination: {
          ...query,
          total_count: res?.count,
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const fetchNote = async (id: string): Promise<Note | undefined> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeNote: { ...prevState.activeNote, loading: true },
      }));

      const res = await getNoteWithLocator(id);

      setState((prevState) => ({
        ...prevState,
        activeNote: {
          ...prevState.activeNote,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        activeNote: { ...prevState.activeNote, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const createNote = async (data: NoteCreate) => {
    try {
      setState((prevState) => ({
        ...prevState,
      }));

      const res = await addNoteService(data);

      return res;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const reFetchData = async (reference_type: string, reference_locator: string) => {
    await fetch({ ...state.pagination }, reference_type, reference_locator);
  };

  const deleteNote = async (id: number) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await deleteNoteService(id);

      return res;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const updateNote = async (id: string, data: NoteCreate): Promise<NoteCreate> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeQueue: data,
      }));

      const res = await updateNoteWithLocator(id, data);

      setState((prevState) => ({
        ...prevState,
        activeNote: {
          ...prevState.activeNote,
          data: res,
          loading: false,
          loaded: true,
        },
      }));

      return res;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  return (
    <NotesContext.Provider
      value={{
        ...state,
        fetch,
        fetchNote,
        createNote,
        resetNotesState,
        deleteNote,
        reFetchData,
        updateNote,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
