import { Box, Button, CircularProgress, Typography } from '@mui/material';
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { PaymentIntent, SetupIntent } from 'api/models/Payments/payments.model';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle } from 'helpers/MuiSharedStyles';
import React, { useEffect, useState } from 'react';

interface ICheckoutForm {
  paymentIntent?: PaymentIntent;
  setupIntent?: SetupIntent;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

/**
 * CheckoutForm Component
 *
 * CheckoutForm component handles payment and setup intents using Stripe.
 * This component handles the old payment links, for the new payment links CheckoutV2
 * component is used
 *
 */

const CheckoutForm = ({ paymentIntent, setupIntent, setIsLoading, isLoading }: ICheckoutForm) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(paymentIntent?.customer_email ?? '');
  const [_message, setMessage] = useState(null as null | string);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    // eslint-disable-next-line no-shadow
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const envAppUrl = window.location.origin;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (paymentIntent) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // The URL to redirect the user to after the payment is confirmed
          return_url: `${envAppUrl}/payments/success`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error?.message ?? null);
        displayToastMessage('ERROR', error?.message);
      } else {
        setMessage('An unexpected error occurred.');
        displayToastMessage('ERROR', error?.message);
      }
    } else if (setupIntent) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // The URL to redirect the user to after the setup is confirmed
          return_url: `${envAppUrl}/payments/setup-success`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error?.message ?? null);
        displayToastMessage('ERROR', error?.message);
      } else {
        setMessage('An unexpected error occurred.');
        displayToastMessage('ERROR', error?.message);
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: { type: 'tabs', defaultCollapsed: false },
    fields: { billingDetails: { email: 'never' } },
    defaultValues: {
      billingDetails: {
        address: { country: 'US' },
        email: setupIntent?.customer_email ?? undefined,
      },
    },
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => setEmail(e.value.email)}
          options={{
            defaultValues: {
              email,
            },
          }}
        />

        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {!paymentIntent && (
          <Typography sx={{ fontSize: 12, mt: 2 }}>
            By clicking Enroll in Autopay, I authorize THREE to automatically debit this payment
            method for future insurance payments when they become due.
          </Typography>
        )}
        <Button
          sx={{ ...primaryButtonStyle, width: '100%', mt: 2 }}
          disabled={isLoading || !stripe || !elements}
          id="submit"
          type="submit"
        >
          <Box component="span" id="button-text">
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : paymentIntent ? (
              'Pay now'
            ) : (
              'Enroll in Autopay'
            )}
          </Box>
        </Button>
      </form>
    </>
  );
};

export default CheckoutForm;
