import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { TaskAssociate } from 'api/models/Tasks/taskAssociate.model';
import { getTaskAssociatesList } from 'api/services/Tasks';
import { Pagination } from 'types/Pagination';
import { initialTaskAssociatesData, TaskAssociatesContext } from './TaskAssociatesProviderContext';

export const TaskAssociatesProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialTaskAssociatesData);

  const fetch = async (query: Pagination, forceReset = false): Promise<TaskAssociate[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const taskAssociates = await getTaskAssociatesList({
        ...query,
        page_size: initialTaskAssociatesData.pagination.page_size,
        next: '',
      });

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data:
          !forceReset && query.page && query.page !== prevState.pagination.page
            ? [...prevState.data!, ...taskAssociates]
            : [...taskAssociates],
        pagination: {
          ...prevState.pagination,
          ...query,
        },
      }));

      return taskAssociates ?? [];
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const resetTaskAssociatesState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialTaskAssociatesData,
    }));
  };

  return (
    <TaskAssociatesContext.Provider value={{ ...state, fetch, resetTaskAssociatesState }}>
      {children}
    </TaskAssociatesContext.Provider>
  );
};
