/* eslint-disable no-restricted-syntax */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import AddScheduleIcon from 'assets/images/icon_schedule_mod.svg';
import {
  defaultRowVirtualization,
  exposureTypeFields,
  fixedPremiumChargePeril,
  liabilityExposureBasises,
  liabilityExposurePerils,
  liabilityExposureRelatedKeyValues,
  liabilityExposureRelatedKeyValuesMap,
  liabilityExposuresFieldCodes,
  liabilityPerilsNamesWithCustomExposureBasis,
  LOCATION_DEFAULT_PERIL,
  professionalLiabilityPerilExposureBasis,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import NumberFormatComponent from 'components/NumberFormatComponent';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { primaryButtonStyle, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import { calculateSchedMod } from 'helpers/ScheduleModificationHelpers';
import {
  createColumnVisibilityModel,
  currencyFormat,
  findMatchingKey,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  renderAddress,
  updateQueryStrings,
} from 'helpers/Utils';
import useKeyValues from 'hooks/useKeyValues';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import LiabilityExposurePerilDrawer from './Drawers/LiabilityExposurePerilDrawer/LiabilityExposurePerilDrawer';
import LiabilityExposureScheduleModDrawer from './Drawers/LiabilityExposureScheduleModDrawer/LiabilityExposureScheduleModDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

// Map perils with their exposure basis
const findLiabilityPerilsExposureType = (perilName: string, perilData: any) => {
  // If peril has custom exposure basis, then return it
  if (liabilityPerilsNamesWithCustomExposureBasis.includes(perilName)) {
    return findMatchingKey(perilData, [liabilityExposuresFieldCodes.exposureBasis]);
  }

  for (const type in liabilityExposureBasises) {
    if (liabilityExposureBasises[type].includes(perilName)) {
      if (perilName === liabilityExposurePerils.professionalLiability.code) {
        return perilData?.pl_hazard >= 50
          ? professionalLiabilityPerilExposureBasis.LAWYER_FTE_COUNT
          : professionalLiabilityPerilExposureBasis.REVENUE;
      }
      return type;
    }
  }
  return null;
};

// Find exposure amount of perils
const findExposureAmount = (exposureType: string, perilName: string, perilData: any) => {
  if (liabilityPerilsNamesWithCustomExposureBasis.includes(perilName)) {
    return findMatchingKey(perilData, [liabilityExposuresFieldCodes.exposureAmount]);
  }
  const exposureAmount = findMatchingKey(perilData, [`${exposureTypeFields[exposureType]}`]);
  return exposureAmount;
};

const LiabilityExposures: FC = () => {
  const { t } = useTranslation();
  const themeHook = useTheme();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const [selectedPerilId, setSelectedPerilId] = useState<string>('');
  const [locationFilter, setLocationFilter] = useState('Location');
  const [perilFilter, setPerilFilter] = useState('Peril');
  const { data: userData } = useUser();
  const { exposureList, groups, canEdit } = useQuoteDetail();
  const { fetch: fetchKeyValues, data: keyValueStore } = useKeyValues();

  const SCHED_MOD_LINES_PERILS =
    keyValueStore?.[`${liabilityExposureRelatedKeyValues.SCHED_MOD_LINES_PERILS}`]?.data?.value ??
    [];

  const SCHED_MOD_TABLES =
    keyValueStore?.[`${liabilityExposureRelatedKeyValues.SCHED_MOD_TABLES}`]?.data?.value ?? [];

  const schedModGroupFields: any[] =
    groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data ?? [];

  const getSchedModTableKey = (perilName) => {
    for (const [category, coverages] of Object.entries(SCHED_MOD_LINES_PERILS)) {
      if ((coverages as any).includes(perilName)) {
        return category;
      }
    }

    return null;
  };

  const currentLiabilityExposures = exposureList?.[`${threeExposureNames.LOCATION}`];

  const liabilityExposureRows = useMemo(
    () =>
      currentLiabilityExposures?.data
        ?.flatMap((m) => {
          const exposureData = m.data;

          return (
            m.perils
              ?.filter(({ name }) => name !== fixedPremiumChargePeril)
              .map(({ locator, data, title, name }) => {
                const classCode = findMatchingKey(data, ['class_code', 'hazard']);
                const exposureType = findLiabilityPerilsExposureType(name!, data);
                const exposureAmount = findExposureAmount(
                  name === liabilityExposurePerils.professionalLiability.code
                    ? 'Revenue'
                    : exposureType,
                  name!,
                  data,
                );
                const schedModTable = getSchedModTableKey(name);
                const filteredTableRows = SCHED_MOD_TABLES[schedModTable as string]
                  ?.filter((item) => String(item.FilingSetID) === exposureData?.loc_filing_set_id)
                  ?.map((row) => ({
                    ...row,
                    MaxCredit: -row?.MaxCredit,
                  }));
                const relatedFieldGroups = schedModGroupFields.filter(
                  (schedMod) =>
                    schedMod.pol_sched_mod_line === schedModTable &&
                    schedMod.pol_sched_mod_state === exposureData?.loc_address_state,
                );

                return {
                  id: locator,
                  exposureId: m.locator,
                  location: renderAddress(exposureData),
                  peril: title,
                  classCode,
                  exposureType,
                  exposureAmount,
                  scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
                };
              }) ?? []
          );
        })
        .filter((r) => r.peril !== LOCATION_DEFAULT_PERIL) ?? [],
    [currentLiabilityExposures, schedModGroupFields, keyValueStore],
  );

  const columns: IColumns[] = [
    {
      name: 'location',
      display_name: t('Location'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1.3,
      type: 'string',
      minWidth: 165,
    },
    {
      name: 'peril',
      display_name: t('Peril'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1.2,
      type: 'string',
      minWidth: 110,
    },
    {
      name: 'classCode',
      display_name: t('Class/Hazard'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 110,
      align: 'center',
      headerAlign: 'center',
    },
    {
      name: 'exposureType',
      display_name: t('Exposure Type'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 120,
    },
    {
      name: 'exposureAmount',
      display_name: t('Exposure Amount'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      minWidth: 160,
    },
    ...(userData?.role?.code === userRoles.UNDERWRITER.code
      ? ([
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod.'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 1,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const setScheduleModeColor = (value: number) => {
    if (value > 1) {
      return themeHook.customColors.errorRed;
    } else if (value < 1) {
      return themeHook.customColors.successGreen;
    }

    return themeHook.customColors.gunMetal;
  };

  const liabilityExposureColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { exposureId: params.row.exposureId, perilId: params.row.id },
            })}`}
            underline="hover"
            sx={[
              truncatedTextStyle,
              {
                color: (theme) => theme.customColors.table.link,
              },
            ]}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.name === 'scheduleMod') {
        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    exposureId: params.row.exposureId,
                    perilId: params.row.id,
                    schedule_mod: true,
                  },
                })}`}
                underline="hover"
                sx={[
                  truncatedTextStyle,
                  {
                    color: setScheduleModeColor(+fieldValue),
                    display: 'flex',
                    alignItems: 'center',
                    gap: '14px',
                  },
                ]}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(fieldValue);

        const formatted = formatLocation({
          ...location,
        });
        return <LocationShow location={formatted.showing} />;
      } else if (field.name === 'exposureType') {
        return (
          <Box sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Box>
        );
      } else if (field.name === 'exposureAmount') {
        if (isEmpty(fieldValue) || fieldValue === '-') return '-';
        return <NumberFormatComponent displayType="text" value={fieldValue} allowDecimal={false} />;
      } else if (field.type === 'number') {
        return currencyFormat('USD', fieldValue).merged;
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleChangeSelection = (event: SelectChangeEvent<unknown>, filterName) => {
    const {
      target: { value },
    } = event;

    switch (filterName) {
      case 'location':
        setLocationFilter(String(value));
        break;

      case 'peril':
        setPerilFilter(String(value));
        break;

      default:
        break;
    }
  };

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addPeril: true },
      }),
    });
  };

  const computedRows = useMemo(() => {
    if (locationFilter === 'Location' && perilFilter === 'Peril') {
      return liabilityExposureRows;
    }

    let filteredRows = liabilityExposureRows;

    if (locationFilter !== 'Location') {
      filteredRows = filteredRows.filter((r) => r.location === locationFilter);
    }

    if (perilFilter !== 'Peril') {
      filteredRows = filteredRows.filter(({ peril }) => peril === perilFilter);
      if (isEmpty(filteredRows)) setPerilFilter('Peril');
    }

    return filteredRows;
  }, [liabilityExposureRows, locationFilter, perilFilter]);

  const perilFilterOptions = useMemo(
    () =>
      uniqBy(
        liabilityExposureRows?.map((r) => ({
          id: r.id,
          title: r.peril,
        })) ?? [],
        (r) => r.title,
      ),
    [liabilityExposureRows],
  );

  const locationFilterOptions = useMemo(
    () =>
      uniqBy(
        liabilityExposureRows?.map((r) => {
          const location = parseLocation(r.location);

          const formatted = formatLocation({
            ...location,
          });

          return {
            code: formatted.storing,
            head: formatted.showing.head,
            tail: formatted.showing.tail,
          };
        }) ?? [],
        (r) => r.code,
      ),
    [liabilityExposureRows],
  );

  useEffect(() => {
    try {
      Promise.allSettled(liabilityExposureRelatedKeyValuesMap.map((key) => fetchKeyValues(key)));
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while fetching perils.'));
    }
  }, []);

  const showLoader = useMemo(
    () =>
      currentLiabilityExposures?.loading ||
      groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.loading ||
      liabilityExposureRelatedKeyValuesMap.some(
        (map) => keyValueStore?.[`${map}`]?.loading ?? false,
      ),
    [currentLiabilityExposures, keyValueStore, groups],
  );

  return (
    <>
      <LiabilityExposurePerilDrawer isEdit={canEdit} />

      <LiabilityExposureScheduleModDrawer isEdit={canEdit} />

      <Box
        sx={{
          height: 130,
          boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
          px: 3,
          py: 2,
        }}
      >
        <Stack>
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontWeight: 500,
                width: 100,
                fontSize: 16,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              }}
            >
              {t('Filter by')}
            </Typography>

            <FormControl sx={{ ml: 2, width: 220, height: 37 }} size="small">
              {showLoader ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <Select
                  IconComponent={KeyboardArrowDownRounded}
                  value={locationFilter}
                  onChange={(e) => handleChangeSelection(e, 'location')}
                  MenuProps={{
                    sx: {
                      width: 100,
                    },
                  }}
                >
                  <MenuItem value="Location">{t('Location (All)')}</MenuItem>
                  {locationFilterOptions.map(({ code, head, tail }) => (
                    <MenuItem key={code} value={code} sx={{ w: 100 }}>
                      <LocationShow location={{ head, tail }} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Box>

          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontWeight: 500,
                width: 100,
                fontSize: 16,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              }}
            >
              {t('Filter by')}
            </Typography>

            <FormControl sx={{ ml: 2, width: 220, height: 37 }} size="small">
              {showLoader ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <Select
                  IconComponent={KeyboardArrowDownRounded}
                  value={perilFilter}
                  onChange={(e) => handleChangeSelection(e, 'peril')}
                  MenuProps={{
                    sx: {
                      width: 100,
                    },
                  }}
                >
                  <MenuItem value="Peril">{t('Peril (All)')}</MenuItem>
                  {perilFilterOptions
                    .filter((item) => item.title !== LOCATION_DEFAULT_PERIL)
                    .map(({ id, title }) => (
                      <MenuItem key={id} value={title} sx={{ w: 100, whiteSpace: 'unset' }}>
                        {title}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ mt: 3 }}>
        <DataTable
          onRowClick={(r) => setSelectedPerilId(r.id === selectedPerilId ? '' : (r.id as string))}
          rows={computedRows}
          columns={liabilityExposureColumns}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={computedRows?.length! < defaultRowVirtualization}
          focusedRowId={selectedPerilId}
          loading={showLoader}
        />

        {canEdit && currentLiabilityExposures?.loaded && (
          <Box>
            <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]}>
              {t('Add Peril')}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default LiabilityExposures;
