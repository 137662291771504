import { Box, Link, Typography } from '@mui/material';
import Head from 'components/Head';
import Header from 'components/Header';
import TokenStorage from 'helpers/TokenStorage';
import { isEmpty } from 'lodash-es';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

const UnauthorizedPage: FC = () => {
  const token = TokenStorage.get()?.access;
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();

  useEffect(() => {
    if (isEmpty(LOCATION.state)) {
      HISTORY.push('/');
    }
  }, [LOCATION]);

  return (
    <>
      <Head title="403" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {token && <Header />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: (theme) => theme.palette.common.white,
            overflow: 'hidden',
            width: 1,
            marginTop: (theme) => ({
              xs: `${theme.appBarHeight}px`,
              sm: `${theme.appBarHeight}px`,
              lg: 0,
            }),
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontWeight: 600, mb: 3, fontSize: 120, lineHeight: '120px' }}
          >
            403
          </Typography>
          <Typography sx={{ fontSize: 24, fontWeight: 400 }}>{t('ACCESS DENIED')}</Typography>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Typography
              sx={{
                fontSize: 16,
                lineHeight: '28px',
                fontWeight: 400,
                display: 'inline',
                whiteSpace: 'pre-wrap',
              }}
            >
              {t('The page at ')}
              <Box
                sx={{
                  border: '1px solid',
                  background: (theme) => theme.customColors.white100,
                  borderRadius: 1,
                  height: 20,
                  fontStyle: 'italic',
                  whiteSpace: 'nowrap',
                  px: 1.25,
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderColor: (theme) => theme.customColors.white100,
                  verticalAlign: 'text-bottom',
                }}
              >
                {(LOCATION?.state as string) ?? ''}
              </Box>
              {t(
                ' you are trying to access is forbidden and you do not have the proper authorization to view its contents.',
              )}
            </Typography>
            <br />
            <Link
              sx={{
                fontSize: 16,
                lineHeight: '28px',
                fontWeight: 400,
                display: 'inline',
                textDecoration: 'none',
                color: (theme) => theme.customColors.linkText,
              }}
              component={RouterLink}
              to={token ? '/' : '/login'}
            >
              {t('Return to Homepage')}
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UnauthorizedPage;
