import ApiService, { Params } from 'api/helpers/Sender';
import { PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getAdvancedSearchService = async (
  body: {},
  searchType: string,
): Promise<PaginationResponse<[]>> => {
  const { data } = await API.get(`search/${searchType}`, body as Params);
  return data;
};
