import qs from 'query-string';
import React, { FC, useEffect } from 'react';
// Helpers - Constants
import logoPrimary from 'assets/images/logo-primary.png';
import TokenStorage from 'helpers/TokenStorage';
import { toast } from 'react-toastify';
// hooks
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
// Styles
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { isEmpty } from 'lodash-es';

export const RedirectSSO: FC = () => {
  const { auth, getAccessTokenFromSSO } = useAuth();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const url = qs.parse(LOCATION.search);
  const ERROR_DESCRIPTION = url.error_description;
  const { t } = useTranslation();
  const token = TokenStorage.get()?.access;
  const { user, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (!isEmpty(user) && isAuthenticated && isEmpty(TokenStorage.get())) {
      getAccessTokenFromSSO();
    }
  }, [user, TokenStorage]);
  useEffect(() => {
    const { redirect } = qs.parse(LOCATION.search);
    if (auth && token) {
      if (redirect) {
        toast.dismiss();
        HISTORY.push(redirect === '/login' ? '/' : redirect.toString());
      } else {
        toast.dismiss();
        HISTORY.push('/');
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  useEffect(() => {
    if (LOCATION.search.includes('error_description')) {
      const errorMessage =
        ERROR_DESCRIPTION === 'user is blocked'
          ? t('Your account is blocked. Please contact your support team.')
          : ERROR_DESCRIPTION;
      displayToastMessage('ERROR', errorMessage);
      setTimeout(() => {
        HISTORY.push('/login');
      }, 5000);
    }
  }, [LOCATION]);
  return (
    <Box
      sx={{
        background: (theme) => theme.customColors.tableLoadingBackdrop,
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: (theme) => theme.zIndex.appBar - 1,
      }}
    >
      <Box
        component="img"
        src={logoPrimary}
        sx={{
          // width: 197,
          height: 113,
          objectFit: 'contain',
          marginBottom: 4,
        }}
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          mb: '12px',
          userSelect: 'none',
          color: (theme) => theme.customColors.pageHeader.title,
          textTransform: 'capitalize',
        }}
      >
        {t('Redirecting...')}
      </Typography>
    </Box>
  );
};
export default RedirectSSO;
