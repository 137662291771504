import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { supportedLocales } from 'common/constants';
import getUserLocale from 'helpers/UserLocale';

// translation files
import { resources } from './locales';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: getUserLocale(),
    keySeparator: false,
    debug: false,
    resources,
    supportedLngs: supportedLocales,
    nsSeparator: '*',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
