import { Box, Checkbox, Typography } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import { updateRuleEngineDecision } from 'api/services/RuleEngineDecisions';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  underwritingResultDecisions,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import TextBadge from 'components/TextBadge/TextBadge';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { tabTitleStyles } from 'helpers/MuiSharedStyles';
import { createColumnVisibilityModel, getNestedValueFromObject } from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IUWResultsProps {}

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align: GridAlignment;
  headerAlign: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const determineStatusColor = (decision: string) => {
  switch (decision) {
    case underwritingResultDecisions.APPROVED.code:
      return 'green200';
    case underwritingResultDecisions.REFERRED.code:
      return 'darkGrey';
    case underwritingResultDecisions.DECLINED.code:
      return 'errorRed';

    default:
      return 'grey850';
  }
};

const UWResults: FC<IUWResultsProps> = () => {
  const { t } = useTranslation();
  const { getRuleEngineResults, ruleEngineResults } = usePolicyDetail();
  const { data: user } = useUser();
  const { id: locator } = useParams<{ id: string }>();
  const [approveLoading, setApproveLoading] = useState(false);

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    getRuleEngineResults(locator);
  }, []);

  const columns: IColumns[] = [
    {
      name: 'underwriting_rule_number',
      display_name: t('Rule ID'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.1,
      type: 'string',
      align: 'right',
      headerAlign: 'right',
    },
    {
      name: 'refer_decline_reason',
      display_name: t('Refer/Decline Reason'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 220,
      align: 'left',
      headerAlign: 'left',
    },
    ...(isUnderwriter
      ? ([
          {
            name: 'referral_support',
            display_name: t('Referral Support'),
            type: 'string',
            is_hidden: false,
            is_sortable: false,
            is_link: false,
            link_type: null,
            align: 'left',
            minWidth: 260,
            flex: 1,
            headerAlign: 'left',
          },
          {
            name: 'uw_instruction',
            display_name: t('UW Instruction'),
            type: 'string',
            is_hidden: false,
            is_sortable: false,
            is_link: false,
            link_type: null,
            align: 'left',
            minWidth: 260,
            flex: 1,
            headerAlign: 'left',
          },
        ] as IColumns[])
      : []),
    {
      name: 'rule_engine_decision_result',
      display_name: t('Outcome'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.1,
      type: 'string',
      minWidth: 100,
      align: 'left',
      headerAlign: 'left',
    },

    {
      name: 'approved',
      display_name: t('Mark Approved'),
      type: 'checkbox',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 0.1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const updateApprovedStatus = async (id: number, is_underwriter_approved: boolean) => {
    try {
      setApproveLoading(true);
      await updateRuleEngineDecision(id, {
        is_underwriter_approved,
      });
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occured while marking.'));
    } finally {
      setApproveLoading(false);
    }
  };

  const uwResultsColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.name === 'rule_engine_decision_result') {
        return row.rule_engine_decision_result?.code;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.type === 'checkbox') {
        const { rule_engine_decision_result, is_underwriter_approved, id } = params.row;

        return rule_engine_decision_result?.code === underwritingResultDecisions.REFERRED.code ? (
          <Checkbox
            defaultChecked={is_underwriter_approved}
            onChange={(_e, v) => updateApprovedStatus(id, v)}
            disabled={!isUnderwriter}
          />
        ) : (
          <></>
        );
      } else if (field.name === 'rule_engine_decision_result') {
        const { rule_engine_decision_result } = params.row;
        const determined = determineStatusColor(rule_engine_decision_result?.code);

        return <TextBadge label={rule_engine_decision_result?.name} color={determined} />;
      } else if (field.name === 'reason') {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography
              sx={{
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                color: (theme) => theme.customColors.black,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue.substring(0, 40) || '-'}
            </Typography>

            {fieldValue?.length > 40 && (
              <Typography
                sx={{
                  lineHeight: (theme) => theme.typography.body1.lineHeight,
                  fontSize: (theme) => theme.typography.body1.fontSize,
                  color: (theme) => theme.customColors.black,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                title={fieldValue}
              >
                {fieldValue.substring(40) || '-'}
              </Typography>
            )}
          </Box>
        );
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const isTableLoading = ruleEngineResults.loading || approveLoading;

  return (
    <>
      <Typography sx={[tabTitleStyles]}>
        {quotePolicyEndorsementInfoTitlesDescriptions.UW_RESULTS.title()}
      </Typography>

      <Box sx={{ mt: 3 }}>
        <DataTable
          getRowId={(row) => `${row.id}_${row.is_underwriter_approved ?? ''}`}
          autoRowCellHeight
          dynamicRowHeight
          rows={ruleEngineResults?.data ?? []}
          loading={isTableLoading}
          columns={uwResultsColumns}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={ruleEngineResults?.data?.length! < defaultRowVirtualization}
          wrapperSx={{ maxWidth: '1400px' }}
        />
      </Box>
    </>
  );
};

export default UWResults;
