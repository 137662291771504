import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { primaryButtonStyle, secondaryButtonStyle } from 'helpers/MuiSharedStyles';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IStopAutopayDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const StopAutopayDialog: React.FC<IStopAutopayDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { data: policyDetail, updatePaymentInfo, getBillingData } = usePolicyDetail();
  const { id } = useParams<{ id: string }>();

  const handleConfirm = async () => {
    try {
      await updatePaymentInfo(Number(policyDetail?.locator as string));
      getBillingData(id);
      onClose();
    } catch (error) {
      displayBackendErrorMessage(error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box sx={{ width: 480, p: 1, backgroundColor: (theme) => theme.customColors.grey1150 }}>
        <DialogTitle>{t('Confirm Stop Autopay')}</DialogTitle>

        <DialogContent>
          <Typography>{t('Are you sure you want to stop Autopay?')}</Typography>
          <Typography>
            {t(
              ' A $7.00 fee applies to each monthly installment for customers not enrolled in autopay.',
            )}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              ...secondaryButtonStyle,
            }}
          >
            {t('Cancel')}
          </Button>

          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{ ...primaryButtonStyle, ml: '0 !important' }}
          >
            {t('Yes')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default StopAutopayDialog;
