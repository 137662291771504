import { Box, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  submissionDetailInfoTabs,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import TextBadge from 'components/TextBadge/TextBadge';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  redirectToProducerTab,
  updateQueryStrings,
} from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import LocationDrawer from './Drawers/LocationDrawer/LocationDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const Locations: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { id } = useParams<{ id: string }>();
  const themeHook = useTheme();
  const { data: user } = useUser();
  const matches = useMediaQuery(themeHook.breakpoints.down('md'));
  const { setLoading } = useLoader();
  const [selectedLocationRowId, setSelectedLocationRowId] = useState<string>('');
  const {
    data: quoteDetail,
    exposureList,
    canEdit,
    updateQuoteProgress,
    progress: { data: progressData },
  } = useQuoteDetail();

  const currentExposures = exposureList?.[`${threeExposureNames.LOCATION}`];

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const canSeeNavigationButtons = canEdit && isProducer;
  const isLocationsTabNotCompleted = !(submissionDetailInfoTabs.LOCATIONS.code in progressData);

  const renderAddressBadge = (addressType: { isPrimary: string; isMailing: string }) => {
    const { isMailing, isPrimary } = addressType;
    const pb = <TextBadge label={t('Primary')} color="chipPrimary" />;
    const mb = <TextBadge label={t('Mailing')} color="chipMailing" />;

    if (isPrimary === 'Yes' && isMailing === 'Yes') {
      return (
        <Stack direction="row" gap={1}>
          {pb}
          {mb}
        </Stack>
      );
    } else if (isPrimary === 'Yes') {
      return pb;
    } else if (isMailing === 'Yes') {
      return mb;
    }

    return <></>;
  };

  const columns: IColumns[] = [
    {
      name: 'state',
      display_name: t('State'),
      flex: 1,
      type: 'string',
      is_sortable: true,
      is_hidden: false,
      is_link: false,
      link_type: null,
      minWidth: 80,
    },
    {
      name: 'address',
      display_name: t('Address'),
      flex: 5,
      type: 'string',
      is_sortable: true,
      is_link: true,
      is_hidden: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'address_type',
      display_name: '',
      flex: 2,
      type: 'string',
      is_sortable: true,
      align: 'center',
      is_hidden: false,
      is_link: false,
      link_type: null,
      minWidth: 150,
    },
  ];

  const locationsColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) =>
      field.name === 'address_type'
        ? `${getNestedValueFromObject(row, field.name)?.isPrimary === 'Yes' ? 'primary' : ''} ${
            getNestedValueFromObject(row, field.name)?.isMailing === 'Yes' ? 'mailing' : ''
          }`
        : getNestedValueFromObject(row, field.name),
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.is_link) {
        const location = parseLocation(fieldValue);

        const formatted = formatLocation({
          ...location,
        });

        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { location: params.row.locator },
            })}`}
            underline="hover"
            sx={[
              truncatedTextStyle,
              {
                color: (theme) => theme.customColors.table.link,
              },
            ]}
          >
            <LocationShow location={formatted.showing} />
          </Link>
        );
      } else if (field.name === 'address_type') {
        return <Box sx={{ pl: 1 }}>{renderAddressBadge(fieldValue)}</Box>;
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const getRowHeight = useCallback(() => (matches ? 'auto' : null), [matches]);

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addLocation: true },
      }),
    });
  };

  const renderAddress = (data?: any) => {
    const formatted = formatLocation({
      addressLine1: data?.loc_address_line1,
      addressLine2: data?.loc_address_line2,
      city: data?.loc_address_city,
      state: data?.loc_address_state,
      zip: data?.loc_address_zip,
    });

    return formatted.storing;
  };

  const rows = useMemo(() => {
    const expArr =
      currentExposures?.data?.map((ex) => ({
        ...(ex.data ?? {}),
        locator: ex.locator,
        address: renderAddress(ex.data),
        state: ex.data?.loc_address_state,
        address_type: {
          isPrimary: ex.data?.loc_address_isprimary,
          isMailing: ex.data?.loc_address_ismailing,
        },
      })) ?? [];

    // move primary address to the first
    if (expArr?.length > 0) {
      const foundFirstPrimaryIndex = expArr.findIndex((e) => e.address_type.isPrimary === 'Yes');

      if (foundFirstPrimaryIndex !== -1) {
        expArr?.unshift(expArr.splice(foundFirstPrimaryIndex, 1)[0]);
      }
    }

    return expArr;
  }, [currentExposures]);

  const handleNext = async () => {
    if (!rows?.length) {
      displayToastMessage('ERROR', 'There must be at least one location.');
      return;
    }

    try {
      setLoading(true);

      if (isLocationsTabNotCompleted) {
        await updateQuoteProgress({
          locator: id,
          currentTab: submissionDetailInfoTabs.LOCATIONS.code,
          isCompleted: Boolean(rows?.length),
        });
      }
    } finally {
      setLoading(false);
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: {
          tab: redirectToProducerTab({
            currentTab: submissionDetailInfoTabs.LOCATIONS,
            productType: quoteDetail?.product?.code,
          }),
        },
      }),
    });
  };

  const handlePrevious = async () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.LOCATIONS.prevTab },
      }),
    });
  };

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <LocationDrawer isEdit={canEdit} />

      <Box>
        <DataTable
          onRowClick={(r) =>
            setSelectedLocationRowId(r.id === selectedLocationRowId ? '' : (r.id as string))
          }
          getRowId={(row) => row.locator}
          columns={locationsColumns}
          rows={rows ?? []}
          loading={currentExposures?.loading}
          dynamicRowHeight={matches}
          getRowHeight={getRowHeight}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={rows?.length! < defaultRowVirtualization}
          focusedRowId={selectedLocationRowId}
        />

        {canEdit && currentExposures?.loaded && (
          <Box>
            <Button
              data-test="add-location"
              onClick={handleOpenAddDrawer}
              sx={[primaryButtonStyle]}
            >
              {t('Add Location')}
            </Button>
          </Box>
        )}
      </Box>

      {canSeeNavigationButtons && (
        <NavigationButtons handlePrevious={handlePrevious} handleNext={handleNext} />
      )}
    </Stack>
  );
};

export default Locations;
