import { Box, Button, Stack, Typography } from '@mui/material';
import DrawerComponent from 'components/DrawerComponent';
import { useFormik } from 'formik';
import { deleteFromQueryStrings } from 'helpers/Utils';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

// TODO: Get fields from backend
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threePolicyGroupNames } from 'common/constants';
import { ClaimsHistoryDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ClaimsHistoryDrawerFieldParser';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isEmpty } from 'lodash-es';

export interface ClaimsHistoryDetailProps {
  isDrawerOpen: boolean;
}

const ClaimsHistoryDetailDrawer: FC<ClaimsHistoryDetailProps> = ({ isDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const CLAIM = url.claim;
  const {
    fields: fieldConfig,
    fields: {
      policy: { loading: fieldConfigLoading },
    },
    loading: policyLoading,
    loaded: policyLoaded,
    groups,
  } = usePolicyDetail();

  const [state, setState] = useState({});

  const claims: any[] = groups?.pol_claims?.data ?? [];

  const claim = useMemo(() => claims.find((ow) => ow.locator === CLAIM) ?? {}, [claims, CLAIM]);

  const showLoader = fieldConfigLoading || policyLoading || isEmpty(state);

  const fields = useMemo(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])
        ?.find((con) => con.code === 'policy-details')
        ?.fields?.find((x) => x.code === threePolicyGroupNames.CLAIMS)?.nested_fields ?? [];

    return tmpFields as DynamicField[];
  }, [fieldConfig]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({ locationSearch: LOCATION.search, omitKeys: ['claim'] }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (policyLoaded && isEmpty(claim)) {
      displayToastMessage('ERROR', t('Claim not found.'));
      handleQuery();
    }
  }, [claim, policyLoaded]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (isEmpty(state)) {
      setState((prevState) => ({ ...prevState, ...claim }));
      formik.setValues(claim);
    }
  }, [claim]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Claim Details')}
        </Typography>
      }
      content={
        <Stack gap={2} sx={{ mb: 6, pt: 1 }}>
          <ClaimsHistoryDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={showLoader}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default ClaimsHistoryDetailDrawer;
