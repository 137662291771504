import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { getAgencies } from 'api/services/Agencies';
import { Pagination } from 'types/Pagination';
import { AgenciesContext, initialAgenciesData } from './AgenciesProviderContext';
import { IAgencies } from './types';

export const AgenciesProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialAgenciesData);

  const fetch = async (pagination: Pagination): Promise<IAgencies[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await getAgencies({
        ...pagination,
        page_size: pagination?.page_size
          ? pagination?.page_size
          : initialAgenciesData.pagination!.page_size,
      });

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res?.results,
        pagination: {
          ...pagination,
          total_count: res?.count,
          page_size: pagination?.page_size
            ? pagination?.page_size
            : initialAgenciesData.pagination!.page_size,
        },
      }));

      return res?.results!;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const resetAgenciesState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialAgenciesData,
    }));
  };

  return (
    <AgenciesContext.Provider value={{ ...state, fetch, resetAgenciesState }}>
      {children}
    </AgenciesContext.Provider>
  );
};
