import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import VehicleDetailDrawer from './Detail';
import VehicleEditDrawer from './Edit';

export interface IVehicleDrawerProps {
  isEdit: boolean;
}

const VehicleDrawer: FC<IVehicleDrawerProps> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isVehicleDrawerOpen, setIsVehicleDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (
      url?.tab === 'auto_exposures' &&
      (url?.vehicle || !isEmpty(url?.addVehicle)) &&
      !url?.schedule_mod
    ) {
      setIsVehicleDrawerOpen(true);
    } else if (url?.tab === 'auto_exposures') {
      setIsVehicleDrawerOpen(false);
    }
  }, [LOCATION]);

  return isVehicleDrawerOpen ? (
    isEdit ? (
      <VehicleEditDrawer
        isDrawerOpen={isVehicleDrawerOpen}
        setIsDrawerOpen={setIsVehicleDrawerOpen}
        isAdd={!isEmpty(url?.addVehicle)}
      />
    ) : (
      <VehicleDetailDrawer
        isDrawerOpen={isVehicleDrawerOpen}
        setIsDrawerOpen={setIsVehicleDrawerOpen}
      />
    )
  ) : (
    <></>
  );
};

export default VehicleDrawer;
