import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  threeEmployerExposurePerilNames,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
  wcAuditPolOoFields,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { WcOwnersMembersDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/WcOwnersMembersDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import {
  addRequiredValidationToDynamicFields,
  changeFieldsHiddenStatus,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
  makeFieldsReadonly,
  makeFieldsRequired,
  parseLocation,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty, isNil, omit } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import EndorsementLocationSelect from '../../../Inputs/LocationSelectInput';
import WcClassCodeAutocomplete from '../../../Inputs/WcClassCodeAutocomplete';

export interface WcOwnersMembersDrawerEditProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  position: string;
}

const WcOwnersMembersDrawerEditDrawer: FC<WcOwnersMembersDrawerEditProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  position,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const OWNER_MEMBER = url.owner_member;
  const COVER_TOGGLE_ACTION = url.forceCover;
  const {
    data: endorsementDetail,
    loaded: endorsementLoaded,
    exposureList,
    groups,
    fields: fieldConfig,
    updateEndorsementDetail,
    isWcFinal,
    isWc,
  } = useEndorsementDetail();
  const { data: user } = useUser();

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const owners = groups?.pol_oo?.data ?? [];

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  const isGroupNewlyAdded = useMemo(() => {
    const isNewlyAdded = OWNER_MEMBER?.includes('added-');
    const index = isNewlyAdded ? Number(OWNER_MEMBER?.slice(6)) : undefined;

    return { isNewlyAdded, index };
  }, [OWNER_MEMBER]);

  const owner = useMemo(() => {
    const { isNewlyAdded, index } = isGroupNewlyAdded;

    return (
      owners?.find((ow) => (isNewlyAdded ? ow.index === index : ow.locator === OWNER_MEMBER)) ?? {}
    );
  }, [owners, OWNER_MEMBER, isGroupNewlyAdded]);

  const relatedClassCodes = useMemo(
    () =>
      employerExposures
        .find((e) => e?.data?.emp_state === parseLocation(owner.pol_oo_location).state)
        ?.perils?.filter((p) => p.name === threeEmployerExposurePerilNames.MANUAL_PREMIUM)
        ?.map((p) => `${p.data?.man_class_code} - ${p.data?.man_class_code_desc}`),
    [employerExposures, owner],
  );

  const [state, setState] = useState<any>({});

  const [fields, setFields] = useState<any[]>([]);

  const { setLoading } = useLoader();

  useEffect(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])?.[0]?.fields?.find(
        (f) => f.code === `${threePolicyGroupNames.OWNER}_wc`,
      )?.nested_fields ?? [];

    setFields(tmpFields);
  }, [fieldConfig]);

  useEffect(() => {
    if (isWc) {
      setFields((prevFields) => {
        const { ESTIMATED, FINAL, ORIGINALS } = wcAuditPolOoFields;

        let tmpFields = changeFieldsHiddenStatus(
          prevFields,
          isWcFinal ? [...FINAL, ...ESTIMATED] : ESTIMATED,
          false,
        );

        tmpFields = makeFieldsReadonly(
          tmpFields,
          isWcFinal ? [...ESTIMATED, ...ORIGINALS] : ORIGINALS,
          true,
        );

        return tmpFields;
      });
    }
  }, [isWcFinal, fieldConfig, isWc]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['owner_member', 'add', 'forceCover'],
      }),
    });
  };

  useEffect(() => {
    if (endorsementLoaded && !isNil(isGroupNewlyAdded.isNewlyAdded) && isEmpty(owner)) {
      displayToastMessage(
        'ERROR',
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'owner',
        }),
      );
      handleQuery();
    }
  }, [owner, endorsementLoaded]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema,
    onSubmit: async (_values) => {},
  });

  useEffect(() => {
    if (isUnderwriter) {
      const integrationFields: string[] = [];

      if (owner.pol_oo_isprimary === 'Yes') {
        integrationFields.push('pol_oo_b505');
        integrationFields.push('pol_oo_ncf_score');

        setFields((prevFields) => changeFieldsHiddenStatus(prevFields, integrationFields, false));
      }
    }

    const parsedLoacation = parseLocation(owner.pol_oo_location);

    const foundExposure = employerExposures.find(
      (e) => e?.data?.emp_state === parsedLoacation.state,
    );

    setState((prevState) => ({
      ...prevState,
      ...owner,
      emp_filing_set_id: foundExposure?.data?.emp_filing_set_id ?? -1,
    }));

    formik.setValues(owner);
  }, [owner, isUnderwriter, employerExposures]);

  useEffect(() => {
    if (COVER_TOGGLE_ACTION) {
      setState((prev) => ({ ...prev, pol_oo_wc_excluded: 'No' }));
      formik.setFieldValue('pol_oo_wc_excluded', 'No');
    }
  }, [COVER_TOGGLE_ACTION]);

  useEffect(() => {
    let tmpFields = fields;
    if (state?.pol_oo_wc_excluded === 'Yes') {
      tmpFields = makeFieldsReadonly(tmpFields, [
        'pol_oo_wc_payroll',
        'pol_oo_wc_class_code',
        'pol_oo_wc_payroll_audit_est',
        'pol_oo_wc_payroll_audit_final',
      ]);

      const updatedData = makeFieldsRequired(
        tmpFields,
        [
          'pol_oo_wc_payroll',
          'pol_oo_wc_class_code',
          'pol_oo_wc_payroll_audit_est',
          'pol_oo_wc_payroll_audit_final',
        ],
        true,
      );

      setFields(updatedData);
    } else if (state?.pol_oo_wc_excluded === 'No') {
      let updatedData;
      if (isWc) {
        if (!isWcFinal) {
          const notReadOnlyFields = makeFieldsReadonly(
            tmpFields,
            ['pol_oo_wc_class_code', 'pol_oo_wc_payroll_audit_est'],
            false,
          );
          updatedData = makeFieldsRequired(
            notReadOnlyFields,
            ['pol_oo_wc_class_code', 'pol_oo_wc_payroll_audit_est'],
            false,
          );
        } else if (isWcFinal) {
          const notReadOnlyFields = makeFieldsReadonly(
            tmpFields,
            ['pol_oo_wc_class_code', 'pol_oo_wc_payroll_audit_final'],
            false,
          );
          updatedData = makeFieldsRequired(
            notReadOnlyFields,
            ['pol_oo_wc_class_code', 'pol_oo_wc_payroll_audit_final'],
            false,
          );
        }
      } else if (!isWc) {
        const notReadOnlyFields = makeFieldsReadonly(
          tmpFields,
          ['pol_oo_wc_payroll', 'pol_oo_wc_class_code'],
          false,
        );

        updatedData = makeFieldsRequired(notReadOnlyFields, [
          'pol_oo_wc_payroll',
          'pol_oo_wc_class_code',
        ]);
      }

      setFields(updatedData);
    }

    formik.setTouched({}, false);
  }, [state?.pol_oo_wc_excluded, isWc]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      !endorsementLoaded ||
      !fieldConfig?.policy?.loaded ||
      !exposureList?.[`${threeExposureNames.LOCATION}`].loaded;

    return defaultLoading || isEmpty(owner);
  }, [owner, endorsementLoaded, exposureList, fieldConfig]);

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const tmpOwners = [...owners];
      const rowIndex = tmpOwners.findIndex((row) => row.locator === owner.locator);
      tmpOwners[rowIndex] = {
        ...state,
      };

      const query = owner?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.OWNER, strategy: 'create' }
        : {
            validate: threePolicyGroupNames.OWNER,
            strategy: 'update',
            locator: owner.locator,
          };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.OWNER}`]: tmpOwners?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The owner has been updated.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the owner.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      handleUpdate();
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const handleEmitedInputChange = async ({ field, value }: { field: string; value: any }) => {
    switch (field) {
      case 'pol_oo_wc_class_code': {
        setState((prevState) => ({
          ...prevState,
          pol_oo_wc_class_code_desc: value?.FriendlyLabel,
        }));

        break;
      }

      default:
        break;
    }
  };

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: 2,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('{{entityPosition}} Details', {
            entityPosition: position,
          })}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <WcOwnersMembersDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            showLoader={showLoader}
            setState={setState}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
            LocationSelect={EndorsementLocationSelect}
            WcClassCodeAutocomplete={WcClassCodeAutocomplete}
            relatedClassCodes={relatedClassCodes}
          />
        </Stack>
      }
      footer={
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Box />

          <Stack direction="row">
            {showLoader ? (
              <>
                <Skeleton animation="wave" width="60px" height={32} sx={{ mr: 1 }} />
                <Skeleton animation="wave" width="60px" height={32} />
              </>
            ) : (
              <>
                <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                  {t('Cancel')}
                </Button>
                <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                  {t('Save')}
                </Button>
              </>
            )}
          </Stack>
        </Box>
      }
    />
  );
};

export default WcOwnersMembersDrawerEditDrawer;
