import { WcClassCodes } from 'api/models/THREEMappings/WcClassCodes/wcClassCodes.model';
import { fieldHasValue, parseLocation } from 'helpers/Utils';
import { TGroups } from 'providers/QuoteDetailProvider/types';

export const calculateOwnerPayroll = (
  classCodeConcatLookup: String,
  st: String,
  groups?: TGroups,
) =>
  groups?.pol_oo?.data?.reduce((a, oo) => {
    const {
      pol_oo_wc_payroll,
      pol_oo_wc_class_code,
      pol_oo_wc_class_code_desc,
      pol_oo_location,
      pol_oo_wc_excluded,
    } = oo;

    const { state } = parseLocation(pol_oo_location);

    if (
      state === st &&
      `${pol_oo_wc_class_code ?? ''} - ${pol_oo_wc_class_code_desc ?? ''}` ===
        classCodeConcatLookup &&
      pol_oo_wc_excluded !== 'Yes' &&
      fieldHasValue(pol_oo_wc_payroll)
    ) {
      return a + Number(pol_oo_wc_payroll);
    }

    return a;
  }, 0) ?? 0;

export const classCodeOptions = (st: String, filingSetId: String, classCodes: WcClassCodes[]) =>
  classCodes?.filter(
    (code) => code.State === st && Number(code.FilingSetID) === Number(filingSetId),
  ) ?? [];
