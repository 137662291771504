import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropertyDetailDrawer from './Detail';

const PropertyDrawer: FC = () => {
  const LOCATION = useLocation();
  const [isPropertyDrawerOpen, setIsPropertyDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'property_exposures' && url?.property && !url?.schedule_mod) {
      setIsPropertyDrawerOpen(true);
    } else if (url?.tab === 'property_exposures') {
      setIsPropertyDrawerOpen(false);
    }
  }, [LOCATION]);

  return isPropertyDrawerOpen ? (
    <PropertyDetailDrawer
      isDrawerOpen={isPropertyDrawerOpen}
      setIsDrawerOpen={setIsPropertyDrawerOpen}
    />
  ) : (
    <></>
  );
};

export default PropertyDrawer;
