import { Box, Button, Link, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import { defaultRowVirtualization } from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import TextBadge from 'components/TextBadge/TextBadge';
import { primaryButtonStyle, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  determineOwnerOfficerPositions,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  updateQueryStrings,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import { isEmpty } from 'lodash-es';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import OwnersMembersDrawer from './Drawers/OwnersMembersDrawer/OwnersMembersDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const OwnersMembers: FC = () => {
  const { t } = useTranslation();
  const themeHook = useTheme();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const matches = useMediaQuery(themeHook.breakpoints.down('md'));
  const [selectedID, setSelectedID] = useState<string>('');
  const {
    data: endorsementDetail,
    groups,
    loading: policyLoading,
    loaded: policyLoaded,
    canEdit,
  } = useEndorsementDetail();

  const entity = endorsementDetail?.policy?.characteristics?.data?.pol_legal_entity ?? '';

  const rows = useMemo(() => {
    const r =
      groups?.pol_oo?.data?.map((o) => ({
        ...o,
        id: o.isNewAddedOnEndorsement ? `added-${o.index}` : o.locator,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const columns: IColumns[] = [
    {
      name: 'location',
      display_name: t('Location'),
      flex: 3,
      type: 'string',
      is_sortable: true,
      minWidth: 360,
    },
    {
      name: 'name',
      display_name: t('Name'),
      flex: 3,
      type: 'string',
      is_sortable: true,
      is_link: true,
      minWidth: 200,
    },
    {
      name: 'is_primary',
      display_name: '',
      flex: 1,
      type: 'string',
      is_sortable: false,
      align: 'center',
      minWidth: 96,
    },
  ];

  const ownersMembersColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.is_link) {
        const { pol_oo_name_first = '', pol_oo_name_last = '' } = row;
        const name = `${pol_oo_name_first} ${pol_oo_name_last}`;

        return name;
      }

      if (field.name === 'location') {
        return row.pol_oo_location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.is_link) {
        const name = params.value;

        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { owner_member: params.row.id },
            })}`}
            underline="hover"
            sx={[
              truncatedTextStyle,
              {
                color: (theme) => theme.customColors.table.link,
              },
            ]}
            title={name}
          >
            {name?.trim() === '' ? '-' : name}
          </Link>
        );
      } else if (field.name === 'is_primary') {
        const { pol_oo_isprimary } = params.row;

        return pol_oo_isprimary === 'Yes' ? (
          <TextBadge label={t('Primary')} color="chipPrimary" />
        ) : (
          <></>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(params.row.pol_oo_location);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      }

      return (
        <Typography sx={truncatedTextStyle} title={fieldValue}>
          {fieldValue || '-'}
        </Typography>
      );
    },
  }));

  const getRowHeight = useCallback(() => (matches ? 'auto' : null), [matches]);

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { add: true },
      }),
    });
  };

  const isPolicyInitialLoading = policyLoading && !policyLoaded;

  const entityType = useMemo(() => determineOwnerOfficerPositions(entity as string), [entity]);

  return isPolicyInitialLoading ? (
    <>
      <Box>
        <Skeleton animation="wave" width="30%" height={40} />
        <Skeleton animation="wave" width="70%" height={40} />
        <Skeleton animation="wave" width="60%" height={40} />

        <Box sx={{ maxWidth: '664px', mt: -3, height: 300 }}>
          <Skeleton animation="wave" width="100%" height="100%" />
        </Box>
      </Box>
    </>
  ) : isEmpty(entity) ? (
    <Box>
      <Typography sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px', mb: 0.5 }}>
        {t('List of Business Owners/Members')}
      </Typography>

      <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
        {t('Legal entity is empty. You must select entity first. ')}
      </Typography>
    </Box>
  ) : (
    <>
      <OwnersMembersDrawer isEdit={canEdit} position={entityType.position} />

      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px', mb: 0.5 }}>
          {entityType.title}
        </Typography>

        <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 1.5 }}>
          {entityType.description}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: '664px' }}>
        <DataTable
          onRowClick={(r) => setSelectedID(r.id === selectedID ? '' : (r.id as string))}
          columns={ownersMembersColumns}
          rows={rows}
          loading={groups?.pol_oo?.loading}
          dynamicRowHeight={matches}
          getRowHeight={getRowHeight}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={rows?.length! < defaultRowVirtualization}
          focusedRowId={selectedID}
        />

        {canEdit && (
          <Box>
            <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]} data-test="add-partner">
              {t('Add {{entityPosition}}', { entityPosition: entityType.position })}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default OwnersMembers;
