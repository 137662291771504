import { Box, Fade, Link, Typography } from '@mui/material';
import { GridAlignment, GridColDef, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { DynamicTableColumn } from 'api/models/DynamicTableColum/DynamicTableColumn.model';
import { COLUMNS, dataFieldTypes, defaultDebounceDelay } from 'common/constants';
import DataTable from 'components/DataTable';
import Head from 'components/Head';
import PageHeader from 'components/PageHeader';
import ViewSearch from 'components/ViewSearch';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { usePreviousValue } from 'helpers/PreviousValue';
import { getNestedValueFromObject, hasDiffKeyValues } from 'helpers/Utils';
import useAgencies from 'hooks/useAgencies';
import { debounce } from 'lodash-es';
import { IPoliciesPagination } from 'providers/PoliciesProvider/types';
import qs from 'query-string';
import React, { ChangeEvent, createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

const AgencyList: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const queryParams = qs.parse(LOCATION.search) as unknown as IPoliciesPagination;
  const previousLocationSearch = usePreviousValue(queryParams);
  const { data, fetch, pagination, loading, loaded } = useAgencies();
  const [pageNumber, setPage] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const searchInputRef = createRef<HTMLInputElement>();
  const [searchDefaultValue, _] = useState(queryParams?.search);

  const DEFAULT_ORDER = { field: 'name', sort: 'asc' } as GridSortItem;

  const columnsAgencies = (cl: DynamicTableColumn[]) =>
    cl.map((field) => ({
      field: field.code!,
      headerName: field.name,
      flex: field.additional_data?.flex ?? 1,
      minWidth: field.additional_data?.minWidth,
      hide: field.is_hidden ?? false,
      sortable: field.is_sortable ?? true,
      align: field.additional_data?.headerAlign as GridAlignment,
      headerAlign: field.additional_data?.headerAlign as GridAlignment,
      type: field.type,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, field.code!);
        if (field.is_link && field.code === 'name') {
          return (
            <Link
              component={RouterLink}
              to={`/agencies/${params.row.id}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else if (field.type === dataFieldTypes.EMAIL) {
          return (
            <Link
              href={`mailto:${fieldValue}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Typography>
          );
        }
      },
    }));

  const memoizedColumns = useMemo(() => columnsAgencies(COLUMNS.AGENCY_COLUMNS), []);

  const checkIsOrderFieldInColumns = (field: string) =>
    memoizedColumns?.some((col) => col.field === field?.replaceAll('-', ''));

  const checkIsOrderDefaultField = () => {
    if (DEFAULT_ORDER?.sort === 'desc') {
      return `-${DEFAULT_ORDER.field}`;
    } else {
      return DEFAULT_ORDER.field;
    }
  };

  const parseOrdering = () => {
    if (queryParams?.ordering) {
      const splitedOrdering = queryParams.ordering?.split(',')?.[0];
      if (splitedOrdering?.includes('-')) {
        const field = splitedOrdering?.replaceAll('-', '');
        return checkIsOrderFieldInColumns(field)
          ? ({ field, sort: 'desc' } as GridSortItem)
          : DEFAULT_ORDER;
      } else {
        return checkIsOrderFieldInColumns(splitedOrdering)
          ? ({ field: splitedOrdering, sort: 'asc' } as GridSortItem)
          : DEFAULT_ORDER;
      }
    } else {
      return DEFAULT_ORDER;
    }
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([parseOrdering()]);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    HISTORY.push({ search: qs.stringify({ ...queryParams, search: event.target.value, page: 1 }) });
  };

  const setCurrentSortModel = (splitedOrdering: string | undefined) => {
    if (!splitedOrdering) {
      setSortModel([DEFAULT_ORDER]);
      return;
    }

    if (splitedOrdering?.includes('-')) {
      const field = splitedOrdering?.replaceAll('-', '');
      setSortModel([{ field, sort: 'desc' }]);
    } else {
      setSortModel([{ field: splitedOrdering, sort: 'asc' }]);
    }
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length) {
      const [nm] = newModel;
      HISTORY.push({
        search: qs.stringify({
          ...queryParams,
          page: 1,
          ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
        }),
      });
    } else {
      HISTORY.push({
        search: qs.stringify({
          ...queryParams,
          ordering: '',
        }),
      });
    }
  };

  useEffect(() => {
    const filteredQueryParams = Object.entries(queryParams).reduce((acc, [key, value]) => {
      if (value && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {}) as IPoliciesPagination;

    if (hasDiffKeyValues(queryParams, previousLocationSearch)) {
      const getAgencyList = async () => {
        try {
          await fetch({
            ...filteredQueryParams,
            ...queryParams,
            // Returns the encoded form of the string. test& -> test%26.
            search: queryParams.search ? encodeURIComponent(queryParams.search) : '',
            ordering: checkIsOrderFieldInColumns(queryParams.ordering ?? '')
              ? queryParams.ordering
              : checkIsOrderDefaultField(),
          });
        } catch (error) {
          displayBackendErrorMessage(error);
        }
      };

      const updateSearchPageSortableHeaderValue = () => {
        if (searchInputRef.current) {
          searchInputRef.current!.value = queryParams.search ?? '';
        }

        setPage(queryParams?.page ? parseInt(queryParams?.page as unknown as string, 10) - 1 : 0);
        setCurrentSortModel(queryParams?.ordering);
      };
      const getFunctions = async () => {
        await getAgencyList(); // async function
        updateSearchPageSortableHeaderValue();
      };

      getFunctions();
    }
  }, [LOCATION.search]);

  return (
    <>
      <Head title={t('Agencies')} />
      <Box ref={scrollRef} />
      <Fade in timeout={500}>
        <Box>
          <PageHeader
            sx={{
              px: 3,
            }}
            left={
              <Typography
                variant="h6"
                sx={{
                  userSelect: 'none',
                  color: (theme) => theme.customColors.pageHeader.title,
                  fontWeight: '500',
                }}
              >
                {t('Agencies')}
              </Typography>
            }
            right={
              <ViewSearch
                onChange={debounce(handleSearchInputChange, defaultDebounceDelay)}
                inputRef={searchInputRef}
                defaultValue={searchDefaultValue}
              />
            }
          />
          <DataTable
            isHeaderWidthFit
            rows={data ?? []}
            initialLoading={loading && !loaded}
            loading={loading ?? true}
            columns={memoizedColumns as GridColDef[]}
            paginationMode="server"
            paginationModel={{ page: pageNumber, pageSize: pagination?.page_size! }}
            pageSizeOptions={[pagination?.page_size!]}
            rowCount={pagination?.total_count}
            onPageChange={(page) => {
              HISTORY.push({
                search: qs.stringify({ ...queryParams, page: page + 1 }),
              });
            }}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            hideFooterPagination={pagination?.total_count! < pagination?.page_size!}
            wrapperSx={{ mx: 3 }}
          />
        </Box>
      </Fade>
    </>
  );
};
export default AgencyList;
