import { createContext } from 'react';

/// types
import { ITaskAssignUsersContextType, ITaskAssignUsersStore } from './types';

export const initialTaskAssignUsersData: ITaskAssignUsersStore = {
  data: [],
  loading: false,
  loaded: false,
  pagination: {
    fields: 'full_name,id,email,role',
    ordering: '',
    page: 1,
    page_size: 5,
    search: '',
    total_count: 0,
  },
};

export const taskAssignUsersContextDefault: ITaskAssignUsersContextType = {
  ...initialTaskAssignUsersData,
  resetTaskAssignUsersState: () => {},
  fetch: () => new Promise(() => {}),
};

export const TaskAssignUsersContext = createContext<ITaskAssignUsersContextType>(null!);
