import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  submissionDetailInfoTabs,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle, tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  redirectToProducerTab,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import DriverDrawer from './Drawers/DriverDrawer/DriverDrawer';
import DrivingIncidentsDrawer from './Drawers/DrivingIncidentsDrawer/DrivingIncidentsDrawer';

interface IcolumnsDrivers {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const Drivers: FC = () => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const { setLoading } = useLoader();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { id } = useParams<{ id: string }>();
  const themeHook = useTheme();
  const { data: user } = useUser();
  const {
    data: quoteDetail,
    loaded,
    groups,
    exposureList,
    canEdit,
    updateQuoteProgress,
    progress: { data: progressData },
  } = useQuoteDetail();
  const [selectedDriverRowId, setSelectedDriverRowId] = useState<string>('');
  const [selectedDrivingIncidentRowId, setSelectedDrivingIncidentRowId] = useState<string>('');

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const isDriversTabNotCompleted = !(submissionDetailInfoTabs.DRIVERS.code in progressData);

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`].data;

  const rowsDrivers = useMemo(() => {
    const r =
      groups?.pol_drivers?.data?.map((driver) => ({
        ...driver,
        id: driver.pol_drivers_id,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const rowsDrivingIncidents = useMemo(() => {
    const r =
      groups?.pol_incidents?.data?.map((inc) => ({
        ...inc,
        id: inc.locator,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const handleOpenAddDrawer = (key) => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { [key]: true },
      }),
    });
  };

  const handlePrevious = async () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.DRIVERS.prevTab },
      }),
    });
  };

  const handleNext = async () => {
    if (!rowsDrivers?.length && vehicleExposures?.length) {
      displayToastMessage(
        'ERROR',
        t('There must be at least one driver if the number of vahicles is 1 to 5.'),
      );
      return;
    }

    if (isDriversTabNotCompleted) {
      try {
        setLoading(true);
        await updateQuoteProgress({
          locator: id,
          currentTab: submissionDetailInfoTabs.DRIVERS.code,
          isCompleted:
            vehicleExposures?.length === 0
              ? true
              : Boolean(rowsDrivers?.length && vehicleExposures?.length),
        });
      } finally {
        setLoading(false);
      }
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: {
          tab: redirectToProducerTab({
            currentTab: submissionDetailInfoTabs.DRIVERS,
            productType: quoteDetail?.product?.code,
          }),
        },
      }),
    });
  };

  const columnsDrivers: IcolumnsDrivers[] = [
    {
      name: 'name',
      display_name: t('Name'),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: true,
      link_type: null,
      minWidth: 160,
    },
    {
      name: 'pol_drivers_dob',
      display_name: t('Date of Birth'),
      flex: 0.1,
      type: 'date',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'center',
      headerAlign: 'center',
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'pol_drivers_license_state',
      display_name: t("Driver's License State"),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'pol_drivers_license_number',
      display_name: t("Driver's License Number"),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
  ];

  const driversColumnsDrivers: GridColDef[] = columnsDrivers.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    // eslint-disable-next-line consistent-return
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.is_link) {
        if (!fieldValue || fieldValue.trim() === '') {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { driver: params.row.id },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
            >
              -
            </Link>
          );
        } else if (fieldValue) {
          const { pol_drivers_first = '', pol_drivers_last = '' } = params.row;
          const name = `${pol_drivers_first} ${pol_drivers_last}`;

          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { driver: params.row.id },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
              title={name}
            >
              {name.trim() === '' ? '-' : name}
            </Link>
          );
        }
      } else if (field.type === 'date') {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const columnsDrivingIncidents: IcolumnsDrivers[] = [
    {
      name: 'pol_incidents_date',
      display_name: t('Date of Incident'),
      flex: 0.1,
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'pol_incidents_driver',
      display_name: t('Driver'),
      flex: 2,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'pol_incidents_type',
      display_name: t('Type'),
      flex: 4,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
  ];

  const incidentsColumnsDriving: GridColDef[] = columnsDrivingIncidents.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    // eslint-disable-next-line consistent-return
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        if (!fieldValue) {
          return (
            <Typography
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              -
            </Typography>
          );
        } else if (fieldValue && field.type === 'date') {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { drivingIncident: params.row.id },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
            >
              {formatDateInTimeZone(fieldValue) || '-'}
            </Link>
          );
        } else if (fieldValue) {
          return (
            <Typography sx={truncatedTextStyle} title={fieldValue}>
              {fieldValue || '-'}
            </Typography>
          );
        }
      } else if (field.type === 'date') {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else if (field.name.includes('driver')) {
        const { pol_drivers_first = null, pol_drivers_last = null } =
          rowsDrivers.find((d) => d.id === fieldValue.split(';')?.[0]) ?? {};
        const name = pol_drivers_first
          ? `${pol_drivers_first} ${pol_drivers_last}`
          : pol_drivers_last ?? '-';

        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {name}
          </Typography>
        );
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {(fieldValue.includes(';') ? 'Multiple' : fieldValue) || '-'}
          </Typography>
        );
      }
    },
  }));

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <DriverDrawer isEdit={canEdit} />
      <DrivingIncidentsDrawer isEdit={canEdit} />

      <Box>
        <Box sx={{ maxWidth: 1164 }}>
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.DRIVERS.title()}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 14,
              mb: (theme) => theme.spacing(1.5),
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              minWidth: 100,
            }}
          >
            {quotePolicyEndorsementInfoTitlesDescriptions.DRIVERS.description()}
          </Typography>
          <Box sx={{ maxWidth: 800, mx: 0, mb: 4 }}>
            <DataTable
              onRowClick={(r) =>
                setSelectedDriverRowId(r.id === selectedDriverRowId ? '' : (r.id as string))
              }
              columns={driversColumnsDrivers}
              rows={rowsDrivers}
              loading={groups?.pol_drivers?.loading}
              columnVisibilityModel={createColumnVisibilityModel(columnsDrivers)}
              pageSize={defaultRowVirtualization}
              hideFooterPagination={rowsDrivers?.length! < defaultRowVirtualization}
              focusedRowId={selectedDriverRowId}
            />
            {canEdit && loaded && (
              <Box>
                <Button onClick={() => handleOpenAddDrawer('addDriver')} sx={[primaryButtonStyle]}>
                  {t('Add Driver')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ maxWidth: 1164 }}>
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.DRIVING_INCIDENTS.title()}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 14,
              mb: (theme) => theme.spacing(1.5),
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              minWidth: 100,
            }}
          >
            {quotePolicyEndorsementInfoTitlesDescriptions.DRIVING_INCIDENTS.description()}
          </Typography>
          <Box sx={{ maxWidth: 800, mx: 0, mb: 4 }}>
            <DataTable
              onRowClick={(r) =>
                setSelectedDrivingIncidentRowId(
                  r.id === selectedDrivingIncidentRowId ? '' : (r.id as string),
                )
              }
              columns={incidentsColumnsDriving}
              rows={rowsDrivingIncidents}
              loading={groups?.pol_incidents?.loading}
              focusedRowId={selectedDrivingIncidentRowId}
              columnVisibilityModel={createColumnVisibilityModel(columnsDrivingIncidents)}
              pageSize={defaultRowVirtualization}
              hideFooterPagination={rowsDrivingIncidents?.length! < defaultRowVirtualization}
            />
            {canEdit && loaded && (
              <Box>
                <Button
                  onClick={() => handleOpenAddDrawer('addDrivingIncident')}
                  sx={[primaryButtonStyle]}
                >
                  {t('Add Driving Incident')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {canEdit && isProducer && (
        <NavigationButtons handlePrevious={handlePrevious} handleNext={handleNext} />
      )}
    </Stack>
  );
};

export default Drivers;
