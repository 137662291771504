import useAuth from 'hooks/useAuth';
import React, { FC, useEffect } from 'react';

export const SignIn: FC = () => {
  const { signInWithSSO } = useAuth();

  useEffect(() => {
    signInWithSSO({ email: '' });
  }, []);

  return <></>;
};

export default SignIn;
