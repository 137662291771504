import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ClassDetailDrawer from './Detail';

const ClassDrawer: FC = () => {
  const LOCATION = useLocation();
  const [isClassDrawerOpen, setIsClassDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'wc_exposures' && (url?.wc || !isEmpty(url?.addWc))) {
      setIsClassDrawerOpen(true);
    } else if (url?.tab === 'wc_exposures') {
      setIsClassDrawerOpen(false);
    }
  }, [LOCATION]);

  return isClassDrawerOpen ? (
    <ClassDetailDrawer isDrawerOpen={isClassDrawerOpen} setIsDrawerOpen={setIsClassDrawerOpen} />
  ) : (
    <></>
  );
};
export default ClassDrawer;
