import { createContext } from 'react';

/// types
import { ISmartyAddressAutocompleteContextType, ISmartyAddressAutocompleteStore } from './types';

export const initialSmartyAddressAutocompleteData: ISmartyAddressAutocompleteStore = {
  data: undefined,
  loading: false,
  loaded: false,
};

export const smartyAddressAutocompleteContextDefault: ISmartyAddressAutocompleteContextType = {
  ...initialSmartyAddressAutocompleteData,
  resetSmartyAddressAutocompleteState: () => {},
  getAddressSuggestion: () => new Promise(() => {}),
};

export const SmartyAddressAutocompleteContext =
  createContext<ISmartyAddressAutocompleteContextType>(null!);
