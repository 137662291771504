import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { PaymentIntent } from 'api/models/Payments/payments.model';
import { currencyFormat } from 'helpers/Utils';
import React, { FC } from 'react';

interface IPaymentSummaryV2 {
  paymentIntent: PaymentIntent;
  setIsRecurring: (isRecurring: boolean) => void;
  isRecurring: boolean;
}

const PaymentSummaryV2: FC<IPaymentSummaryV2> = ({
  paymentIntent,
  setIsRecurring,
  isRecurring,
}) => {
  const invoices = Object.values(paymentIntent?.invoices ?? {});

  const handleEnrolledInAutopay = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const recurring = e.target.checked;
    setIsRecurring(recurring);
  };

  return (
    <>
      <Box>
        <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 2 }}>Payment Summary</Typography>

        {invoices.map((invoice) => (
          <Stack key={invoice.display_id} direction="row" justifyContent="space-between">
            <Typography>Invoice No. {invoice?.display_id ?? ''}</Typography>
            <Typography>
              {paymentIntent?.amount ? currencyFormat(undefined, invoice.amount).merged : '-'}
            </Typography>
          </Stack>
        ))}

        <Divider sx={{ my: 1 }} />

        <Stack direction="row" justifyContent="space-between" my={3}>
          <Typography sx={{ fontWeight: 700 }}>TOTAL</Typography>
          <Typography sx={{ fontSize: 32, fontWeight: 700 }}>
            {paymentIntent?.amount ? currencyFormat(undefined, paymentIntent.amount).merged : '-'}
          </Typography>
        </Stack>
      </Box>
      <Typography sx={{ fontSize: 12 }}>
        <Box component="span" sx={{ fontWeight: 500 }}>
          Reminder:{' '}
        </Box>
        A $7.00 fee applies to each monthly installment for customers not enrolled in autopay.
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleEnrolledInAutopay}
              checked={isRecurring}
              name="enroll-in-autopay-checkbox"
            />
          }
          label="Enroll in Autopay"
        />
      </FormGroup>
      <Typography sx={{ ml: 4, fontSize: 12 }}>
        By clicking{' '}
        <Box component="span" sx={{ fontWeight: 500 }}>
          Pay Now
        </Box>
        , I also authorize THREE to automatically debit this payment method for future insurance
        payments when they become due.
      </Typography>
    </>
  );
};

export default PaymentSummaryV2;
