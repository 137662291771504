import React from 'react';

// Styles
import { Link, Typography } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';

// Components
import DataTable from 'components/DataTable';

// Hooks
import { Link as RouterLink } from 'react-router-dom';

// Helpers - Constants
import { dataFieldTypes } from 'common/constants';
import { getNestedValueFromObject } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import { POLICY_TABLE_CONFIG } from '../constants/table-config';

const AgentPolicies = ({ policies, isLoading }) => {
  const { formatDateInTimeZone } = useConfig();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: 'original_contract_start_time', sort: 'desc' },
  ]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const columnsPolicies: GridColDef[] = POLICY_TABLE_CONFIG.map((field) => ({
    ...field,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.field);
      if (field.field === 'three_policy_number') {
        const policyNo = getNestedValueFromObject(params.row, 'three_policy_number');
        return (
          <Link
            component={RouterLink}
            to={`/policies/${params.row.locator}/overview`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {policyNo !== '-' ? policyNo : fieldValue ?? '-'}
          </Link>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  return (
    <>
      <DataTable
        isHeaderWidthFit
        rows={policies ?? []}
        columns={columnsPolicies}
        loading={isLoading}
        pageSize={25}
        hideFooterPagination={policies?.length <= 25}
        sortingMode="client"
        paginationMode="client"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};
export default AgentPolicies;
