import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { getAdvancedSearchService } from 'api/services/AdvancedSearch';
import { Pagination } from 'types/Pagination';
import { AdvancedSearchContext, initialAdvancedSearchData } from './AdvancedSearchProviderContext';

export const AdvancedSearchProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialAdvancedSearchData);

  const fetch = async (pagination: Pagination, searchType: string): Promise<[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        searchType,
        loaded: searchType !== prevState.searchType ? false : prevState.loaded,
      }));

      // Get table results
      const searchResultsRes = await getAdvancedSearchService(
        {
          ...pagination,
          page_size: state.pagination.page_size,
          next: '',
        },
        searchType,
      );

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: searchResultsRes.results,
        pagination: {
          ...prevState.pagination,
          ...pagination,
          total_count: searchResultsRes.count,
          next: searchResultsRes!.next,
          page_size: pagination.page_size ?? prevState.pagination?.page_size,
        },
      }));

      return searchResultsRes.results ?? [];
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const resetAdvancedSearchState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialAdvancedSearchData,
    }));
  };

  const setActiveTabIndex = (idx: number) => {
    setState((prevState) => ({
      ...prevState,
      activeTabIndex: idx,
    }));
  };

  const setActiveTabValues = (activeTabValues: {}) => {
    setState((prevState) => ({
      ...prevState,
      activeTabValues,
    }));
  };

  const resetActiveTabValues = () => {
    setState((prevState) => ({
      ...prevState,
      activeTabValues: {},
    }));
  };

  const resetActiveTabIndex = () => {
    setState((prevState) => ({
      ...prevState,
      activeTabIndex: 0,
    }));
  };

  const setActiveTabType = (type: string) => {
    setState((prevState) => ({
      ...prevState,
      activeTabType: type,
    }));
  };

  return (
    <AdvancedSearchContext.Provider
      value={{
        ...state,
        fetch,
        resetAdvancedSearchState,
        setActiveTabIndex,
        setActiveTabValues,
        resetActiveTabValues,
        resetActiveTabIndex,
        setActiveTabType,
      }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  );
};
