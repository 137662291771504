import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropertyDetailDrawer from './Detail';
import PropertyEditDrawer from './Edit';

export interface IPropertyDrawer {
  isEdit: boolean;
  updateUnderwritingQuestions: (isPropertyTabHasUnderwritingQuestion: boolean) => void;
}

const PropertyDrawer: FC<IPropertyDrawer> = ({ isEdit, updateUnderwritingQuestions }) => {
  const LOCATION = useLocation();
  const [isPropertyDrawerOpen, setIsPropertyDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (
      url?.tab === 'property_exposures' &&
      (url?.property || !isEmpty(url?.addProperty)) &&
      !url?.schedule_mod
    ) {
      setIsPropertyDrawerOpen(true);
    } else if (url?.tab === 'property_exposures') {
      setIsPropertyDrawerOpen(false);
    }
  }, [LOCATION]);

  return isPropertyDrawerOpen ? (
    isEdit ? (
      <PropertyEditDrawer
        isDrawerOpen={isPropertyDrawerOpen}
        setIsDrawerOpen={setIsPropertyDrawerOpen}
        isAdd={!isEmpty(url?.addProperty)}
        updateUnderwritingQuestions={updateUnderwritingQuestions}
      />
    ) : (
      <PropertyDetailDrawer
        isDrawerOpen={isPropertyDrawerOpen}
        setIsDrawerOpen={setIsPropertyDrawerOpen}
      />
    )
  ) : (
    <></>
  );
};

export default PropertyDrawer;
