import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WcOwnersMembersDrawerDetailDrawer from './Detail';

export interface IWcOwnersMembersDrawer {
  position: string;
}

const WcOwnersMembersDrawer: FC<IWcOwnersMembersDrawer> = ({ position }) => {
  const LOCATION = useLocation();
  const [isOwnersMembersDrawerOpen, setIsOwnersMembersDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'wc_exposures' && url?.owner_member) {
      setIsOwnersMembersDrawerOpen(true);
    } else if (url?.tab === 'wc_exposures') {
      setIsOwnersMembersDrawerOpen(false);
    }
  }, [LOCATION]);

  return isOwnersMembersDrawerOpen ? (
    <WcOwnersMembersDrawerDetailDrawer
      isDrawerOpen={isOwnersMembersDrawerOpen}
      setIsDrawerOpen={setIsOwnersMembersDrawerOpen}
      position={position}
    />
  ) : (
    <></>
  );
};
export default WcOwnersMembersDrawer;
