/* eslint-disable no-restricted-syntax */
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Pagination } from 'types/Pagination';

// helpers
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { fieldHasValue, getGroupTypeFields } from 'helpers/Utils';
import { ITaskPagination } from 'providers/TasksProvider/types';

// services
import {
  getCancellationDetailsWithLocator,
  getCustomerStripeInfo,
  getGracePeriodsWithLocator,
  getPolicyCancellationsWithLocator,
  getPolicyEndorsmentsWithLocator,
  getPolicyExposuresWithLocator,
  getPolicyExposureWithLocator,
  getPolicyInvoicesWithLocator,
  getPolicyPerilsWithLocator,
  getPolicyPriceWithLocator,
  getPolicyTasksWithLocator,
  getPolicyTransactionHistoryWithLocator,
  getPolicyWithLocator,
  updateCustomerStripeInfo,
} from 'api/services/Policies';

import { getRuleEngineDecisions } from 'api/services/RuleEngineDecisions';

/// types
import { Params } from 'api/helpers/Sender';
import { CancellationCreate } from 'api/models/Policy/Cancellation/cancellationCreate.model';
import { PolicyPaymentInfo } from 'api/models/Policy/policyPaymentInfo.model';
import { PolicyPerilResult } from 'api/models/Policy/policyPerils.model';
import { getDocumentTypesService } from 'api/services/Documents';
import {
  getWorkFlowFormSetsFormsWithFormSetType,
  getWorkFlowPerilFormsWithFormCode,
} from 'api/services/NewQuote';
import { DOCUMENT_TYPES_FROM } from 'common/constants';
import { groupByExposureAndSumPremiums } from 'helpers/PricingBreakdownHelpers';
import { groupBy, isEmpty, omit } from 'lodash-es';
import { IContextProps } from 'providers/types';
import { IAxiosError } from 'types/ErrorResponseTypes';
import { initialPolicyDetailData, PolicyDetailContext } from './PolicyDetailProviderContext';
import {
  IGroupedInvoicesProps,
  IPoliciesCancellationsProps,
  IPoliciesInvoicesProps,
  IPoliciesTransactionHistoryProps,
  IPolicyExposuresProps,
  TGroups,
} from './types';

/// data

export const PolicyDetailProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialPolicyDetailData);

  const fetch = async (
    id: string,
    excludePrice: boolean = true,
    newBusiness: boolean = false,
  ): Promise<any> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await getPolicyWithLocator(id, excludePrice, newBusiness);

      const groupFields = getGroupTypeFields(res.characteristics?.data);

      const characteristic = res?.characteristics ?? {};

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
        groups: {
          ...prevState.groups,
          ...(Object.entries(prevState.groups ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupFields?.[`${key}`] ?? [],
              },
            }),
            {},
          ) as TGroups),
        },
        underwritingQuestionsState: {
          ...prevState.underwritingQuestionsState,
          ...{
            ...(characteristic?.data ?? {}),
            product: res?.product?.code,
            effective_date: fieldHasValue(characteristic.started_at)
              ? new Date(characteristic.started_at as string)
              : undefined,

            // map answered questions to state
            ...((characteristic?.data?.underwriting_question as any[])?.reduce(
              (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
              {},
            ) ?? {}),
          },
        },
      }));
      return res;
    } catch (error) {
      const e = error as AxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e.response?.status === 404) {
        displayBackendErrorMessage(e, 'Not found');
      }
      throw e;
    }
  };

  const getExposures = async (
    id: string,
    query: Pagination,
    exposureCode = '',
    isNewBusiness: boolean = false,
  ) => {
    const hasExposureCode = !isEmpty(exposureCode);

    try {
      setState((prevState) => ({
        ...prevState,
        exposures: { ...prevState.exposures, loading: true },
        exposureList: hasExposureCode
          ? {
              ...prevState.exposureList,
              [`${exposureCode}`]: {
                ...prevState.exposureList?.[`${exposureCode}`],
                loading: true,
              },
            }
          : {
              ...prevState.exposureList,
              ...Object.keys(prevState.exposureList ?? {}).reduce(
                (a, curr) => ({
                  ...a,
                  [`${curr}`]: {
                    ...prevState.exposureList?.[`${curr}`],
                    loading: true,
                  },
                }),
                {},
              ),
            },
      }));

      const policyExposures: IPolicyExposuresProps[] = [];

      const res = await getPolicyExposuresWithLocator(id, {
        ...query,
        page_size: query.page_size ?? initialPolicyDetailData.exposures!.pagination?.page_size,
        ...(isNewBusiness && { new_business: true }),
      });

      const groupedExposures = groupBy(res.results ?? [], (item) => item.name);

      for (const ph of res.results!) {
        policyExposures.push({
          ...ph,
          id: ph.locator!,
        });
      }

      setState((prevState) => ({
        ...prevState,
        exposures: {
          ...prevState.exposures,
          loading: false,
          loaded: true,
          data: policyExposures,
          pagination: {
            ...query,
            total_count: res.count,
          },
        },
        exposureList: {
          ...prevState.exposureList,
          ...Object.entries(prevState.exposureList ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupedExposures?.[`${key}`] ?? [],
                pagination: {
                  ...query,
                  total_count: groupedExposures?.[`${key}`]?.length ?? 0,
                },
              },
            }),
            {},
          ),
        },
      }));

      return policyExposures;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        exposures: { ...prevState.exposures, loading: false, loaded: true },
        exposureList: hasExposureCode
          ? {
              ...prevState.exposureList,
              [`${exposureCode}`]: { loading: false, loaded: true },
            }
          : {
              ...prevState.exposureList,
              ...Object.keys(prevState.exposureList ?? {}).reduce(
                (a, curr) => ({
                  ...a,
                  [`${curr}`]: {
                    ...prevState.exposureList?.[`${curr}`],
                    loading: false,
                    loaded: true,
                  },
                }),
                {},
              ),
            },
      }));

      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getUnderwritingQuestions = async (
    productCode: string,
    formset: string,
    formCode?: string,
    query?: Params,
  ) => {
    try {
      if (isEmpty(state.underwritingQuestions?.[`${formset}`]?.data)) {
        setState((prevState) => ({
          ...prevState,
          underwritingQuestions: {
            ...prevState.underwritingQuestions,
            [`${formset}`]: {
              ...(prevState.underwritingQuestions![`${formset}`] ?? {}),
              loading: true,
            },
          },
        }));

        if (formCode) {
          const forms = await getWorkFlowPerilFormsWithFormCode({
            product_code: productCode,
            work_flow_name: 'underwriting-questions',
            config_formset_type: formset,
            form_code: formCode,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            underwritingQuestions: {
              ...prevState.underwritingQuestions,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: {
                  ...prevState?.underwritingQuestions[`${formset}`]?.data,
                  [`${formCode}`]: forms,
                },
              },
            },
          }));
        } else {
          const forms = await getWorkFlowFormSetsFormsWithFormSetType({
            product_code: productCode,
            work_flow_name: 'underwriting-questions',
            config_formset_type: formset,
            cache: true,
            modifyResultForUnderwritingQuestions: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            underwritingQuestions: {
              ...prevState.underwritingQuestions,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: forms,
              },
            },
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        underwritingQuestions: {
          ...prevState.underwritingQuestions,
          [`${formset}`]: { loading: false, loaded: true },
        },
      }));

      throw error;
    }
  };

  const getTasks = async (locator: string, query: ITaskPagination) => {
    try {
      setState((prevState) => ({
        ...prevState,
        tasks: { ...prevState.tasks, loading: true },
      }));

      let res;

      try {
        res = await getPolicyTasksWithLocator(locator, {
          ...query,
          page_size: initialPolicyDetailData.tasks?.pagination?.page_size,
        });
      } catch (error) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          if (e.response?.status === 404) {
            res = await getPolicyTasksWithLocator(locator, {
              ...query,
              page_size: initialPolicyDetailData.tasks?.pagination?.page_size,
              page: 1,
            });
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        tasks: {
          ...prevState.tasks,
          loading: false,
          loaded: true,
          data: res?.results ?? [],
          pagination: {
            ...query,
            total_count: res?.count,
          },
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        tasks: { ...prevState.tasks, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getPerils = async (id: string, query: Pagination) => {
    try {
      setState((prevState) => ({
        ...prevState,
        perils: { ...prevState.perils, loading: true },
      }));

      const res = await getPolicyPerilsWithLocator(id, {
        ...query,
        page_size: initialPolicyDetailData.perils!.pagination?.page_size,
      });

      const policyPerils: PolicyPerilResult[] = [];

      for (const ph of res.results!) {
        policyPerils.push({
          perilType: ph.display_name!,
          id: ph.locator,
          assignedExposure: ph.assigned_exposure!,
          dateCreated: ph.created_at,
          start_at: ph.start_at,
          end_at: ph.end_at,
          is_active: ph.is_active,
        });
      }

      setState((prevState) => ({
        ...prevState,
        perils: {
          ...prevState.perils,
          loading: false,
          loaded: true,
          data: policyPerils,
          pagination: {
            ...query,
            total_count: res.count,
          },
        },
      }));

      return policyPerils;
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getBillingData = async (
    id: string,
    assumeCustomerIsNotEnrolledInAutopay: boolean = false,
  ) => {
    try {
      setState((prevState) => ({
        ...prevState,
        invoices: { ...prevState.invoices, loading: true },
        billingPaymentInfo: { ...prevState.billingPaymentInfo, loading: true },
      }));

      const invoiceReq = getPolicyInvoicesWithLocator(id, {
        ...initialPolicyDetailData.invoices?.pagination,
        include_future_invoices: true,
      });

      let paymentInfoReq;
      if (assumeCustomerIsNotEnrolledInAutopay) {
        // Customer request is disabled for quote billings and assumed that the customer is not enrolled in autopay
        // https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/13065
        paymentInfoReq = Promise.resolve({ is_recurring: false } as PolicyPaymentInfo);
      } else {
        // Get customer info to check enroll in autopay status
        paymentInfoReq = getCustomerStripeInfo(id);
      }

      await Promise.all([invoiceReq, paymentInfoReq]).then(([invoices, paymentInfo]) => {
        const policyInvoices: IPoliciesInvoicesProps[] = [];

        const isEnrolledInAutopay = paymentInfo.is_recurring;
        let modified_grand_total = invoices.summary?.grand_total;
        let modified_total_fees = invoices.summary?.total_fees;
        /**
         * This function calculates the status of policy invoices.
         * It iterates through each invoice in the invoices.results array,
         * Determines whether the invoice due date is in the past Due or current due,
         * Applies specific fee adjustments if the invoice is a future invoice and not enrolled in autopay,
         * And then updates the invoice status based on the due date and settlement status.
         */
        const calculatePolicyInvoicesStatus = () => {
          for (const ph of invoices.results!) {
            let { fee } = ph;
            let { total_due } = ph;

            // #11781 requires future invoices to display 7.00 fee if not enrolled in autopay
            // #11781 requires future invoices to add 7.00 fee to total if not enrolled in autopay
            // If the invoice is a future invoice and not enrolled in autopay, apply specific fee adjustments
            if (!isEnrolledInAutopay && ph.future_invoice) {
              fee = '7.00';
              total_due = (+(ph.total_due || 0) + 7).toFixed(2);

              modified_total_fees = (+(modified_total_fees || 0) + 7).toFixed(2);
              modified_grand_total = (+(modified_grand_total || 0) + 7).toFixed(2);
            }

            policyInvoices.push({
              ...ph,
              id: ph.locator!,
              fee,
              total_due,
            });
          }
        };

        // Calculate the invoive statıses
        calculatePolicyInvoicesStatus();

        // Generate group array by invoice settlement_status.key
        const groupedInvociesTemplate = groupBy(policyInvoices, 'status.key');

        const groupedInvoices: IGroupedInvoicesProps = {
          scheduled: groupedInvociesTemplate.undefined,
          ...omit(groupedInvociesTemplate, 'undefined'),
        };
        // remove outstanding
        if (groupedInvoices.outstanding) {
          delete groupedInvoices.outstanding;
        }

        const {
          total_premium = 0,
          paid_to_date = 0,
          past_due = 0,
          current_due = 0,
          total_due = 0,
        } = invoices.summary;
        setState((prevState) => ({
          ...prevState,
          invoices: {
            ...prevState.invoices,
            loading: false,
            loaded: true,
            data: policyInvoices,
            pagination: {
              ...prevState.invoices.pagination,
              total_count: invoices.count,
            },
          },
          groupedInvoices,
          billingPaymentAmounts: {
            totalPremium: +total_premium,
            totalFees: +modified_total_fees,
            total: +modified_grand_total,
            paidToDate: +paid_to_date,
            pastDue: +past_due,
            currentDue: +current_due,
            totalDue: +total_due,
          },
          billingPaymentInfo: {
            loading: false,
            loaded: true,
            data: { ...paymentInfo },
          },
        }));
      });
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        invoices: { ...initialPolicyDetailData.invoices, loading: false, loaded: true },
        billingPaymentInfo: {
          ...initialPolicyDetailData.billingPaymentInfo,
          loading: false,
          loaded: true,
        },
      }));
      displayBackendErrorMessage(error);
    }
  };

  const updatePaymentInfo = async (policylocator: number) => {
    try {
      setState((prevState) => ({
        ...prevState,
        billingPaymentInfo: { ...prevState.billingPaymentInfo, loading: true },
      }));

      const res = await updateCustomerStripeInfo(policylocator);

      setState((prevState) => ({
        ...prevState,
        billingPaymentInfo: {
          loading: false,
          loaded: true,
          data: { ...res },
        },
      }));
      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        billingPaymentInfo: { ...prevState.billingPaymentInfo, loading: false, loaded: true },
      }));
      displayBackendErrorMessage(error);
      throw error;
    }
  };

  const getCancellations = async (id: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        cancellations: { ...prevState.cancellations, loading: true },
      }));
      const policyCancellations: IPoliciesCancellationsProps[] = [];

      const res = await getPolicyCancellationsWithLocator(id);

      for (const ph of res) {
        policyCancellations.push({
          ...ph,
          id: ph.locator!,
        });
      }

      setState((prevState) => ({
        ...prevState,
        cancellations: {
          ...prevState.cancellations,
          loading: false,
          loaded: true,
          data: policyCancellations,
          // pagination: {
          //   ...query,
          //   total_count: res.count,
          // },
        },
      }));

      return policyCancellations;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        cancellations: { ...prevState.cancellations, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getEndorsements = async (id: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        endorsements: { ...prevState.endorsements, loading: true },
      }));

      const res = await getPolicyEndorsmentsWithLocator(id);

      setState((prevState) => ({
        ...prevState,
        endorsements: {
          ...prevState.endorsements,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res ?? [];
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        endorsements: { ...prevState.endorsements, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getTransactionHistory = async (id: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        transactionHistory: { ...prevState.transactionHistory, loading: true },
      }));
      const policyTransactionHistory: IPoliciesTransactionHistoryProps[] = [];
      const res = await getPolicyTransactionHistoryWithLocator(id, {
        page_size: initialPolicyDetailData?.transactionHistory?.pagination?.page_size!,
      });

      for (const ph of res.results!) {
        policyTransactionHistory.push({
          ...ph,
          id: ph.locator!,
        });
      }

      setState((prevState) => ({
        ...prevState,
        transactionHistory: {
          ...prevState.transactionHistory,
          loading: false,
          loaded: true,
          data: policyTransactionHistory,
          pagination: {
            total_count: res.count,
            page_size: prevState.transactionHistory?.pagination?.page_size!,
            next: res.next,
          },
        },
      }));
      return res?.results ?? [];
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        transactionHistory: {
          ...prevState.transactionHistory,
          loading: false,
          loaded: true,
        },
      }));

      throw error;
    }
  };

  const resetTransactionHistory = (): void => {
    setState((prevState) => ({
      ...prevState,
      transactionHistory: initialPolicyDetailData.transactionHistory,
    }));
  };

  const getGracePeriods = async (id: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        gracePeriods: { ...prevState.gracePeriods, loading: true },
      }));
      const res = await getGracePeriodsWithLocator(id);

      setState((prevState) => ({
        ...prevState,
        gracePeriods: {
          ...prevState.gracePeriods,
          loading: false,
          loaded: true,
          data: res?.results,
        },
      }));
      return res?.results ?? [];
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        gracePeriods: {
          ...prevState.gracePeriods,
          loading: false,
          loaded: true,
        },
      }));

      throw error;
    }
  };

  const getCancellationDetails = async (policyLocator: string, cancellationLocator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        cancellationDocuments: { ...prevState.cancellationDocuments, loading: true },
        cancellationPrice: { ...prevState.cancellationPrice, loading: true },
        cancellationDetailList: { ...prevState.cancellationDetailList, loading: true },
      }));

      const res = await getCancellationDetailsWithLocator(policyLocator, cancellationLocator);

      const cancellationDocuments = res?.document!.map((it, ix) => ({
        ...it,
        id: ix,
      }));

      setState((prevState) => ({
        ...prevState,
        cancellationDocuments: {
          ...prevState?.cancellationDocuments,
          loading: false,
          data: cancellationDocuments,
          loaded: true,
        },
        cancellationPrice: {
          ...prevState?.cancellationPrice,
          data: res?.price!,
          loading: false,
          loaded: true,
        },
        cancellationDetailList: {
          ...prevState?.cancellationDetailList,
          data: res,
          loading: false,
          loaded: true,
        },
      }));

      return res;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        cancellationDocuments: { ...prevState.cancellationDocuments, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const resetPolicyCancellations = (): void => {
    setState((prevState) => ({
      ...prevState,
      cancellations: initialPolicyDetailData.cancellations,
    }));
  };

  const resetPolicyInvoices = (): void => {
    setState((prevState) => ({
      ...prevState,
      invoices: initialPolicyDetailData.invoices,
    }));
  };

  const resetPolicyEndorsements = (): void => {
    setState((prevState) => ({
      ...prevState,
      endorsements: initialPolicyDetailData.endorsements,
    }));
  };

  const getExposure = async (id: string, policylocator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        exposureDetail: { ...prevState.exposureDetail, loading: true },
      }));

      const res = await getPolicyExposureWithLocator(id, policylocator);

      setState((prevState) => ({
        ...prevState,
        exposureDetail: {
          loading: false,
          loaded: true,
          data: { ...res },
        },
      }));

      return res;
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getPolicyPrice = async (locator: string, newBusiness: boolean = false): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        price: { ...prevState.price, loading: true },
      }));

      const res = await getPolicyPriceWithLocator(locator, newBusiness);

      const exposureGroups = groupByExposureAndSumPremiums(res.exposure_pricing, 'Policy');

      setState((prevState) => ({
        ...prevState,
        price: {
          loading: false,
          loaded: true,
          data: { ...res, groups: exposureGroups },
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        price: { ...prevState.price, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const getFieldConfig = async (
    productCode: string,
    formset: string,
    formCode?: string,
    query?: Params,
  ) => {
    try {
      if (isEmpty(state?.fields?.[`${formset}`]?.data)) {
        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [`${formset}`]: {
              ...(prevState.fields![`${formset}`] ?? {}),
              loading: true,
            },
          },
        }));

        if (formCode) {
          const forms = await getWorkFlowPerilFormsWithFormCode({
            product_code: productCode,
            work_flow_name: 'quick-quote',
            config_formset_type: formset,
            form_code: formCode,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: {
                  ...prevState?.fields[`${formset}`]?.data,
                  [`${formCode}`]: forms,
                },
              },
            },
          }));
        } else {
          const forms = await getWorkFlowFormSetsFormsWithFormSetType({
            product_code: productCode,
            work_flow_name: 'quick-quote',
            config_formset_type: formset,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: forms,
              },
            },
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [`${formset}`]: { loading: false, loaded: true },
        },
      }));

      throw error;
    }
  };

  const resetPolicyDetailState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialPolicyDetailData,
    }));
  };

  const resetTasks = (): void => {
    setState((prevState) => ({
      ...prevState,
      tasks: initialPolicyDetailData.tasks,
    }));
  };

  const resetPolicyExposures = (): void => {
    setState((prevState) => ({
      ...prevState,
      exposures: initialPolicyDetailData.exposures,
    }));
  };

  const resetPolicyPerils = (): void => {
    setState((prevState) => ({
      ...prevState,
      perils: initialPolicyDetailData.perils,
    }));
  };

  const setCancellationDetails = (cancellationDetails: CancellationCreate): void => {
    setState((prevState) => ({
      ...prevState,
      cancellationDetailList: {
        data: cancellationDetails,
        loading: false,
        loaded: true,
      },
    }));
  };

  const resetCancellationDetails = (): void => {
    setState((prevState) => ({
      ...prevState,
      cancellationDetailList: {
        data: {},
        loading: true,
        loaded: false,
      },
    }));
  };

  const getRuleEngineResults = async (locator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: true },
      }));

      const res = await getRuleEngineDecisions({
        policy_transaction_type: 'policy',
        socotra_locator: locator,
      });

      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: {
          loading: false,
          loaded: true,
          data: res,
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const getPolicyDocumentTypes = async () => {
    try {
      let results = state.documentTypes.data;

      if (isEmpty(state.documentTypes.data)) {
        setState((prevState) => ({
          ...prevState,
          documentTypes: {
            ...prevState.documentTypes,
            loading: true,
          },
        }));

        const res = await getDocumentTypesService(DOCUMENT_TYPES_FROM.POLICY);
        results = res.results;

        setState((prevState) => ({
          ...prevState,
          documentTypes: {
            ...prevState.documentTypes,
            loading: false,
            loaded: true,
            data: res?.results,
          },
        }));
      }

      return results! ?? [];
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        documentTypes: {
          ...prevState.documentTypes,
          loading: true,
          loaded: true,
        },
      }));
      throw error;
    }
  };

  return (
    <PolicyDetailContext.Provider
      value={{
        ...state,
        fetch,
        resetPolicyDetailState,
        getExposures,
        getBillingData,
        updatePaymentInfo,
        getEndorsements,
        resetPolicyEndorsements,
        getRuleEngineResults,
        getCancellations,
        getCancellationDetails,
        getGracePeriods,
        getTransactionHistory,
        resetTransactionHistory,
        resetPolicyCancellations,
        resetPolicyInvoices,
        getPerils,
        getExposure,
        getPolicyPrice,
        getFieldConfig,
        getTasks,
        resetTasks,
        resetPolicyExposures,
        resetPolicyPerils,
        setCancellationDetails,
        resetCancellationDetails,
        getUnderwritingQuestions,
        getPolicyDocumentTypes,
      }}
    >
      {children}
    </PolicyDetailContext.Provider>
  );
};
