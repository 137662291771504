import { Elements } from '@stripe/react-stripe-js';
import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useState } from 'react';

import { Grid, useTheme } from '@mui/material';
import { PaymentIntent } from 'api/models/Payments/payments.model';
import { getPaymentIntent, getStripeConfig } from 'api/services/Payments';
import CheckoutFormV2 from 'components/Payments/CheckoutFormV2';
import Error from 'components/Payments/Error';
import PaymentHeader from 'components/Payments/PaymentHeader';
import PaymentSummaryV2 from 'components/Payments/PaymentSummaryV2';
import useLoader from 'hooks/useLoader';
import { useParams } from 'react-router-dom';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
let stripePromise: undefined | Promise<any>;

interface ErrorState {
  message?: string;
}

/**
 * PayV2 Component
 *
 * This component handles the payment process using Stripe Elements.
 *
 * Features:
 * - Fetches PaymentIntent and Stripe configuration from the backend.
 * - Displays payment details and a payment form using Stripe Elements.
 * - Handles loading state and error handling.
 *
 * @remarks
 * This component is used for the main payment process and is designed to be used
 * after redirection from the Pay component for old payment links.
 */
const PayV2 = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntent, setPaymentIntent] = useState(undefined as undefined | PaymentIntent);
  const themeHook = useTheme();

  const { paymentIntentId, policyLocator } = useParams<{
    paymentIntentId: string;
    policyLocator: string;
  }>();

  const { setLoading } = useLoader();
  const [isRecurring, setIsRecurring] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<ErrorState | null>(null);

  const fetchPaymentIntent = async () => {
    if (!paymentIntentId) {
      setError({
        message:
          'Your payment link is not valid. Please try again later or contact your support team.',
      });
      setIsLoaded(true);
      setLoading(false);
    } else if (!clientSecret) {
      setLoading(true);

      const stripeConfig = await getStripeConfig();
      stripePromise = loadStripe(stripeConfig.publishable_key);

      try {
        const res = await getPaymentIntent({ policyLocator, paymentIntentId });
        setPaymentIntent(res);
        setClientSecret(res.client_secret);
      } catch (e) {
        const err = e as unknown as ErrorState;
        setError(err);
      } finally {
        setLoading(false);
        setIsLoaded(true);
      }
    }
  };

  React.useEffect(() => {
    // Fetch PaymentIntent as soon as the page loads
    fetchPaymentIntent();
  }, []);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: themeHook.palette.secondary.main,
      fontFamily: 'Nexa, sans-serif',
      borderRadius: '0px',
      focusBoxShadow: `0 0 0 1px ${themeHook.palette.secondary.main}`,
      focusOutline: 'none',
    },
    labels: 'floating',
  };

  const options: StripeElementsOptions = {
    fonts: [
      {
        family: 'Nexa',
        src: 'local(Nexa), url(/fonts/Nexa/NexaHeavy.woff2) format(woff2)',
        weight: '700',
      },
      {
        family: 'Nexa',
        src: 'local(Nexa), url(/fonts/Nexa/NexaBold.woff2) format(woff2)',
        weight: '500',
      },
      {
        family: 'Nexa',
        src: 'local(Nexa), url(/fonts/Nexa/NexaRegular.woff2) format(woff2)',
        weight: '400',
      },
    ],
    clientSecret,
    appearance,
  };

  return (
    <>
      {error ? (
        <Error error={error} />
      ) : isLoaded && paymentIntent ? (
        <Grid
          container
          columnSpacing={{ xs: 0, sm: '75px' }}
          rowSpacing={4}
          sx={{ p: 4, maxWidth: '1140px', marginLeft: 0 }}
        >
          <Grid item xs={12}>
            <PaymentHeader />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PaymentSummaryV2
              isRecurring={isRecurring}
              setIsRecurring={setIsRecurring}
              paymentIntent={paymentIntent}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {clientSecret && stripePromise && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutFormV2
                  isRecurring={isRecurring}
                  paymentIntent={paymentIntent}
                  setIsLoading={setLoading}
                  isLoading={!isLoaded}
                />
              </Elements>
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default PayV2;
