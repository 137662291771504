import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridCellModes, GridColDef } from '@mui/x-data-grid';
import { bulkExposureUpdateQuote } from 'api/services/NewQuote';
import AddScheduleIcon from 'assets/images/icon_schedule_mod.svg';
import {
  dataFieldTypes,
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  smodLines,
  submissionDetailInfoTabs,
  TAB_POSITIONS,
  threeEmployerExposurePerilNames,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
  wcExposureRelatedKeyValues,
  wcExposureRelatedKeyValuesMap,
} from 'common/constants';
import DataTable from 'components/DataTable';
import DataTableFieldParser from 'components/DataTableFieldParser/DataTableFieldParser';
import DataTablePro from 'components/DataTablePro';
import LocationShow from 'components/LocationShow';
import WCExclusionToogle from 'components/QuotePolicyDetailEndorsement/Buttons/WCExclusionToogle';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import { defaultTableCellStyle, primaryButtonStyle, tabTitleStyles } from 'helpers/MuiSharedStyles';
import { calculateSchedMod } from 'helpers/ScheduleModificationHelpers';
import {
  createColumnVisibilityModel,
  currencyFormat,
  determineOwnerOfficerPositions,
  fieldHasValue,
  formatLocation,
  getNestedValueFromObject,
  handleBackendErrorsWithFormik,
  parseLocation,
  redirectToProducerTab,
  sortComputedWcRowsByMainStatus,
  updateQueryStrings,
} from 'helpers/Utils';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import ClassDrawer from './Drawers/ClassDrawer/ClassDrawer';
import ExpModeDrawer from './Drawers/ExpMod/ExpMod';
import WCExposureScheduleModDrawer from './Drawers/WCExposureScheduleModDrawer/WCExposureScheduleModDrawer';
import WcOwnersMembersDrawer from './Drawers/WcOwnersMembersDrawer/WcOwnersMembersDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

interface IWCExposures {
  id: string;
  state: string;
  location: string;
  name: string;
  status: string;
  class_code?: string;
  payroll?: string;
  exposure_mod?: string;
  scheduleMod: string | null;
}

interface IWCExposuresTotal {
  [key: string]: string | number | undefined;
}

const WCExposures = forwardRef<any, any>((_props, ref) => {
  const { t } = useTranslation();
  const [locationFilter, setLocationFilter] = useState('Location');
  const themeHook = useTheme();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useUser();
  const { setLoading } = useLoader();
  const [selectedClassID, setSelectedClassID] = useState<string>('');
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const [cellModesModel, setCellModesModel] = useState<any>({});
  const [rows, setRows] = useState<any[]>([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [
    computeWcRowsEmployeePayrollAndSubPayrollTotalObject,
    setComputeWcRowsEmployeePayrollAndSubPayrollTotalObject,
  ] = useState<IWCExposuresTotal>({});
  const [totalNotIsMainValues, setTotalNotIsMainValues] = useState<IWCExposuresTotal>({});
  const {
    data: quoteDetail,
    groups,
    exposureList,
    canEdit,
    updateQuoteProgress,
    updateQuoteDetail,
    getExposures,
    underwritingQuestionsState,
    progress: { data: progressData },
  } = useQuoteDetail();
  const { fetch: fetchKeyValues, data: keyValueStore } = useKeyValues();

  const isProducer = userData?.role?.code === userRoles.AGENT.code;
  const isUW = userData?.role?.code === userRoles.UNDERWRITER.code;

  const isProgressTab = isProducer && canEdit;
  const isWCExposuresTabNotCompleted = !(
    submissionDetailInfoTabs.WC_EXPOSURES.code in progressData
  );

  const SCHED_MOD_TABLES =
    keyValueStore?.[`${wcExposureRelatedKeyValues.SCHED_MOD_TABLES}`]?.data?.value ?? [];

  let updateComputedRows;

  useEffect(() => {
    try {
      Promise.allSettled(wcExposureRelatedKeyValuesMap.map((key) => fetchKeyValues(key)));
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  const currentWcExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`];

  const schedModGroupFields: any[] =
    groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data ?? [];

  const owners: any[] = groups?.pol_oo?.data ?? [];

  const ownersMembersRows = useMemo(
    () =>
      groups?.pol_oo?.data?.map((m) => {
        const {
          pol_oo_name_first = '',
          pol_oo_name_last = '',
          pol_oo_location: location,
          pol_oo_wc_payroll: payroll,

          pol_oo_wc_class_code,
          pol_oo_wc_class_code_desc,
          pol_oo_wc_excluded: status,
          locator,
        } = m;

        const { state } = parseLocation(location);
        const name = `${pol_oo_name_first} ${pol_oo_name_last}`;

        const exposureData = currentWcExposures?.data?.find(
          (e) => e.data?.emp_state === state,
        )?.data;

        const exposure_mod = fieldHasValue(exposureData?.emp_xmod_override)
          ? exposureData?.emp_xmod_override
          : fieldHasValue(exposureData?.emp_xmod)
          ? exposureData?.emp_xmod
          : '-';

        const filteredTableRows = SCHED_MOD_TABLES?.[`${smodLines.EMPLOYER}`]
          ?.filter((item) => String(item.FilingSetID) === exposureData?.emp_filing_set_id)
          ?.map((row) => ({
            ...row,
            MaxCredit: -row?.MaxCredit,
          }));
        const relatedFieldGroups = schedModGroupFields.filter(
          (schedMod) =>
            schedMod.pol_sched_mod_line === smodLines.EMPLOYER &&
            schedMod.pol_sched_mod_state === exposureData?.emp_state,
        );

        return {
          id: locator,
          state,
          location,
          name: name?.trim() === '' ? '-' : name,
          class_code: `${pol_oo_wc_class_code ?? ''} - ${pol_oo_wc_class_code_desc ?? ''}`,
          payroll,
          exposure_mod,
          status,
          scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
        };
      }) ?? [],
    [groups, currentWcExposures, keyValueStore],
  );

  const classCodeRows = useMemo(
    () =>
      currentWcExposures?.data?.flatMap((m) => {
        const exposureData = m.data;

        const exposure_mod = fieldHasValue(exposureData?.emp_xmod_override)
          ? exposureData?.emp_xmod_override
          : fieldHasValue(exposureData?.emp_xmod)
          ? exposureData?.emp_xmod
          : '-';

        const filteredTableRows = SCHED_MOD_TABLES.WC?.filter(
          (item) => String(item.FilingSetID) === exposureData?.emp_filing_set_id,
        )?.map((row) => ({
          ...row,
          MaxCredit: -row?.MaxCredit,
        }));
        const relatedFieldGroups = schedModGroupFields.filter(
          (schedMod) =>
            schedMod.pol_sched_mod_line === smodLines.EMPLOYER &&
            schedMod.pol_sched_mod_state === exposureData?.emp_state,
        );

        return (
          m.perils
            ?.filter((p) => p.name === threeEmployerExposurePerilNames.MANUAL_PREMIUM)
            ?.map(({ locator, data }) => ({
              id: locator,
              exposureId: m.locator,
              state: exposureData?.emp_state,
              location: data?.man_location,
              classCode: `${data?.man_class_code ?? ''} - ${data?.man_class_code_desc ?? ''}`,
              employeePayroll: data?.man_payroll_w2,
              uninsuredSubPayroll: data?.man_payroll_sub,
              exposure_mod,
              isMain: data?.man_ismain,
              isAncillary: data?.man_isancillary,
              scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
            })) ?? []
        );
      }) ?? [],
    [currentWcExposures, keyValueStore, groups],
  );

  const isTablesLoading = useMemo(
    () =>
      currentWcExposures?.loading ||
      groups?.pol_oo?.loading ||
      groups?.pol_sched_mod?.loading ||
      wcExposureRelatedKeyValuesMap.some((map) => keyValueStore?.[`${map}`]?.loading ?? false),

    [groups, currentWcExposures, keyValueStore],
  );

  const setScheduleModeColor = (value: number) => {
    if (value > 1) {
      return themeHook.customColors.errorRed;
    } else if (value < 1) {
      return themeHook.customColors.successGreen;
    }

    return themeHook.customColors.gunMetal;
  };

  const validatePayroll = (value: string, fieldName: string) => {
    const remainingWcTotalExcludingEditedValue =
      parseFloat(String(computeWcRowsEmployeePayrollAndSubPayrollTotalObject[fieldName] ?? 0)) -
      parseFloat(isEmpty(value) ? '0' : value);

    const remainingPayrollAfterValidation =
      fieldName === 'employeePayroll'
        ? parseFloat(underwritingQuestionsState?.payrollW2 ?? 0) -
          remainingWcTotalExcludingEditedValue
        : parseFloat(underwritingQuestionsState?.payrollSubcontracted ?? 0) -
          remainingWcTotalExcludingEditedValue;

    return remainingPayrollAfterValidation < parseFloat(isEmpty(value) ? '0' : value);
  };

  const validationSchema = yup.object({
    computedWcRows: yup.array().of(
      yup.object().shape({
        employeePayroll: yup.string().when('isMain', {
          is: () => 'No',
          then: yup
            .string()
            .test(
              'validatePayroll',
              'Employee payroll exceeds remaining available payroll',
              (value) =>
                !isEmpty(value) ? !validatePayroll(value as string, 'employeePayroll') : true,
            ),
        }),

        uninsuredSubPayroll: yup.string().when('isMain', {
          is: () => 'No',
          then: yup
            .string()
            .test(
              'validatePayroll',
              'Uninsured sub payroll exceeds remaining available payroll',
              (value) =>
                !isEmpty(value) ? !validatePayroll(value as string, 'uninsuredSubPayroll') : true,
            ),
        }),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {} as any,
    validationSchema,
    validateOnChange: false,
    onSubmit: async () => {},
  });

  const formatCellModes = (rowValues) => {
    const obj = {};
    rowValues.forEach((item) => {
      if (item.isMain === 'No') {
        obj[item.id] = { ...obj[item.id], employeePayroll: { mode: GridCellModes.Edit } };
      } else if (item.isMain === 'Yes') {
        obj[item.id] = { ...obj[item.id], employeePayroll: { mode: GridCellModes.View } };
      }
      if (item.isMain === 'No') {
        obj[item.id] = { ...obj[item.id], uninsuredSubPayroll: { mode: GridCellModes.Edit } };
      } else if (item.isMain === 'Yes') {
        obj[item.id] = { ...obj[item.id], uninsuredSubPayroll: { mode: GridCellModes.View } };
      }
    });

    return obj;
  };

  const checkWcRowHasError = (rowInfo) => {
    const { field } = rowInfo;
    const pickValidationWcFields = ['employeePayroll', 'uninsuredSubPayroll'];
    if (pickValidationWcFields.includes(field)) {
      const errorValues = Object.values(formik.errors?.computedWcRows ?? []);

      for (let i = 0; i < errorValues.length; i += 1) {
        const errVal = errorValues[i];
        if (errVal && errVal[field] && typeof errVal[field] === 'string') {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (isProgressTab && rows.length > 0) {
      const cellModes = formatCellModes(rows);
      setCellModesModel(cellModes);
    }
  }, [rows]);

  const generateTotalObject = (arr: IWCExposuresTotal[]) => {
    let totalEmployeePayroll = 0;
    let totalUninsuredSubPayroll = 0;
    let totalNotIsMainEmployeePayroll = 0;
    let totalNotIsMainUninsuredSubPayroll = 0;

    arr?.forEach((obj) => {
      totalEmployeePayroll += parseFloat(
        String(isEmpty(obj.employeePayroll) ? 0 : obj.employeePayroll),
      );
      totalUninsuredSubPayroll += parseFloat(
        String(isEmpty(obj.uninsuredSubPayroll) ? 0 : obj.uninsuredSubPayroll),
      );
      if (obj.isMain === 'No') {
        totalNotIsMainEmployeePayroll += parseFloat(
          String(isEmpty(obj.employeePayroll) ? 0 : obj.employeePayroll),
        );
        totalNotIsMainUninsuredSubPayroll += parseFloat(
          String(isEmpty(obj.uninsuredSubPayroll) ? 0 : obj.uninsuredSubPayroll),
        );
        setTotalNotIsMainValues({
          totalNotIsMainEmployeePayroll,
          totalNotIsMainUninsuredSubPayroll,
        });
      }
    });
    const totalId = uuidv4();

    return {
      id: totalId,
      exposureId: '',
      state: 'Total',
      location: '',
      classCode: '',
      employeePayroll: totalEmployeePayroll.toString(),
      uninsuredSubPayroll: totalUninsuredSubPayroll.toString(),
      exposure_mod: '',
      isMain: '',
      isAncillary: '',
      scheduleMod: '',
    };
  };

  const replaceBack = () =>
    HISTORY.replace({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.WC_EXPOSURES.code },
      }),
    });

  const handleUpdate = async (excludeLoading: boolean = false) => {
    try {
      setLoading(true);

      const perilData = formik.values.computedWcRows.reduce((acc, crr) => {
        const { exposureId } = crr;
        if (!acc[exposureId]) {
          acc[exposureId] = {
            locator: exposureId,
            name: 'employer',
            data: {},
            perils: formik.values.computedWcRows
              .filter((el) => el.exposureId === exposureId)
              .map((peril) => ({
                name: 'manual_premium',
                locator: peril.id,
                data: {
                  man_payroll_sub_user: peril.uninsuredSubPayroll,
                  man_payroll_w2_user: peril.employeePayroll,
                },
              })),
          };
        }
        return acc[exposureId];
      }, {});

      await bulkExposureUpdateQuote(
        quoteDetail?.policy_locator as string,
        quoteDetail?.locator as string,
        [perilData],
      );

      displayToastMessage('SUCCESS', t('The class code has been updated.'));

      getExposures(quoteDetail?.locator!, { page_size: 10000 }, threeExposureNames.EMPLOYER);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      if (!excludeLoading) {
        setLoading(false);
      }
    }
  };

  const isEditCell = useMemo(() => {
    return (formik.values.computedWcRows ?? []).some((row) => row.isMain === 'No');
  }, [formik.values.computedWcRows]);

  const employeePayrollAndSubPayrollTotalObject = useMemo(() => {
    return generateTotalObject(formik.values.computedWcRows);
  }, [formik.values.computedWcRows]);

  const handleSave = async (excludeLoading: boolean = false) => {
    if (isEditCell) {
      await formik.submitForm();

      const errors = await formik.validateForm();

      if (
        Number(employeePayrollAndSubPayrollTotalObject.employeePayroll) >
          Number(underwritingQuestionsState?.payrollW2 ?? 0) ||
        Number(employeePayrollAndSubPayrollTotalObject.uninsuredSubPayroll) >
          Number(underwritingQuestionsState?.payrollSubcontracted ?? 0)
      ) {
        displayToastMessage('ERROR', 'Payroll allocation by class code exceeds total.');
        setIsNextButtonClicked(false);
        replaceBack();
        throw new Error('Payroll allocation by class code exceeds total.');
      } else if (isEmpty(errors)) {
        await handleUpdate(excludeLoading);
      }
    }
    // Validation is requested not to be performed when there are no edit cells
    else if (!isEditCell) {
      await handleUpdate(excludeLoading);
    }
  };

  const wcExposuresError = (data: IWCExposures[]) =>
    !data?.every(
      (item) =>
        item.status === 'Yes' ||
        (fieldHasValue(item.class_code, ['-', ' - ']) && fieldHasValue(item.payroll, ['-', ' - '])),
    );

  const handleExclude = async (ownerLocator: string, excluded: boolean) => {
    if (!excluded) {
      HISTORY.push({
        search: updateQueryStrings({
          locationSearch: LOCATION.search,
          newQueries: { owner_member: ownerLocator, forceCover: true },
        }),
      });

      return;
    }

    try {
      setLoading(true);
      if (isProgressTab) {
        await handleSave(true);
      }
      const tmpOwners = [...owners];
      const rowIndex = tmpOwners.findIndex((row) => row.locator === ownerLocator);
      tmpOwners[rowIndex] = {
        ...tmpOwners[rowIndex],
        pol_oo_wc_excluded: excluded ? 'Yes' : 'No',
      };

      await updateQuoteDetail(
        quoteDetail?.policy_locator as string,
        quoteDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.OWNER}`]: tmpOwners,
          },
        },
        { validate: threePolicyGroupNames.OWNER, strategy: 'update', locator: ownerLocator },
      );

      displayToastMessage('SUCCESS', t('The owner has been updated.'));
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the owner.'));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: 'state',
      headerName: t('State'),
      flex: 0.5,
      minWidth: 80,
      type: 'string',
      sortable: true,
      sortComparator: (a, b, param1, param2) => {
        if (param1.id === 'total' || param2.id === 'total') {
          return 0;
        }

        return a.localeCompare(b);
      },
      renderCell(params) {
        return (
          <Typography
            sx={[
              defaultTableCellStyle,
              {
                fontWeight: (theme) =>
                  params.row.state === 'Total'
                    ? theme.typography.body2.fontWeight
                    : theme.typography.subtitle2.fontWeight,
              },
            ]}
            title={params.row.state}
          >
            {params.row.state}
          </Typography>
        );
      },
    },
    {
      field: 'location',
      headerName: t('Location'),
      flex: 1.5,
      minWidth: 150,
      type: 'string',
      sortable: true,
      editable: false,
      sortComparator: (a, b, param1, param2) => {
        if (param1.id === 'total' || param2.id === 'total') {
          return 0;
        }

        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const location = parseLocation(params.row.location);

        const formatted = formatLocation({
          ...location,
        });

        return !isEmpty(location.addressLine1) ? <LocationShow location={formatted.showing} /> : '';
      },
    },
    {
      field: 'classCode',
      sortComparator: (a, b, param1, param2) => {
        if (param1.id === 'total' || param2.id === 'total') {
          return 0;
        }

        return a.localeCompare(b);
      },
      headerName: t('Class Code'),
      flex: 1,
      minWidth: 130,
      type: 'string',
      sortable: true,
      editable: false,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'classCode');
        if (isProducer) {
          return (
            <Typography sx={defaultTableCellStyle} title={params.row.classCode}>
              {params.row.classCode}
            </Typography>
          );
        } else if (fieldValue.length > 10) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { wc: params.row.exposureId, wcp: params.row.id },
              })}`}
              underline="hover"
              title={fieldValue || '-'}
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
            >
              {fieldValue || ''}
            </Link>
          );
        } else
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { wc: params.row.exposureId, wcp: params.row.id },
              })}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || ''}
            </Link>
          );
      },
    },
    ...(isUW
      ? ([
          {
            field: 'isMain',
            headerName: t('Is Main'),
            flex: 1,
            minWidth: 90,
            type: 'string',
            sortable: true,
            editable: false,
            sortComparator: (a, b, param1, param2) => {
              if (param1.id === 'total' || param2.id === 'total') {
                return 0;
              }
              return a.localeCompare(b);
            },
            renderCell(params) {
              return (
                <Typography sx={defaultTableCellStyle} title={params.row.isMain}>
                  {params.row.isMain}
                </Typography>
              );
            },
          },
        ] as any[])
      : []),
    {
      field: 'employeePayroll',
      headerName: t('Employee Payroll'),
      flex: 1,
      minWidth: 120,
      type: 'currency',
      sortable: true,
      editable: true,
      headerAlign: 'right',
      align: 'right',
      sortComparator: (a, b, param1, param2) => {
        if (param1.id === 'total' || param2.id === 'total') {
          return 0;
        }

        // Set n/a value as -1, to compare n/a with number
        const _a = a ?? -1;
        const _b = b ?? -1;

        if (!Number.isNaN(Number(_a)) && !Number.isNaN(Number(_b))) return Number(_a) - Number(_b);

        return a.localeCompare(b);
      },
      renderEditCell(params) {
        const rowID = params.row.id;
        const fieldValue = formik.values.computedWcRows.find(
          (wcRow) => wcRow.id === rowID,
        ).employeePayroll;

        return (
          <DataTableFieldParser
            props={{
              ...params,
              field: `employeePayroll`,
              value: fieldValue || '',
            }}
            definitions={{
              autoFocus: false,
              required: true,
              formik,
              type: dataFieldTypes.NUMBER,
              align: 'right',
              withDollarIcon: true,
              formatted: true,
              fieldName: `employeePayroll`,
              valueType: 'array',
              relatedFieldName: 'computedWcRows',
              placeholder:
                fieldValue && fieldValue !== '-'
                  ? currencyFormat('USD', fieldValue, true).merged
                  : '$0',
              isChanged: () => {
                setIsDataChanged(true);
              },

              onBlurChange: () => {
                setIsDataChanged(true);

                updateComputedRows = formik.values.computedWcRows.map((row) => {
                  let isMainUpdatePayrollW2 = 0;
                  let employeePayrollValue;
                  // If wc exposure values are greater than the total value
                  if (
                    Number(employeePayrollAndSubPayrollTotalObject.employeePayroll) >
                    Number(underwritingQuestionsState?.payrollW2 ?? 0)
                  ) {
                    isMainUpdatePayrollW2 =
                      Number(employeePayrollAndSubPayrollTotalObject.employeePayroll) -
                      Number(underwritingQuestionsState?.payrollW2 ?? 0);

                    employeePayrollValue = isEmpty(row.employeePayroll)
                      ? row.employeePayroll
                      : Number(row.employeePayroll) - isMainUpdatePayrollW2;
                  }
                  // If wc exposure values are less than to the total value
                  else if (
                    Number(employeePayrollAndSubPayrollTotalObject.employeePayroll) <=
                    Number(underwritingQuestionsState?.payrollW2 ?? 0)
                  ) {
                    isMainUpdatePayrollW2 =
                      Number(underwritingQuestionsState?.payrollW2) -
                      Number(totalNotIsMainValues.totalNotIsMainEmployeePayroll);
                    employeePayrollValue =
                      isEmpty(row.employeePayroll) &&
                      Number(totalNotIsMainValues.totalNotIsMainEmployeePayroll) === 0 &&
                      isEmpty(underwritingQuestionsState?.payrollW2)
                        ? row.employeePayroll
                        : isMainUpdatePayrollW2;
                  }

                  if (row.isMain === 'Yes') {
                    return {
                      ...row,
                      employeePayroll:
                        employeePayrollValue >= 0
                          ? String(employeePayrollValue)
                          : row.employeePayroll,
                    };
                  } else {
                    return row;
                  }
                });
                formik.setValues({ computedWcRows: updateComputedRows });
              },
            }}
          />
        );
      },
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'employeePayroll');
        return fieldValue && fieldValue !== '-'
          ? currencyFormat('USD', fieldValue, true).merged
          : '$0';
      },
    },
    {
      field: 'uninsuredSubPayroll',
      headerName: t('Uninsured Subcontractor Payroll'),
      flex: 1,
      minWidth: 210,
      type: 'currency',
      sortable: true,
      editable: true,
      headerAlign: 'right',
      align: 'right',
      sortComparator: (a, b, param1, param2) => {
        if (param1.id === 'total' || param2.id === 'total') {
          return 0;
        }

        // Set n/a value as -1, to compare n/a with number
        const _a = a ?? -1;
        const _b = b ?? -1;

        if (!Number.isNaN(Number(_a)) && !Number.isNaN(Number(_b))) return Number(_a) - Number(_b);

        return a.localeCompare(b);
      },
      renderEditCell(params) {
        const rowID = params.row.id;
        const fieldValue = formik.values.computedWcRows.find(
          (wcRow) => wcRow.id === rowID,
        ).uninsuredSubPayroll;

        return (
          <DataTableFieldParser
            props={{
              ...params,
              field: `uninsuredSubPayroll`,
              value: fieldValue || '',
            }}
            definitions={{
              autoFocus: false,
              required: true,
              formik,
              type: dataFieldTypes.NUMBER,
              align: 'right',
              withDollarIcon: true,
              formatted: true,
              fieldName: `uninsuredSubPayroll`,
              valueType: 'array',
              relatedFieldName: 'computedWcRows',
              placeholder:
                fieldValue && fieldValue !== '-'
                  ? currencyFormat('USD', fieldValue, true).merged
                  : '$0',
              isChanged: () => {
                setIsDataChanged(true);
              },
              onBlurChange: () => {
                setIsDataChanged(true);

                updateComputedRows = formik.values.computedWcRows.map((row) => {
                  let isMainUpdatePayrollSubcontracted = 0;
                  let uninsuredSubPayrollValue;
                  // If wc exposure values are greater than the total value
                  if (
                    Number(employeePayrollAndSubPayrollTotalObject.uninsuredSubPayroll) >
                    Number(underwritingQuestionsState?.payrollSubcontracted ?? 0)
                  ) {
                    isMainUpdatePayrollSubcontracted =
                      Number(employeePayrollAndSubPayrollTotalObject.uninsuredSubPayroll) -
                      Number(underwritingQuestionsState?.payrollSubcontracted ?? 0);
                    uninsuredSubPayrollValue = isEmpty(row.uninsuredSubPayroll)
                      ? row.uninsuredSubPayroll
                      : Number(row.uninsuredSubPayroll) - isMainUpdatePayrollSubcontracted;
                  }
                  // If wc exposure values are less than or equal to the total value
                  else if (
                    Number(employeePayrollAndSubPayrollTotalObject.uninsuredSubPayroll) <=
                    Number(underwritingQuestionsState?.payrollSubcontracted ?? 0)
                  ) {
                    isMainUpdatePayrollSubcontracted =
                      Number(underwritingQuestionsState?.payrollSubcontracted) -
                      Number(totalNotIsMainValues.totalNotIsMainUninsuredSubPayroll);
                    uninsuredSubPayrollValue =
                      isEmpty(row.uninsuredSubPayroll) &&
                      // If the sum of the edited wc exposures is equal to 0, the incoming undefined value is written directly
                      Number(totalNotIsMainValues.totalNotIsMainUninsuredSubPayroll) === 0 &&
                      isEmpty(underwritingQuestionsState?.payrollSubcontracted)
                        ? row.uninsuredSubPayroll
                        : isMainUpdatePayrollSubcontracted;
                  }

                  if (row.isMain === 'Yes') {
                    return {
                      ...row,
                      uninsuredSubPayroll:
                        uninsuredSubPayrollValue >= 0
                          ? String(uninsuredSubPayrollValue)
                          : row.uninsuredSubPayroll,
                    };
                  } else {
                    return row;
                  }
                });
                formik.setValues({ computedWcRows: updateComputedRows });
              },
            }}
          />
        );
      },
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, 'uninsuredSubPayroll');
        return fieldValue && fieldValue !== '-'
          ? currencyFormat('USD', fieldValue, true).merged
          : '$0';
      },
    },
    ...(isUW
      ? ([
          {
            field: 'exposure_mod',
            headerName: t('Exp. Mod.'),
            flex: 0.5,
            minWidth: 110,
            type: 'string',
            sortable: true,
            editable: false,
            headerAlign: 'right',
            align: 'right',
            sortComparator: (a, b, param1, param2) => {
              if (param1.id === 'total' || param2.id === 'total') {
                return 0;
              }

              return a.localeCompare(b);
            },
            renderCell(params) {
              const fieldValue = getNestedValueFromObject(params.row, 'exposure_mod');
              return (
                <Box sx={{ display: 'flex' }}>
                  <Typography>{fieldValue}</Typography>
                  <Typography
                    component="span"
                    sx={{
                      marginLeft: 1.5,
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to={`?${updateQueryStrings({
                        locationSearch: LOCATION.search,
                        newQueries: { exp_mod: params.row.state },
                      })}`}
                      underline="none"
                    >
                      <ReactSVG className="icon" src={AddScheduleIcon} />
                    </Link>
                  </Typography>
                </Box>
              );
            },
          },
          {
            field: 'scheduleMod',
            headerName: t('Schedule Mod.'),
            flex: 0.7,
            minWidth: 140,
            type: 'string',
            sortable: true,
            editable: false,
            headerAlign: 'right',
            align: 'right',
            sortComparator: (a, b, param1, param2) => {
              if (param1.id === 'total' || param2.id === 'total') {
                return 0;
              }

              return a.localeCompare(b);
            },
            renderCell(params) {
              const fieldValue = getNestedValueFromObject(params.row, 'scheduleMod');
              return (
                <>
                  {fieldValue === null || fieldValue === '-' ? (
                    '-'
                  ) : (
                    <Link
                      component={RouterLink}
                      to={`?${updateQueryStrings({
                        locationSearch: LOCATION.search,
                        newQueries: {
                          schedule_mod: params.row.state,
                        },
                      })}`}
                      underline="hover"
                      sx={{
                        color: setScheduleModeColor(+fieldValue),
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '14px',
                        textOverflow: 'ellipsis',
                      }}
                      title={fieldValue}
                    >
                      {fieldValue}
                      <ReactSVG className="icon" src={AddScheduleIcon} />
                    </Link>
                  )}
                </>
              );
            },
          },
        ] as any[])
      : []),
  ];

  const viewModelColumns: IColumns[] = [
    {
      name: 'state',
      display_name: t('State'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.5,
      type: 'string',
      minWidth: 80,
    },
    {
      name: 'location',
      display_name: t('Location'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1.5,
      type: 'string',
      minWidth: 150,
    },
    {
      name: 'classCode',
      display_name: t('Class Code'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 130,
    },
    ...(isUW
      ? ([
          {
            name: 'isMain',
            display_name: t('Is Main'),
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 1,
            type: 'string',
            minWidth: 90,
          },
        ] as IColumns[])
      : []),

    {
      name: 'employeePayroll',
      display_name: t('Employee Payroll'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.3,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 110,
    },
    {
      name: 'uninsuredSubPayroll',
      display_name: t('Uninsured Subcontractor Payroll'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 130,
    },
    ...(isUW
      ? ([
          {
            name: 'exposure_mod',
            display_name: t('Exp. Mod.'),
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.5,
            align: 'right',
            headerAlign: 'right',
            type: 'string',
            minWidth: 110,
          },
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod.'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.7,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const wCExposureEmployeeColumns: GridColDef[] = viewModelColumns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.is_link) {
        return row.classCode;
      }

      if (field.name === 'location') {
        return row.location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        if (fieldValue.length > 10) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { wc: params.row.exposureId, wcp: params.row.id },
              })}`}
              underline="hover"
              title={fieldValue || '-'}
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { wc: params.row.exposureId, wcp: params.row.id },
              })}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        }
      } else if (field.name === 'exposure_mod') {
        return (
          <Box sx={{ display: 'flex' }}>
            <Typography>{fieldValue}</Typography>
            <Typography
              component="span"
              sx={{
                marginLeft: 1.5,
              }}
            >
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: { exp_mod: params.row.state },
                })}`}
                underline="none"
              >
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            </Typography>
          </Box>
        );
        // return <></>;
      } else if (field.name === 'scheduleMod') {
        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    schedule_mod: params.row.state,
                  },
                })}`}
                underline="hover"
                sx={{
                  color: setScheduleModeColor(+fieldValue),
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '14px',
                  textOverflow: 'ellipsis',
                }}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(params.row.location);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      } else if (field.type === 'currency') {
        return fieldValue && fieldValue !== '-'
          ? currencyFormat('USD', fieldValue, true).merged
          : '$0';
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const individualOwnersColumns: IColumns[] = [
    {
      name: 'state',
      display_name: t('State'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.5,
      type: 'string',
      minWidth: 80,
    },
    {
      name: 'location',
      display_name: t('Location'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1.5,
      type: 'string',
      minWidth: 150,
    },
    {
      name: 'name',
      display_name: t('Name'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 100,
    },
    {
      name: 'status',
      display_name: t('WC Election'),
      type: 'toogle',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      name: 'class_code',
      display_name: t('Class Code'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 130,
    },
    {
      name: 'payroll',
      display_name: t('Payroll'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 110,
    },
    ...(isUW
      ? ([
          {
            name: 'exposure_mod',
            display_name: t('Exp. Mod.'),
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.5,
            align: 'right',
            headerAlign: 'right',
            type: 'string',
            minWidth: 110,
          },
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod.'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.7,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const wCExposureIndividualOwnersColumns: GridColDef[] = individualOwnersColumns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.is_link) {
        return row.name;
      }

      if (field.name === 'location') {
        return row.location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        if (fieldValue.length > 10) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { owner_member: params.row.id },
              })}`}
              underline="hover"
              title={fieldValue}
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { owner_member: params.row.id },
              })}`}
              underline="hover"
              title={fieldValue}
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        }
      } else if (field.name === 'exposure_mod') {
        return isTablesLoading ? (
          <></>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Typography>{fieldValue}</Typography>
            <Typography
              component="span"
              sx={{
                marginLeft: 1.5,
              }}
            >
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: { exp_mod: params.row.state },
                })}`}
                underline="none"
              >
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            </Typography>
          </Box>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(params.row.location);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      } else if (field.name === 'scheduleMod') {
        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    schedule_mod: params.row.state,
                  },
                })}`}
                underline="hover"
                sx={{
                  color: setScheduleModeColor(+fieldValue),
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '14px',
                  textOverflow: 'ellipsis',
                }}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.type === 'currency') {
        return fieldHasValue(fieldValue, ['-'])
          ? currencyFormat('USD', fieldValue, true).merged
          : '$0';
      } else if (field.type === 'toogle') {
        const { status, id: ownerLocator } = params.row;
        const excluded = status === 'Yes';

        return (
          <WCExclusionToogle
            excluded={excluded}
            handleChange={(_e, v) => handleExclude(ownerLocator, !v)}
            canEdit={canEdit}
          />
        );
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleChangeSelection = (event: SelectChangeEvent<unknown>, filterName) => {
    switch (filterName) {
      case 'location':
        setLocationFilter(String(event.target.value));
        break;

      default:
        break;
    }
  };

  const entity = quoteDetail?.characteristics?.[0]?.data?.pol_legal_entity ?? '';

  const entityType = useMemo(() => determineOwnerOfficerPositions(entity as string), [entity]);

  const locationFilterOptions = useMemo(
    () =>
      uniqBy(
        [...ownersMembersRows, ...classCodeRows]?.map((r) => {
          const location = parseLocation(r?.location);

          const formatted = formatLocation({
            ...location,
          });

          return {
            code: formatted.storing,
            head: formatted.showing.head,
            tail: formatted.showing.tail,
          };
        }) ?? [],
        (r) => r.code,
      ),
    [ownersMembersRows, classCodeRows],
  );

  const computeOwnersMembersRows = useMemo(() => {
    if (locationFilter === 'Location') {
      return ownersMembersRows;
    } else {
      return ownersMembersRows.filter((r) =>
        isEmpty(r.location)
          ? locationFilter === formatLocation({ addressLine1: '' }).storing
            ? true
            : r.location === locationFilter
          : r.location === locationFilter,
      );
    }
  }, [ownersMembersRows, locationFilter]);

  const computedWcRows = useMemo(() => {
    if (locationFilter === 'Location') {
      return classCodeRows;
    } else {
      return classCodeRows.filter((r) =>
        isEmpty(r?.location)
          ? locationFilter === formatLocation({ addressLine1: '' }).storing
            ? true
            : r?.location === locationFilter
          : r?.location === locationFilter,
      );
    }
  }, [classCodeRows, locationFilter]);

  const computedRowsWithTotalRow = useMemo(() => {
    const totalComputedRow = {
      id: 'total',
      state: 'Total',
      location: '',
      classCode: '',
      employeePayroll: underwritingQuestionsState?.payrollW2,
      uninsuredSubPayroll: underwritingQuestionsState?.payrollSubcontracted,
    };
    if (!isEmpty(formik.values.computedWcRows)) {
      return [...sortComputedWcRowsByMainStatus(formik.values.computedWcRows), totalComputedRow];
    } else return [...sortComputedWcRowsByMainStatus(computedWcRows), totalComputedRow];
  }, [computedWcRows, formik.values.computedWcRows]);

  useEffect(() => {
    setRows(computedWcRows);
    formik.setValues({ computedWcRows: [...computedWcRows] });
  }, [computedWcRows]);

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addWc: true },
      }),
    });
  };

  useEffect(() => {
    if (formik.values.computedWcRows) {
      const totalObjectValues = generateTotalObject(formik.values.computedWcRows);
      setComputeWcRowsEmployeePayrollAndSubPayrollTotalObject(totalObjectValues);
    }
  }, [formik.values.computedWcRows]);

  useImperativeHandle(ref, () => ({
    validateWCExposures: async ({ positionTabClicked }) => {
      const errors = wcExposuresError(computeOwnersMembersRows);
      if (
        !isProducer ||
        (isProgressTab && (isNextButtonClicked || positionTabClicked === TAB_POSITIONS.NEXT))
      ) {
        if (errors) {
          displayToastMessage(
            'ERROR',
            'Class code election is not finished for all Covered Officers/Owners.',
          );

          setIsNextButtonClicked(false);
          replaceBack();
        }
      }
      if (isProgressTab) {
        if (errors && (isNextButtonClicked || positionTabClicked === TAB_POSITIONS.NEXT)) {
          displayToastMessage(
            'ERROR',
            'Class code election is not finished for all Covered Officers/Owners.',
          );

          setIsNextButtonClicked(false);
          replaceBack();
        } else {
          await handleSave();
        }
      }
    },
  }));

  const handlePrevious = async () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.WC_EXPOSURES.prevTab },
      }),
    });
  };

  const handleNext = async () => {
    setIsNextButtonClicked(true);

    if (!wcExposuresError(computeOwnersMembersRows)) {
      try {
        setLoading(true);

        if (isWCExposuresTabNotCompleted) {
          await updateQuoteProgress({
            locator: id,
            currentTab: submissionDetailInfoTabs.WC_EXPOSURES.code,
            isCompleted: !wcExposuresError(computeOwnersMembersRows),
          });
        }
      } finally {
        setLoading(false);
      }
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: {
          tab: redirectToProducerTab({
            currentTab: submissionDetailInfoTabs.WC_EXPOSURES,
            productType: quoteDetail?.product?.code,
          }),
        },
      }),
    });
  };

  useEffect(() => {
    const handleSubmit = async (emitterAction: keyof Events) => {
      // emit event to action bar
      if (isProgressTab) {
        try {
          await handleSave();
          emitter.emit(emitterAction, true);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          emitter.emit(emitterAction, false);
        }
      }
    };

    emitter.on('validateQuestionEngineInputs', handleSubmit);

    return () => {
      emitter.off('validateQuestionEngineInputs', handleSubmit);
    };
  }, [formik.values.computedWcRows, isDataChanged]);

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <ClassDrawer isEdit={canEdit} />
      <ExpModeDrawer isEdit={canEdit} />

      <WcOwnersMembersDrawer isEdit={canEdit} position={entityType.position} />

      <WCExposureScheduleModDrawer isEdit={canEdit} />

      <Box>
        <Box
          sx={{
            height: 68,
            boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
            px: 3,
            py: 2,
          }}
        >
          <Stack>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
                  width: 100,
                }}
              >
                {t('Filter by')}
              </Typography>

              <FormControl sx={{ ml: 2, width: 220, height: 37 }} size="small">
                {isTablesLoading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <Select
                    IconComponent={KeyboardArrowDownRounded}
                    value={locationFilter}
                    onChange={(e) => handleChangeSelection(e, 'location')}
                    MenuProps={{
                      sx: {
                        width: 100,
                      },
                    }}
                  >
                    <MenuItem value="Location">{t('Location (All)')}</MenuItem>
                    {locationFilterOptions.map(({ code, head, tail }) => (
                      <MenuItem key={code} value={code} sx={{ w: 100 }}>
                        <LocationShow location={{ head, tail }} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Stack>
        </Box>

        <Box sx={{ my: 1.5 }}>
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.WC_EXPOSURES.title()}
          </Typography>

          <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
            {quotePolicyEndorsementInfoTitlesDescriptions.WC_EXPOSURES.description()}
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          {isProducer ? (
            <>
              <Box
                sx={{
                  mb: 3.5,
                  '& .border-top': {
                    borderTop: '1px solid',
                    borderColor: (theme) => theme.customColors.gunMetal,
                  },
                  '& .border-none .MuiDataGrid-cell': {
                    border: 'none',
                  },
                  '& .bg :not(:first-of-type) ': {
                    backgroundColor: 'red',
                  },
                  '& .bg-additional': {
                    backgroundColor: 'red',
                  },
                  '& .bg-error': {
                    border: `1px solid ${themeHook.customColors.alert} !important`,
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'nowrap',
                  },
                  '& .disabled': {
                    color: `${themeHook.customColors.grey1100} !important`,
                  },
                  '& .MuiDataGrid-root .MuiDataGrid-virtualScroller': {
                    overflowY: 'hidden',
                    minHeight: rows?.length > 0 ? 'unset' : '200px',
                  },
                }}
              >
                <DataTablePro
                  loading={isTablesLoading}
                  getRowId={(row) => row.id}
                  columns={columns}
                  isTotalRowVisible
                  rows={computedRowsWithTotalRow}
                  pageSize={defaultRowVirtualization}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: (theme) => theme.customColors.gunMetal,
                  }}
                  isSummaryTable
                  cellModesModel={cellModesModel}
                  hideFooter={computedRowsWithTotalRow.length <= defaultRowVirtualization}
                  hideFooterPagination={
                    computedRowsWithTotalRow?.length! < defaultRowVirtualization
                  }
                  onRowClick={(r) =>
                    setSelectedClassID(r.id === selectedClassID ? '' : (r.id as string))
                  }
                  isHeaderWidthFit
                  wrapperSx={{
                    ...(computedRowsWithTotalRow?.length! < defaultRowVirtualization
                      ? { mb: '-28px' }
                      : {}),
                  }}
                  focusedRowId={selectedClassID}
                  initialState={{
                    sorting: {
                      sortModel: [
                        { field: 'isMain', sort: 'desc' },
                        { field: 'isAncillary', sort: 'asc' },
                      ],
                    },
                  }}
                  getCellClassName={(params) => {
                    if (
                      params.row.isMain === 'No' &&
                      ['employeePayroll', 'uninsuredSubPayroll'].includes(params.field) &&
                      checkWcRowHasError(params)
                    ) {
                      return 'bg-error';
                    }

                    return '';
                  }}
                />
              </Box>
            </>
          ) : (
            <DataTable
              onRowClick={(r) =>
                setSelectedClassID(r.id === selectedClassID ? '' : (r.id as string))
              }
              isHeaderWidthFit
              autoRowCellHeight
              rows={computedWcRows}
              columns={wCExposureEmployeeColumns}
              columnVisibilityModel={createColumnVisibilityModel(columns)}
              pageSize={defaultRowVirtualization}
              hideFooterPagination={computedWcRows?.length! < defaultRowVirtualization}
              loading={isTablesLoading}
              wrapperSx={{
                ...(computedWcRows?.length! < defaultRowVirtualization ? { mb: '-28px' } : {}),
              }}
              focusedRowId={selectedClassID}
              initialState={{
                sorting: {
                  sortModel: [
                    { field: 'isMain', sort: 'desc' },
                    { field: 'isAncillary', sort: 'asc' },
                  ],
                },
              }}
              isCellEditable={() => false}
            />
          )}

          {isUW && canEdit && currentWcExposures?.loaded && (
            <Box>
              <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]}>
                {t('Add Class Code')}
              </Button>
            </Box>
          )}

          <Box sx={{ mt: 7 }}>
            <Typography sx={[tabTitleStyles]}>
              {quotePolicyEndorsementInfoTitlesDescriptions.INDIVIDUAL_OWNERS_OFFICERS.title()}
            </Typography>

            <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
              {quotePolicyEndorsementInfoTitlesDescriptions.INDIVIDUAL_OWNERS_OFFICERS.description()}
            </Typography>
          </Box>

          <DataTable
            onRowClick={(r) => setSelectedClassID(r.id === selectedClassID ? '' : (r.id as string))}
            isHeaderWidthFit
            autoRowCellHeight
            rows={computeOwnersMembersRows}
            columns={wCExposureIndividualOwnersColumns}
            loading={isTablesLoading}
            columnVisibilityModel={createColumnVisibilityModel(individualOwnersColumns)}
            pageSize={defaultRowVirtualization}
            hideFooterPagination={computeOwnersMembersRows?.length! < defaultRowVirtualization}
            focusedRowId={selectedClassID}
          />
        </Box>
      </Box>

      {isProgressTab && (
        <NavigationButtons handlePrevious={handlePrevious} handleNext={handleNext} />
      )}
    </Stack>
  );
});

export default WCExposures;
