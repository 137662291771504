import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import AddScheduleIcon from 'assets/images/icon_schedule_mod.svg';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  smodLines,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
  vehicleExposureRelatedMappings,
  vehicleExposureRelatedMaps,
} from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import { primaryButtonStyle, tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import { calculateSchedMod } from 'helpers/ScheduleModificationHelpers';
import {
  createColumnVisibilityModel,
  currencyFormat,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig/useConfig';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useKeyValues from 'hooks/useKeyValues';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import AutoExposureScheduleModDrawer from './Drawers/AutoExposureScheduleModDrawer/AutoExposureScheduleModDrawer';
import VehicleDrawer from './Drawers/VehicleDrawer/VehicleDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align: GridAlignment;
  headerAlign: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const AutoExposures: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const themeHook = useTheme();
  const { exposureList, groups, canEdit } = useEndorsementDetail();

  const queryParams = qs.parse(LOCATION.search) as unknown as any;
  const [locationFilter, setLocationFilter] = useState('Location');
  const { data: userData } = useUser();
  const [selectedVehicleRowId, setSelectedVehicleRowId] = useState<string>('');
  const { fetch, data: keyValueStore } = useKeyValues();
  const { fetchVintelligenceYears } = useConfig();

  const SCHED_MOD_TABLES =
    keyValueStore?.[`${vehicleExposureRelatedMaps.SCHED_MOD_TABLES}`]?.data?.value ?? [];

  const currentExposures = exposureList?.[`${threeExposureNames.VEHICLE}`];

  const currentLiabilityExposures = exposureList?.[`${threeExposureNames.LOCATION}`];

  const schedModGroupFields: any[] =
    groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data ?? [];

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addVehicle: 'true' },
      }),
    });
  };

  const rows = useMemo(() => {
    const expArr =
      currentExposures?.data?.map((ex) => {
        const relatedLocationExposure = currentLiabilityExposures?.data?.find(
          (exposure) => exposure?.data?.loc_seq_no === ex?.data?.veh_loc_seq_no,
        )?.data;

        const filteredTableRows = SCHED_MOD_TABLES?.[`${smodLines.AUTO}`]
          ?.filter(
            (item) => String(item.FilingSetID) === relatedLocationExposure?.loc_filing_set_id,
          )
          ?.map((row) => ({
            ...row,
            MaxCredit: -row?.MaxCredit,
          }));

        const relatedFieldGroups = schedModGroupFields.filter(
          (schedMod) =>
            schedMod.pol_sched_mod_line === smodLines.AUTO &&
            schedMod.pol_sched_mod_state === relatedLocationExposure?.loc_address_state,
        );

        return {
          ...(ex.data ?? {}),
          locator: ex.is_newly_added ? `added-${ex.index}` : ex.locator,
          scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
        };
      }) ?? [];

    return expArr as any[];
  }, [currentExposures, keyValueStore, groups, currentLiabilityExposures]);

  const columns: IColumns[] = [
    {
      name: 'veh_location',
      display_name: t('Garaging Location'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 170,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'veh_vin',
      display_name: t('VIN'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1.2,
      type: 'string',
      minWidth: 175,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'veh_year',
      display_name: t('Year'),
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      minWidth: 60,
      flex: 0.5,
      headerAlign: 'left',
    },
    {
      name: 'veh_make',
      display_name: t('Make'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 70,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'veh_model',
      display_name: t('Model'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 60,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'veh_limit',
      display_name: t('Insured Value'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.7,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      minWidth: 130,
    },
    ...(userData?.role?.code === userRoles.UNDERWRITER.code
      ? ([
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.7,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const setScheduleModeColor = (value: number) => {
    if (value > 1) {
      return themeHook.customColors.errorRed;
    } else if (value < 1) {
      return themeHook.customColors.successGreen;
    }

    return themeHook.customColors.gunMetal;
  };

  const handleChangeSelection = (event: SelectChangeEvent, filterName) => {
    switch (filterName) {
      case 'location':
        setLocationFilter(event.target.value);
        break;

      default:
        break;
    }
  };

  const liabilityExposureColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.name === 'veh_location') {
        return row.veh_location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { vehicle: params.row.locator },
            })}`}
            underline="hover"
            sx={[
              truncatedTextStyle,
              {
                color: (theme) => theme.customColors.table.link,
              },
            ]}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.name === 'scheduleMod') {
        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    vehicle: params.row.locator,
                    schedule_mod: true,
                  },
                })}`}
                underline="hover"
                sx={[
                  truncatedTextStyle,

                  {
                    color: setScheduleModeColor(+fieldValue),
                    alignItems: 'center',
                    gap: '14px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  },
                ]}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.name === 'veh_location') {
        const location = parseLocation(fieldValue);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      } else if (field.type === 'number' && field.name === 'veh_limit') {
        return fieldValue !== '-' ? currencyFormat('USD', fieldValue).merged : '-';
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  useEffect(() => {
    if (!queryParams?.vehicle && !queryParams?.addVehicle) {
      setSelectedVehicleRowId('');
    }
  }, [LOCATION]);

  useEffect(() => {
    try {
      fetchVintelligenceYears();
      Promise.allSettled(vehicleExposureRelatedMappings.map((key) => fetch(key)));
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  const locationFilterOptions = useMemo(
    () =>
      uniqBy(
        rows?.map((r) => {
          const location = parseLocation(r.veh_location);

          const formatted = formatLocation({
            ...location,
          });

          return {
            code: formatted.storing,
            head: formatted.showing.head,
            tail: formatted.showing.tail,
          };
        }) ?? [],
        (r) => r.code,
      ),
    [rows],
  );

  const computedRows = useMemo(() => {
    if (locationFilter === 'Location') {
      return rows;
    } else {
      return rows.filter((r) =>
        isEmpty(r.veh_location)
          ? locationFilter === formatLocation({ addressLine1: '' }).storing
            ? true
            : r.veh_location === locationFilter
          : r.veh_location === locationFilter,
      );
    }
  }, [rows, locationFilter]);

  const showLoader = useMemo(
    () =>
      currentLiabilityExposures?.loading ||
      currentExposures?.loading ||
      groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.loading ||
      vehicleExposureRelatedMappings.some((map) => keyValueStore?.[`${map}`]?.loading ?? false),
    [currentLiabilityExposures, currentExposures, keyValueStore, groups],
  );

  return (
    <>
      <VehicleDrawer isEdit={canEdit} />

      <AutoExposureScheduleModDrawer isEdit={canEdit} />

      <Box
        sx={{
          height: 68,
          boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
          px: 3,
          py: 2,
        }}
      >
        <Stack>
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontWeight: 500,
                width: 100,
                fontSize: 16,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              }}
            >
              {t('Filter by')}
            </Typography>

            <FormControl sx={{ ml: 2, width: 220, height: 37 }} size="small">
              {showLoader ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <Select
                  IconComponent={KeyboardArrowDownRounded}
                  value={locationFilter}
                  onChange={(e) => handleChangeSelection(e, 'location')}
                  MenuProps={{
                    sx: {
                      width: 100,
                    },
                  }}
                >
                  <MenuItem value="Location">{t('Location (All)')}</MenuItem>
                  {locationFilterOptions.map(({ code, head, tail }) => (
                    <MenuItem key={code} value={code} sx={{ w: 100 }}>
                      <LocationShow location={{ head, tail }} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ my: 1.5 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.AUTO_EXPOSURES.title()}
        </Typography>

        <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
          {quotePolicyEndorsementInfoTitlesDescriptions.AUTO_EXPOSURES.description()}
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <DataTable
          onRowClick={(r) =>
            setSelectedVehicleRowId(r.id === selectedVehicleRowId ? '' : (r.id as string))
          }
          getRowId={(row) => row.locator}
          isHeaderWidthFit
          rows={computedRows}
          loading={showLoader}
          columns={liabilityExposureColumns}
          pageSize={defaultRowVirtualization}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          hideFooterPagination={computedRows?.length! < defaultRowVirtualization}
          focusedRowId={selectedVehicleRowId}
        />

        {canEdit && currentExposures?.loaded && (
          <Box>
            <Button data-test="add-vehicle" onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]}>
              {t('Add Vehicle')}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AutoExposures;
