import { Box } from '@mui/system';
import Head from 'components/Head';
import React from 'react';
import AgentTable from './components/agent-table';

const AgentList = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Head title="Agents" />
      <AgentTable agencyId={null} />
    </Box>
  );
};
export default AgentList;
