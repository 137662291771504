/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { PolicyDetail } from 'api/models/Policy/policyDetail.model';
import { createEnrollAutoPayURL, sendEmailEnrollAutoPayURL } from 'api/services/Policies';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { accordionFieldParserReadOnlyInputStyle } from 'helpers/MuiSharedStyles';
import useLoader from 'hooks/useLoader';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IEnrollInAutopayDialogProps {
  policyDetail: PolicyDetail | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const EnrollInAutopayDialog: React.FC<IEnrollInAutopayDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useLoader();
  const [inPageLoading, setInPageLoading] = React.useState(false);
  const [enrollInAutoPayUrlValue, setEnrollInAutoPayUrlValue] = React.useState();
  const setupIntentId = useRef<string>();

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const response = await sendEmailEnrollAutoPayURL(id, setupIntentId.current!);
      displayToastMessage('SUCCESS', response?.message);
    } catch (error) {
      displayBackendErrorMessage(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setInPageLoading(true);

      try {
        const response: any = await createEnrollAutoPayURL(Number(id));
        setupIntentId.current = response?.setup_intent_id;
        setEnrollInAutoPayUrlValue(response?.payment_link);
      } catch (error) {
        displayBackendErrorMessage(error);
        onClose();
      } finally {
        setInPageLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box sx={{ width: 480, p: 1, backgroundColor: (theme) => theme.customColors.grey1150 }}>
        <DialogTitle>{t('Enroll in Autopay')}</DialogTitle>

        <DialogContent>
          <Box>
            <Typography>
              {t('By pressing “Send” the payment link will be sent to the customer.')}
            </Typography>
            {inPageLoading ? (
              <Skeleton animation="wave" width="100%" height={50} />
            ) : (
              !isEmpty(enrollInAutoPayUrlValue) && (
                <TextField
                  sx={[
                    accordionFieldParserReadOnlyInputStyle,
                    {
                      width: '100%',
                      mt: 2,
                      '.MuiOutlinedInput-input.MuiInputBase-input': {
                        color: (theme) => theme.customColors.copper,
                      },
                    },
                  ]}
                  size="small"
                  label={t('URL')}
                  name="name"
                  autoComplete="off"
                  value={enrollInAutoPayUrlValue}
                  inputProps={{
                    autoComplete: 'new-password',
                    readOnly: true,
                  }}
                  title={enrollInAutoPayUrlValue}
                />
              )
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              color: (theme) => theme.customColors.copper,
              borderColor: (theme) => theme.customColors.copper,
              py: 0.5,
              px: 3,
              m: 1,
              mr: 0,
              fontSize: 14,
              borderRadius: '2px',
            }}
          >
            {t('Cancel')}
          </Button>

          <Button
            disabled={isEmpty(enrollInAutoPayUrlValue)}
            variant="contained"
            onClick={handleConfirm}
            sx={{
              py: 0.5,
              px: 3,
              m: 1,
              mr: 2,
              fontSize: 14,
              borderRadius: '2px',
            }}
          >
            {t('Send Link')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EnrollInAutopayDialog;
