import ApiService, { Params } from 'api/helpers/Sender';
import { Queues } from 'api/models/Queues/queues.model';
import { Task } from 'api/models/Tasks/task.model';
import { cacheIds, prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getTaskQueues = async (): Promise<PaginationResponse<Queues[]>> => {
  const { data } = await API.get(
    `task-queues/?page_size=10000`,
    {},
    { ...prepareCacheOptions({ id: cacheIds.taskQueueList }) },
  );

  return data;
};

export const getTaskQueueDetail = async (id: string): Promise<Queues> => {
  const { data } = await API.get(`task-queues/${id}`);

  return data;
};

export const getQueueTasks = async (
  queueId: string,
  query: Pagination,
): Promise<PaginationResponse<Task[]>> => {
  const { data } = await API.get(`task-queues/${queueId}/tasks`, query as Params);

  return data;
};
