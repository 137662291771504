import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ICreatePeril } from 'api/models/NewQuote/createPeril';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { WcClassCodes } from 'api/models/THREEMappings/WcClassCodes/wcClassCodes.model';
import { bulkExposureUpdateEndorsement } from 'api/services/PolicyEndorsement';
import {
  employerManCapitaRealatedFields,
  manIsancillaryFieldCode,
  threeEmployerExposurePerilNames,
  threeExposureNames,
  userRoles,
  wcAuditEmployerFields,
  wcExposureRelatedKeyValues,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { ClassDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ClassDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  changeFieldsHiddenStatus,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
  makeFieldsReadonly,
  makeFieldsRequired,
  parseLocation,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty, omit } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import EndorsementLocationSelect from '../../../Inputs/LocationSelectInput';
import WcClassCodeAutocomplete from '../../../Inputs/WcClassCodeAutocomplete';
import { calculateOwnerPayroll, classCodeOptions } from '../helpers';

export interface PropertyEditProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isAdd?: boolean;
}

const ClassEditDrawer: FC<PropertyEditProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isAdd = false,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const WC_ID = url?.wc;
  const WCP_ID = url?.wcp;
  const {
    fields: fieldConfig,
    data: endorsementDetail,
    getExposures,
    exposureList,
    exposures,
    groups,
    isWcFinal,
    isWc,
  } = useEndorsementDetail();

  const endorsementDetailData = endorsementDetail?.policy?.characteristics?.data ?? {};

  const { data: keyValueStore } = useKeyValues();

  const { data: user } = useUser();

  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  const [fields, setFields] = useState<any[]>([]);

  const WC_CLASS_CODES: WcClassCodes[] =
    keyValueStore?.[`${wcExposureRelatedKeyValues.WC_CLASS_CODES}`]?.data?.value ?? [];

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['wc', 'wcp', 'addWc'],
      }),
    });
  };

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  const isExposureNewlyAdded = useMemo(() => {
    const isNewlyAdded = WC_ID?.includes('added-');
    const index = isNewlyAdded ? WC_ID?.slice(6) : undefined;

    return { isNewlyAdded, index };
  }, [WC_ID]);

  const isPerilNewlyAdded = useMemo(() => {
    const isNewlyAdded = WCP_ID?.includes('added-');
    const index = isNewlyAdded ? WCP_ID?.slice(6) : undefined;

    return { isNewlyAdded, index };
  }, [WCP_ID]);

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(() => {
    const { isNewlyAdded, index } = isExposureNewlyAdded;

    return (
      employerExposures?.find((e) => (isNewlyAdded ? e.index === index : e.locator === WC_ID)) ?? {}
    );
  }, [employerExposures, WC_ID, isExposureNewlyAdded]);

  // try to find selected exposure in the exposures list
  const activeClass = useMemo(() => {
    const { isNewlyAdded, index } = isPerilNewlyAdded;

    return (
      activeExposure?.perils?.find((p) =>
        isNewlyAdded ? p.index === index : p.locator === WCP_ID,
      ) ?? {}
    );
  }, [activeExposure]);

  const activeClassData = activeClass?.data ?? {};

  useEffect(() => {
    // check if found exposure and peril is in the right type
    if (
      !isAdd &&
      exposures?.loaded &&
      !isEmpty(activeExposure) &&
      !isEmpty(activeClass) &&
      (activeExposure.name !== threeExposureNames.EMPLOYER ||
        activeClass.name !== threeEmployerExposurePerilNames.MANUAL_PREMIUM)
    ) {
      displayToastMessage(
        'ERROR',
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'class code',
        }),
      );
      handleQuery();
    }
  }, [activeClass, exposures]);

  const {
    pol_man_payrollw2_default,
    pol_man_payrollw2_rule,
    pol_man_payrollsub_default,
    pol_man_payrollsub_rule,
    pol_oo_wc_payroll,
  } = endorsementDetailData;

  const [state, setState] = useState<any>(
    isAdd
      ? // When manually adding a class code MAN_ISMAIN = "No" by default.
        { man_ismain: 'No' }
      : activeClassData
      ? {
          ...activeClassData,
          man_payroll_w2:
            activeClassData.man_ismain === 'No'
              ? 0
              : pol_man_payrollw2_rule ?? pol_man_payrollw2_default,
          man_payroll_sub:
            activeClassData.man_ismain === 'No'
              ? 0
              : pol_man_payrollsub_rule ?? pol_man_payrollsub_default,
          owner_payroll: pol_oo_wc_payroll,
        }
      : {},
  );

  useEffect(() => {
    const manualPremiumFields =
      (fieldConfig?.peril?.data as ProductWorkFlow[])?.[`${threeExposureNames.EMPLOYER}`]?.find(
        (p) => p.code === threeEmployerExposurePerilNames.MANUAL_PREMIUM,
      )?.fields ?? [];
    // This field should be neither visible nor editable by Producer role.
    let tmpFields = manualPremiumFields.map((field) => {
      if (user?.role?.code === userRoles.AGENT.code) {
        if (field.code === manIsancillaryFieldCode) {
          return { ...field, is_hidden: true };
        }
      }
      return field;
    });

    if (isWc) {
      tmpFields = changeFieldsHiddenStatus(
        tmpFields,
        ['man_payroll_sub_user', 'man_payroll_w2_user'],
        true,
      );
    }
    if (isAdd) {
      const updatedStaticFields = tmpFields.map((field) => {
        if (field.code === 'man_class_code') {
          return { ...field, is_readonly: false };
        }
        return field;
      });
      setFields(makeFieldsReadonly(updatedStaticFields, ['man_location'], false));
    } else {
      setFields(tmpFields);
    }
  }, [fieldConfig]);

  useEffect(() => {
    if (isWc) {
      setFields((prevFields) => {
        const { ESTIMATED, FINAL, ORIGINALS } = wcAuditEmployerFields;

        let tmpFields = changeFieldsHiddenStatus(
          prevFields,
          isWcFinal ? [...FINAL, ...ESTIMATED] : ESTIMATED,
          false,
        );

        tmpFields = makeFieldsReadonly(
          tmpFields,
          isWcFinal ? [...ESTIMATED, ...ORIGINALS] : ORIGINALS,
          true,
        );

        tmpFields = makeFieldsRequired(
          tmpFields,
          isWcFinal
            ? ['man_payroll_w2_audit_final', 'man_payroll_sub_audit_final']
            : ['man_payroll_w2_audit_est', 'man_payroll_sub_audit_est'],
          false,
        );

        return tmpFields;
      });
    }
  }, [isWcFinal, fieldConfig, isWc]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema,
    onSubmit: async (_values) => {},
  });

  const showLoader = isAdd
    ? !fieldConfig?.exposure?.loaded
    : !fieldConfig?.exposure?.loaded || isEmpty(activeExposure);

  const getEndorsementDetailAndExposures = async () => {
    await getExposures(
      endorsementDetail?.policy?.locator!,
      endorsementDetail?.locator!,
      threeExposureNames.EMPLOYER,
      true,
    );
  };

  const handleAdd = async () => {
    try {
      setLoading(true);

      const parsedLoacation = parseLocation(state.man_location);

      const exposureData = {
        emp_state: parsedLoacation.state,
        emp_filing_set_id: state.emp_filing_set_id,
      };

      const perilData = {
        data: omit(state, [
          'emp_filing_set_id',
          'total_rated_payroll',
          'man_payroll_w2',
          'man_payroll_sub',
          'owner_payroll',
        ]),
        name: threeEmployerExposurePerilNames.MANUAL_PREMIUM,
      };

      const foundExposure = employerExposures.find(
        (e) => e?.data?.emp_state === exposureData.emp_state,
      );

      let modifiedExposures: any[] = [];

      if (foundExposure) {
        modifiedExposures =
          exposures?.data?.map((exp) => {
            let data = omit(exp?.data, ['locator', 'is_newly_added', 'perils', 'index']);
            let perils =
              exp.perils?.map((peril) => ({
                name: peril.name,
                data: peril.data,
                ...(peril.is_newly_added ? {} : { locator: peril.locator }),
                ...omit(peril, ['locator', 'index', 'name', 'is_newly_added']),
              })) ?? [];

            // add new peril to found exposure
            if (exp.locator === foundExposure.locator) {
              data = { ...data, ...exposureData };
              perils = [...perils, perilData];
            }

            return {
              name: exp.name,
              ...(exp.is_newly_added ? {} : { locator: exp.locator }),
              data,
              perils,
            };
          }) ?? [];
      } else {
        // TODO: delete this, there will always found exposure
        modifiedExposures =
          exposures?.data?.map((exp) => {
            const data = omit(exp?.data, ['locator', 'is_newly_added', 'perils', 'index']);
            const perils =
              exp.perils?.map((peril) => ({
                name: peril.name,
                data: peril.data,
                ...(peril.is_newly_added ? {} : { locator: peril.locator }),
                ...omit(peril, ['locator', 'index', 'name', 'is_newly_added']),
              })) ?? [];

            return {
              name: exp.name,
              ...(exp.is_newly_added ? {} : { locator: exp.locator }),
              data,
              perils,
            };
          }) ?? [];

        modifiedExposures.push({
          name: threeExposureNames.EMPLOYER,
          data: exposureData,
          perils: [perilData],
        });
      }

      await bulkExposureUpdateEndorsement(
        endorsementDetail?.policy?.locator!,
        endorsementDetail?.locator!,
        modifiedExposures,
      );

      displayToastMessage('SUCCESS', t('The class code has been added.'));
      handleQuery();
      await getEndorsementDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  // only delete the peril
  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_CLASS_CODE',
        isOpen: false,
      });
      setLoading(true);

      const { isNewlyAdded: isExposureNewAdded, index: exposureAddedIndex } = isExposureNewlyAdded;

      const { isNewlyAdded: isPerilNewAdded, index: perilAddedIndex } = isPerilNewlyAdded;

      const modifiedExposures = exposures?.data?.map((exp) => {
        const data = omit(exp?.data, ['locator', 'is_newly_added', 'perils', 'index']);

        const perils = [...(exp?.perils ?? [])];

        if (
          (isExposureNewAdded && exp.index === exposureAddedIndex) ||
          (!isExposureNewAdded && exp.locator === WC_ID)
        ) {
          const foundPerilIndex = perils.findIndex((p) =>
            isPerilNewAdded ? p.index === perilAddedIndex : p.locator === WCP_ID,
          );

          perils.splice(foundPerilIndex, 1);
        }

        return {
          name: exp.name,
          ...(exp.is_newly_added ? {} : { locator: exp.locator }),
          data,
          perils:
            perils?.map((peril) => ({
              name: peril.name,
              data: peril.data,
              ...(peril.is_newly_added ? {} : { locator: peril.locator }),
              ...omit(peril, ['locator', 'index', 'name', 'is_newly_added']),
            })) ?? [],
        };
      });

      await bulkExposureUpdateEndorsement(
        endorsementDetail?.policy?.locator!,
        endorsementDetail?.locator!,
        modifiedExposures,
      );

      displayToastMessage('SUCCESS', t('The class code has been deleted.'));
      handleQuery();
      await getEndorsementDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const parsedLoacation = parseLocation(state.man_location);

      const exposureData = { ...activeExposure.data, emp_state: parsedLoacation.state };

      const perilData = { ...activeClass, data: state } as ICreatePeril;

      const { isNewlyAdded: isExposureNewAdded, index: exposureAddedIndex } = isExposureNewlyAdded;

      const { isNewlyAdded: isPerilNewAdded, index: perilAddedIndex } = isPerilNewlyAdded;

      const modifiedExposures = exposures?.data?.map((exp) => {
        let data = omit(exp?.data, ['locator', 'is_newly_added', 'perils', 'index']);

        if (
          (isExposureNewAdded && exp.index === exposureAddedIndex) ||
          (!isExposureNewAdded && exp.locator === WC_ID)
        ) {
          data = { ...data, ...exposureData };
        }

        return {
          name: exp.name,
          ...(exp.is_newly_added ? {} : { locator: exp.locator }),
          data,
          perils:
            exp.perils?.map((peril) => {
              let pData = peril.data;

              if (
                (isPerilNewAdded && peril.index === perilAddedIndex) ||
                (!isPerilNewAdded && peril.locator === WCP_ID)
              ) {
                pData = { ...pData, ...perilData?.data };
              }

              return {
                name: peril.name,
                ...(peril.is_newly_added ? {} : { locator: peril.locator }),
                ...omit(peril, ['locator', 'index', 'name', 'is_newly_added']),
                data: pData,
              };
            }) ?? [],
        };
      });

      await bulkExposureUpdateEndorsement(
        endorsementDetail?.policy?.locator!,
        endorsementDetail?.locator!,
        modifiedExposures,
      );

      displayToastMessage('SUCCESS', t('The class code has been updated.'));
      handleQuery();
      await getEndorsementDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  useEffect(() => {
    formik.setValues(state);

    setFields((prevState) =>
      changeFieldsHiddenStatus(
        prevState,
        isWc ? employerManCapitaRealatedFields : [employerManCapitaRealatedFields[0]],
        state.exposure_type !== 'Per Capita',
      ),
    );

    // remove its value field change its hidden status
    setState((prevState) => ({
      ...prevState,
      man_capita: state.exposure_type !== 'Per Capita' ? undefined : prevState.man_capita,
      total_rated_payroll_audit_est:
        Number(prevState.man_payroll_w2_audit_est ?? '0') +
        Number(prevState.man_payroll_sub_audit_est ?? '0') +
        prevState.owner_payroll_audit_est,
      total_rated_payroll_audit_final:
        Number(prevState.man_payroll_w2_audit_final ?? '0') +
        Number(prevState.man_payroll_sub_audit_final ?? '0') +
        prevState.owner_payroll_audit_final,
    }));
  }, [JSON.stringify(state)]);

  const calculateOwnerPayrolls = (concatLookup: string, locationState: string) => {
    const owner_payroll = calculateOwnerPayroll(
      concatLookup!,
      locationState,
      'pol_oo_wc_payroll',
      groups,
    );

    const owner_payroll_audit_est = calculateOwnerPayroll(
      concatLookup,
      locationState,
      'pol_oo_wc_payroll_audit_est',
      groups,
    );

    const owner_payroll_audit_final = calculateOwnerPayroll(
      concatLookup,
      locationState,
      'pol_oo_wc_payroll_audit_final',
      groups,
    );

    return {
      owner_payroll,
      owner_payroll_audit_est,
      owner_payroll_audit_final,
    };
  };

  const handleEmitedInputChange = async ({ field, value }: { field: string; value: any }) => {
    switch (field) {
      case 'man_class_code': {
        const {
          man_payroll_sub = 0,
          man_payroll_w2 = 0,
          man_location,
          man_payroll_sub_user,
          man_payroll_w2_user,
        } = state;

        const parsedLocation = parseLocation(man_location);

        const owner_payroll = calculateOwnerPayroll(
          value?.ConcatLookup!,
          parsedLocation?.state,
          'pol_oo_wc_payroll',
          groups,
        );

        setState((prevState) => ({
          ...prevState,
          man_class_code_desc: value?.FriendlyLabel,
          emp_filing_set_id: value?.FilingSetID,
          exposure_type: value?.ExposureType,
          rate: value?.Rate,
          total_rated_payroll:
            (man_payroll_sub_user ? Number(man_payroll_sub_user) : Number(man_payroll_sub)) +
            (man_payroll_w2_user ? Number(man_payroll_w2_user) : Number(man_payroll_w2)) +
            owner_payroll,
          ...calculateOwnerPayrolls(value?.ConcatLookup!, parsedLocation?.state),
        }));
        break;
      }

      case 'man_payroll_sub_user': {
        const {
          man_payroll_w2,
          owner_payroll = 0,
          man_payroll_w2_user,
          man_payroll_sub = 0,
        } = state;

        const employeePayroll = man_payroll_w2 ? Number(man_payroll_w2) : 0;

        setState((prevState) => ({
          ...prevState,
          total_rated_payroll:
            (man_payroll_w2_user ? Number(man_payroll_w2_user) : employeePayroll) +
            (!isEmpty(value) ? Number(value) : Number(man_payroll_sub)) +
            owner_payroll,
        }));

        break;
      }

      case 'man_payroll_w2_user': {
        const {
          man_payroll_sub,
          owner_payroll = 0,
          man_payroll_sub_user,
          man_payroll_w2 = 0,
        } = state;

        const subPayroll = man_payroll_sub ? Number(man_payroll_sub) : 0;

        setState((prevState) => ({
          ...prevState,
          total_rated_payroll:
            (!isEmpty(value) ? Number(value) : Number(man_payroll_w2)) +
            (man_payroll_sub_user ? Number(man_payroll_sub_user) : subPayroll) +
            owner_payroll,
        }));

        break;
      }

      case 'man_location': {
        const parsedLoacation = parseLocation(value);

        const foundExposure = employerExposures.find(
          (e) => e?.data?.emp_state === parsedLoacation.state,
        );

        if (foundExposure) {
          setState((prevState) => ({
            ...prevState,
            emp_filing_set_id: foundExposure.data?.emp_filing_set_id,
          }));
        }
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    if (!isAdd) {
      const {
        man_payroll_sub = 0,
        man_payroll_w2 = 0,
        man_payroll_sub_user,
        man_payroll_w2_user,
        man_location,
        man_class_code,
        man_class_code_desc,
      } = activeClassData;

      const filingSetId = activeExposure.data?.emp_filing_set_id;
      const parsedLocation = parseLocation(man_location);

      const foundClassCode = classCodeOptions(
        parsedLocation?.state,
        filingSetId,
        WC_CLASS_CODES,
      )?.find((o) => o.ConcatLookup === `${man_class_code} - ${man_class_code_desc}`);

      const calculatedPayrolls = calculateOwnerPayrolls(
        foundClassCode?.ConcatLookup!,
        parsedLocation?.state,
      );

      setState((prevState) => ({
        ...prevState,
        ...activeClassData,
        total_rated_payroll:
          (man_payroll_sub_user ? Number(man_payroll_sub_user) : Number(man_payroll_sub)) +
          (man_payroll_w2_user ? Number(man_payroll_w2_user) : Number(man_payroll_w2)) +
          calculatedPayrolls.owner_payroll,
        total_rated_payroll_audit_est:
          Number(activeClassData.man_payroll_w2_audit_est ?? '0') +
          Number(activeClassData.man_payroll_sub_audit_est ?? '0') +
          calculatedPayrolls.owner_payroll_audit_est,
        total_rated_payroll_audit_final:
          Number(activeClassData.man_payroll_w2_audit_final ?? '0') +
          Number(activeClassData.man_payroll_sub_audit_final ?? '0') +
          calculatedPayrolls.owner_payroll_audit_final,
        emp_filing_set_id: filingSetId,
        exposure_type: foundClassCode?.ExposureType,
        rate: foundClassCode?.Rate,
        ...calculatedPayrolls,
      }));

      formik.setValues(activeClassData);
    }
  }, [activeClassData]);

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        width="476px"
        onClose={handleQuery}
        headerSx={{
          mb: '14px',
          mt: 5,
        }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Class Code Details')}
          </Typography>
        }
        content={
          <Stack gap={2} sx={{ mb: 6, pt: 1 }}>
            <ClassDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              setState={setState}
              showLoader={showLoader}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
              onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
              LocationSelect={EndorsementLocationSelect}
              WcClassCodeAutocomplete={WcClassCodeAutocomplete}
            />
          </Stack>
        }
        footer={
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {user?.role?.code === userRoles.UNDERWRITER.code && !isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_CLASS_CODE',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row" gap={1.5}>
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default ClassEditDrawer;
