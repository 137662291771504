import { Box, Stack } from '@mui/material';
import {
  policyDetailInfoTabs,
  productCodes,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import Head from 'components/Head';
import TextBadge from 'components/TextBadge/TextBadge';
import VerticalTabs from 'components/VerticalTabs';
import { updateQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { cloneDeep, isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// #region Tabs
import AdditionalInterests from './Tabs/AdditionalInterests';
import AutoExposures from './Tabs/AutoExposures';
import BusinessInfo from './Tabs/BusinessInfo';
import ClaimsHistory from './Tabs/ClaimsHistory';
import Drivers from './Tabs/Drivers';
import LiabilityExposures from './Tabs/LiabilityExposures';
import Locations from './Tabs/Locations';
import Operations from './Tabs/Operations';
import OwnersMembers from './Tabs/OwnersMembers';
import PricingBreakdown from './Tabs/PricingBreakdown';
import PropertyExposures from './Tabs/PropertyExposures';
import SummaryPricing from './Tabs/SummaryPricing';
import UWResults from './Tabs/UWResults';
import WCExposures from './Tabs/WCExposures';
// #endregion

const tabs = [...Object.values(policyDetailInfoTabs).map((v) => v)];

const PolicyDetailInfoTab: FC = () => {
  const { formatDateInTimeZone } = useConfig();
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const { tab } = qs.parse(LOCATION.search) as { tab: string };
  const {
    data,
    loading,
    getExposures,
    getFieldConfig,
    data: policyDetail,
    getUnderwritingQuestions,
    exposureList,
  } = usePolicyDetail();
  const { data: user } = useUser();
  // if it's true means clicked new business in history tab
  const isNewBusinessView = LOCATION.pathname.endsWith('/new-business/');

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`];
  const isDriverTabHidden = (exposureList?.[`${threeExposureNames.VEHICLE}`].data?.length || 0) > 5;

  const fetchExposures = () => {
    setTimeout(() => {
      getExposures(id, { page_size: 10000 }, undefined, isNewBusinessView);
    }, 100);
  };
  const policyTabs = useMemo(
    () =>
      tabs
        .map((currTab) => {
          const tmpTab = cloneDeep(currTab);

          if (
            currTab.code === policyDetailInfoTabs.PRICING_BREAKDOWN.code &&
            user?.role?.code !== userRoles.UNDERWRITER.code
          ) {
            tmpTab.disabled = true;
          }

          // unhide liablity tab for underwriters
          if (
            policyDetailInfoTabs.LIABILITY_EXPOSURES.code === currTab.code &&
            user?.role?.code === userRoles.UNDERWRITER.code
          ) {
            tmpTab.hidden = false;
          }

          // hide Pricing Breakdown tab for producers
          if (
            policyDetailInfoTabs.PRICING_BREAKDOWN.code === currTab.code &&
            user?.role?.code === userRoles.AGENT.code
          ) {
            tmpTab.hidden = true;
          }

          // Display Workers’ Comp tab for three_with_workers_compensation product
          if (
            policyDetailInfoTabs.WC_EXPOSURES.code === currTab.code &&
            policyDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION
          ) {
            tmpTab.hidden = false;
          }

          // Hide Drivers Tab when more than 5 vehicles added
          if (currTab.code === policyDetailInfoTabs.DRIVERS.code && isDriverTabHidden) {
            tmpTab.hidden = true;
          }

          return tmpTab;
        })
        .filter((ta) => !ta.hidden),
    [tabs, user, policyDetail?.product?.code, isDriverTabHidden],
  );

  const findTabWithIndex = (index: number) => policyTabs[index];

  const findIndexInTabs = (step: string) => {
    const found = policyTabs.findIndex((s) => s.code === step && !s.disabled);

    return found === -1 ? 0 : found;
  };

  const [activeTab, setActiveTab] = useState(findIndexInTabs(tab));

  // Change query to the default tab. if the tab is not in the tabs list.
  useEffect(() => {
    if (findIndexInTabs(tab) === 0) {
      HISTORY.replace({ search: `?tab=${policyDetailInfoTabs.BUSINESS_INFO.code}` });
    }

    fetchExposures();
  }, [isNewBusinessView]);

  // to scroll on tab change
  useEffect(() => {
    const el = document?.getElementById('scroll-anchor');
    // Scroll to top on load
    setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 200);
  }, [activeTab]);

  // Get exposure field configs
  useEffect(() => {
    if (!isEmpty(policyDetail?.product?.code)) {
      const productCode = policyDetail?.product!.code!;
      const policyLocator = policyDetail?.locator;
      const query = { policy_locator: policyLocator };

      getUnderwritingQuestions(productCode, 'policy', undefined, query);
      getFieldConfig(productCode, 'exposure', undefined, query);
      getFieldConfig(productCode, 'policy', undefined, query);
      getFieldConfig(productCode, 'peril', threeExposureNames.EMPLOYER, query);
      getFieldConfig(productCode, 'peril', threeExposureNames.LOCATION, query);
    }
  }, [policyDetail?.product?.code]);

  useEffect(() => {
    if (!loading) {
      setActiveTab(findIndexInTabs(tab) ?? 0);
    }
  }, [tab, loading]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: {
          tab: findTabWithIndex(newValue)?.code ?? '',
        },
      }),
    });
  };

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case findIndexInTabs(policyDetailInfoTabs.BUSINESS_INFO.code):
        return <BusinessInfo />;

      case findIndexInTabs(policyDetailInfoTabs.OPERATIONS.code):
        return <Operations />;

      case findIndexInTabs(policyDetailInfoTabs.LIABILITY_EXPOSURES.code):
        return user?.role?.code === userRoles.UNDERWRITER.code ? <LiabilityExposures /> : <></>;

      case findIndexInTabs(policyDetailInfoTabs.PROPERTY_EXPOSURES.code):
        return <PropertyExposures />;

      case findIndexInTabs(policyDetailInfoTabs.LOCATIONS.code):
        return <Locations />;

      case findIndexInTabs(policyDetailInfoTabs.DRIVERS.code):
        return <Drivers />;

      case findIndexInTabs(policyDetailInfoTabs.WC_EXPOSURES.code):
        return <WCExposures />;

      case findIndexInTabs(policyDetailInfoTabs.AUTO_EXPOSURES.code):
        return <AutoExposures />;

      case findIndexInTabs(policyDetailInfoTabs.OWNERS_MEMBERS.code):
        return <OwnersMembers />;

      case findIndexInTabs(policyDetailInfoTabs.CLAIMS_HISTORY.code):
        return <ClaimsHistory />;

      case findIndexInTabs(policyDetailInfoTabs.ADDITIONAL_INTEREST.code):
        return <AdditionalInterests />;

      case findIndexInTabs(policyDetailInfoTabs.UW_RESULTS.code):
        return <UWResults />;

      case findIndexInTabs(policyDetailInfoTabs.SUMMARY_PRICING.code):
        return <SummaryPricing />;

      case findIndexInTabs(policyDetailInfoTabs.PRICING_BREAKDOWN.code):
        return <PricingBreakdown />;

      default:
        return <></>;
    }
  }, [activeTab, user]);

  const renderHead = useMemo(
    () =>
      loading ? (
        <Head title={t('Policy')} />
      ) : (
        <Head
          title={`${t('Policy')} - ${data?.policyholder?.name} - ${findTabWithIndex(
            activeTab,
          )?.name?.()}`}
        />
      ),
    [activeTab, loading],
  );

  return (
    <>
      <Stack direction="row" sx={{ height: '100%' }}>
        {renderHead}
        <Box
          sx={{
            height: '100%',
            flexShrink: 0,
            overflowY: 'auto',
            p: 3,
            pr: 2,
          }}
        >
          {isNewBusinessView && (
            <Box sx={{ mb: 2 }}>
              <TextBadge
                label={`New Business ${formatDateInTimeZone(
                  policyDetail?.original_contract_start_time,
                )}`}
                color="darkCopper"
              />
            </Box>
          )}

          <VerticalTabs
            tabs={policyTabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            progressLoading={vehicleExposures?.loading}
          />
        </Box>

        <Box
          sx={{ width: '100%', overflow: 'auto', padding: '4px', pt: 3.5, pl: 1.5 }}
          id="tabContentContanier"
        >
          <Box id="scroll-anchor" sx={{ width: '1px' }} />
          {renderActiveTab}
        </Box>
      </Stack>
    </>
  );
};

export default PolicyDetailInfoTab;
