import { Box, Stack } from '@mui/material';
import { productCodes, submissionDetailInfoTabs, userRoles } from 'common/constants';
import Head from 'components/Head';
import VerticalTabs from 'components/VerticalTabs';
import { useBlockRoute } from 'helpers/RouteBlocker';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useRouterPrompt from 'hooks/useRouterPrompt/useRouterPrompt';
import useUser from 'hooks/useUser/useUser';
import { cloneDeep, isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// #region Tabs
import BusinessInfo from '../../Info/Tabs/BusinessInfo';
import Operations from '../../Info/Tabs/Operations';
import UWResults from '../../Info/Tabs/UWResults';
// #endregion

const tabs = [...Object.values(submissionDetailInfoTabs).map((v) => v)];

const PreQualSubmissionDetailInfoTab: FC = () => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const { tab } = qs.parse(LOCATION.search) as { tab: string };
  const { data: user } = useUser();
  const { setWhen } = useRouterPrompt();
  const { data, loading, getUnderwritingQuestions, data: quoteDetail, canEdit } = useQuoteDetail();

  const businessInfoRef = createRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();
  const operationsRef = createRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();

  const preQualTabs = useMemo(
    () =>
      tabs
        .map((currTab) => {
          const tmpTab = cloneDeep(currTab);

          if (
            ![
              submissionDetailInfoTabs.BUSINESS_INFO.code,
              submissionDetailInfoTabs.OPERATIONS.code,
              submissionDetailInfoTabs.UW_RESULTS.code,
            ].includes(currTab.code)
          ) {
            tmpTab.disabled = true;
          } else {
            tmpTab.disabled = false;
          }

          // unhide liablity tab for underwriters
          if (
            submissionDetailInfoTabs.LIABILITY_EXPOSURES.code === currTab.code &&
            user?.role?.code === userRoles.UNDERWRITER.code
          ) {
            tmpTab.hidden = false;
          }

          // hide Pricing Breakdown tab for producers
          if (
            submissionDetailInfoTabs.PRICING_BREAKDOWN.code === currTab.code &&
            user?.role?.code === userRoles.AGENT.code
          ) {
            tmpTab.hidden = true;
          }

          // Display Workers’ Comp tab for three_with_workers_compensation product
          if (
            submissionDetailInfoTabs.WC_EXPOSURES.code === currTab.code &&
            quoteDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION
          ) {
            tmpTab.hidden = false;
          }

          return tmpTab;
        })
        .filter((ta) => !ta.hidden),
    [tabs, user, quoteDetail?.product?.code],
  );

  const findTabWithIndex = (index: number) => preQualTabs[index];

  const findIndexInTabs = (step: string) => {
    const found = preQualTabs.findIndex((s) => s.code === step && !s.disabled);

    return found === -1 ? 0 : found;
  };

  const [activeTab, setActiveTab] = useState(findIndexInTabs(tab));

  useEffect(() => {
    setWhen(true);
    if (findIndexInTabs(tab) === 0) {
      HISTORY.replace({ search: `?tab=${submissionDetailInfoTabs.BUSINESS_INFO.code}` });
    }
  }, []);

  // Get exposure field configs
  useEffect(() => {
    if (!isEmpty(quoteDetail?.product?.code)) {
      const policyLocator = quoteDetail?.policy_locator;
      const query = { policy_locator: policyLocator };
      getUnderwritingQuestions(quoteDetail?.product!.code!, 'policy', undefined, query);
    }
  }, [quoteDetail?.product?.code]);

  const handleTabChangeSave = async (currentTab: number) => {
    if (canEdit) {
      // when chaning tab from business info or operations, save the page informations
      switch (currentTab) {
        case findIndexInTabs(submissionDetailInfoTabs.BUSINESS_INFO.code): {
          await businessInfoRef?.current?.savePageInfo(false);
          break;
        }

        case findIndexInTabs(submissionDetailInfoTabs.OPERATIONS.code): {
          await operationsRef?.current?.savePageInfo(false);
          break;
        }

        default:
          break;
      }
    }
  };

  const updateTab = async (currentTab: number, nextTab: string) => {
    try {
      if (nextTab && findTabWithIndex(currentTab)?.code !== nextTab) {
        await handleTabChangeSave(currentTab);
      }

      setActiveTab(findIndexInTabs(nextTab) ?? 0);
      return null;
    } catch (_error) {
      return null;
    }
  };

  useEffect(() => {
    updateTab(activeTab, tab);
  }, [tab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    HISTORY.push({ search: `?tab=${findTabWithIndex(newValue)?.code ?? ''}` });
  };

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case findIndexInTabs(submissionDetailInfoTabs.BUSINESS_INFO.code):
        /**
         * TODO: if this will always same as full quote we can delete this component and directy use it from
         * src/components/Quotes/QuoteDetail/Info/Tabs/BusinessInfo.tsx
         */
        return <BusinessInfo ref={businessInfoRef} />;

      case findIndexInTabs(submissionDetailInfoTabs.OPERATIONS.code):
        /**
         * TODO: if this will always same as full quote we can delete this component and directy use it from
         * src/components/Quotes/QuoteDetail/Info/Tabs/Operations.tsx
         */
        return <Operations ref={operationsRef} />;

      case findIndexInTabs(submissionDetailInfoTabs.UW_RESULTS.code):
        /**
         * TODO: if this will always same as full quote we can delete this component and directy use it from
         * src/components/Quotes/QuoteDetail/Info/Tabs/UWResults.tsx
         */
        return <UWResults />;

      default:
        return <></>;
    }
  }, [activeTab, businessInfoRef, operationsRef]);

  // block route change until save is done
  useBlockRoute(async (location) => {
    if (!location.pathname.includes(`/quotes/${id}/details`)) {
      try {
        if (businessInfoRef?.current?.isDirty() || operationsRef?.current?.isDirty()) {
          await handleTabChangeSave(activeTab);
        }
        setWhen(false);
        return false;
      } catch (_error) {
        return true;
      }
    }

    return false;
  });

  const renderHead = useMemo(
    () =>
      loading ? (
        <Head title={t('Quote')} />
      ) : (
        <Head
          title={`${t('Quote')} - ${data?.policyholder?.name} - ${findTabWithIndex(
            activeTab,
          )?.name?.()}`}
        />
      ),
    [activeTab, loading],
  );

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      {renderHead}
      <Box
        sx={{
          height: '100%',
          flexShrink: 0,
          overflowY: 'auto',
          p: 3,
          pr: 2,
        }}
      >
        <VerticalTabs tabs={preQualTabs} activeTab={activeTab} onTabChange={handleTabChange} />
      </Box>

      <Box
        sx={{ width: '100%', overflow: 'auto', padding: '4px', pt: 3.5, pl: 1.5 }}
        id="tabContentContanier"
      >
        {renderActiveTab}
      </Box>
    </Stack>
  );
};

export default PreQualSubmissionDetailInfoTab;
