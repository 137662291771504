import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import DriverDetailDrawer from './Detail';
import DriverEditDrawer from './Edit';

export interface IDriverDrawer {
  isEdit: boolean;
}

const DriverDrawer: FC<IDriverDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isDriverDrawerOpen =
    url?.tab === 'drivers' && (!isEmpty(url?.driver) || !isEmpty(url?.addDriver));

  return isDriverDrawerOpen ? (
    isEdit ? (
      <DriverEditDrawer
        isDrawerOpen={isDriverDrawerOpen ?? false}
        isAdd={!isEmpty(url?.addDriver)}
      />
    ) : (
      <DriverDetailDrawer isDrawerOpen={isDriverDrawerOpen ?? false} />
    )
  ) : (
    <></>
  );
};
export default DriverDrawer;
