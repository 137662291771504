import ApiService from 'api/helpers/Sender';
import { Products } from 'api/models/Products/products.model';
import { prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';

const API = new ApiService();

export const getProducts = async (): Promise<Products[]> => {
  const { data }: { data: Products[] } = await API.get(
    'products',
    {},
    { ...prepareCacheOptions() },
  );
  return data;
};

export const getProductDetail = async (id: string): Promise<Products> => {
  const { data }: { data: Products } = await API.get(`products/${id}`);
  return data;
};
