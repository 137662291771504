import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { stateList, threeExposureNames, threePolicyGroupNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { AdditionalInterestsDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/AdditionalInterestsDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface AdditionalInterestsDetailProps {
  isDrawerOpen: boolean;
}

const multipleTypes = ['Loss Payee', 'Mortgagee'];

const initialValues = {
  info_ai_name: '',
  info_ai_type: '',
  info_ai_address_line1: '',
  info_ai_address_line2: '',
  info_ai_address_city: '',
  info_ai_address_state: '',
  info_ai_address_zip: '',
  info_ai_address_phone: '',
  info_ai_address_email: '',
  info_ai_subject: '',
};

const AdditionalInterestsDetailDrawer: FC<AdditionalInterestsDetailProps> = ({ isDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const [state, setState] = useState({ ...initialValues });
  const url = qs.parse(LOCATION.search);
  const ADDITIONAL_INTEREST = url.additional_interest;
  const {
    exposureList,
    exposures,
    fields: fieldConfig,
    loaded: endorsementDetailLoaded,
  } = useEndorsementDetail();
  const [fields, setFields] = useState<any[]>([]);

  const additionalInterestFields = useMemo(
    () =>
      (fieldConfig?.exposure?.data as ProductWorkFlow[])
        ?.find((con) => con.code === threeExposureNames.POLICY_INFORMATION)
        ?.fields?.find((x) => x.code === threePolicyGroupNames.ADDITIONAL_INTEREST)
        ?.nested_fields ?? [],
    [fieldConfig],
  );

  const propertyExposures = useMemo(
    () => exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [],
    [exposureList, exposures],
  );

  const vehicleExposures = useMemo(
    () => exposureList?.[`${threeExposureNames.VEHICLE}`]?.data ?? [],
    [exposureList, exposures],
  );

  const policyInformationExposures =
    exposureList?.[`${threeExposureNames.POLICY_INFORMATION}`]?.data ?? [];

  const handleBuildingAndVehicleFields = () => {
    if (!additionalInterestFields.length) {
      return;
    }

    if (propertyExposures?.length || vehicleExposures?.length) {
      const buildings = propertyExposures.map((property) => ({
        code: property?.locator ?? '',
        name: property?.data?.bdg_location,
      }));
      const vehicles = vehicleExposures.map((vehicle) => ({
        code: vehicle?.locator ?? '',
        name: vehicle?.data?.veh_vin,
      }));

      const tmpFields = [...additionalInterestFields];
      const subjectOfInterestIndex = tmpFields.findIndex((i) => i.code === 'info_ai_subject');
      tmpFields[subjectOfInterestIndex].choices = [...buildings, ...vehicles];

      const addressStateIndex = tmpFields.findIndex((i) => i.code === 'info_ai_address_state');
      const addressStateField = tmpFields[addressStateIndex];

      if (addressStateField?.additional_data?.setDefaultStateListAsChoices) {
        addressStateField.choices = stateList.map(({ code, name }) => ({ code, name })) ?? [];
      }

      setFields([...tmpFields]);
    }
  };

  const additionalInterests: any[] = policyInformationExposures?.[0]?.data?.info_ai ?? [];

  const isGroupNewlyAdded = useMemo(() => {
    const isNewlyAdded = ADDITIONAL_INTEREST?.includes('added-');
    const index = isNewlyAdded ? Number(ADDITIONAL_INTEREST?.slice(6)) : undefined;

    return { isNewlyAdded, index };
  }, [ADDITIONAL_INTEREST]);

  const additionalInterest = useMemo(() => {
    const { isNewlyAdded, index } = isGroupNewlyAdded;
    return (
      additionalInterests.find((ai) =>
        isNewlyAdded ? ai.index === index : ai.locator === ADDITIONAL_INTEREST,
      ) ?? {}
    );
  }, [additionalInterests, ADDITIONAL_INTEREST]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['additional_interest'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (endorsementDetailLoaded && isEmpty(additionalInterest)) {
      displayToastMessage('ERROR', t('Additional Interest not found.'));
      handleQuery();
    }
  }, [additionalInterest, endorsementDetailLoaded]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {
      if (formik.isValid) {
        try {
          displayToastMessage('SUCCESS', t('Changes have been saved.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        }
      }
    },
  });

  useEffect(() => {
    const isAllValuesEmpty = Object.values(state).every((value) => isEmpty(value));

    if (isAllValuesEmpty) {
      let tmpAdditionalInterest = { ...additionalInterest };

      if (multipleTypes.includes(additionalInterest?.info_ai_type)) {
        tmpAdditionalInterest = {
          ...tmpAdditionalInterest,
          info_ai_subject: Array.isArray(additionalInterest?.info_ai_subject)
            ? additionalInterest?.info_ai_subject?.[0]?.split(',')
            : additionalInterest?.info_ai_subject?.split(','),
        };
      }

      setState((prevState) => ({ ...prevState, ...tmpAdditionalInterest }));
      formik.setValues({ ...initialValues, ...tmpAdditionalInterest });
    }
  }, [additionalInterest]);

  useEffect(() => {
    handleBuildingAndVehicleFields();
  }, [exposures, exposureList, additionalInterestFields]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Additional Interest Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1.25 }}>
          <AdditionalInterestsDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={fieldConfig.exposure.loading}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default AdditionalInterestsDetailDrawer;
