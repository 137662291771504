import { Backdrop, CircularProgress } from '@mui/material';
import useLoader from 'hooks/useLoader';
import React, { FC } from 'react';

const TopActionBar: FC = () => {
  const { loading } = useLoader();

  return (
    <Backdrop
      sx={{
        color: (theme) => theme.customColors.white50,
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default TopActionBar;
