import { MoreVert } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Task } from 'api/models/Tasks/task.model';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultDebounceDelay,
  taskStatuses,
} from 'common/constants';
import DataTable from 'components/DataTable';
import MenuComponent from 'components/MenuComponent';
import PageHeader from 'components/PageHeader';
import TaskDetail from 'components/Tasks/TaskDetail';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  hasDiffKeyValues,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useQueues from 'hooks/useQueues';
import useTasks from 'hooks/useTasks';
import useUser from 'hooks/useUser';
import { debounce } from 'lodash-es';
import { initialQueuesData } from 'providers/QueuesProvider/QueuesProviderContext';
import React, { ChangeEvent, createRef, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';

import AssignToMe from 'assets/images/icon_dropdownassigntome.svg';
import Delete from 'assets/images/icon_dropdowndelete.svg';
import MarkAsClosed from 'assets/images/icon_markasclosed.svg';

import Head from 'components/Head';
import ViewSearch from 'components/ViewSearch';
import { dropdownItemsStyle } from 'helpers/MuiSharedStyles';
import { usePreviousValue } from 'helpers/PreviousValue';
import useConfig from 'hooks/useConfig';
import { IQueuesPagination } from 'providers/QueuesProvider/types';
import qs from 'query-string';
import { ReactSVG } from 'react-svg';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  flex: number;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
}
interface IHeaderMenuOptions {
  id: number;
  value: string;
  status?: string;
}

const QueueList: FC = () => {
  const { setDialogOpen } = useDialog();
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { formatDateInTimeZone } = useConfig();
  const { id } = useParams<{ id: string }>();

  const { getQueue, queueTasks, reFetchData, fetchQueueTasks, pagination } = useQueues();

  const { deleteTask, closeTask, updateTask } = useTasks();
  const { data: user } = useUser();

  const url = qs.parse(LOCATION.search);

  const [selectedRowId, setSelectedRowId] = useState<number>(-1);
  const [isClickedMoreButton, setIsClickedMoreButton] = useState<boolean>(false);
  const isDetailTaskDrawerOpen = url?.message !== undefined;
  const [pageNumber, setPage] = useState(0);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const searchInputRef = createRef<HTMLInputElement>();
  const [inPageLoading, setInPageLoading] = useState(false);
  const themeHook = useTheme();
  const queryParams = qs.parse(LOCATION.search) as unknown as IQueuesPagination;
  const previousLocationSearch = usePreviousValue(queryParams);
  const queuesLoading = queueTasks[`${id}`]?.loading;

  const headerMenuOptions: IHeaderMenuOptions[] = [
    { id: 1, value: t('Open Messages'), status: 'open' },
    { id: 2, value: t('Closed Messages'), status: 'closed' },
  ];

  const parseStatus = () => {
    if (queryParams?.status) {
      switch (queryParams.status) {
        case taskStatuses.OPEN.key:
          return headerMenuOptions[0];
        case taskStatuses.CLOSED.key:
          return headerMenuOptions[1];

        default:
          return headerMenuOptions[0];
      }
    } else {
      return headerMenuOptions[0];
    }
  };

  const [headerValue, setHeaderValue] = useState(parseStatus());


  const handleAssign = async (task: Task) => {
    try {
      setInPageLoading(true);

      const updatedTask = { assignee: user?.id };
      await updateTask(task.display_id!, updatedTask);
      await reFetchData(id);
      displayToastMessage('SUCCESS', t('The message has been assigned to me.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleCloseTask = async (task: Task) => {
    try {
      setInPageLoading(true);

      const updatedTask = { status: taskStatuses.CLOSED.key };
      await closeTask(task.display_id!, updatedTask);
      await reFetchData(id);
      displayToastMessage('SUCCESS', t('The message has been marked as closed.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleClickAway = () => {
    setIsClickedMoreButton(false);
    setSelectedRowId(-1);
  };

  const handleDeleteTask = async (taskId: number) => {
    try {
      setDialogOpen({
        dialog: 'DELETE_QUEUE',
        isOpen: false,
      });
      setInPageLoading(true);
      await deleteTask(taskId);
      await reFetchData(id);

      displayToastMessage('SUCCESS', t('The message has been deleted.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleDetailTask = async (taskId: number) => {
    HISTORY.push({
      search: `?message=${taskId}`,
    });
  };


  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Returns the encoded form of the string. test& -> test%26.
    const encodedSearch = event.target.value ? encodeURIComponent(event.target.value) : '';

    setPage(0);
    fetchQueueTasks(id, {
      ...pagination,
      page: 1,
      search: encodedSearch,
    });
  };

  const onFilterChange = (option: IHeaderMenuOptions) => {
    HISTORY.push({
      search: qs.stringify({
        ...queryParams,
        status: option.id === headerMenuOptions[0].id ? 'open' : 'closed',
      }),
    });
  };

  useEffect(() => {
    // Fetch queueTasks when status change in query params but not on first render
    if (previousLocationSearch && hasDiffKeyValues(queryParams, previousLocationSearch, 'status')) {
      fetchQueueTasks(id, {
        ...pagination,
        page: 1,
        status: queryParams?.status ?? parseStatus().status,
      });

      setHeaderValue(parseStatus());
    }
  }, [LOCATION]);

  useEffect(() => {
    try {
      getQueue(id);
      fetchQueueTasks(id, {
        ...initialQueuesData.pagination,
        status: queryParams?.status ?? parseStatus().status,
      });

      if (searchInputRef.current) {
        searchInputRef.current!.value = '';
      }

      setPage(0);
      setSortModel([]);
    } catch (error) {
      displayBackendErrorMessage(error);
    }
  }, [id]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);

    if (newModel.length) {
      const [nm] = newModel;
      setPage(0);
      fetchQueueTasks(id, {
        ...pagination,
        page: 1,
        ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
      });
    } else {
      fetchQueueTasks(id, {
        ...pagination,
        ordering: '',
      });
    }
  };

  const columns: IColumns[] = [
    {
      name: 'id',
      display_name: t('Queue No.'),
      type: 'string',
      is_hidden: true,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      flex: 0,
    },
    {
      name: 'title',
      display_name: t('Title'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 130,
      flex: 1,
      align: 'left',
    },
    {
      name: 'button',
      display_name: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 40,
    },
    {
      name: 'reference__reference_type',
      display_name: t('Related to'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 120,
      flex: 0.5,
    },
    {
      name: 'named_insured',
      display_name: t('Named Insured'),
      type: 'string',
      minWidth: 140,
      flex: 1,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'assigned_to__full_name',
      display_name: t('Assigned to'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 140,
      flex: 1,
      align: 'left',
    },
    {
      name: 'created_at',
      display_name: t('Opened at'),
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
    },
    // {
    //   name: 'due_date',
    //   display_name: t('Due Date'),
    //   type: 'date',
    //   is_hidden: false,
    //   is_sortable: true,
    //   is_link: false,
    //   link_type: null,
    //   minWidth: themeHook.dateColumnWidth,
    //   flex: 0.1,
    // },
    {
      name: 'priority__value',
      display_name: t('Priority'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 120,
      flex: 0.1,
    },
  ];

  const columnsPolicyQueues: GridColDef[] = columns.map((field, i) => ({
    field: field.name,
    headerName: field.display_name,
    flex: field.flex,
    minWidth: field.minWidth,
    sortable: field.is_sortable,
    align: field.align,
    headerAlign: field.headerAlign,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (fieldValue === '-' && i !== 0 && field.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (i === 1) {
        return (
          <Box
            onClick={() => handleDetailTask(params.row.display_id)}
            sx={{
              width: 400,
              color: (theme) => theme.customColors.table.link,
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
          >
            {fieldValue}
          </Box>
        );
      } else if (field.type === dataFieldTypes.ACTION) {
        return (
          <Box sx={{}}>
            <MenuComponent
              renderDefaultIcon={false}
              anchor={
                <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
                  <IconButton
                    disableRipple
                    size="small"
                    sx={{
                      padding: '1px',
                      bgcolor:
                        selectedRowId === params.row.id && isClickedMoreButton
                          ? (theme) => theme.palette.background.default
                          : 'none',
                      color:
                        selectedRowId === params.row.id && isClickedMoreButton
                          ? (theme) => theme.customColors.black
                          : 'none',
                      '&:hover': {
                        color: (theme) => theme.customColors.black,
                        bgcolor: (theme) => theme.palette.background.default,
                      },
                    }}
                    onClick={() => {
                      setIsClickedMoreButton(true);
                      setSelectedRowId(params.row.id);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </ClickAwayListener>
              }
            >
              {getNestedValueFromObject(params.row, 'assigned_to__full_name') === '-' ? (
                <>
                  <MenuItem
                    sx={dropdownItemsStyle}
                    value="assignToMe"
                    onClick={() => handleAssign(params.row)}
                  >
                    <ReactSVG src={AssignToMe} />
                    {t('Assign to me')}
                  </MenuItem>
                  {getNestedValueFromObject(params.row, 'status__key') ===
                    taskStatuses.OPEN.key && (
                    <MenuItem
                      sx={dropdownItemsStyle}
                      value="markAsClosed"
                      onClick={() => handleCloseTask(params.row)}
                    >
                      <ReactSVG src={MarkAsClosed} />
                      {t('Mark as Closed')}
                    </MenuItem>
                  )}
                  <MenuItem
                    sx={dropdownItemsStyle}
                    value="delete"
                    onClick={() => {
                      setDialogOpen({
                        dialog: 'DELETE_QUEUE',
                        isOpen: true,
                        onAccept: () => handleDeleteTask(params.row.display_id!),
                      });
                    }}
                  >
                    <ReactSVG src={Delete} />
                    {t('Delete')}
                  </MenuItem>
                </>
              ) : (
                <>
                  {getNestedValueFromObject(params.row, 'status__key') ===
                    taskStatuses.OPEN.key && (
                    <MenuItem
                      sx={dropdownItemsStyle}
                      value="markAsClosed"
                      onClick={() => handleCloseTask(params.row)}
                    >
                      <ReactSVG src={MarkAsClosed} />
                      {t('Mark as Closed')}
                    </MenuItem>
                  )}
                  <MenuItem
                    sx={dropdownItemsStyle}
                    value="delete"
                    onClick={() => {
                      setDialogOpen({
                        dialog: 'DELETE_QUEUE',
                        isOpen: true,
                        onAccept: () => handleDeleteTask(params.row.display_id!),
                      });
                    }}
                  >
                    <ReactSVG src={Delete} />
                    {t('Delete')}
                  </MenuItem>
                </>
              )}
            </MenuComponent>
          </Box>
        );
      } else if (field.name === 'reference__reference_type') {
        const r = params.row as Task;
        let mainPath;

        switch (r.reference?.reference_link_type) {
          case 'policy':
            mainPath = `/policies/${r.reference.locator}/overview`;
            break;

          case 'policyholder':
            mainPath = `/policyholders/${r.reference.locator}/details`;
            break;

          case 'quote':
            mainPath = `/quotes/${r.reference.locator}`;
            break;

          default:
            break;
        }

        return (
          <Link
            component={RouterLink}
            to={mainPath}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
            }}
          >
            <Stack
              sx={{
                flexDirection: {
                  sm: 'column',
                },
              }}
            >
              <Typography>
                {r.reference?.reference_type !== 'Policyholder' ? r.three_number : '-'}
              </Typography>
            </Stack>
          </Link>
        );
      } else if (field?.name === 'created_at') {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');
        return (
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                mr: 1,
              }}
            >
              {splitDate[0]}
            </Box>
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Box>
          </Box>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  return (
    <>
      <Head title={t('Queues')} />
      <Fade in timeout={500}>
        <Box>
          {isDetailTaskDrawerOpen && <TaskDetail isDrawerOpen={isDetailTaskDrawerOpen} />}
          <PageHeader
            sx={{
              px: 3,
            }}
            left={
              <MenuComponent
                titleMenu
                anchor={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        userSelect: 'none',
                        color: (theme) => theme.customColors.pageHeader.title,
                        fontWeight: '500',
                      }}
                    >
                      {headerValue.value}
                    </Typography>
                  </Box>
                }
              >
                <Box sx={{ padding: (theme) => theme.spacing(0.5, 0) }}>
                  {headerMenuOptions.map((option) => (
                    <MenuItem
                      onClick={() => onFilterChange(option)}
                      key={option.value}
                      value={option.id}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Box>
              </MenuComponent>
            }
            right={
              <ViewSearch onChange={debounce(handleSearchInputChange, defaultDebounceDelay)} />
            }
          />
          <DataTable
            columns={columnsPolicyQueues}
            loading={(queuesLoading || inPageLoading) ?? true}
            rows={queueTasks[`${id}`]?.data ?? []}
            rowHeight={75}
            paginationMode="server"
            focusedRowId={selectedRowId}
            columnVisibilityModel={createColumnVisibilityModel(columns)}
            paginationModel={{ page: pageNumber, pageSize: pagination.page_size! }}
            pageSizeOptions={[pagination.page_size!]}
            rowCount={pagination.total_count}
            onPageChange={(page) => {
              setPage(page);
              fetchQueueTasks(id, { ...pagination, page: page + 1 });
            }}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            hideFooterPagination={pagination.total_count! < pagination.page_size!}
            wrapperSx={{ mx: 3 }}
          />
        </Box>
      </Fade>
    </>
  );
};

export default QueueList;
