import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { UserResponse } from 'api/models/Users/userList.model';
import { getAssignUsers } from 'api/services/Tasks';
import {
  initialTaskAssignUsersData,
  TaskAssignUsersContext,
} from './TaskAssignUsersProviderContext';
import { ITaskAssignUsersPagination } from './types';

export const TaskAssignUsersProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialTaskAssignUsersData);

  const fetch = async (
    query: ITaskAssignUsersPagination,
    forceReset = false,
  ): Promise<UserResponse[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await getAssignUsers({
        ...query,
        fields: initialTaskAssignUsersData.pagination.fields,
        page_size: initialTaskAssignUsersData.pagination.page_size,
        next: '',
      });

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data:
          !forceReset && query.page && query.page !== prevState.pagination.page
            ? [...prevState.data!, ...res?.results!]
            : [...res?.results!],
        pagination: {
          ...prevState.pagination,
          ...query,
          total_count: res?.count,
          next: res?.next,
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const resetTaskAssignUsersState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialTaskAssignUsersData,
    }));
  };

  return (
    <TaskAssignUsersContext.Provider value={{ ...state, fetch, resetTaskAssignUsersState }}>
      {children}
    </TaskAssignUsersContext.Provider>
  );
};
