import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import ClaimsHistoryDetailDrawer from './Detail';

const ClaimsHistoryDrawer: FC = () => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);

  const isClaimsHistoryDrawerOpen = url?.tab === 'claims_history' && !isEmpty(url?.claim);

  return isClaimsHistoryDrawerOpen ? (
    <ClaimsHistoryDetailDrawer isDrawerOpen={isClaimsHistoryDrawerOpen ?? false} />
  ) : (
    <></>
  );
};
export default ClaimsHistoryDrawer;
