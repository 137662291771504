import { Box, Link, Typography, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
} from 'common/constants';
import DataTable from 'components/DataTable';
import { tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DriverDrawer from './Drawers/DriverDrawer/DriverDrawer';
import DrivingIncidentsDrawer from './Drawers/DrivingIncidentsDrawer/DrivingIncidentsDrawer';

interface IcolumnsDrivers {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const Drivers: FC = () => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const LOCATION = useLocation();
  const themeHook = useTheme();
  const { groups } = usePolicyDetail();
  const [selectedDriverRowId, setSelectedDriverRowId] = useState<string>('');
  const [selectedDrivingIncidentRowId, setSelectedDrivingIncidentRowId] = useState<string>('');

  const rowsDrivers = useMemo(() => {
    const r =
      groups?.pol_drivers?.data?.map((driver) => ({
        ...driver,
        id: driver.pol_drivers_id,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const rowsDrivingIncidents = useMemo(() => {
    const r =
      groups?.pol_incidents?.data?.map((inc) => ({
        ...inc,
        id: inc.locator,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const columnsDrivers: IcolumnsDrivers[] = [
    {
      name: 'name',
      display_name: t('Name'),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: true,
      link_type: null,
      minWidth: 160,
    },
    {
      name: 'pol_drivers_dob',
      display_name: t('Date of Birth'),
      flex: 0.1,
      type: 'date',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'center',
      headerAlign: 'center',
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'pol_drivers_license_state',
      display_name: t("Driver's License State"),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'pol_drivers_license_number',
      display_name: t("Driver's License Number"),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
  ];

  const driversColumnsDrivers: GridColDef[] = columnsDrivers.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    // eslint-disable-next-line consistent-return
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.is_link) {
        if (!fieldValue || fieldValue.trim() === '') {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { driver: params.row.id },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
            >
              -
            </Link>
          );
        } else if (fieldValue) {
          const { pol_drivers_first = '', pol_drivers_last = '' } = params.row;
          const name = `${pol_drivers_first} ${pol_drivers_last}`;

          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { driver: params.row.id },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
              title={name}
            >
              {name.trim() === '' ? '-' : name}
            </Link>
          );
        }
      } else if (field.type === 'date') {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const columnsDrivingIncidents: IcolumnsDrivers[] = [
    {
      name: 'pol_incidents_date',
      display_name: t('Date of Incident'),
      flex: 0.1,
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'pol_incidents_driver',
      display_name: t('Driver'),
      flex: 2,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'pol_incidents_type',
      display_name: t('Type'),
      flex: 4,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
  ];

  const incidentsColumnsDriving: GridColDef[] = columnsDrivingIncidents.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    // eslint-disable-next-line consistent-return
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        if (!fieldValue) {
          return (
            <Typography
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              -
            </Typography>
          );
        } else if (fieldValue && field.type === 'date') {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { drivingIncident: params.row.id },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
            >
              {formatDateInTimeZone(fieldValue) || '-'}
            </Link>
          );
        } else if (fieldValue) {
          return (
            <Typography sx={truncatedTextStyle} title={fieldValue}>
              {fieldValue || '-'}
            </Typography>
          );
        }
      } else if (field.type === 'date') {
        return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
      } else if (field.name.includes('driver')) {
        const { pol_drivers_first = null, pol_drivers_last = null } =
          rowsDrivers.find((d) => d.id === fieldValue.split(';')?.[0]) ?? {};
        const name = pol_drivers_first
          ? `${pol_drivers_first} ${pol_drivers_last}`
          : pol_drivers_last ?? '-';

        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {name}
          </Typography>
        );
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {(fieldValue.includes(';') ? 'Multiple' : fieldValue) || '-'}
          </Typography>
        );
      }
    },
  }));

  return (
    <>
      <DriverDrawer />
      <DrivingIncidentsDrawer />
      <Box sx={{ maxWidth: 1164 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.DRIVERS.title()}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 14,
            mb: (theme) => theme.spacing(1.5),
            lineHeight: (theme) => theme.typography.body1.lineHeight,
            minWidth: 100,
          }}
        >
          {quotePolicyEndorsementInfoTitlesDescriptions.DRIVERS.description()}
        </Typography>
        <Box sx={{ maxWidth: 800, mb: 4 }}>
          <DataTable
            onRowClick={(r) =>
              setSelectedDriverRowId(r.id === selectedDriverRowId ? '' : (r.id as string))
            }
            columns={driversColumnsDrivers}
            rows={rowsDrivers}
            loading={groups?.pol_drivers?.loading}
            pageSize={defaultRowVirtualization}
            columnVisibilityModel={createColumnVisibilityModel(columnsDrivers)}
            hideFooterPagination={rowsDrivers?.length! < defaultRowVirtualization}
            focusedRowId={selectedDriverRowId}
          />
        </Box>
      </Box>
      <Box sx={{ maxWidth: 1164 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.DRIVING_INCIDENTS.title()}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 14,
            mb: (theme) => theme.spacing(1.5),
            lineHeight: (theme) => theme.typography.body1.lineHeight,
            minWidth: 100,
          }}
        >
          {quotePolicyEndorsementInfoTitlesDescriptions.DRIVING_INCIDENTS.description()}
        </Typography>
        <Box sx={{ maxWidth: 800, mb: 4 }}>
          <DataTable
            onRowClick={(r) =>
              setSelectedDrivingIncidentRowId(
                r.id === selectedDrivingIncidentRowId ? '' : (r.id as string),
              )
            }
            columns={incidentsColumnsDriving}
            rows={rowsDrivingIncidents}
            loading={groups?.pol_incidents?.loading}
            focusedRowId={selectedDrivingIncidentRowId}
            pageSize={defaultRowVirtualization}
            columnVisibilityModel={createColumnVisibilityModel(columnsDrivingIncidents)}
            hideFooterPagination={rowsDrivingIncidents?.length! < defaultRowVirtualization}
          />
        </Box>
      </Box>
    </>
  );
};

export default Drivers;
