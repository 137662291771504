import { Box } from '@mui/material';
import PaymentsAppBar from 'components/Payments/PaymentsAppBar';
import React, { FC } from 'react';

interface PaymentLayoutProps {
  children: React.ReactNode;
}

const PaymentLayout: FC<PaymentLayoutProps> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '100vh',
      overflow: 'hidden',
    }}
  >
    <PaymentsAppBar />
    <Box
      component="main"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        marginTop: (theme) => `${theme.appBarHeight}px`,
        mb: '1rem',
        overflow: 'auto',
      }}
    >
      {children}
    </Box>
  </Box>
);

export default PaymentLayout;
