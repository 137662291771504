import React, { useState } from 'react';

// helpers
import { getFirstValueIfArray } from 'helpers/Utils';

// services
import { getPolicies, getPolicyCountAndStatus } from 'api/services/Policies';

/// types
import { IContextProps } from 'providers/types';

/// data
import { initialPoliciesData, PoliciesContext } from './PoliciesProviderContext';
import { IPoliciesPagination, IPolicy } from './types';

export const PoliciesProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialPoliciesData);

  const fetchStatus = async () => {
    const status = await getPolicyCountAndStatus();
    setState((prevState) => ({
      ...prevState,
      filterListArray: {
        ...prevState.filterListArray,
        loading: false,
        loaded: true,
        data: status,
      },
    }));
  };

  const fetch = async (
    pagination: IPoliciesPagination,
    newSubmission = false,
  ): Promise<IPolicy[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        selectedPolicyholderRow: undefined,
      }));

      if (!(state?.filterListArray?.data?.length! > 0) || newSubmission) {
        fetchStatus();
      }

      const res = await getPolicies({
        ...pagination,
        page_size: pagination.page_size ?? initialPoliciesData.pagination.page_size,
      });

      const policies: IPolicy[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const ph of res.results) {
        policies.push({
          ...Object.entries(ph).reduce(
            (acc, [key, value]) => ({ ...acc, [key]: getFirstValueIfArray(value) }),
            {},
          ),
          id: ph.locator,
        });
      }

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: policies,
        selectedPolicyRow: undefined,
        pagination: {
          ...pagination,
          total_count: res.count,
          page_size: pagination.page_size ?? prevState.pagination.page_size,
        },
      }));

      return policies;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const resetThreePolicyState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialPoliciesData,
    }));
  };

  return (
    <PoliciesContext.Provider
      value={{
        ...state,
        fetch,
        resetThreePolicyState,
      }}
    >
      {children}
    </PoliciesContext.Provider>
  );
};
