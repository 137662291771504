import React, { useState } from 'react';

/// types
import {
  addDocumentService,
  deleteDocumentService,
  getDocumentsService,
  getDocumentWithLocator,
  updateDocumentWithLocator,
} from 'api/services/Documents';
import { IContextProps } from 'providers/types';

/// data
import { PolicyDocument } from 'api/models/Documents/policyDocument.model';
import { PolicyDocumentCreate } from 'api/models/Documents/policyDocumentCreate.model';
import { AxiosError } from 'axios';
import { Pagination } from 'types/Pagination';
import { DocumentsContext, initialDocumentsData } from './DocumentsProviderContext';

export const DocumentsProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialDocumentsData);

  const resetDocumentsState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialDocumentsData,
    }));
  };

  const fetch = async (
    query: Pagination,
    reference_type: string,
    reference_locator: string,
  ): Promise<PolicyDocument[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      let res;

      try {
        res = await getDocumentsService(
          {
            ...query,
            page_size: initialDocumentsData.pagination.page_size,
          },
          reference_type,
          reference_locator,
        );
      } catch (error) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          if (e.response?.status === 404) {
            res = await getDocumentsService(
              {
                ...query,
                page_size: initialDocumentsData.pagination.page_size,
                page: 1,
              },
              reference_type,
              reference_locator,
            );
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res?.results ?? [],
        pagination: {
          ...query,
          total_count: res?.count,
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const fetchDocument = async (
    policyId: string,
    locator: string,
  ): Promise<PolicyDocument | undefined> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeDocument: { ...prevState.activeDocument, loading: true },
      }));

      const res = await getDocumentWithLocator(policyId, locator);

      setState((prevState) => ({
        ...prevState,
        activeDocument: {
          ...prevState.activeDocument,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        activeDocument: { ...prevState.activeDocument, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const createDocument = async (
    data: FormData,
    reference_type: string,
    reference_locator: string,
    onUploadProgress?: (progressEvent: any) => void,
    controller?: AbortController,
  ) => {
    try {
      setState((prevState) => ({
        ...prevState,
      }));

      const res = await addDocumentService(
        reference_type,
        reference_locator,
        data,
        onUploadProgress,
        controller,
      );

      return res;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const reFetchData = async (reference_type: string, reference_locator: string) => {
    await fetch(state.pagination, reference_type, reference_locator);
  };

  const deleteDocument = async (policyId: string, documentLocator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await deleteDocumentService(policyId, documentLocator);

      return res;
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const updateDocument = async (
    policyId: string,
    locator: string,
    data: PolicyDocumentCreate,
  ): Promise<PolicyDocument> => {
    try {
      setState((prevState) => ({
        ...prevState,
        activeQueue: data,
      }));

      const res = await updateDocumentWithLocator(policyId, locator, data);

      setState((prevState) => ({
        ...prevState,
        activeDocument: {
          ...prevState.activeDocument,
          data: res,
          loading: false,
          loaded: true,
        },
      }));

      return res ?? {};
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  return (
    <DocumentsContext.Provider
      value={{
        ...state,
        fetch,
        resetDocumentsState,
        fetchDocument,
        createDocument,
        deleteDocument,
        reFetchData,
        updateDocument,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
