/* eslint-disable dot-notation */

import { supportedLocales } from 'common/constants';

const getUserLocale = (): string => {
  const userLocale = (window.navigator.language || window.navigator['userLanguage']).split('-')[0];

  // defaults to en
  return localStorage.getItem('selectedLanguage')
    ? localStorage.getItem('selectedLanguage')
    : supportedLocales.includes(userLocale)
    ? userLocale
    : 'en';
};

export default getUserLocale;
