import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { WcClassCodes } from 'api/models/THREEMappings/WcClassCodes/wcClassCodes.model';
import {
  employerManCapitaRealatedFields,
  manIsancillaryFieldCode,
  threeEmployerExposurePerilNames,
  threeExposureNames,
  userRoles,
  wcAuditEmployerFields,
  wcExposureRelatedKeyValues,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { ClassDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ClassDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import {
  changeFieldsHiddenStatus,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
  makeFieldsReadonly,
  parseLocation,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useKeyValues from 'hooks/useKeyValues';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import EndorsementLocationSelect from '../../../Inputs/LocationSelectInput';
import WcClassCodeAutocomplete from '../../../Inputs/WcClassCodeAutocomplete';
import { calculateOwnerPayroll, classCodeOptions } from '../helpers';

export interface ClassDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const ClassDetailDrawer: FC<ClassDetailProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const WC_ID = url?.wc;
  const WCP_ID = url?.wcp;
  const {
    fields: fieldConfig,
    exposureList,
    exposures,
    groups,
    isWcFinal,
    isWc,
    data: endorsementDetail,
  } = useEndorsementDetail();

  const { data: keyValueStore } = useKeyValues();
  const { data: user } = useUser();

  const endorsementDetailData = endorsementDetail?.policy?.characteristics?.data ?? {};

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['wc', 'wcp', 'addWc'],
      }),
    });
  };

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(() => {
    const isNewlyAdded = WC_ID?.includes('added-');
    const index = isNewlyAdded ? WC_ID?.slice(6) : undefined;

    return (
      employerExposures?.find((e) => (isNewlyAdded ? e.index === index : e.locator === WC_ID)) ?? {}
    );
  }, [employerExposures, WC_ID]);

  // try to find selected exposure in the exposures list
  const activeClass = useMemo(() => {
    const isNewlyAdded = WCP_ID?.includes('added-');
    const index = isNewlyAdded ? WCP_ID?.slice(6) : undefined;

    return (
      activeExposure?.perils?.find((p) =>
        isNewlyAdded ? p.index === index : p.locator === WCP_ID,
      ) ?? {}
    );
  }, [activeExposure]);

  const activeClassData = activeClass?.data ?? {};

  useEffect(() => {
    // check if found exposure and peril is in the right type
    if (
      exposures?.loaded &&
      !isEmpty(activeExposure) &&
      !isEmpty(activeClass) &&
      (activeExposure.name !== threeExposureNames.EMPLOYER ||
        activeClass.name !== threeEmployerExposurePerilNames.MANUAL_PREMIUM)
    ) {
      displayToastMessage(
        'ERROR',
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'class code',
        }),
      );
      handleQuery();
    }
  }, [activeClass, exposures]);

  const {
    pol_man_payrollw2_default,
    pol_man_payrollw2_rule,
    pol_man_payrollsub_default,
    pol_man_payrollsub_rule,
    pol_oo_wc_payroll,
  } = endorsementDetailData;

  const [state, setState] = useState<any>(
    activeClassData
      ? {
          ...activeClassData,
          man_payroll_w2:
            activeClassData.man_ismain === 'No'
              ? 0
              : pol_man_payrollw2_rule ?? pol_man_payrollw2_default,
          man_payroll_sub:
            activeClassData.man_ismain === 'No'
              ? 0
              : pol_man_payrollsub_rule ?? pol_man_payrollsub_default,
          owner_payroll: pol_oo_wc_payroll,
        }
      : {},
  );

  const [fields, setFields] = useState<any[]>([]);

  const WC_CLASS_CODES: WcClassCodes[] =
    keyValueStore?.[`${wcExposureRelatedKeyValues.WC_CLASS_CODES}`]?.data?.value ?? [];

  useEffect(() => {
    const manualPremiumFields =
      (fieldConfig?.peril?.data as ProductWorkFlow[])?.[`${threeExposureNames.EMPLOYER}`]?.find(
        (p) => p.code === threeEmployerExposurePerilNames.MANUAL_PREMIUM,
      )?.fields ?? [];
    // This field should be neither visible nor editable by Producer role.
    const tmpFields = manualPremiumFields.map((field) => {
      if (user?.role?.code === userRoles.AGENT.code) {
        if (field.code === manIsancillaryFieldCode) {
          return { ...field, is_hidden: true };
        }
      }
      return field;
    });

    setFields(tmpFields);
  }, [fieldConfig]);

  useEffect(() => {
    if (isWc) {
      setFields((prevFields) => {
        const { ESTIMATED, FINAL, ORIGINALS } = wcAuditEmployerFields;

        let tmpFields = changeFieldsHiddenStatus(
          prevFields,
          isWcFinal ? [...FINAL, ...ESTIMATED] : ESTIMATED,
          false,
        );

        tmpFields = makeFieldsReadonly(
          tmpFields,
          isWcFinal ? [...ESTIMATED, ...ORIGINALS] : ORIGINALS,
          true,
        );

        return tmpFields;
      });
    }
  }, [isWcFinal, fieldConfig, isWc]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {
      if (formik.isValid) {
        try {
          displayToastMessage('SUCCESS', t('Changes have been saved.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        }
      }
    },
  });

  const calculateOwnerPayrolls = (concatLookup: string, locationState: string) => {
    const owner_payroll = calculateOwnerPayroll(
      concatLookup!,
      locationState,
      'pol_oo_wc_payroll',
      groups,
    );

    const owner_payroll_audit_est = calculateOwnerPayroll(
      concatLookup,
      locationState,
      'pol_oo_wc_payroll_audit_est',
      groups,
    );

    const owner_payroll_audit_final = calculateOwnerPayroll(
      concatLookup,
      locationState,
      'pol_oo_wc_payroll_audit_final',
      groups,
    );

    return {
      owner_payroll,
      owner_payroll_audit_est,
      owner_payroll_audit_final,
    };
  };

  useEffect(() => {
    const {
      man_payroll_sub = 0,
      man_payroll_w2 = 0,
      man_location,
      man_class_code,
      man_class_code_desc,
      man_payroll_sub_user,
      man_payroll_w2_user,
    } = activeClassData;

    const filingSetId = activeExposure.data?.emp_filing_set_id;
    const parsedLocation = parseLocation(man_location);

    const foundClassCode = classCodeOptions(
      parsedLocation?.state,
      filingSetId,
      WC_CLASS_CODES,
    )?.find((o) => o.ConcatLookup === `${man_class_code} - ${man_class_code_desc}`);

    const calculatedPayrolls = calculateOwnerPayrolls(
      foundClassCode?.ConcatLookup!,
      parsedLocation?.state,
    );

    setState((prevState) => ({
      ...prevState,
      ...activeClassData,
      total_rated_payroll:
        (man_payroll_sub_user ? Number(man_payroll_sub_user) : Number(man_payroll_sub)) +
        (man_payroll_w2_user ? Number(man_payroll_w2_user) : Number(man_payroll_w2)) +
        calculatedPayrolls.owner_payroll,
      total_rated_payroll_audit_est:
        Number(activeClassData.man_payroll_w2_audit_est ?? '0') +
        Number(activeClassData.man_payroll_sub_audit_est ?? '0') +
        calculatedPayrolls.owner_payroll_audit_est,
      total_rated_payroll_audit_final:
        Number(activeClassData.man_payroll_w2_audit_final ?? '0') +
        Number(activeClassData.man_payroll_sub_audit_final ?? '0') +
        calculatedPayrolls.owner_payroll_audit_final,
      emp_filing_set_id: filingSetId,
      exposure_type: foundClassCode?.ExposureType,
      rate: foundClassCode?.Rate,
      ...calculatedPayrolls,
    }));

    formik.setValues(activeClassData);
  }, [activeClassData]);

  const showLoader = fieldConfig?.exposure?.loading || isEmpty(activeExposure);

  useEffect(() => {
    formik.setValues(state);

    setFields((prevState) =>
      changeFieldsHiddenStatus(
        prevState,
        isWc ? employerManCapitaRealatedFields : [employerManCapitaRealatedFields[0]],
        state.exposure_type !== 'Per Capita',
      ),
    );
  }, [JSON.stringify(state)]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Class Code Details')}
        </Typography>
      }
      content={
        <Stack gap={2} sx={{ mb: 6, pt: 1 }}>
          <ClassDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            isEdit
            splitSize={3}
            showLoader={showLoader}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
            LocationSelect={EndorsementLocationSelect}
            WcClassCodeAutocomplete={WcClassCodeAutocomplete}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default ClassDetailDrawer;
