import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

const SetupSuccessPage: FC = () => (
  <>
    <Box
      sx={{
        textAlign: 'center',
        maxWidth: '400px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{ fontSize: 24, fontWeight: 600, color: (theme) => theme.customColors.successGreen }}
      >
        SUCCESS
      </Typography>
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Typography
          sx={{
            fontSize: 16,
            lineHeight: '28px',
            fontWeight: 400,
            display: 'inline',
            whiteSpace: 'pre-wrap',
            color: (theme) => theme.customColors.successGreen,
          }}
        >
          Setup completed successfully.
        </Typography>
      </Box>
    </Box>
  </>
);

export default SetupSuccessPage;
