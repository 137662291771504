import { Box, Fade, Link, Typography } from '@mui/material';
import { GridAlignment, GridColDef, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import NewItem from 'assets/images/icon_newitem.svg';
import { COLUMNS, dataFieldTypes, userRoles } from 'common/constants';
import DataTable from 'components/DataTable';
import Head from 'components/Head';
import NewSubmissionDialog from 'components/Quotes/NewSubmissionDialog';
import SubmissionHeader from 'components/Submissions/SubmissionsDetail/Header';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import { usePreviousValue } from 'helpers/PreviousValue';
import {
  currencyFormat,
  getNestedValueFromObject,
  getSymmetricDiffrence,
  hasDiffKeyValues,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useQuotes from 'hooks/useQuotes';
import useUser from 'hooks/useUser';
import { has } from 'lodash-es';
import { IPoliciesPagination } from 'providers/PoliciesProvider/types';
import { IQuotesPagination } from 'providers/QuotesProvider/types';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

const modalQueries = ['newSubmission', 'newPreQual'];

const Quotes: FC = () => {
  const { t } = useTranslation();
  const { formatDateInTimeZone, timezoneConfig } = useConfig();
  const { data, loading, loaded, pagination, fetch } = useQuotes();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const queryParams = qs.parse(LOCATION.search) as IQuotesPagination;
  const [newSubmissionDialogVisible, setNewSubmissionDialogVisible] = useState(false);
  const [isPreQualDialogOpen, setIsPreQualDialogOpen] = useState(false);
  const previousLocationSearch = usePreviousValue(queryParams);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { data: user } = useUser();

  const isColumnExcludedForUser = useMemo(
    () => (fieldCode: string) =>
      ['team', 'agency'].includes(fieldCode!) &&
      ![userRoles.UNDERWRITER.code].includes(user?.role?.code!),

    [user],
  );

  const defaultOrder = {
    field: 'created_at',
    sort: 'desc',
  } as GridSortItem;

  const renderColumns = (cl): GridColDef[] =>
    cl.map((field, i) => ({
      field: field.code!,
      headerName: field.name,
      flex: field.additional_data?.flex ?? 1,
      minWidth: field.additional_data?.minWidth,
      maxWidth: field.additional_data?.maxWidth,
      sortable: field.is_sortable ?? true,
      align: field.additional_data?.headerAlign as GridAlignment,
      headerAlign: field.additional_data?.headerAlign as GridAlignment,
      type: field.type,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, field.code!);

        if (fieldValue === '-' && i !== 0) {
          return fieldValue;
        } else if (field.code === 'three_quote_number') {
          const locator = getNestedValueFromObject(params.row, 'locator');

          return (
            <Link
              component={RouterLink}
              to={`/quotes/${params.row.id}/details`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue ?? locator ?? '-'}
            </Link>
          );
        } else if (field.is_link) {
          let to = '';

          switch (field.link_type) {
            case 'policyholder':
              to = `/policyholders/${getNestedValueFromObject(
                params.row,
                `policyholder__locator`,
              )}/details`;
              break;

            default:
              break;
          }

          return (
            <Link
              component={RouterLink}
              to={to}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else if (field.type === dataFieldTypes.CURRENCY) {
          return fieldValue
            ? `${
                currencyFormat(
                  getNestedValueFromObject(params.row, `${field.code}_currency`),
                  fieldValue,
                ).merged
              }`
            : '-';
        } else if (field.type === dataFieldTypes.DATE) {
          return fieldValue ? formatDateInTimeZone(fieldValue) : '-';
        } else if (field.type === dataFieldTypes.EMAIL) {
          return (
            <Link
              href={`mailto:${fieldValue}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue}
            </Link>
          );
        } else if (field.code === 'state__name') {
          if (!fieldValue) return '-';
          const r = params.row;
          const statusColor = r?.state?.code!;
          return (
            <Box
              sx={{
                px: (theme) => theme.spacing(1.5),
                borderRadius: (theme) => theme.spacing(0.5),
                color: (theme) => theme.customColors.black,
                backgroundColor: (theme) => theme.customColors.submissionStatusColor[statusColor],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: (theme) => theme.typography.subtitle2.fontSize,
                  color: (theme) => theme.customColors.white50,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                  whiteSpace: 'nowrap',
                }}
                variant="body1"
              >
                {r?.state?.name!}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Typography>
          );
        }
      },
    }));
  const memoizedColumns = useMemo(
    () => renderColumns(COLUMNS.QUOTE_COLUMNS),
    [isColumnExcludedForUser],
  );

  const checkIsOrderFieldInColumns = (field: string) =>
    memoizedColumns.some((col) => col.field === field?.replaceAll('-', ''));

  const checkIsOrderDefaultField = () => {
    if (defaultOrder?.sort === 'desc') {
      return `-${defaultOrder.field}`;
    } else {
      return defaultOrder.field;
    }
  };

  const parseOrdering = () => {
    if (queryParams.ordering) {
      const splitedOrdering = queryParams.ordering?.split(',')?.[0];
      if (splitedOrdering?.includes('-')) {
        const field = splitedOrdering?.replaceAll('-', '');
        return checkIsOrderFieldInColumns(field)
          ? ({ field, sort: 'desc' } as GridSortItem)
          : defaultOrder;
      } else {
        return checkIsOrderFieldInColumns(splitedOrdering)
          ? ({ field: splitedOrdering, sort: 'asc' } as GridSortItem)
          : defaultOrder;
      }
    } else {
      return defaultOrder;
    }
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([parseOrdering()]);

  const [pageNumber, setPage] = useState(
    queryParams?.page ? parseInt(queryParams?.page as unknown as string, 10) - 1 : 0,
  );

  const handleAddSubmission = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { newSubmission: true },
      }),
    });
  };

  const reFetchData = () => {
    const filteredQueryParams = Object.entries(queryParams).reduce((acc, [key, value]) => {
      if (value && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {}) as IPoliciesPagination;

    fetch(
      {
        ...filteredQueryParams,
        ordering: checkIsOrderFieldInColumns(queryParams.ordering ?? '')
          ? queryParams.ordering
          : checkIsOrderDefaultField(),
      },
      true,
    );
    setPage(queryParams?.page ? parseInt(queryParams?.page as unknown as string, 10) - 1 : 0);
    setSortModel([parseOrdering()]);
  };

  useEffect(() => {
    // Do not re-fetch table data if just one of these keys changed
    if (
      modalQueries.every(
        (k) =>
          !getSymmetricDiffrence(
            Object.keys(queryParams ?? {}),
            Object.keys(previousLocationSearch ?? {}),
          ).includes(k),
      ) &&
      hasDiffKeyValues(queryParams, previousLocationSearch)
    ) {
      reFetchData();
    }
  }, [LOCATION.search]);

  useEffect(() => {
    if (modalQueries.some((v) => has(queryParams, v))) {
      reFetchData();
    }
  }, []);

  useEffect(() => {
    setNewSubmissionDialogVisible(LOCATION.search.includes('newSubmission'));
    setIsPreQualDialogOpen(LOCATION.search.includes('newPreQual'));
  }, [LOCATION]);

  const visibilityModel = useMemo(() => {
    const model = {};
    if (COLUMNS.QUOTE_COLUMNS) {
      COLUMNS.QUOTE_COLUMNS.forEach((field) => {
        if (isColumnExcludedForUser(field.code!) || field.is_hidden) {
          model[field.code!] = false;
        }
      });
    }
    return model;
  }, [COLUMNS.QUOTE_COLUMNS]);

  return (
    <>
      <Head title={t('Submissions')} />
      <Box ref={scrollRef} />
      {newSubmissionDialogVisible && (
        <NewSubmissionDialog
          isOpen={newSubmissionDialogVisible}
          setIsOpen={setNewSubmissionDialogVisible}
        />
      )}

      {isPreQualDialogOpen && (
        <NewSubmissionDialog
          isOpen={isPreQualDialogOpen}
          setIsOpen={setIsPreQualDialogOpen}
          isPreQual
        />
      )}

      <Fade in timeout={500}>
        <Box>
          <TopActionBar>
            {/*
            New Pre-Qual button hidden task #13089
             <TopActionBarButton
              onClick={handleAddPreQual}
              startIcon={
                <Box
                  component="img"
                  src={NewItem}
                  alt="New Item"
                  sx={{ width: '24px', height: '24px' }}
                />
              }
            >
              {t('New Pre-Qual')}
            </TopActionBarButton> */}
            <TopActionBarButton
              onClick={handleAddSubmission}
              data-test="new-submission"
              startIcon={
                <Box
                  component="img"
                  src={NewItem}
                  alt="New Item"
                  sx={{ width: '24px', height: '24px' }}
                />
              }
            >
              {t('New Submission')}
            </TopActionBarButton>
          </TopActionBar>

          <SubmissionHeader />

          <DataTable
            autoRowCellHeight
            columns={memoizedColumns}
            rows={data!}
            initialLoading={!timezoneConfig.loaded && loading && !loaded}
            loading={loading}
            columnVisibilityModel={visibilityModel}
            paginationMode="server"
            paginationModel={{ page: pageNumber, pageSize: pagination?.page_size! }}
            pageSizeOptions={[pagination?.page_size!]}
            rowCount={pagination?.total_count}
            onPageChange={(page) => {
              setPage(page);
              HISTORY.push({
                search: qs.stringify({ ...queryParams, page: page + 1 }),
              });
              scrollRef?.current?.scrollIntoView();
            }}
            sortingMode="server"
            sortModel={sortModel}
            hideFooterPagination={pagination?.total_count! < pagination?.page_size!}
            onSortModelChange={([newModel]) => {
              if (newModel) {
                setSortModel([newModel]);
                setPage(0);
                HISTORY.push({
                  search: qs.stringify({
                    ...{ ordering: queryParams.ordering, page: 1 },
                    ordering: `${newModel.sort === 'desc' ? '-' : ''}${newModel?.field}`,
                  }),
                });
              } else {
                HISTORY.push({
                  search: qs.stringify({
                    ...queryParams,
                  }),
                });
              }
            }}
            wrapperSx={{ mx: 3 }}
          />
        </Box>
      </Fade>
    </>
  );
};

export default Quotes;
