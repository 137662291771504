import React, { useState } from 'react';

/// types
import { getSmartyAddress } from 'api/services/SmartyAddress';
import { IContextProps } from 'providers/types';

/// data
import {
  initialSmartyAddressAutocompleteData,
  SmartyAddressAutocompleteContext,
} from './SmartyAddressAutocompleteProviderContext';

export const SmartyAddressAutocompleteProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialSmartyAddressAutocompleteData);

  const getAddressSuggestion = async (body: any) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await getSmartyAddress(body);

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
      }));
    } catch (error) {
      setState({ ...state, loading: false, loaded: true });
      throw error;
    }
  };

  const resetSmartyAddressAutocompleteState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialSmartyAddressAutocompleteData,
    }));
  };

  return (
    <SmartyAddressAutocompleteContext.Provider
      value={{ ...state, getAddressSuggestion, resetSmartyAddressAutocompleteState }}
    >
      {children}
    </SmartyAddressAutocompleteContext.Provider>
  );
};
