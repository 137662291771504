import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { initialTabData, TabContext } from './TabContext';

export const TabProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialTabData);

  const setActiveTab = (tabIndex: number): number => {
    setState((prevState) => ({
      ...prevState,
      activeTab: tabIndex,
    }));

    return tabIndex;
  };

  const resetTabState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialTabData,
    }));
  };

  return (
    <TabContext.Provider value={{ ...state, resetTabState, setActiveTab }}>
      {children}
    </TabContext.Provider>
  );
};
