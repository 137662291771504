import { Box, Button, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { stateList, threeExposureNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { LocationFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/LocationFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface LocationDetailProps {
  isDrawerOpen: boolean;
}

const LocationDetailDrawer: FC<LocationDetailProps> = ({ isDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const LOCATION_ID = url?.location;
  const { fields: fieldConfig, data: quoteDetail, getExposure, exposureList } = useQuoteDetail();

  const policyState = quoteDetail?.characteristics?.[0]?.data?.pol_state as string;

  const locationExposures = exposureList?.[`${threeExposureNames.LOCATION}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => locationExposures?.find((e) => e.locator === LOCATION_ID) ?? {},
    [locationExposures, LOCATION_ID],
  );

  const [state, setState] = useState<any>({ ...(activeExposure?.data ?? {}) });
  const [fields, setFields] = useState<any[]>([]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({ locationSearch: LOCATION.search, omitKeys: ['location'] }),
    });
  };

  const formik = useFormik<any>({
    initialValues: {},
    onSubmit: () => {},
  });

  useEffect(() => {
    setFields(
      (fieldConfig?.exposure?.data as ProductWorkFlow[])
        ?.find((con) => con.code === threeExposureNames.LOCATION)
        ?.fields?.map((f) => {
          const tmpField = { ...f };

          if (f.code === 'loc_address_state') {
            tmpField.choices = stateList.map(({ code, name }) => ({ code, name })) ?? [];
          }

          return tmpField;
        }) ?? [],
    );
  }, [fieldConfig]);

  const fetchExposureDetail = async () => {
    try {
      const detail = await getExposure(quoteDetail?.policy_locator!, LOCATION_ID as string);

      setState((prevState) => ({ ...prevState, ...(detail?.data ?? {}) }));
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'location',
        }),
      );
      handleQuery();
    }
  };

  useEffect(() => {
    if (!isEmpty(quoteDetail)) {
      // if exposure was finded in the exposures list, do not fetch exposure detail from BE
      if (isEmpty(state)) {
        fetchExposureDetail();
      }
    }
  }, [quoteDetail]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...(activeExposure?.data ?? {}) }));
  }, [activeExposure]);

  useEffect(() => {
    formik.setValues(state ?? {});
  }, [state]);

  const showLoader = fieldConfig?.exposure?.loading || isEmpty(activeExposure);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Location Details')}
        </Typography>
      }
      content={
        <LocationFieldParser
          formik={formik}
          fields={fields}
          loaded
          state={state}
          policyState={policyState}
          showLoader={showLoader}
          setState={setState}
          isReadOnly
        />
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default LocationDetailDrawer;
