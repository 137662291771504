import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Typography,
} from '@mui/material';
import displayToastMessage from 'helpers/DisplayToastMessage';
import usePolicyDetail from 'hooks/usePolicyDetail';
import qs from 'query-string';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

interface IBillingConfirmFeeWaiverDialogProps {
  isOpen: boolean;
  onAccept: (invoiceId, policyId) => void;
  onCancel: () => void;
}

const BillingConfirmFeeWaiverDialog: FC<IBillingConfirmFeeWaiverDialogProps> = ({
  isOpen,
  onAccept,
  onCancel,
}) => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const { id: policyId } = useParams<{ id: string }>();
  const invoiceId = url.waive as string;
  const { invoices } = usePolicyDetail();
  const invoice = invoices.data?.find((item) => item.locator === invoiceId);

  useEffect(() => {
    if (isOpen && invoices.loaded && (!invoice || !url.waive)) {
      displayToastMessage('ERROR', t('Invoice not found.'));
      onCancel();
    }
  }, [invoices.loaded, invoice, url.waive, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <Box sx={{ width: 480, p: 1, backgroundColor: (theme) => theme.customColors.grey1150 }}>
        <DialogTitle>{t('Confirm Fee Waiver')}</DialogTitle>

        <DialogContent>
          <Box>
            {invoices.loading ? (
              <Skeleton animation="wave" width="100%" height={37} />
            ) : (
              <Typography>
                {t('Are sure you want to waive the Fee')} - {invoice?.display_id ?? ''}
              </Typography>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={onCancel}
            sx={{
              color: (theme) => theme.customColors.copper,
              borderColor: (theme) => theme.customColors.copper,
              py: 0.5,
              px: 3,
              m: 1,
              mr: 0,
              fontSize: 14,
              borderRadius: '2px',
            }}
          >
            {t('Cancel')}
          </Button>

          <Button
            disabled={invoices.loading}
            variant="contained"
            onClick={() => onAccept(invoiceId, policyId)}
            sx={{
              py: 0.5,
              px: 3,
              m: 1,
              mr: 2,
              fontSize: 14,
              borderRadius: '2px',
            }}
          >
            {t('Waive')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BillingConfirmFeeWaiverDialog;
