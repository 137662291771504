import { Box, Button, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { getRiskMeterWithLocation } from 'api/services/Integrations/Corelogic';
import { addExposure, deleteExposure, updateExposure } from 'api/services/NewQuote';
import { validateSmartyAddress } from 'api/services/SmartyAddress';
import { threeExposureNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { LocationFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/LocationFieldParser';
import LocationDrawerValidationContent from 'components/QuotePolicyDetailEndorsement/Location/LocationDrawerValidationContent';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  disablePrimaryButtonStyle,
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  deleteFromQueryStrings,
  displayIntegrationErrorMessage,
  findAddressesDifference,
  formatLocationCase,
  formatStreetAbbr,
  handleBackendErrorsWithFormik,
  makeFieldsReadonly,
  parseLocation,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

export interface LocationDetailProps {
  isDrawerOpen: boolean;
  isAdd?: boolean;
}

const primaryCheckboxCode = 'loc_address_isprimary';
const mailingCheckboxCode = 'loc_address_ismailing';
const addressLine1Code = 'loc_address_line1';
const addressLine2Code = 'loc_address_line2';
const addressCityCode = 'loc_address_city';
const addressStateCode = 'loc_address_state';
const addressZipCode = 'loc_address_zip';
const addressFieldCodes = [
  addressLine1Code,
  addressLine2Code,
  addressCityCode,
  addressStateCode,
  addressZipCode,
];

const LocationEditDrawer: FC<LocationDetailProps> = ({ isDrawerOpen, isAdd = false }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const LOCATION_ID = url?.location;
  const {
    fields: fieldConfig,
    data: quoteDetail,
    getExposures,
    getExposure,
    exposureList,
  } = useQuoteDetail();

  const { products } = useConfig();

  const policyState = quoteDetail?.characteristics?.[0]?.data?.pol_state as string;

  const states = useMemo(
    () =>
      products?.loaded
        ? products?.data?.find((p) => p.code === quoteDetail?.product?.code)?.states ?? []
        : [],
    [products?.loaded, quoteDetail],
  );
  // get location exposures from provider
  const locationExposures = exposureList?.[`${threeExposureNames.LOCATION}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => locationExposures?.find((e) => e.locator === LOCATION_ID) ?? {},
    [locationExposures, LOCATION_ID],
  );

  const [state, setState] = useState<any>(
    isAdd
      ? { loc_address_isvalidated: 'No', loc_address_isprimary: 'No', loc_address_ismailing: 'No' }
      : { ...(activeExposure?.data ?? {}) },
  );

  const [fields, setFields] = useState<any[]>([]);
  const [isVerifiedOptionsVisible, setIsVerifiedOptionsVisible] = useState(false);
  const [addressComparisonResult, setAddressComparisonResult] = useState({});
  const [selectedAddressVariant, setSelectedAddressVariant] = useState('');
  const [integrationLoading, setIntegrationLoading] = useState(false);
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['location', 'addLocation'],
      }),
    });
  };

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const formik = useFormik<any>({
    initialValues: {
      loc_address_line1: '',
      ...state,
    },
    validationSchema: yup.object({ ...commonValidations }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });

  const validateAddress = async (addressInfo: any) => {
    setIntegrationLoading(true);
    try {
      const res = await validateSmartyAddress({
        street: addressInfo.loc_address_line1,
        street2: addressInfo.loc_address_line2,
        city: addressInfo.loc_address_city,
        zipcode: addressInfo.loc_address_zip,
        state: addressInfo.loc_address_state,
        secondary: addressInfo.loc_address_line2,
      });

      const validatedAddress = res?.metadata;

      const latitude = validatedAddress?.latitude;
      const longitude = validatedAddress?.longitude;

      if (!(latitude && longitude)) {
        displayToastMessage(
          'ERROR',
          t('Latitude or Longitude can not be found. Please review the address and try again.'),
        );
        return null;
      }

      setState((prevState) => ({
        ...prevState,
        ...addressInfo,
        loc_address_lat: latitude,
        loc_address_long: longitude,
      }));

      return { latitude, longitude, addressData: res };
    } catch (error) {
      displayIntegrationErrorMessage(error, t('An error occurred while validating the location.'));
      return null;
    } finally {
      setIntegrationLoading(false);
    }
  };

  const isDistanceToShoreEnabled = () => {
    const locDtcField = fields?.find((field) => field.code === 'loc_dtc');
    if (!locDtcField) return false;

    const { enabledStates } = locDtcField?.additional_data;
    const isEnabledStates = enabledStates?.includes(policyState);

    return isEnabledStates;
  };

  const fetchLongitudeAndLatitudeFromAddress = async (latitude, longitude) => {
    try {
      setIntegrationLoading(true);
      if (latitude && longitude) {
        const scores = await getRiskMeterWithLocation({
          latitude,
          longitude,
          fetchDistanceToShore: isDistanceToShoreEnabled(),
        });

        setState((prevState) => ({
          ...prevState,
          loc_address_isvalidated: 'Yes',
          loc_flood_zone: scores.floodZone ?? '',
          loc_violent_crime_score: scores.crimeScore ?? '',
          loc_property_crime_score: scores?.propertyCrimeScore ?? '',
          loc_dtc: scores?.distanceMile,
          loc_250ft_flood_zone: scores?.within250FtOfFloodZone,
        }));

        displayToastMessage('SUCCESS', t('The location has been validated.'));
      }
    } catch (error) {
      displayIntegrationErrorMessage(
        error,
        t('An error occurred while fetching the integration results.'),
      );
    } finally {
      setIntegrationLoading(false);
    }
  };

  useEffect(() => {
    let tmpFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (con) => con.code === threeExposureNames.LOCATION,
      )?.fields ?? [];

    // if this is the first location. Make it default primary and don't let user to uncheck it.
    if (isAdd && locationExposures?.length === 0) {
      tmpFields = makeFieldsReadonly(tmpFields, [primaryCheckboxCode, mailingCheckboxCode]);

      setState((prevState) => ({
        ...prevState,
        loc_address_isprimary: 'Yes',
        loc_address_ismailing: 'Yes',
      }));
    } else {
      // if this is an edit mode and selected location is primary make related fields readonly
      if (!isAdd && state.loc_address_isprimary === 'Yes') {
        tmpFields = makeFieldsReadonly(tmpFields, [primaryCheckboxCode, ...addressFieldCodes]);
      }

      // if this is an edit mode and selected location is mailing make field readonly
      if (!isAdd && state.loc_address_ismailing === 'Yes') {
        tmpFields = makeFieldsReadonly(tmpFields, [mailingCheckboxCode]);
      }

      // if this is an edit mode make state field readonly
      if (!isAdd && state?.loc_address_state) {
        tmpFields = makeFieldsReadonly(tmpFields, [addressStateCode]);

        // limit smarty search results to selected state
        tmpFields = tmpFields.map((f) => {
          if (f.code === addressLine1Code) {
            return {
              ...f,
              additional_data: {
                ...f.additional_data,
                includedStates: [state.loc_address_state],
              },
            };
          }
          return f;
        });
      }
    }

    tmpFields = tmpFields.map((f) => {
      const tmpField = { ...f };

      if (f.code === addressStateCode) {
        tmpField.choices = policyState ? [{ code: policyState, name: policyState }] : [];
      }

      return tmpField;
    });

    setFields(tmpFields);
  }, [fieldConfig, states, state?.loc_address_state]);

  const fetchExposureDetail = async () => {
    try {
      const detail = await getExposure(quoteDetail?.policy_locator!, LOCATION_ID as string);

      setState((prevState) => ({ ...prevState, ...(detail?.data ?? {}) }));

      // if selected location is primary make field readonly
      if (detail?.data?.loc_address_isprimary === 'Yes') {
        setFields((prevState) => makeFieldsReadonly(prevState, [primaryCheckboxCode]));
      }
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'location',
        }),
      );
      handleQuery();
    }
  };

  useEffect(() => {
    if (!isAdd && !isEmpty(quoteDetail)) {
      // if exposure was finded in the exposures list, do not fetch exposure detail from BE
      if (isEmpty(state)) {
        fetchExposureDetail();
      }
    }
  }, [isAdd, quoteDetail]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...(activeExposure?.data ?? {}) }));
  }, [activeExposure]);

  useEffect(() => {
    formik.setValues(state ?? {});
  }, [state]);

  useEffect(() => {
    if (fields.length > 0) {
      setFields((prevState) =>
        prevState.map((f) => {
          const field = f;

          if (f.additional_data?.integration) {
            field.additional_data!.loading = integrationLoading;
          }

          return field;
        }),
      );
    }
  }, [integrationLoading]);

  const getQuoteDetailAndExposures = async () => {
    await getExposures(
      quoteDetail?.locator!,
      { page_size: 10000 },
      threeExposureNames.LOCATION,
      true,
    );
  };

  const handleAdd = async () => {
    try {
      setLoading(true);

      await addExposure(quoteDetail?.policy_locator!, {
        name: 'location',
        data: state,
        quote_locator: quoteDetail?.locator,
      });

      displayToastMessage('SUCCESS', t('The location has been added.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the location.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_LOCATION_EXPOSURE',
        isOpen: false,
      });
      setLoading(true);

      await deleteExposure(quoteDetail?.policy_locator!, LOCATION_ID as string);
      displayToastMessage('SUCCESS', t('The location has been deleted.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the location.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const checkDeletable = () => {
    if (activeExposure?.data?.loc_address_isprimary === 'Yes') {
      setDialogOpen({
        dialog: 'PRIMARY_LOCATION_DELETE',
        isOpen: true,
      });
    } else if (activeExposure?.data?.loc_address_ismailing === 'Yes') {
      setDialogOpen({
        dialog: 'MAILING_LOCATION_DELETE',
        isOpen: true,
      });
    } else {
      setDialogOpen({
        dialog: 'DELETE_LOCATION_EXPOSURE',
        isOpen: true,
        onAccept: () => handleDelete(),
      });
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      await updateExposure(quoteDetail?.policy_locator!, LOCATION_ID as string, {
        data: state,
      });

      displayToastMessage('SUCCESS', t('The location has been updated.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the location.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleValidate = async (newState, isSmartySelect = false) => {
    const trimmedStateValues = { ...newState };

    addressFieldCodes.forEach((field) => {
      trimmedStateValues[field] = trimmedStateValues[field]?.trim();

      if (!isSmartySelect && trimmedStateValues[field]) {
        if (field === addressLine1Code || field === addressLine2Code) {
          trimmedStateValues[field] = formatStreetAbbr(trimmedStateValues[field]);
        }
      }
    });

    await formik.submitForm();
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      if (integrationLoading) {
        setDialogOpen({
          dialog: 'INTEGRATION_RUNNING',
          isOpen: true,
        });
      } else {
        const validatedAddress = await validateAddress(trimmedStateValues);
        if (validatedAddress) {
          await fetchLongitudeAndLatitudeFromAddress(
            validatedAddress.latitude,
            validatedAddress.longitude,
          );
        }

        // format address case
        const formattedAddress = formatLocationCase(
          trimmedStateValues,
          validatedAddress?.addressData,
        );
        setState((prevState) => ({
          ...prevState,
          ...formattedAddress,
        }));

        const comparisonResult = findAddressesDifference(
          formattedAddress,
          validatedAddress?.addressData,
        );

        setIsVerifiedOptionsVisible(comparisonResult.isDifferent);
        setAddressComparisonResult(comparisonResult);
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (integrationLoading) {
        setDialogOpen({
          dialog: 'INTEGRATION_RUNNING',
          isOpen: true,
        });
      } else if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const replaceBack = () => {
    setState((prevState) => ({
      ...prevState,
      loc_address_isvalidated: 'No',
    }));
    setIsVerifiedOptionsVisible(false);
    setAddressComparisonResult({});
  };

  const handleContinue = () => {
    const parseAdress = parseLocation(selectedAddressVariant);
    setState((prevState) => ({
      ...prevState,
      loc_address_line1: parseAdress.addressLine1,
      loc_address_line2: parseAdress.addressLine2,
      loc_address_city: parseAdress.city,
      loc_address_state: parseAdress.state,
      loc_address_zip: parseAdress.zip,
    }));
    setIsVerifiedOptionsVisible(false);
    setAddressComparisonResult({});
  };

  const showLoader = isAdd
    ? !fieldConfig?.exposure?.loaded
    : !fieldConfig?.exposure?.loaded || isEmpty(state);

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        width="476px"
        onClose={handleQuery}
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Location Details')}
          </Typography>
        }
        content={
          isVerifiedOptionsVisible ? (
            <LocationDrawerValidationContent
              selectedAddressVariant={selectedAddressVariant}
              setSelectedAddressVariant={setSelectedAddressVariant}
              addressComparisonResult={addressComparisonResult}
            />
          ) : (
            <LocationFieldParser
              formik={formik}
              fields={fields}
              loaded
              state={state}
              policyState={policyState}
              setState={setState}
              showLoader={showLoader ?? true}
              addressFieldCodes={addressFieldCodes}
              onSmartySelect={(newState) => handleValidate(newState, true)}
              // smartIncludedStates will be a fetch parameter to filter locations from the given states
              smartyIncludedStates={[policyState] as string[]}
            />
          )
        }
        footer={
          isVerifiedOptionsVisible ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={replaceBack} sx={[drawerFooterSecondaryButtonStyle]}>
                {t('Go Back')}
              </Button>
              {isEmpty(selectedAddressVariant) ? (
                <Tooltip title="Please select one of the address options above to continue.">
                  <span>
                    <Button
                      aria-readonly={isEmpty(selectedAddressVariant)}
                      disabled={isEmpty(selectedAddressVariant)}
                      onClick={handleContinue}
                      sx={[
                        drawerFooterPrimaryButtonStyle,
                        {
                          '&.Mui-disabled': {
                            ...disablePrimaryButtonStyle,
                          },
                        },
                      ]}
                    >
                      {t('Continue')}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  aria-readonly={isEmpty(selectedAddressVariant)}
                  disabled={isEmpty(selectedAddressVariant)}
                  onClick={handleContinue}
                  sx={[
                    drawerFooterPrimaryButtonStyle,
                    {
                      '&.Mui-disabled': {
                        ...disablePrimaryButtonStyle,
                      },
                    },
                  ]}
                >
                  {t('Continue')}
                </Button>
              )}
            </Box>
          ) : (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              {!isAdd ? (
                showLoader ? (
                  <Skeleton animation="wave" width="20%" height={32} />
                ) : (
                  <Button onClick={checkDeletable} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Delete')}
                  </Button>
                )
              ) : (
                <Box />
              )}

              <Stack direction="row" gap={1.5}>
                {showLoader ? (
                  <>
                    <Skeleton animation="wave" width="60px" height={32} />
                    <Skeleton animation="wave" width="60px" height={32} />
                  </>
                ) : (
                  <>
                    <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                      {t('Cancel')}
                    </Button>
                    <Button
                      data-test="save"
                      onClick={
                        state.loc_address_isvalidated === 'Yes'
                          ? handleSave
                          : () => handleValidate(state)
                      }
                      sx={[drawerFooterPrimaryButtonStyle]}
                    >
                      {state.loc_address_isvalidated === 'Yes' ? t('Save') : t('Validate')}
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
          )
        }
      />
    </>
  );
};

export default LocationEditDrawer;
