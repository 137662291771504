import ApiService from 'api/helpers/Sender';
import { User } from 'api/models/User/user.model';
import { UserUpdate } from 'api/models/User/userUpdate.model';
import { cacheIds, prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';

const API = new ApiService();

export const getCurrentUserInformation = async (): Promise<User> => {
  const { data }: { data: User } = await API.get(
    'users/me',
    {},
    { ...prepareCacheOptions({ id: cacheIds.userInfo }) },
  );

  return data;
};

export const patchCurrentUser = async (body: UserUpdate): Promise<User> => {
  const { data }: { data: User } = await API.patch('users/me', body, {
    cache: {
      update: {
        [`${cacheIds.userInfo}`]: 'delete',
      },
    },
  });

  return data;
};
