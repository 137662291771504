import { createContext } from 'react';

/// types
import { ITaskAssociatesContextType, ITaskAssociatesStore } from './types';

export const initialTaskAssociatesData: ITaskAssociatesStore = {
  data: [],
  loading: false,
  loaded: false,
  pagination: {
    ordering: '',
    page: 1,
    page_size: 5,
    search: '',
    total_count: 0,
  },
};

export const taskAssociatesContextDefault: ITaskAssociatesContextType = {
  ...initialTaskAssociatesData,
  resetTaskAssociatesState: () => {},
  fetch: () => new Promise(() => {}),
};

export const TaskAssociatesContext = createContext<ITaskAssociatesContextType>(null!);
