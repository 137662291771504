import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

// helpers
import {
  ISocotraScheduleModificationTable,
  transformScheduleModificationTable,
} from 'helpers/ScheduleModificationHelpers';

/// data
import { KeyValues } from 'api/models/KeyValues/keyValues.model';
import { getKeyValuesService } from 'api/services/KeyValues';
import { keyValueTables } from 'common/constants';
import { isEmpty } from 'lodash-es';
import { initialKeyValuesData, KeyValuesContext } from './KeyValuesProviderContext';

export const KeyValuesProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialKeyValuesData);
  const SCHED_MOD_TABLES = keyValueTables.SCHED_MOD_TABLE;

  const fetch = async (key: string, cache = true): Promise<KeyValues> => {
    if (isEmpty(state.data?.[`${key}`] ?? {})) {
      try {
        setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            [`${key}`]: {
              ...(prevState.data![`${key}`] ?? {}),
              loading: true,
            },
          },
        }));

        let res = await getKeyValuesService(key, { cache });

        // transform schedule modification table data to required portal format
        if (key === SCHED_MOD_TABLES) {
          res = transformScheduleModificationTable(res as ISocotraScheduleModificationTable);
        }

        setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            [`${key}`]: {
              loading: false,
              loaded: true,
              data: res,
            },
          },
        }));
        return res;
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            [`${key}`]: { loading: false, loaded: true },
          },
        }));
        throw error;
      }
    } else {
      return state.data?.[`${key}`]?.data ?? {};
    }
  };

  const resetKeyValuesState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialKeyValuesData,
    }));
  };

  return (
    <KeyValuesContext.Provider value={{ ...state, fetch, resetKeyValuesState }}>
      {children}
    </KeyValuesContext.Provider>
  );
};
