import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  submissionDetailInfoTabs,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle, tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  redirectToProducerTab,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import ClaimsHistoryDrawer from './Drawers/ClaimsHistoryDrawer/ClaimsHistoryDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const ClaimsHistory: FC = () => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { id } = useParams<{ id: string }>();
  const themeHook = useTheme();
  const { setLoading } = useLoader();
  const { data: user } = useUser();
  const {
    data: quoteDetail,
    groups,
    canEdit,
    updateQuoteProgress,
    progress: { data: progressData },
    exposureList,
  } = useQuoteDetail();
  const matches = useMediaQuery(themeHook.breakpoints.down('md'));

  const [isClaimsAbsenceConfirmed, setIsClaimsAbsenceConfirmed] = useState(false);

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const isProgressTab = canEdit && isProducer;
  const isClaimsHistoryTabNotCompleted =
    !progressData?.[submissionDetailInfoTabs.CLAIMS_HISTORY.code]?.is_completed;

  const isClaimsAbsenceAffirmedInProgress =
    progressData?.[submissionDetailInfoTabs.CLAIMS_HISTORY.code]?.is_claims_absence_affirmed ??
    false;

  const rows = useMemo(() => {
    const r =
      groups?.pol_claims?.data!.map((item) => ({
        locator: item.locator,
        date_of_loss: item.pol_claims_date,
        type: item.pol_claims_type,
        approximate_cost: item.pol_claims_amount,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const isClaimsAffirmationCheckboxVisible = isProgressTab && !rows.length;

  useEffect(() => {
    setIsClaimsAbsenceConfirmed(isClaimsAbsenceAffirmedInProgress);
  }, [progressData]);

  const columns: IColumns[] = [
    {
      name: 'date_of_loss',
      display_name: t('Date of Loss'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1,
      type: 'date',
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'type',
      display_name: t('Type'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 130,
    },

    {
      name: 'approximate_cost',
      display_name: t('Approximate Cost'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      minWidth: 110,
    },
  ];

  const ClaimsHistoryColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        if (fieldValue) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { claim: params.row.locator },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
            >
              {formatDateInTimeZone(fieldValue) || '-'}
            </Link>
          );
        } else {
          return (
            <Typography
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Typography>
          );
        }
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const getRowHeight = useCallback(() => (matches ? 'auto' : null), [matches]);

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addClaim: true },
      }),
    });
  };

  const handlePrevious = async () => {
    const vehicleExposuresAmount = exposureList?.[`${threeExposureNames.VEHICLE}`].data?.length;
    const isDriversTabHidden = vehicleExposuresAmount && vehicleExposuresAmount > 5;

    const updateProgress = isClaimsAbsenceAffirmedInProgress !== isClaimsAbsenceConfirmed;

    if (updateProgress) {
      try {
        setLoading(true);
        await updateQuoteProgress({
          locator: id,
          currentTab: submissionDetailInfoTabs.CLAIMS_HISTORY.code,
          isClaimsAbsenceConfirmed,
        });
      } finally {
        setLoading(false);
      }
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: {
          tab: isDriversTabHidden
            ? submissionDetailInfoTabs.CLAIMS_HISTORY.prevTabWithNoDrivers
            : submissionDetailInfoTabs.CLAIMS_HISTORY.prevTab,
        },
      }),
    });
  };

  const handleNext = async () => {
    const showAffirmationError = isClaimsAffirmationCheckboxVisible && !isClaimsAbsenceConfirmed;

    const updateProgress =
      isClaimsHistoryTabNotCompleted ||
      isClaimsAbsenceAffirmedInProgress !== isClaimsAbsenceConfirmed;

    if (showAffirmationError) {
      displayToastMessage(
        'ERROR',
        t('Please confirm you have no claims filed within the last 3 years or add at least one'),
      );
      return;
    }

    if (updateProgress) {
      try {
        setLoading(true);
        await updateQuoteProgress({
          locator: id,
          currentTab: submissionDetailInfoTabs.CLAIMS_HISTORY.code,
          isCompleted: true,
          isClaimsAbsenceConfirmed,
        });
      } finally {
        setLoading(false);
      }
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: {
          tab: redirectToProducerTab({
            currentTab: submissionDetailInfoTabs.CLAIMS_HISTORY,
            productType: quoteDetail?.product?.code,
          }),
        },
      }),
    });
  };

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <ClaimsHistoryDrawer isEdit={canEdit} />

      <Box>
        <Box sx={{ maxWidth: 600 }}>
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.CLAIMS_HISTORY.title()}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 14,
              mb: (theme) => theme.spacing(1.5),
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              minWidth: 100,
            }}
          >
            {quotePolicyEndorsementInfoTitlesDescriptions.CLAIMS_HISTORY.description()}
          </Typography>
          <Box sx={{ maxWidth: 600, mx: 0 }}>
            <DataTable
              getRowId={(row) => row.locator}
              rows={rows}
              loading={groups?.pol_claims?.loading}
              columns={ClaimsHistoryColumns}
              columnVisibilityModel={createColumnVisibilityModel(columns)}
              pageSize={defaultRowVirtualization}
              getRowHeight={getRowHeight}
              dynamicRowHeight={matches}
              hideFooterPagination={rows?.length! < defaultRowVirtualization}
            />

            {canEdit && (
              <Box>
                <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]}>
                  {t('Add Claim')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        {isClaimsAffirmationCheckboxVisible && (
          <FormControlLabel
            sx={{
              marginY: '26px',
            }}
            control={
              <Checkbox
                checked={isClaimsAbsenceConfirmed}
                id="isClaimsAbsenceConfirmed"
                onChange={(_, checked) => {
                  setIsClaimsAbsenceConfirmed(checked);
                }}
              />
            }
            label={t('I confirm that no claims occurred within the last 3 years.')}
          />
        )}
      </Box>

      {isProgressTab && (
        <NavigationButtons handlePrevious={handlePrevious} handleNext={handleNext} />
      )}
    </Stack>
  );
};

export default ClaimsHistory;
