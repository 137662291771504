/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { acceptReinstatePolicy, createReinstateRequest } from 'api/services/Policies';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { defaultDateFormat } from 'common/constants';
import DatePickerComponent from 'components/DatePickerComponent';
import { addDays, isBefore, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  calendarIconStyle,
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isArray, isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

const ReinstateDialog: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { setLoading } = useLoader();
  const { id } = useParams<{ id: string }>();
  const {
    data: policyDetail,
    loading,
    transactionHistory,
    getTransactionHistory,
  } = usePolicyDetail();
  const { convertZonedTimeToUtc } = useConfig();
  const isModalOpen = useMemo(
    () => LOCATION.search.includes('requestReinstatement'),
    [LOCATION.search],
  );

  const CANCELLATION_ID = useMemo(() => {
    const { requestReinstatement } = qs.parse(LOCATION.search);
    return isArray(requestReinstatement) ? requestReinstatement[0] : requestReinstatement;
  }, [LOCATION]);

  const cancellation = useMemo(
    () => transactionHistory?.data?.find((c) => c.locator === CANCELLATION_ID) ?? {},
    [transactionHistory, CANCELLATION_ID],
  );

  const tomorrow = convertZonedTimeToUtc(addDays(startOfDay(new Date()), 1));

  const getMinDate = () => {
    if (cancellation?.effective_date) {
      const effectiveDate = new Date(cancellation.effective_date);
      if (isBefore(effectiveDate, new Date())) {
        return tomorrow;
      }
      return effectiveDate;
    }
    return tomorrow;
  };

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['requestReinstatement'],
      }),
    });
  };

  const handleClose = () => {
    handleQuery();
  };

  const validationSchema = yup.object({
    expiration_date: yup
      .date()
      .nullable()
      .default(null)
      .typeError(t('Invalid date format.'))
      .required(t('This field may not be blank.'))
      .min(getMinDate(), t('Date should not be before reinstatement effective date or tomorrow.')),
  });

  const formik = useFormik({
    initialValues: {
      effective_date: cancellation?.effective_date
        ? new Date(cancellation.effective_date).toISOString()
        : null,
      expiration_date: null,
    },
    validationSchema,
    onSubmit: async () => {
      if (formik.isValid && formik.values.expiration_date && formik.values.effective_date) {
        try {
          setLoading(true);
          await acceptReinstatePolicy(
            id,
            CANCELLATION_ID!,
            cancellation.reinstatement?.locator ?? '',
            {
              effective_date: new Date(formik.values.effective_date).toISOString(),
              expiration_date: new Date(formik.values.expiration_date).toISOString(),
            },
          );
          getTransactionHistory(id);
          handleClose();
        } catch (error) {
          displayBackendErrorMessage(error, t('An error occurred while reinstating cancellation.'));
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const createReinstatement = async () => {
    try {
      setLoading(true);
      const payload = {
        effective_date: new Date(cancellation?.effective_date ?? '').toISOString(),
      };
      await createReinstateRequest(id, CANCELLATION_ID!, payload);
      await getTransactionHistory(id);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while reinstating cancellation.'));
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!formik.values.effective_date && cancellation?.effective_date) {
      formik.setFieldValue('effective_date', cancellation.effective_date);
    }
    if ((transactionHistory?.loaded && isEmpty(cancellation)) || !CANCELLATION_ID) {
      displayToastMessage('ERROR', t('Cancellation not found.'));
      handleClose();
    }
    if (!isEmpty(cancellation)) {
      const isReinstatementExpired = cancellation?.reinstatement?.state === 'expired';

      // create draft reinstatement if cancellation has no reinstatement or cancellation has expired reinstatement
      if (isEmpty(cancellation.reinstatement) || isReinstatementExpired) {
        createReinstatement();
      }
    }
  }, [transactionHistory?.loaded, cancellation, CANCELLATION_ID]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 0,
          px: 7.5,
          py: 7.5,
          minWidth: { md: '42%', lg: '616px' },
          maxWidth: '616px',
        },
      }}
    >
      <ScrollToFormikError formik={formik} />
      <form
        id="createReinstateForm"
        name="createReinstateForm"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box>
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, mb: 0.5, display: 'flex', alignItems: 'flex-start' }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {t('Reinstate Policy')}
            </Typography>
          </DialogTitle>
          {loading ? (
            <Skeleton animation="wave" width="100%" height={20} />
          ) : (
            <Typography>
              {t('Policy No.: ')}
              {policyDetail?.three_policy_number}
            </Typography>
          )}
          <DialogContent sx={{ p: 0, m: 0 }}>
            <Grid container gap={2} columnSpacing={2} mt={2} sx={{ display: 'grid' }}>
              <Grid item xs={12}>
                <FormControl fullWidth required variant="standard" sx={{ ...calendarIconStyle }}>
                  {!transactionHistory?.loaded ? (
                    <Skeleton animation="wave" width="100%" height={37} />
                  ) : (
                    <DatePickerComponent
                      disabled
                      format={defaultDateFormat}
                      slots={{
                        openPickerIcon: () => (
                          <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                        ),
                      }}
                      value={formik.values.effective_date}
                      onChange={(newValue) => {
                        formik.setFieldValue('effective_date', newValue!);
                      }}
                      shouldDisableDate={(date) => {
                        let disable = false;

                        if (
                          isBefore(
                            startOfDay(new Date(date!)),
                            startOfDay(new Date(cancellation?.effective_date ?? tomorrow)),
                          )
                        ) {
                          disable = true;
                        }

                        return disable;
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          autoComplete: 'off',
                          required: true,
                          label: t('Reinstatement Effective Date'),
                          inputProps: {
                            autoComplete: 'new-password',
                          },
                          name: 'effective_date',
                          error:
                            formik.touched.effective_date && Boolean(formik.errors.effective_date),
                          helperText: formik.touched.effective_date && formik.errors.effective_date,
                        },
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required variant="standard" sx={{ ...calendarIconStyle }}>
                  {!transactionHistory?.loaded ? (
                    <Skeleton animation="wave" width="100%" height={37} />
                  ) : (
                    <DatePickerComponent
                      format={defaultDateFormat}
                      slots={{
                        openPickerIcon: () => (
                          <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                        ),
                      }}
                      value={formik.values.expiration_date}
                      onChange={(newValue) => {
                        formik.setFieldValue('expiration_date', newValue);
                      }}
                      shouldDisableDate={(date) => {
                        let disable = false;

                        if (isBefore(new Date(date!), startOfDay(getMinDate()))) {
                          disable = true;
                        }

                        return disable;
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          autoComplete: 'off',
                          required: true,
                          label: t('Reinstatement Deadline'),
                          inputProps: {
                            autoComplete: 'new-password',
                          },
                          name: 'expiration_date',
                          error:
                            formik.touched.expiration_date &&
                            Boolean(formik.errors.expiration_date),
                          helperText:
                            formik.touched.effective_date && formik.errors.expiration_date,
                        },
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ padding: (theme) => theme.spacing(2, 0, 0, 1), m: 0 }}>
            <Button onClick={handleClose} sx={{ ...secondaryButtonStyle }}>
              {t('Close')}
            </Button>

            <Button
              disabled={transactionHistory?.loading}
              type="submit"
              form="createReinstateForm"
              sx={{ ...primaryButtonStyle, ml: '0 !important' }}
            >
              {t('Accept')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

export default ReinstateDialog;
