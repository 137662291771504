import React, { useState } from 'react';

import { getPaymentLink } from 'api/services/Payments';
import Error from 'components/Payments/Error';
import useLoader from 'hooks/useLoader';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

interface ErrorState {
  message?: string;
}

/**
 * Pay Component
 *
 * This component handles the old payment links, where it fetches the payment link
 * and redirects the user to the appropriate payment page(PayV2).
 *
 * Features:
 * - Fetches the payment link based on a query parameter.
 * - Redirects the user to the payment page.
 * - Handles loading state and error handling.
 *
 * @remarks
 * This component is used to handle old payment links. It fetches the new payment link and then
 * redirects the user to the PayV2 component for the main payment process.
 */
const Pay = () => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { key } = qs.parse(LOCATION.search);

  const { setLoading } = useLoader();
  const [error, setError] = useState<ErrorState | null>(null);

  const fetchPaymentIntent = async () => {
    if (!key) {
      setError({
        message:
          'Your payment link is not valid. Please try again later or contact your support team.',
      });
      setLoading(false);
    } else {
      setLoading(true);

      try {
        const paymentKey = key as string;
        const res = await getPaymentLink(paymentKey);
        const url = res.payment_link;
        const paymentURL = `/${url.split('/').slice(-3).join('/')}`;
        // navigate to "/payments/:policyLocator/:paymentIntentId"
        HISTORY.push(paymentURL);
      } catch (_e) {
        setError({
          message: 'Payment link is not valid.',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    // Fetch PaymentIntent as soon as the page loads
    fetchPaymentIntent();
  }, []);

  return <>{error ? <Error error={error} /> : <div />}</>;
};

export default Pay;
