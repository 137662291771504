import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  propertyExposureRelatedKeyValues,
  propertyExposureRelatedKeyValuesMap,
  propertyExposureScheduleBuildingFields,
  propertyExposureScheduleBuildingOccupancies,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { PropertyDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/PropertyDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { changeFieldsHiddenStatus, deleteFromQueryStrings, formatLocation } from 'helpers/Utils';
import useKeyValues from 'hooks/useKeyValues';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PolicyLocationSelect from '../../../Inputs/LocationSelectInput';

export interface PropertyDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const PropertyDetailDrawer: FC<PropertyDetailProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const { data: user } = useUser();

  const PROPERTY_ID = url.property;

  const { data: policyDetail, fields: fieldConfig, exposureList, getExposure } = usePolicyDetail();
  const [fields, setFields] = useState<any[]>([]);

  const { data: keyValueStore } = useKeyValues();

  const CLASS_CODES =
    keyValueStore?.[`${propertyExposureRelatedKeyValues.CLASS_CODES}`]?.data?.value ?? [];

  const policyDetailData = policyDetail?.characteristics?.data ?? {};

  // get location exposures from provider
  const locationExposures = useMemo(
    () =>
      exposureList?.[`${threeExposureNames.LOCATION}`]?.data?.map((ex) => ({
        ...ex,
        formattedAddress: formatLocation({
          addressLine1: ex.data?.loc_address_line1,
          addressLine2: ex.data?.loc_address_line2,
          city: ex.data?.loc_address_city,
          state: ex.data?.loc_address_state,
          zip: ex.data?.loc_address_zip,
        }).storing,
      })) ?? [],
    [exposureList],
  );

  // get property exposures from provider
  const propertyExposures = exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => propertyExposures?.find((e) => e.locator === PROPERTY_ID) ?? {},
    [propertyExposures, PROPERTY_ID],
  );

  const { pol_tax_cf_default, pol_tax_cf_rule, pol_tax_bm_class, pol_bm_class_rule, pol_state } =
    policyDetailData;

  const [state, setState] = useState<any>(
    activeExposure?.data
      ? {
          ...activeExposure?.data,
          bdg_class_code: pol_tax_cf_rule ?? pol_tax_cf_default,
          bdg_bm_occupancy: pol_bm_class_rule ?? pol_tax_bm_class,
        }
      : {},
  );

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({ locationSearch: LOCATION.search, omitKeys: ['property'] }),
    });
  };

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {},
  });

  const fetchExposureDetail = async () => {
    const temp_bdg_class_descr = state.bdg_class_code_user ?? pol_tax_cf_rule ?? pol_tax_cf_default;
    try {
      const detail = await getExposure(policyDetail?.locator!, PROPERTY_ID as string);

      setState((prevState) => ({
        ...prevState,
        ...{ ...(detail?.data ?? {}), bdg_class_descr: CLASS_CODES?.[`${temp_bdg_class_descr}`] },
      }));
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'property',
        }),
      );
      handleQuery();
    }
  };

  useEffect(() => {
    if (!isEmpty(policyDetail)) {
      // if exposure was finded in the exposures list, do not fetch exposure detail from BE
      if (isEmpty(state)) {
        fetchExposureDetail();
      }
    }
  }, [policyDetail]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...{
        ...(activeExposure?.data ?? {}),
        bdg_class_code: pol_tax_cf_rule ?? pol_tax_cf_default,
        bdg_bm_occupancy: pol_bm_class_rule ?? pol_tax_bm_class,
      },
    }));
  }, [activeExposure]);

  useEffect(() => {
    formik.setValues(state ?? {});
  }, [state]);

  useEffect(() => {
    const temp_bdg_class_descr = state.bdg_class_code_user ?? pol_tax_cf_rule ?? pol_tax_cf_default;

    setState((prevState) => ({
      ...prevState,
      bdg_class_descr: CLASS_CODES?.[`${temp_bdg_class_descr}`],
    }));
  }, [state.bdg_class_code_user]);

  useEffect(() => {
    let tmpFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (con) => con.code === threeExposureNames.BUILDING,
      )?.fields ?? [];

    if (user?.role?.code === userRoles.UNDERWRITER.code) {
      tmpFields = tmpFields.map((f) => {
        const tmpField = { ...f };

        if (tmpField.code === 'bdg_class_code') {
          tmpField.type = 'select';
          tmpField.choices = Object.keys(CLASS_CODES)
            .sort()
            .map((code) => ({ code, name: code }));
          tmpField.additional_data = {
            emitIsChangedEvent: true,
            showOnlyForRoles: ['underwriter'],
          };
        }

        return tmpField;
      });
    }

    if (
      !propertyExposureScheduleBuildingOccupancies.includes(activeExposure?.data?.bdg_occupancy)
    ) {
      setFields(changeFieldsHiddenStatus(tmpFields, propertyExposureScheduleBuildingFields, true));
    } else {
      setFields(changeFieldsHiddenStatus(tmpFields, propertyExposureScheduleBuildingFields, false));
    }
  }, [fieldConfig, activeExposure]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      fieldConfig?.exposure?.loading ||
      exposureList?.[`${threeExposureNames.LOCATION}`].loading ||
      exposureList?.[`${threeExposureNames.BUILDING}`].loading ||
      propertyExposureRelatedKeyValuesMap.some(
        (map) => keyValueStore?.[`${map}`]?.loading ?? false,
      );

    return defaultLoading || isEmpty(activeExposure);
  }, [activeExposure, fieldConfig, exposureList, keyValueStore]);

  const updateLocationRelatedFields = (address: string) => {
    const foundLocation = locationExposures.find((ex) => ex.formattedAddress === address);

    if (foundLocation) {
      setState((prevState) => ({
        ...prevState,
        loc_flood_zone: foundLocation?.data?.loc_flood_zone,
        loc_violent_crime_score: foundLocation?.data?.loc_violent_crime_score,
        loc_property_crime_score: foundLocation?.data?.loc_property_crime_score,
        loc_dtc: foundLocation?.data?.loc_dtc,
        loc_250ft_flood_zone: foundLocation?.data?.loc_250ft_flood_zone,
      }));
    }
  };

  useEffect(() => {
    if (!showLoader) {
      updateLocationRelatedFields(state.bdg_location);
    }
  }, [showLoader]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{ mb: 2 }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Property Details')}
        </Typography>
      }
      content={
        <Stack gap={2} sx={{ mb: 3, pt: 1 }}>
          <PropertyDrawerFieldParser
            formik={formik}
            state={state}
            polState={pol_state}
            fields={fields}
            setState={setState}
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            showLoader={showLoader}
            isReadOnly
            LocationSelect={PolicyLocationSelect}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default PropertyDetailDrawer;
