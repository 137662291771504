/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { updateGracePeriod } from 'api/services/Policies';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { defaultDateFormat } from 'common/constants';
import DatePickerComponent from 'components/DatePickerComponent';
import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  calendarIconStyle,
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isArray } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

const ReviseCancellationDialog = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const themeHook = useTheme();
  const { setLoading } = useLoader();
  const { id } = useParams<{ id: string }>();
  const { transactionHistory, gracePeriods, getGracePeriods } = usePolicyDetail();
  const [minSelectableDate, setMinSelectableDate] = useState(new Date());
  const [maxSelectableDate, setMaxSelectableDate] = useState(new Date());
  const loading = transactionHistory?.loading;

  const isModalOpen = useMemo(
    () => LOCATION.search.includes('reviseCancellation'),
    [LOCATION.search],
  );

  const CANCELLATION_ID = useMemo(() => {
    const { reviseCancellation } = qs.parse(LOCATION.search);
    return isArray(reviseCancellation) ? reviseCancellation[0] : reviseCancellation;
  }, [LOCATION]);

  const cancellation = useMemo(
    () => transactionHistory?.data?.find((c) => c.locator === CANCELLATION_ID) ?? {},
    [transactionHistory, CANCELLATION_ID],
  );

  const cancellationGracePeriod = useMemo(
    () => gracePeriods?.data?.find((gracePeriod) => CANCELLATION_ID === gracePeriod.locator),
    [gracePeriods, CANCELLATION_ID],
  );

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['reviseCancellation'],
      }),
    });
  };

  const handleClose = () => {
    handleQuery();
  };

  const validationSchema = yup.object({
    revised_effective_timestamp: yup
      .date()
      .nullable()
      .default(null)
      .typeError(t('Invalid date format.'))
      .required(t('This field may not be blank.'))
      .min(minSelectableDate, t('Grace period days cannot be extended less than 1 day.'))
      .max(
        maxSelectableDate,
        t('Grace period days cannot be extended more than 31 days at a time.'),
      ),
  });

  const formik = useFormik({
    initialValues: {
      effective_timestamp: '',
      revised_effective_timestamp: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          const payload = {
            cancel_effective_at: new Date(values.revised_effective_timestamp).toISOString(),
          };
          await updateGracePeriod(id, CANCELLATION_ID!, payload);
          getGracePeriods(id);
          setTimeout(() => {
            handleClose();
            setTimeout(() => {
              displayToastMessage(
                'SUCCESS',
                t('Policy Cancellation effective date has been revised.'),
              );
            }, themeHook.transitions.duration.leavingScreen - 100);
          }, 100);
        } catch (error) {
          displayBackendErrorMessage(
            error,
            t('An error occurred while Revise Cancellation effective date.'),
          );
        } finally {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (cancellationGracePeriod?.cancel_effective_at) {
      setMinSelectableDate(addDays(new Date(cancellationGracePeriod?.cancel_effective_at), 1));
      setMaxSelectableDate(addDays(new Date(cancellationGracePeriod?.cancel_effective_at), 31));
      formik.setFieldValue('effective_timestamp', cancellationGracePeriod?.cancel_effective_at);
      formik.setFieldValue(
        'revised_effective_timestamp',
        addDays(new Date(cancellationGracePeriod?.cancel_effective_at), 1),
      );
    } else if (cancellation?.effective_date) {
      setMinSelectableDate(addDays(new Date(cancellation?.effective_date), 1));
      setMaxSelectableDate(addDays(new Date(cancellation?.effective_date), 31));
      formik.setFieldValue('effective_timestamp', cancellation?.effective_date);
      formik.setFieldValue(
        'revised_effective_timestamp',
        addDays(new Date(cancellation?.effective_date), 1),
      );
    }
  }, [cancellation?.effective_date, cancellationGracePeriod]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 0,
          px: 7.5,
          py: 7.5,
          minWidth: { md: '42%', lg: '616px' },
          maxWidth: '616px',
        },
      }}
    >
      <ScrollToFormikError formik={formik} />
      <form
        id="reviseCancellationForm"
        name="reviseCancellationForm"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box>
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, display: 'flex', alignItems: 'flex-start' }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {t('Revise Cancellation Effective Date')}
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ p: 0, m: 0 }}>
            <Grid container gap={2} columnSpacing={2} mt={2} sx={{ display: 'grid' }}>
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton animation="wave" width="100%" height={35} />
                ) : (
                  <FormControl fullWidth required variant="standard" sx={{ ...calendarIconStyle }}>
                    <DatePickerComponent
                      readOnly
                      disabled
                      format={defaultDateFormat}
                      slots={{
                        openPickerIcon: () => (
                          <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                        ),
                      }}
                      value={formik.values.effective_timestamp}
                      onChange={(newValue) => {
                        formik.setFieldValue('effective_timestamp', newValue!);
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          autoComplete: 'off',
                          label: t('Current Cancellation Effective Date'),
                          inputProps: {
                            autoComplete: 'new-password',
                          },
                          name: 'effective_timestamp',
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton animation="wave" width="100%" height={35} />
                ) : (
                  <FormControl fullWidth required variant="standard" sx={{ ...calendarIconStyle }}>
                    <DatePickerComponent
                      format={defaultDateFormat}
                      slots={{
                        openPickerIcon: () => (
                          <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                        ),
                      }}
                      value={formik.values.revised_effective_timestamp}
                      onChange={(newValue) => {
                        formik.setFieldValue('revised_effective_timestamp', newValue!);
                      }}
                      minDate={minSelectableDate}
                      maxDate={maxSelectableDate}
                      slotProps={{
                        textField: {
                          size: 'small',
                          autoComplete: 'off',
                          required: true,
                          label: t('Revised Cancellation Effective Date'),
                          inputProps: {
                            autoComplete: 'new-password',
                          },
                          name: 'revised_effective_timestamp',
                          error:
                            formik.touched.revised_effective_timestamp &&
                            Boolean(formik.errors.revised_effective_timestamp),
                          helperText:
                            formik.touched.revised_effective_timestamp &&
                            formik.errors.revised_effective_timestamp,
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ padding: (theme) => theme.spacing(2.5, 0, 0, 1), m: 0 }}>
            <Button onClick={handleClose} sx={{ ...secondaryButtonStyle }}>
              {t('Cancel')}
            </Button>

            <Button
              disabled={loading}
              type="submit"
              form="reviseCancellationForm"
              sx={{ ...primaryButtonStyle, ml: '0 !important' }}
            >
              {t('Confirm')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

export default ReviseCancellationDialog;
