import { useFrontChatBoot } from '@frontapp/front-chat-sdk/lib/hooks/use-front-chat-boot';
import type { UnbindHandler } from '@frontapp/front-chat-sdk/lib/types';
import { Box, Button, Stack, useTheme } from '@mui/material';
import Chat from 'assets/images/Chat.svg';
import useUser from 'hooks/useUser';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

const FrontChat = () => {
  const theme = useTheme();
  const { data: user } = useUser();
  const [isButtonHidden, setIsButtonHidden] = useState(false);
  const { frontChat, initialize, isInitialized } = useFrontChatBoot();

  const CHAT_ID = import.meta.env.REACT_APP_FRONT_CHAT_ID;
  const chatCanLoad = CHAT_ID && user?.front_chat_hash;

  useEffect(() => {
    // cleanup needs to be in a separate effect handler, was prematurely shutting down the chat
    return () => {
      if (isInitialized) {
        frontChat?.('shutdown');
      }
    };
  }, [isInitialized]);

  useEffect(() => {
    if (CHAT_ID) {
      if (user && user.front_chat_hash) {
        initialize?.({
          // onInitCompleted gets overridden by the SDK itself, so we can't rely on it here
          chatId: CHAT_ID,
          useDefaultLauncher: false,
          shouldExpandOnShowWindow: false,
          // user details
          userId: String(user.id),
          name: String(user.full_name),
          contact: {
            email: String(user.email),
          },
          userHash: String(user.front_chat_hash), // must be an HMAC hash of the user's primary key
        });
      } else if (isInitialized) {
        // if we don't have a valid user, shut down the chat widget
        frontChat?.('shutdown', { clearSession: true });
      }
    }
  }, [initialize, isInitialized, user]);

  useEffect(() => {
    let unbind: UnbindHandler | undefined;

    if (isInitialized) {
      unbind = frontChat?.('onWindowVisibilityChanged', ({ is_window_visible }) =>
        setIsButtonHidden(is_window_visible),
      );
    }

    return () => {
      unbind?.();
    };
  }, [frontChat, isInitialized]);

  const onClick = () => frontChat?.('show');

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer - 1,
      }}
    >
      {chatCanLoad && (
        <Button
          color="secondary"
          variant="contained"
          onClick={onClick}
          sx={{
            display: isButtonHidden ? 'none' : 'flex',
            fontSize: theme.typography.subtitle2,
            fontWeight: theme.typography.fontWeightBold,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Box sx={{ height: 16, marginTop: '2px' }}>
              <ReactSVG src={Chat} />
            </Box>
            Chat
          </Stack>
        </Button>
      )}
    </Box>
  );
};

export default FrontChat;
