import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { initialLoaderData, LoaderContext } from './LoaderProviderContext';

export const LoaderProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialLoaderData);

  const resetLoaderState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialLoaderData,
    }));
  };

  const setLoading = (isLoading: boolean): void => {
    setState((prevState) => ({
      ...prevState,
      loading: isLoading,
    }));
  };

  return (
    <LoaderContext.Provider value={{ ...state, setLoading, resetLoaderState }}>
      {children}
    </LoaderContext.Provider>
  );
};
