import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
} from 'common/constants';
import DataTable from 'components/DataTable';
import { tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ClaimsHistoryDrawer from './Drawers/ClaimsHistoryDrawer/ClaimsHistoryDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const ClaimsHistory: FC = () => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const LOCATION = useLocation();
  const themeHook = useTheme();
  const { groups } = usePolicyDetail();
  const matches = useMediaQuery(themeHook.breakpoints.down('md'));

  const rows = useMemo(() => {
    const r =
      groups?.pol_claims?.data!.map((item) => ({
        locator: item.locator,
        date_of_loss: item.pol_claims_date,
        type: item.pol_claims_type,
        approximate_cost: item.pol_claims_amount,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const columns: IColumns[] = [
    {
      name: 'date_of_loss',
      display_name: t('Date of Loss'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1,
      type: 'date',
      minWidth: themeHook.dateColumnWidth,
    },
    {
      name: 'type',
      display_name: t('Type'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 130,
    },

    {
      name: 'approximate_cost',
      display_name: t('Approximate Cost'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      minWidth: 110,
    },
  ];

  const ClaimsHistoryColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        if (fieldValue) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries: { claim: params.row.locator },
              })}`}
              underline="hover"
              sx={[
                truncatedTextStyle,
                {
                  color: (theme) => theme.customColors.table.link,
                },
              ]}
            >
              {formatDateInTimeZone(fieldValue) || '-'}
            </Link>
          );
        } else {
          return (
            <Typography
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Typography>
          );
        }
      } else {
        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const getRowHeight = useCallback(() => (matches ? 'auto' : null), [matches]);

  return (
    <>
      <ClaimsHistoryDrawer />

      <Box sx={{ maxWidth: 600 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.CLAIMS_HISTORY.title()}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 14,
            mb: (theme) => theme.spacing(1.5),
            lineHeight: (theme) => theme.typography.body1.lineHeight,
            minWidth: 100,
          }}
        >
          {quotePolicyEndorsementInfoTitlesDescriptions.CLAIMS_HISTORY.description()}
        </Typography>
        <Box sx={{ maxWidth: 600, mx: 0 }}>
          <DataTable
            getRowId={(row) => row.locator}
            rows={rows}
            loading={groups?.pol_claims?.loading}
            columns={ClaimsHistoryColumns}
            columnVisibilityModel={createColumnVisibilityModel(columns)}
            pageSize={defaultRowVirtualization}
            getRowHeight={getRowHeight}
            dynamicRowHeight={matches}
            hideFooterPagination={rows?.length! < defaultRowVirtualization}
            focusedRowId={-1}
          />
        </Box>
      </Box>
    </>
  );
};

export default ClaimsHistory;
