/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { Pagination } from 'types/Pagination';

/// types
import {
  IQuoteDetailQueryParams,
  IQuoteExposuresProps,
  IQuoteInvoicesProps,
  IUpdateQuoteProgress,
  TGroups,
} from 'providers/QuoteDetailProvider/types';
import { ITaskPagination } from 'providers/TasksProvider/types';
import { IContextProps } from 'providers/types';

/// data
import { Params } from 'api/helpers/Sender';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { IUpdateQuote } from 'api/models/NewQuote/updateQuote';
import { IPolicyholder } from 'api/models/Policyholder/policyholder.model';
import { QuoteDetailResponse } from 'api/models/Quote/quoteDetailResponse.model';
import { QuoteExposureDetail } from 'api/models/Quote/quoteExposureDetail.model';
import { ProgressData } from 'api/models/Quote/quoteProgress.model';
import { getDocumentTypesService } from 'api/services/Documents';
import {
  getWorkFlowFormSetsFormsWithFormSetType,
  getWorkFlowPerilFormsWithFormCode,
  updateQuoteInfo,
} from 'api/services/NewQuote';
import {
  acceptQuoteRequest,
  deleteDocumentService,
  discardQuoteRequest,
  getQuoteDetail,
  getQuoteExposureWithLocator,
  getQuotePriceRequest,
  getQuoteProgressRequest,
  getQuotesDcoumentsWithLocator,
  getQuotesExposuresWithLocator,
  getQuotesInvoicesWithLocator,
  getQuotesTasksWithLocator,
  invalidateQuoteRequest,
  issueQuoteRequest,
  lockQuoteRequest,
  referQuoteRequest,
  sendBackQuoteRequest,
  setQuoteProgressRequest,
  updateQuoteProducerRequest,
} from 'api/services/Quote';
import { getRuleEngineDecisions, updateRuleEngineDecision } from 'api/services/RuleEngineDecisions';
import { AxiosError } from 'axios';
import {
  DOCUMENT_TYPES_FROM,
  quoteStatuses,
  submissionDetailInfoTabs,
  userRoles,
  uwQuestionAliases,
} from 'common/constants';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { groupByExposureAndSumPremiums } from 'helpers/PricingBreakdownHelpers';
import { fieldHasValue, getGroupTypeFields } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useUser from 'hooks/useUser/useUser';
import { groupBy, isEmpty, pick, pickBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { IAxiosError } from 'types/ErrorResponseTypes';
import { initialQuoteDetailData, QuoteDetailContext } from './QuoteDetailProviderContext';

const checkCanEdit = (
  quoteState?: string,
  userRole?: string,
  hasAcceptedRelatedQuote?: boolean,
) => {
  let canEdit = false;

  if (hasAcceptedRelatedQuote) {
    canEdit = false;
  } else if (quoteStatuses.DRAFT === quoteState) {
    canEdit = true;
  } else if (userRole === userRoles.UNDERWRITER.code && quoteStatuses.REFERRED === quoteState) {
    canEdit = true;
  } else if (quoteStatuses.DECLINED === quoteState) {
    canEdit = true;
  }

  return canEdit;
};

const checkIsAllowedTopBarActions = (quoteState?: string, hasAcceptedRelatedQuote?: boolean) => {
  let canEdit = false;

  if (!hasAcceptedRelatedQuote && quoteStatuses.ACCEPTED !== quoteState) {
    canEdit = true;
  }

  return canEdit;
};

export const QuoteDetailProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialQuoteDetailData);
  const { t } = useTranslation();
  const { data: user } = useUser();
  const { addYearsDstSafe } = useConfig();

  useEffect(() => {
    if (!isEmpty(state.data)) {
      setState((prevState) => ({
        ...prevState,
        isPreQual: state?.data?.characteristics?.[0]?.data?.pol_isprequal === 'Yes',
      }));
    }
  }, [JSON.stringify(state?.data)]);

  useEffect(() => {
    if (user?.role?.code) {
      setState((prevState) => ({
        ...prevState,
        canEdit: checkCanEdit(
          prevState?.data?.state?.code,
          user?.role?.code,
          state.data?.has_accepted_related_quote,
        ),
        isAllowedTopBarActions: checkIsAllowedTopBarActions(
          prevState?.data?.state?.code,
          state.data?.has_accepted_related_quote,
        ),
      }));
    }
  }, [user?.role?.code, JSON.stringify(state?.data?.state)]);

  const fetch = async (
    locator: string,
    query: IQuoteDetailQueryParams = {},
    triggerLoading = true,
  ): Promise<QuoteDetailResponse> => {
    try {
      if (triggerLoading) {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
      }

      const res = await getQuoteDetail(locator, query as Params);

      const groupFields = getGroupTypeFields(res?.characteristics?.[0]?.data);

      const characteristic = res?.characteristics?.[0] ?? {};

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
        canEdit: checkCanEdit(res?.state?.code, user?.role?.code, res.has_accepted_related_quote),
        isAllowedTopBarActions: checkIsAllowedTopBarActions(
          res?.state?.code,
          res.has_accepted_related_quote,
        ),
        groups: {
          ...prevState.groups,
          ...(Object.entries(prevState.groups ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupFields?.[`${key}`] ?? [],
              },
            }),
            {},
          ) as TGroups),
        },
        underwritingQuestionsState: {
          ...prevState.underwritingQuestionsState,
          ...{
            ...(characteristic?.data ?? {}),
            product: res?.product?.code,
            effective_date: fieldHasValue(characteristic.started_at)
              ? new Date(characteristic.started_at as string)
              : undefined,

            // map answered questions to state
            ...((characteristic?.data?.underwriting_question as any[])?.reduce(
              (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
              {},
            ) ?? {}),
          },
        },
      }));

      return res;
    } catch (error) {
      const e = error as AxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e.response?.status === 404) {
        displayBackendErrorMessage(e, t('Not found.'));
      }
      throw e;
    }
  };

  const updateQuoteDetail = async (
    policyLocator: string,
    quoteLocator: string,
    body: IUpdateQuote,
    query?: Params,
    hasBusinessNameChanged?: { changed: boolean; newName: any; policyholder: IPolicyholder },
  ): Promise<QuoteDetailResponse> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await updateQuoteInfo(
        policyLocator,
        quoteLocator,
        body,
        query,
        hasBusinessNameChanged,
      );

      const groupFields = getGroupTypeFields(res?.characteristics?.[0]?.data);

      const characteristic = res?.characteristics?.[0] ?? {};

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
        canEdit: checkCanEdit(res?.state?.code, user?.role?.code, res.has_accepted_related_quote),
        groups: {
          ...prevState.groups,
          ...(Object.entries(prevState.groups ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupFields?.[`${key}`] ?? [],
              },
            }),
            {},
          ) as TGroups),
        },
        underwritingQuestionsState: {
          ...prevState.underwritingQuestionsState,
          ...{
            ...(characteristic?.data ?? {}),
            product: res?.product?.code,
            effective_date: fieldHasValue(characteristic.started_at)
              ? new Date(characteristic.started_at as string)
              : undefined,

            // map answered questions to state
            ...((characteristic?.data?.underwriting_question as any[])?.reduce(
              (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
              {},
            ) ?? {}),
          },
        },
      }));

      return res;
    } catch (error) {
      const e = error as AxiosError;
      setState({ ...state, loading: false, loaded: true });
      throw e;
    }
  };

  const lockQuote = async (locator: string, query?: IQuoteDetailQueryParams) => {
    try {
      await lockQuoteRequest(locator);
      await fetch(locator, query);
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const referQuote = async (locator: string, query?: IQuoteDetailQueryParams) => {
    try {
      await referQuoteRequest(locator);
      await fetch(locator, query, false);
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const acceptQuote = async (locator: string, query?: IQuoteDetailQueryParams) => {
    try {
      await acceptQuoteRequest(locator);
      await fetch(locator, query);
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const invalidateQuote = async (locator: string, query?: IQuoteDetailQueryParams) => {
    try {
      await invalidateQuoteRequest(locator);
      await fetch(locator, query);
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const sendBackQuote = async (locator: string, query?: IQuoteDetailQueryParams) => {
    try {
      await sendBackQuoteRequest(locator);
      await fetch(locator, query);
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const getExposures = async (
    id: string,
    query: Pagination,
    exposureCode = '',
    fetchQuoteDetail = false,
  ) => {
    const hasExposureCode = !isEmpty(exposureCode);

    try {
      setState((prevState) => ({
        ...prevState,
        exposures: { ...prevState.exposures, loading: true },
        exposureList: hasExposureCode
          ? {
              ...prevState.exposureList,
              [`${exposureCode}`]: {
                ...prevState.exposureList?.[`${exposureCode}`],
                loading: true,
              },
            }
          : {
              ...prevState.exposureList,
              ...Object.keys(prevState.exposureList ?? {}).reduce(
                (a, curr) => ({
                  ...a,
                  [`${curr}`]: {
                    ...prevState.exposureList?.[`${curr}`],
                    loading: true,
                  },
                }),
                {},
              ),
            },
      }));

      const quoteExposures: IQuoteExposuresProps[] = [];

      const res = await getQuotesExposuresWithLocator(id, {
        ...query,
        page_size: query.page_size ?? initialQuoteDetailData.exposures!.pagination?.page_size,
      });

      const groupedExposures = groupBy(res?.results ?? [], (item) => item.name);

      for (const ph of res?.results!) {
        quoteExposures.push({
          ...ph,
          id: ph.locator!,
        });
      }

      setState((prevState) => ({
        ...prevState,
        exposures: {
          ...prevState.exposures,
          loading: false,
          loaded: true,
          data: quoteExposures,
          pagination: {
            ...query,
            total_count: res?.count,
          },
        },
        exposureList: {
          ...prevState.exposureList,
          ...Object.entries(prevState.exposureList ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupedExposures?.[`${key}`] ?? [],
                pagination: {
                  ...query,
                  total_count: groupedExposures?.[`${key}`]?.length ?? 0,
                },
              },
            }),
            {},
          ),
        },
      }));

      // Modified exposure data will trigger rule engine and rule engine will change quote data
      // So, quote detail data needs to be refetched
      if (fetchQuoteDetail) {
        await fetch(id, { exclude_price: true });
      }

      return quoteExposures;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        exposures: { ...prevState.exposures, loading: false, loaded: true },
        exposureList: hasExposureCode
          ? {
              ...prevState.exposureList,
              [`${exposureCode}`]: { loading: false, loaded: true },
            }
          : {
              ...prevState.exposureList,
              ...Object.keys(prevState.exposureList ?? {}).reduce(
                (a, curr) => ({
                  ...a,
                  [`${curr}`]: {
                    ...prevState.exposureList?.[`${curr}`],
                    loading: false,
                    loaded: true,
                  },
                }),
                {},
              ),
            },
      }));

      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const getExposure = async (id: string, exposureLocator: string): Promise<QuoteExposureDetail> => {
    try {
      setState((prevState) => ({
        ...prevState,
        exposureDetail: { ...prevState.exposureDetail, loading: true },
      }));

      const res = await getQuoteExposureWithLocator(id, exposureLocator);

      setState((prevState) => ({
        ...prevState,
        exposureDetail: {
          ...prevState.exposureDetail,
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res ?? {};
    } catch (error) {
      const e = error as AxiosError;
      setState((prevState) => ({
        ...prevState,
        exposureDetail: { ...prevState.exposureDetail, loading: false, loaded: true },
      }));

      throw e;
    }
  };

  const getInvoices = async (id: string, reference_type: string, query: Pagination) => {
    try {
      setState((prevState) => ({
        ...prevState,
        invoices: { ...prevState.invoices, loading: true },
      }));
      const quoteInvoices: IQuoteInvoicesProps[] = [];

      const res = await getQuotesInvoicesWithLocator(id, reference_type, {
        ...query,
        page_size: initialQuoteDetailData.invoices!.pagination?.page_size,
      });
      res?.results!.map((ph, index) =>
        quoteInvoices.push({
          ...ph,
          id: index.toString(),
        }),
      );

      setState((prevState) => ({
        ...prevState,
        invoices: {
          ...prevState.invoices,
          loading: false,
          loaded: true,
          data: quoteInvoices,
          pagination: {
            ...query,
            total_count: res?.count,
          },
        },
      }));
      return quoteInvoices;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        invoices: { ...prevState.invoices, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const resetQuoteInvoices = (): void => {
    setState((prevState) => ({
      ...prevState,
      invoices: initialQuoteDetailData.invoices,
    }));
  };

  const getQuoteDocuments = async (id: string, query: Pagination) => {
    try {
      setState((prevState) => ({
        ...prevState,
        documents: { ...prevState.documents, loading: true },
      }));

      const res = await getQuotesDcoumentsWithLocator(id, {
        ...query,
        page_size: initialQuoteDetailData.exposures!.pagination?.page_size,
      });

      setState((prevState) => ({
        ...prevState,
        documents: {
          ...prevState.documents,
          loading: false,
          loaded: true,
          data: res?.results,
          pagination: {
            ...query,
            total_count: res?.count,
          },
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        documents: { ...prevState.documents, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const reFetchData = async (reference_type: string, reference_locator: string) => {
    await getQuoteDocuments(reference_locator, { ...initialQuoteDetailData.documents!.pagination });
  };

  const deleteDocument = async (quoteyId: string, documentLocator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        documents: { ...prevState.documents, loading: true },
      }));

      const res = await deleteDocumentService(quoteyId, documentLocator);

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        documents: { ...prevState.documents, loading: false, loaded: true },
      }));
      throw error;
    } finally {
      setState((prevState) => ({
        ...prevState,
        documents: { ...prevState.documents, loading: false, loaded: true },
      }));
    }
  };

  const resetDocumentsState = (): void => {
    setState((prevState) => ({
      ...prevState,
      documents: { ...initialQuoteDetailData.documents },
    }));
  };

  const discardQuote = async (locator: string) => {
    try {
      await discardQuoteRequest(locator);
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const issueQuote = async (locator: string) => {
    try {
      await issueQuoteRequest(locator);
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const getFieldConfig = async (
    productCode: string,
    formset: string,
    formCode?: string,
    query?: Params,
  ) => {
    try {
      if (isEmpty(state?.fields?.[`${formset}`]?.data)) {
        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [`${formset}`]: {
              ...(prevState?.fields![`${formset}`] ?? {}),
              loading: true,
            },
          },
        }));

        if (formCode) {
          const forms = await getWorkFlowPerilFormsWithFormCode({
            product_code: productCode,
            work_flow_name: 'quick-quote',
            config_formset_type: formset,
            form_code: formCode,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: {
                  ...prevState?.fields[`${formset}`]?.data,
                  [`${formCode}`]: forms,
                },
              },
            },
          }));
        } else {
          const forms = await getWorkFlowFormSetsFormsWithFormSetType({
            product_code: productCode,
            work_flow_name: 'quick-quote',
            config_formset_type: formset,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: forms,
              },
            },
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [`${formset}`]: { loading: false, loaded: true },
        },
      }));

      throw error;
    }
  };

  const getUnderwritingQuestions = async (
    productCode: string,
    formset: string,
    formCode?: string,
    query?: Params,
  ) => {
    try {
      if (isEmpty(state?.underwritingQuestions?.[`${formset}`]?.data)) {
        setState((prevState) => ({
          ...prevState,
          underwritingQuestions: {
            ...prevState.underwritingQuestions,
            [`${formset}`]: {
              ...(prevState?.underwritingQuestions![`${formset}`] ?? {}),
              loading: true,
            },
          },
        }));

        if (formCode) {
          const forms = await getWorkFlowPerilFormsWithFormCode({
            product_code: productCode,
            work_flow_name: 'underwriting-questions',
            config_formset_type: formset,
            form_code: formCode,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            underwritingQuestions: {
              ...prevState.underwritingQuestions,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: {
                  ...prevState?.underwritingQuestions[`${formset}`]?.data,
                  [`${formCode}`]: forms,
                },
              },
            },
          }));
        } else {
          const forms = await getWorkFlowFormSetsFormsWithFormSetType({
            product_code: productCode,
            work_flow_name: 'underwriting-questions',
            config_formset_type: formset,
            cache: true,
            modifyResultForUnderwritingQuestions: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            underwritingQuestions: {
              ...prevState.underwritingQuestions,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: forms,
              },
            },
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        underwritingQuestions: {
          ...prevState.underwritingQuestions,
          [`${formset}`]: { loading: false, loaded: true },
        },
      }));

      throw error;
    }
  };

  const getQuotePrice = async (locator: string): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        price: { ...prevState.price, loading: true },
      }));

      const res = await getQuotePriceRequest(locator);

      const exposureGroups = groupByExposureAndSumPremiums(res?.exposure_pricing, 'Policy');

      setState((prevState) => ({
        ...prevState,
        price: {
          loading: false,
          loaded: true,
          data: { ...res, groups: exposureGroups },
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        price: { ...prevState.price, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const resetQuoteExposures = (): void => {
    setState((prevState) => ({
      ...prevState,
      exposures: initialQuoteDetailData.exposures,
    }));
  };

  const resetQuoteDetailState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialQuoteDetailData,
    }));
  };

  const getTasks = async (locator: string, query: ITaskPagination) => {
    try {
      setState((prevState) => ({
        ...prevState,
        tasks: { ...prevState.tasks, loading: true },
      }));

      let res;

      try {
        res = await getQuotesTasksWithLocator(locator, {
          ...query,
          page_size: initialQuoteDetailData.tasks?.pagination?.page_size,
        });
      } catch (error) {
        const e = error as AxiosError;
        if (e.isAxiosError) {
          if (e.response?.status === 404) {
            res = await getQuotesTasksWithLocator(locator, {
              ...query,
              page_size: initialQuoteDetailData.tasks?.pagination?.page_size,
              page: 1,
            });
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        tasks: {
          ...prevState.tasks,
          loading: false,
          loaded: true,
          data: res?.results ?? [],
          pagination: {
            ...query,
            total_count: res?.count,
          },
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        tasks: { ...prevState.tasks, loading: false, loaded: true },
      }));
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.message);
      }
      throw e;
    }
  };

  const resetTasks = (): void => {
    setState((prevState) => ({
      ...prevState,
      tasks: initialQuoteDetailData.tasks,
    }));
  };

  const setUnderwritingQuestionsState = (newState: any): void => {
    setState((prevState) => ({
      ...prevState,
      underwritingQuestionsState: {
        ...newState,
      },
    }));
  };

  const prepareUnderwritingQuestionsForSave = (keyword?: string, overrideData?: any) => {
    const { data } = state;
    let underwritingQuestionsStateValue;
    if (isEmpty(overrideData)) {
      const { underwritingQuestionsState } = state;
      underwritingQuestionsStateValue = underwritingQuestionsState;
    } else if (overrideData) {
      underwritingQuestionsStateValue = overrideData;
    }
    const eligibilityValues = pick(underwritingQuestionsStateValue, [
      'pol_state',
      'pol_tax_keyword',
    ]);
    const questionFields: string[] = [];

    (state.underwritingQuestions?.policy?.data as ProductWorkFlow[])?.forEach((page) =>
      page?.fields?.forEach((f) =>
        (f.nested_fields ?? []).forEach((q) => questionFields.push(q.code!)),
      ),
    );

    // Dictionary for quick access to locator using uwq_question_id
    const questionLocatorDict = Object.fromEntries(
      underwritingQuestionsStateValue?.underwriting_question.map((question) => [
        question.uwq_question_id,
        question.locator,
      ]),
    );

    // TODO: Only get the visible fields, check show conditions.
    const questionValues = pickBy(
      underwritingQuestionsStateValue,
      (value, key) => questionFields.includes(key) && fieldHasValue(value),
    );

    return {
      data: {
        ...(data?.characteristics?.[0]?.data ?? {}),
        ...eligibilityValues,
        underwriting_question: Object.entries(questionValues).map(([key, value]) => ({
          uwq_question_id: key,
          uwq_question_answer: value,
          // Retrieve the locator efficiently from the dictionary in constant time (O(1)) using uwq_question_id as the key
          ...(questionLocatorDict[key] ? { locator: questionLocatorDict[key] } : {}),
        })),
        ...(fieldHasValue(keyword) ? { pol_tax_keyword: keyword } : {}),
      },
      contract_start_time: underwritingQuestionsStateValue?.effective_date,
      contract_end_time: addYearsDstSafe(
        new Date(underwritingQuestionsStateValue?.effective_date!),
        1,
      ) as unknown as string,
    };
  };

  const hasBusinessNameChanged = (oldState: any, newState: any) => {
    const { policyholder } = oldState;
    const oldName = policyholder?.data?.business_name ?? '';

    const newName =
      newState?.underwriting_question?.find(
        (q) => q.uwq_question_id === uwQuestionAliases.businessName,
      )?.uwq_question_answer ?? '';

    return {
      changed: oldName !== newName,
      newName,
      policyholder,
    };
  };

  const saveUnderwritingQuestionsState = async (keyword?: string, overrideData = {}) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = state;

      const preparedUnderwritingQuestionsData = prepareUnderwritingQuestionsForSave(
        keyword,
        overrideData,
      );

      const res = await updateQuoteDetail(
        data?.policy_locator!,
        data?.locator!,
        preparedUnderwritingQuestionsData,
        {},
        hasBusinessNameChanged(data, preparedUnderwritingQuestionsData?.data),
      );

      return res;
    } catch (error) {
      throw error;
    }
  };

  const handleKeywordChange = async (keyword?: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = state;

      const preparedUnderwritingQuestionsData = prepareUnderwritingQuestionsForSave(keyword);

      await updateQuoteInfo(
        data?.policy_locator!,
        data?.locator!,
        preparedUnderwritingQuestionsData,
        {},
        hasBusinessNameChanged(data, preparedUnderwritingQuestionsData?.data),
      );
    } catch (error) {
      throw error;
    }
  };

  const getRuleEngineResults = async (locator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: true },
      }));

      const res = await getRuleEngineDecisions({
        policy_transaction_type: 'quote',
        socotra_locator: locator,
      });

      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: {
          loading: false,
          loaded: true,
          data: res,
        },
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const updateRuleEngineDecisionStatus = async (
    locator: number,
    isUnderwriterApproved: boolean,
    withLoading = true,
  ) => {
    try {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: withLoading },
      }));

      const res = await updateRuleEngineDecision(locator, {
        is_underwriter_approved: isUnderwriterApproved,
      });

      setState((prevState) => {
        const updatedRuleStatus =
          prevState.ruleEngineResults.data?.map((rule) => (rule.id === res.id ? res : rule)) ?? [];

        return {
          ...prevState,
          ruleEngineResults: {
            loading: false,
            loaded: true,
            data: updatedRuleStatus,
          },
        };
      });
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: false, loaded: true },
      }));

      throw error;
    }
  };

  const submitPreQual = async (policyLocator: string, quotelocator: string) => {
    try {
      const { data } = state;
      const payload = prepareUnderwritingQuestionsForSave();

      await updateQuoteInfo(
        policyLocator,
        quotelocator,
        {
          ...payload,
          data: { ...payload?.data, pol_isprequal: 'No' },
        },
        {},
        hasBusinessNameChanged(data, payload?.data),
      );

      setState((prevState) => ({
        ...prevState,
        isPreQual: undefined,
      }));
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e?.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
      throw e;
    }
  };

  const getQuoteDocumentTypes = async () => {
    try {
      let results = state?.documentTypes?.data;

      if (isEmpty(state?.documentTypes?.data)) {
        setState((prevState) => ({
          ...prevState,
          documentTypes: {
            ...prevState.documentTypes,
            loading: true,
          },
        }));

        const res = await getDocumentTypesService(DOCUMENT_TYPES_FROM.QUOTE);
        results = res?.results;

        setState((prevState) => ({
          ...prevState,
          documentTypes: {
            ...prevState.documentTypes,
            loading: false,
            loaded: true,
            data: res?.results,
          },
        }));
      }

      return results! ?? [];
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        documentTypes: {
          ...prevState.documentTypes,
          loading: true,
          loaded: true,
        },
      }));
      throw error;
    }
  };

  const getQuoteProgress = async (quotelocator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        progress: {
          ...prevState.progress,
          loading: true,
        },
      }));

      const res = await getQuoteProgressRequest(quotelocator);

      setState((prevState) => ({
        ...prevState,
        progress: {
          loading: false,
          loaded: true,
          data: res?.['progress-data'] ?? {},
        },
      }));
    } catch (_error) {
      setState((prevState) => ({
        ...prevState,
        progress: {
          ...prevState.progress,
          loading: false,
          loaded: true,
        },
      }));
    }
  };
  // TODO Combine  setQuoteProgress and updateQuoteProgress
  const setQuoteProgress = async (quotelocator: string, body: ProgressData) => {
    try {
      setState((prevState) => ({
        ...prevState,
        progress: {
          ...prevState.progress,
          loading: true,
        },
      }));
      const res = await setQuoteProgressRequest(quotelocator, body);
      setState((prevState) => ({
        ...prevState,
        progress: {
          loading: false,
          loaded: true,
          data: res,
        },
      }));
      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        progress: {
          ...prevState.progress,
          loading: false,
          loaded: true,
        },
      }));

      throw error;
    }
  };

  const updateQuoteProgress = async ({
    locator,
    UWTab,
    currentTab,
    isCompleted,
    isPriceCalculated,
    isClaimsAbsenceConfirmed,
  }: IUpdateQuoteProgress) => {
    try {
      const res = await setQuoteProgress(locator, {
        ...state?.progress?.data,
        [currentTab]: {
          ...state?.progress?.data?.[currentTab],
          is_completed: isCompleted ?? state?.progress?.data?.[currentTab]?.is_completed,
          sub_tab: UWTab,
          is_price_calculated: isPriceCalculated,
          ...(currentTab === submissionDetailInfoTabs.CLAIMS_HISTORY.code && {
            is_claims_absence_affirmed: isClaimsAbsenceConfirmed,
          }),
        },
        operations_uw_results: { parent_tab: submissionDetailInfoTabs.OPERATIONS.code },
      });

      setState((prevState) => ({
        ...prevState,
        progress: {
          loading: false,
          loaded: true,
          data: res,
        },
      }));
    } catch (error) {
      const e = error as IAxiosError;

      if (e.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data?.detail);
      }
    }
  };

  const setHandleNextButtonClicked = async (isHandleNextClicked: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isHandleNextClicked,
    }));
  };

  const setIsCoveragesSummaryPriceCalculated = async (isPriceCalculated: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isCoveragesSummaryPriceCalculated: isPriceCalculated,
    }));
  };

  const updateQuoteProducer = async (locator: string, body: { producer: string }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const res = await updateQuoteProducerRequest(locator, body);

      setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          owner: {
            email: res?.producer?.email || '',
            title: res?.producer?.title || '',
          },
          team: res?.team || '',
          agency: res?.agency || '',
        },
      }));
    } catch (error) {
      throw error;
    }
  };

  return (
    <QuoteDetailContext.Provider
      value={{
        ...state,
        fetch,
        updateQuoteDetail,
        setUnderwritingQuestionsState,
        resetQuoteDetailState,
        saveUnderwritingQuestionsState,
        handleKeywordChange,
        getRuleEngineResults,
        updateRuleEngineDecisionStatus,
        lockQuote,
        referQuote,
        sendBackQuote,
        acceptQuote,
        submitPreQual,
        invalidateQuote,
        discardQuote,
        issueQuote,
        reFetchData,
        getExposures,
        getExposure,
        resetQuoteExposures,
        getFieldConfig,
        getUnderwritingQuestions,
        getQuotePrice,
        getQuoteDocuments,
        deleteDocument,
        getInvoices,
        getTasks,
        resetDocumentsState,
        resetQuoteInvoices,
        resetTasks,
        getQuoteDocumentTypes,
        getQuoteProgress,
        setQuoteProgress,
        setHandleNextButtonClicked,
        updateQuoteProgress,
        setIsCoveragesSummaryPriceCalculated,
        updateQuoteProducer,
      }}
    >
      {children}
    </QuoteDetailContext.Provider>
  );
};
