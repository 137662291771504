import {
  Box,
  Button,
  Link,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import { defaultRowVirtualization, submissionDetailInfoTabs, userRoles } from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import TextBadge from 'components/TextBadge/TextBadge';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle, tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  determineOwnerOfficerPositions,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  redirectToProducerTab,
  updateQueryStrings,
} from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import OwnersMembersDrawer from './Drawers/OwnersMembersDrawer/OwnersMembersDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const OwnersMembers: FC = () => {
  const { t } = useTranslation();
  const themeHook = useTheme();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useLoader();
  const { data: user } = useUser();
  const matches = useMediaQuery(themeHook.breakpoints.down('md'));
  const [selectedID, setSelectedID] = useState<string>('');
  const {
    data: quoteDetail,
    groups,
    loading: quoteLoading,
    loaded: quoteLoaded,
    canEdit,
    progress: { data: progressData },
    updateQuoteProgress,
    underwritingQuestionsState,
  } = useQuoteDetail();

  const entity = quoteDetail?.characteristics?.[0]?.data?.pol_legal_entity ?? '';

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const canSeeNavigationButtons = canEdit && isProducer;
  const isOwnersMembersTabNotCompleted = !(
    submissionDetailInfoTabs.OWNERS_MEMBERS.code in progressData
  );

  const rows: any[] = groups?.pol_oo?.data ?? [];

  const columns: IColumns[] = [
    {
      name: 'location',
      display_name: t('Location'),
      flex: 3,
      type: 'string',
      is_sortable: true,
      minWidth: 360,
    },
    {
      name: 'name',
      display_name: t('Name'),
      flex: 3,
      type: 'string',
      is_sortable: true,
      is_link: true,
      minWidth: 200,
    },
    {
      name: 'is_primary',
      display_name: '',
      flex: 1,
      type: 'string',
      is_sortable: false,
      align: 'center',
      minWidth: 96,
    },
  ];

  const ownersMembersColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.is_link) {
        const { pol_oo_name_first = '', pol_oo_name_last = '' } = row;
        const name = `${pol_oo_name_first} ${pol_oo_name_last}`;

        return name;
      }

      if (field.name === 'location') {
        return row.pol_oo_location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params?.row, field.name);

      if (field.is_link) {
        const name = params.value;

        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { owner_member: params?.row.locator },
            })}`}
            underline="hover"
            sx={[
              truncatedTextStyle,
              {
                color: (theme) => theme.customColors.table.link,
              },
            ]}
            title={name}
          >
            {name?.trim() === '' ? '-' : name}
          </Link>
        );
      } else if (field.name === 'is_primary') {
        const { pol_oo_isprimary } = params?.row;

        return pol_oo_isprimary === 'Yes' ? (
          <TextBadge label={t('Primary')} color="chipPrimary" />
        ) : (
          <></>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(params?.row.pol_oo_location);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      }

      return (
        <Typography sx={truncatedTextStyle} title={fieldValue}>
          {fieldValue || '-'}
        </Typography>
      );
    },
  }));

  const getRowHeight = useCallback(() => (matches ? 'auto' : null), [matches]);

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { add: true },
      }),
    });
  };

  const handleNext = async () => {
    if (
      underwritingQuestionsState &&
      underwritingQuestionsState.pol_legal_entity === 'Partnership' &&
      rows.length < 2
    ) {
      displayToastMessage('ERROR', 'A Partnership must have at least 2 partners listed');
      return;
    }

    if (!rows.length) {
      displayToastMessage('ERROR', 'There must be at least one owner/officer.');
      return;
    }

    if (isOwnersMembersTabNotCompleted) {
      try {
        setLoading(true);

        await updateQuoteProgress({
          locator: id,
          currentTab: submissionDetailInfoTabs.OWNERS_MEMBERS.code,
          isCompleted: Boolean(rows.length),
        });
      } catch (error) {
        displayBackendErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: {
          tab: redirectToProducerTab({
            currentTab: submissionDetailInfoTabs.OWNERS_MEMBERS,
            productType: quoteDetail?.product?.code,
          }),
        },
      }),
    });
  };

  const handlePrevious = async () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.OWNERS_MEMBERS.prevTab },
      }),
    });
  };

  const isQuoteInitialLoading = quoteLoading && !quoteLoaded;

  const entityType = useMemo(() => determineOwnerOfficerPositions(entity as string), [entity]);

  return isQuoteInitialLoading ? (
    <>
      <Box>
        <Skeleton animation="wave" width="30%" height={40} />
        <Skeleton animation="wave" width="70%" height={40} />
        <Skeleton animation="wave" width="60%" height={40} />

        <Box sx={{ maxWidth: '664px', mt: -3, height: 300 }}>
          <Skeleton animation="wave" width="100%" height="100%" />
        </Box>
      </Box>
    </>
  ) : isEmpty(entity) ? (
    <Box>
      <Typography sx={[tabTitleStyles]}>{t('List of Business Owners/Members')}</Typography>

      <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
        {t('Legal entity is empty. You must select entity first. ')}
      </Typography>
    </Box>
  ) : (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <OwnersMembersDrawer isEdit={canEdit} position={entityType.position} />

      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px', mb: 0.5 }}>
          {entityType.title}
        </Typography>

        <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 1.5 }}>
          {entityType.description}
        </Typography>

        <Box sx={{ maxWidth: '664px' }}>
          <DataTable
            onRowClick={(r) => setSelectedID(r.id === selectedID ? '' : (r.id as string))}
            getRowId={(row) => row.locator}
            columns={ownersMembersColumns}
            rows={rows}
            loading={groups?.pol_oo?.loading}
            dynamicRowHeight={matches}
            getRowHeight={getRowHeight}
            pageSize={defaultRowVirtualization}
            columnVisibilityModel={createColumnVisibilityModel(columns)}
            hideFooterPagination={rows?.length! < defaultRowVirtualization}
            focusedRowId={selectedID}
          />
        </Box>

        {canEdit && (
          <Box>
            <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]} data-test="add-partner">
              {t('Add {{entityPosition}}', { entityPosition: entityType.position })}
            </Button>
          </Box>
        )}
      </Box>

      {canSeeNavigationButtons && (
        <NavigationButtons handlePrevious={handlePrevious} handleNext={handleNext} />
      )}
    </Stack>
  );
};

export default OwnersMembers;
