import { Box, Skeleton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Choice, ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import RatingIcon from 'assets/images/RatingIcon.svg';
import {
  acceptedBdgOccupancy,
  defaultRowVirtualization,
  productCodes,
  quotePolicyEndorsementInfoTitlesDescriptions,
  selectionChoices,
  states,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { tabTitleStyles } from 'helpers/MuiSharedStyles';
import {
  checkIfValueHasLetters,
  currencyFormat,
  formatLocation,
  parseLocation,
} from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail/usePolicyDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const SummaryPricing: FC = () => {
  const { t } = useTranslation();
  const {
    loading: policyLoading,
    loaded: policyLoaded,
    data: policyDetail,
    exposureList,
    price,
    getPolicyPrice,
    fields,
    exposures,
  } = usePolicyDetail();

  const visibleCoverageSummaryRows = useMemo(() => {
    const policyFormset = fields.policy?.data as ProductWorkFlow[];
    const coverageSummaryRows = policyFormset?.find((x) => x.code === 'coverage-summary-rows');

    return coverageSummaryRows?.fields?.reduce(
      (acc, curr) => ({ ...acc, [curr.code!]: curr }),
      {},
    ) as { [key: string]: ProductWorkFlow };
  }, [fields.policy?.data]);
  const LOCATION = useLocation();

  const policyState = policyDetail?.characteristics?.data?.pol_state;

  const isProductCodeThreeWithWorkersCompensation =
    policyDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION;

  const isNewBusinessView = LOCATION.pathname.endsWith('/new-business/');

  const isEarthquakeRowVisible =
    visibleCoverageSummaryRows?.earthquake?.additional_data?.enabledStates?.includes(policyState);

  const isWindRowVisible =
    visibleCoverageSummaryRows?.wind?.additional_data?.enabledStates?.includes(policyState);

  const isFloodRowVisible =
    visibleCoverageSummaryRows?.flood?.additional_data?.enabledStates?.includes(policyState);

  const { data: userData } = useUser();

  const isUnderwriter = userData?.role?.code === userRoles.UNDERWRITER.code;

  const [rows, setRows] = useState<any>([]);

  const handleChoicesAndRows = async () => {
    try {
      if (!isEmpty(fields.policy.data) && policyLoaded && exposures?.loaded) {
        // choices and fields
        let pol_eq_excluded_choices: Choice[] = [];
        let pol_eq_deductible_choices: Choice[] = [];

        let buildingFields: any[] = [];
        let vehicleFields: any[] = [];

        let pol_fl_excluded_choices: Choice[] = [];
        let pol_wd_deductible_choices: Choice[] = [];

        const policyCharacteristics = policyDetail?.characteristics?.data;
        await getPolicyPrice(policyDetail?.locator as string, isNewBusinessView);

        // policy fields choices loop
        (fields?.policy?.data as ProductWorkFlow[]).forEach((item) => {
          item.fields?.forEach((field) => {
            if (field.code === 'pol_eq_excluded') {
              pol_eq_excluded_choices = field.choices!;
            }
            if (field.code === 'pol_eq_deductible') {
              pol_eq_deductible_choices = field.choices!;
            }
            if (field.code === 'pol_flood_indicator') {
              pol_fl_excluded_choices = field.choices!;
            }
            if (field.code === 'pol_deductible_wind') {
              pol_wd_deductible_choices = field.choices!;
            }
          });
        });

        exposures?.data?.forEach((exposure, index) => {
          const coveragesParseLocation = parseLocation(exposure?.data?.bdg_location);
          const coveragesLocation = `${formatLocation(coveragesParseLocation).showing.head}${
            formatLocation(coveragesParseLocation).showing.tail
          }`;
          if (
            exposure.name === 'building' &&
            acceptedBdgOccupancy.includes(exposure.data?.bdg_occupancy)
          ) {
            buildingFields = [
              ...buildingFields,
              {
                coverages: `${
                  exposure?.data?.bdg_name ? `${exposure?.data?.bdg_name},` : ''
                } ${coveragesLocation} `,
                id: exposure?.display_id,
                section: index === 0,
                limit: exposure.data?.bdg_tiv_building,
                deductible: exposure.data?.bdg_deductible,
              },
            ];
          }
          if (exposure.name === 'vehicle') {
            vehicleFields = [
              ...vehicleFields,
              {
                coverages: exposure.data?.veh_vin
                  ? `${exposure.data.veh_vin} | ${exposure.data.veh_year ?? ''}, ${
                      exposure.data.veh_make ?? ''
                    }, ${exposure.data.veh_model ?? ''}`
                  : `${exposure.data?.veh_year ?? ''}, ${exposure.data?.veh_make ?? ''}, ${
                      exposure.data?.veh_model ?? ''
                    }`,
                id: exposure?.display_id,
                section: index === 0,
                limit: exposure.data?.veh_limit,
                deductible: exposure.data?.veh_deductible,
              },
            ];
          }
        });

        if (!isEmpty(fields.policy.data)) {
          setRows([
            {
              coverages: 'Business Liability',
              limit: policyCharacteristics?.pol_bl_limit_occurrence,
              deductible: policyCharacteristics?.pol_bl_deductible,
              section: true,
              id: 'business_liability',
              additional: 'Per occurrence',
            },
            {
              coverages: '',
              limit: policyCharacteristics?.pol_bl_limit_aggregate,
              deductible: '',
              section: false,
              id: 'business_liability_extra',
              additional: 'Maximum',
            },
            {
              coverages: 'Cyber Incident Response',
              limit: policyCharacteristics?.pol_cy_limit,
              deductible: policyCharacteristics?.pol_cy_deductible,
              section: false,
              id: 'cyber_incident_response',
            },
            ...(isUnderwriter
              ? [
                  {
                    coverages: 'All Other Property ERC',
                    limit: policyCharacteristics?.pol_op_erc_rule || '',
                    deductible: '',
                    id: 'all_other_property_estimated_replacement_cost',
                    section:
                      exposures?.data?.filter((exposure) => exposure.name === 'building')
                        ?.length === 0,
                  },
                ]
              : []),
            ...(isUnderwriter
              ? [
                  {
                    coverages: 'All Other Property ERC Override',
                    limit: policyCharacteristics?.pol_op_erc_user || '',
                    deductible: '',
                    id: 'all_other_property_estimated_replacement_cost_override',
                    section:
                      exposures?.data?.filter((exposure) => exposure.name === 'building')
                        ?.length === 0,
                  },
                ]
              : []),
            {
              coverages: 'Business Interruption Estimated Limit',
              limit: policyCharacteristics?.pol_bi_erc || '',
              deductible: '',
              id: 'business_interruption_estimated',
            },
            ...buildingFields,
            {
              coverages: 'All Other Property',
              limit: policyCharacteristics?.pol_op_limit,
              deductible: policyCharacteristics?.pol_op_deductible,
              id: 'all_other_property',
              section:
                exposures?.data?.filter((exposure) => exposure.name === 'building')?.length === 0,
            },
            {
              coverages: 'Business Interruption',
              limit: policyCharacteristics?.pol_bi_limit,
              deductible: policyCharacteristics?.pol_bi_waiting_period,
              id: 'business_interruption',
              additional: 'Up to 1 Year',
            },
            ...(isEarthquakeRowVisible
              ? [
                  {
                    coverages: 'Earthquake',
                    limit: policyCharacteristics?.pol_eq_excluded,
                    deductible: policyCharacteristics?.pol_eq_deductible,
                    deductibleChoices: pol_eq_deductible_choices,
                    limitChoices: pol_eq_excluded_choices,
                    id: 'earthquake',
                  },
                ]
              : []),
            ...(isWindRowVisible
              ? [
                  {
                    coverages: 'Wind/Hail Coverage',
                    limit: selectionChoices.INCLUDED,
                    deductible: policyCharacteristics?.pol_deductible_wind || '-',
                    deductibleChoices: pol_wd_deductible_choices,
                    id: 'wind',
                  },
                ]
              : []),
            ...(isFloodRowVisible
              ? [
                  {
                    coverages: 'Flood Coverage',
                    limit: policyCharacteristics?.pol_flood_indicator || '-',
                    limitChoices: pol_fl_excluded_choices,
                    deductible:
                      policyCharacteristics?.pol_flood_indicator === selectionChoices.NO
                        ? ''
                        : selectionChoices.FLAT,
                    id: 'flood',
                  },
                ]
              : []),
            ...(!isEmpty(vehicleFields)
              ? [
                  {
                    coverages: 'Auto Liability',
                    limit: policyCharacteristics?.pol_al_limit,
                    deductible: policyCharacteristics?.pol_al_deductible,
                    section: true,
                    id: 'auto_liability',
                  },
                ]
              : []),
            ...vehicleFields,
            ...(isProductCodeThreeWithWorkersCompensation
              ? [
                  {
                    coverages: 'Workers Compensation',
                    limit: 'As required by law',
                    deductible: policyState === states.CA ? 'None' : '0',
                    section: true,
                    id: 'workers_compensation',
                  },
                ]
              : []),
          ]);
        }
      }
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while recalculating.'));
    }
  };

  useEffect(() => {
    handleChoicesAndRows();
  }, [fields.policy.data, policyLoaded, exposures?.loaded]);

  const allLoading =
    policyLoading ||
    exposureList?.building.loading ||
    exposureList?.employer.loading ||
    exposureList?.vehicle.loading ||
    fields.policy.loading ||
    price?.loading;

  const handleBusinessInterruptionValue = (value) => {
    if (value) {
      switch (Number(value)) {
        case 0:
          return value;
        case 1:
          return `${value} day`;
        default:
          return `${value} days`;
      }
    }
    return '-';
  };

  const columns = [
    {
      field: 'coverages',
      headerName: t('Coverages'),
      flex: 2,
      minWidth: 280,
      type: 'string',
      sortable: true,
      renderCell(params) {
        return (
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={params.row.coverages}
          >
            {params.row.coverages}
          </Typography>
        );
      },
    },
    {
      field: 'additional',
      headerName: '',
      flex: 2,
      minWidth: 120,
      type: 'string',
      sortable: true,
      align: 'right',
    },
    {
      field: 'limit',
      headerName: t('Limit'),
      flex: 2,
      minWidth: 170,
      type: 'string',
      sortable: true,
      editable: true,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) =>
        params.row.limitChoices
          ? params.row.limitChoices.find((choice) => choice.code === params.row.limit)?.name
          : params.row.limit
          ? !checkIfValueHasLetters(params.row.limit)
            ? currencyFormat('USD', params.row.limit).merged.slice(0, -3)
            : params.row.limit
          : '-',
    },
    {
      field: 'deductible',
      headerName: t('Deductible'),
      flex: 1,
      minWidth: 170,
      type: 'string',
      sortable: true,
      align: 'right',
      editable: true,
      headerAlign: 'right',
      renderCell: (params) =>
        [
          'business_liability_extra',
          'all_other_property_estimated_replacement_cost',
          'all_other_property_estimated_replacement_cost_override',
          'business_interruption_estimated',
        ].includes(params.id as string)
          ? ''
          : params.id === 'flood'
          ? params.row.deductible
            ? params.row.deductible
            : ''
          : params.id === 'business_interruption'
          ? handleBusinessInterruptionValue(params.row.deductible)
          : params.row.deductibleChoices
          ? params.row.deductibleChoices.find((choice) => choice.code === params.row.deductible)
              ?.name
          : params.row.deductible
          ? !checkIfValueHasLetters(params.row.deductible)
            ? currencyFormat('USD', params.row.deductible).merged.slice(0, -3)
            : params.row.deductible
          : '-',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box sx={{ minWidth: 800, mr: 3, mb: 3 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.SUMMARY_PRICING.title()}
        </Typography>
        <Box
          sx={{
            '& .border-top': {
              borderTop: '1px solid black',
            },
          }}
        >
          <DataTable
            loading={allLoading}
            getRowId={(row) => row.id}
            autoRowCellHeight
            getRowClassName={(params) => params.row.section && 'border-top'}
            columns={columns as GridColDef[]}
            rows={rows}
            pageSize={defaultRowVirtualization}
            hideFooter={rows.length <= defaultRowVirtualization}
            hideFooterPagination={rows.length <= defaultRowVirtualization}
            isCellEditable={() => false}
          />
        </Box>
      </Box>

      <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            boxShadow: (theme) => theme.customShadows.shadow15,
            background: (theme) => theme.customColors.grey1150,
            borderRadius: 2,
            py: 2,
            px: 3,
            color: (theme) => theme.customColors.gunMetal,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 600,
              }}
            >
              {t('Estimated Premium')}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '36px',
                minWidth: 203,
              }}
            >
              {allLoading ? (
                <Skeleton animation="wave" width="100%" height={35} />
              ) : (
                `${currencyFormat('USD', price?.data?.total!).merged}`
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: (theme) => theme.customShadows.shadow15,
            background: (theme) => theme.customColors.grey1150,
            borderRadius: 2,
            py: 2,
            px: 3,
            color: (theme) => theme.customColors.gunMetal,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '18px',
              fontWeight: 600,
            }}
          >
            {t('Pay Plan')}
          </Typography>
          {allLoading ? (
            <Skeleton animation="wave" width="100%" height={35} />
          ) : (
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '18px',
                textTransform: 'capitalize',
              }}
            >
              {policyDetail?.payment_schedule?.value}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            boxShadow: (theme) => theme.customShadows.shadow15,
            background: (theme) => theme.customColors.grey1150,
            borderRadius: 2,
            py: 2,
            px: 3,
            color: (theme) => theme.customColors.gunMetal,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '18px',
            }}
          >
            {t('Policy Issued by')}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '18px',
              fontWeight: 600,
            }}
          >
            {t('Berkshire Hathaway Direct')}
            <br />
            {t('Insurance Company')}
          </Typography>
          <Box sx={{ display: 'flex', pt: 1.25, alignItems: 'center' }}>
            <ReactSVG src={RatingIcon} />
            <Box sx={{ pl: 2 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: (theme) => theme.customColors.orange150,
                }}
                component="span"
              >
                {`${t('A++')} `}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
              >
                {t('AM Best Rating')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryPricing;
