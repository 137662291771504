import ApiService, { TokenRequest } from 'api/helpers/Sender';
import { ISSOChangePassword } from 'api/models/Accounts/ISSOChangePassword.model';

/// types
import { Auth } from 'api/models/Auth/auth.model';
import axios from 'axios';

const API = new ApiService();

export const getTokenReq = async (body: TokenRequest): Promise<Auth> => {
  const { data } = await API.getToken(body);
  return data;
};

export interface IPassword {
  old_password: string;
  new_password: string;
}
export const logOut = async () => {
  const res = await API.post('auth/logout');
  return res;
};
export const SSOChangePassword = async (domain: string, payload: ISSOChangePassword) => {
  const res = await axios.post(`https://${domain}/dbconnections/change_password`, payload);
  return res;
};
