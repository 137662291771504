import { Grid, Typography } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { vehiclesUwQuestionCode } from 'common/constants';
import QuestionEngineFieldParser from 'components/QuestionEngineFieldParser';
import { useFormik } from 'formik';
import { fieldHasValue } from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { omit, pickBy } from 'lodash-es';
import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface IProps {
  isReadOnly: boolean;
  initialData: any;
}

const VehiclesUnderwritingQuestions = forwardRef<any, IProps>(
  ({ isReadOnly = false, initialData = {} }, ref) => {
    const { t } = useTranslation();
    const questionEngineRef = createRef<{ renderedQuestions: () => any[] }>();
    const [renderSectionTitle, setRenderSectionTitle] = useState(true);
    const [state, setState] = useState({ ...initialData });

    const { underwritingQuestions } = usePolicyDetail();

    const vehicleUwQuestions = useMemo(
      () =>
        (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find(
          (f) => f.code === vehiclesUwQuestionCode,
        )?.fields?.[0] ?? {},
      [underwritingQuestions],
    );

    const isFormsLoading = underwritingQuestions.policy?.loading ?? true;

    const commonValidations = {
      field: yup.string().required(t('This field cannot be left blank.')),
    };

    const formik = useFormik({
      initialValues: {
        ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
        ...state,
      },
      validationSchema: yup.object({ ...commonValidations }),
      onSubmit: async (_values) => {},
    });

    useImperativeHandle(ref, () => ({
      underwritingQuestionAnswers: () => {
        const questionKeys = (vehicleUwQuestions?.nested_fields as any[])?.map((f) => f.code) ?? [];

        return pickBy(state, (value, key) => questionKeys.includes(key) && fieldHasValue(value));
      },
    }));

    useEffect(() => {
      setRenderSectionTitle((questionEngineRef?.current?.renderedQuestions()?.length ?? 0) > 0);
    }, [questionEngineRef.current]);

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        ...omit(
          initialData,
          (vehicleUwQuestions?.nested_fields as any[])?.map((f) => f.code) ?? [],
        ),
      }));
      setRenderSectionTitle((questionEngineRef?.current?.renderedQuestions()?.length ?? 0) > 0);
    }, [initialData]);

    return (
      <>
        <Grid item key={`${vehicleUwQuestions?.code}_header`} xs={2} sx={[{ mt: 2, mb: -2 }]}>
          <Typography
            sx={{
              mb: isReadOnly ? 1.5 : 1,
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: 1.5,
              color: (theme) => theme.customColors.black,
            }}
          >
            {renderSectionTitle ? vehicleUwQuestions?.name : ''}
          </Typography>
        </Grid>

        <QuestionEngineFieldParser
          ref={questionEngineRef}
          formik={formik}
          state={state}
          setState={setState}
          fields={(vehicleUwQuestions?.nested_fields as DynamicField[]) ?? []}
          showLoader={isFormsLoading}
          isReadOnly={isReadOnly}
          relationalFields={vehicleUwQuestions.relatedFields}
        />
      </>
    );
  },
);

export default VehiclesUnderwritingQuestions;
