import { userRoles } from 'common/constants';
import useUser from 'hooks/useUser/useUser';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ExpModeDetailDrawer from './Detail';
import ExpModeEditDrawer from './Edit';

export interface IWcOwnersMembersDrawer {
  isEdit: boolean;
}

const ExpModeDrawer: FC<IWcOwnersMembersDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isOwnersMembersDrawerOpen, setIsOwnersMembersDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    if (url?.tab === 'wc_exposures' && url?.exp_mod) {
      setIsOwnersMembersDrawerOpen(true);
    } else if (url?.tab === 'wc_exposures') {
      setIsOwnersMembersDrawerOpen(false);
    }

    if (!isUnderwriter) {
      setIsOwnersMembersDrawerOpen(false);
    }
  }, [LOCATION, isUnderwriter]);

  return isOwnersMembersDrawerOpen ? (
    isEdit ? (
      <ExpModeEditDrawer
        isDrawerOpen={isOwnersMembersDrawerOpen}
        setIsDrawerOpen={setIsOwnersMembersDrawerOpen}
      />
    ) : (
      <ExpModeDetailDrawer
        isDrawerOpen={isOwnersMembersDrawerOpen}
        setIsDrawerOpen={setIsOwnersMembersDrawerOpen}
      />
    )
  ) : (
    <></>
  );
};
export default ExpModeDrawer;
